import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { EmailsLocalStorageData, LocalStorageService } from "@/utils/storage/localstorage-service";
import { cn } from "@/utils/ui.util";
import { ArrowUpRight } from "lucide-react";
import React from "react";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import { BoomBox } from "../components/boom-box";
import { KeyFeatures } from "../components/key-features";
import { LayeredTitle } from "../components/layered-title";
import { LandingSection } from "./landing-section";

export const EmailsHome: React.FC = () => {
  return <LandingPageWrapper head={<EmailsHomeHead />} children={<EmailsHomeContent />} />;
};

export const EmailsHomeHead: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);

  const navigate = useNavigate();

  const emailMutation = ApiMutation.useCreateEmail({
    onSuccess: (data) => {
      LocalStorageService.store<EmailsLocalStorageData>("emails", {
        ...data,
        created: new Date().toISOString(),
      });
      navigate(`/emails/${data.id}`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="container px-4 sm:px-8 pt-8 2xl:pt-16">
      <div className="flex flex-col gap-8 md:flex-row items-center">
        <div className="flex flex-col gap-6 w-full">
          <div className="space-y-4">
            <div className="flex mb-12">
              <LayeredTitle
                title="Emails"
                className="text-6xl lg:text-8xl/none font-black font-[system-ui] tracking-wide"
              />
            </div>
            <p className="mx-auto text-muted-foreground md:text-xl">
              Easily generate temporary email addresses to test sign-ups, notifications, and workflows—without the
              hassle of managing cluttered inboxes.
            </p>
          </div>

          <div className="flex gap-2">
            <Button
              className="bg-primary-300 flex gap-2"
              disabled={emailMutation.isPending}
              onClick={emailMutation.mutate}
            >
              Create Email Address {emailMutation.isPending && <LoadingSpinner size={18} />}
            </Button>

            <Button asChild id="get-started-button">
              <Link to="/emails" className="no-underline">
                <div className="flex items-center gap-1">
                  Get Started
                  <ArrowUpRight size={20} />
                </div>
              </Link>
            </Button>
          </div>
        </div>

        <div className={cn("relative block w-full overflow-hidden", loaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/03.webm"}
            width="100%"
            height="100%"
            muted={true}
            playing={true}
            loop={true}
            onReady={() => setLoaded(true)}
          />
        </div>
      </div>
    </div>
  );
};

export const EmailsHomeContent: React.FC = () => {
  const [quickPreviewLoaded, setQuickPreviewLoaded] = React.useState(false);

  return (
    <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
      <LandingSection className="bg-white flex flex-col gap-y-8 lg:flex-row lg:gap-24">
        <div>
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Overview</h2>
          <div className="text-muted-foreground md:text-xl text-justify">
            Our <b>Emails Tool</b> is designed to take the pain out of email testing. Whether you’re checking the
            formatting of a welcome email, validating notifications, or verifying sign-up flows, our tool lets you
            quickly spin up
            <b>temporary email addresses</b> and track incoming messages in <b>real-time</b>. No more waiting,
            refreshing, or messing around with disposable email services—everything is built in, so you can focus on
            getting your emails right.
          </div>
        </div>

        <KeyFeatures
          className="whitespace-nowrap row-span-2 self-center"
          features={[
            "Instant Email Generation",
            "Real-Time Email Tracking",
            "Full Message Inspection",
            "Local Email Forwarding",
            "Automation with API",
          ]}
        />
      </LandingSection>

      <LandingSection className="bg-secondary-200">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">
          Why you should use our Emails Tool?
        </h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Testing emails can be a headache—clogged inboxes, missed messages, and tons of back-and-forth. Our tool is
          designed to simplify this process, giving you <b>full visibility</b> and <b>control</b> over every email you
          test, from the moment it’s sent to the moment it’s received.
        </p>

        <div className="grid grid-cols-2 gap-6">
          <BoomBox
            title="Instant Email Creation"
            boom="Generate temporary email addresses in seconds, ready for testing."
            description={
              <>
                No need to sign up for external services or clutter your personal inbox with test messages. With our
                tool, you can instantly generate as many email addresses as you need, perfect for testing sign-up flows,
                password resets, or notifications.
              </>
            }
          />
          <BoomBox
            title="Live Updates on Incoming Emails"
            boom="See emails the moment they’re sent, without any delay or refreshing."
            description={
              <>
                Stop waiting for test emails to show up. Our real-time monitoring lets you see every email as it comes
                in, complete with full content, headers, and attachments. You’ll know immediately if something’s broken
                or missing.
              </>
            }
          />
          <BoomBox
            title="Detailed Message Breakdown"
            boom="Inspect every detail of your email to make sure nothing is out of place."
            description={
              <>
                Whether you’re looking for hidden issues in the headers or inspecting attachments, our tool breaks down
                each message to give you complete visibility. From formatting checks to SPF and DKIM records, you can
                ensure every aspect of your emails is functioning as expected.
              </>
            }
          />
          <BoomBox
            title="Forward Emails Locally"
            boom="Debug emails directly in your local development environment for deeper analysis."
            description={
              <>
                For those who need extra control, you can forward test emails directly to your local environment. This
                gives you a true-to-life debugging experience, making sure your application is properly handling email
                receipts and content parsing in real-world conditions.
              </>
            }
          />
          <BoomBox
            title="Automate Your Email Tests"
            boom="Set up automated email tests through our API, saving time and catching issues early."
            description={
              <>
                With our API, you can integrate email testing directly into your CI/CD workflow, ensuring that every
                email sent from your application is working as expected. Automate the process and catch issues in
                real-time, before they become user-facing problems.
              </>
            }
          />
          <BoomBox
            title="No More Inbox Clutter"
            boom="Keep your personal inbox clean—use temporary emails for all your testing needs."
            description={
              <>
                Cluttering your personal or company inbox with test emails is a thing of the past. Generate as many
                temporary emails as you need for each project, and clean them up when you're done. It’s the simplest way
                to manage email testing without the mess.
              </>
            }
          />
        </div>
      </LandingSection>

      <LandingSection className="bg-white">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">Quick Preview</h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Here’s a quick look at how our tool works in action, helping you test and debug emails effortlessly.
        </p>

        <div className={cn("overflow-hidden", quickPreviewLoaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/03.webm"}
            width="100%"
            height="100%"
            controls={true}
            muted={true}
            onReady={() => setQuickPreviewLoaded(true)}
          />
        </div>
      </LandingSection>
    </div>
  );
};
