import { SnakeContext, State, TransformationTag } from "./state";
import { InvalidState } from "./states";
import { ToolsIcons } from "./transformations/common-options/options.transformations";

export enum CanTransformResult {
  No = 0.0,
  Maybe = 0.5,
  Likely = 0.75,
  Yes = 1.0,
  Recommended = 1.5,
}

export type TransformationId =
  | "none"
  | "encode"
  | "string-list-utils"
  | "basic-http-auth"
  | "to-crypto-keys"
  | "to-color"
  | "to-date"
  | "image-exif"
  | "to-ip"
  | "to-json"
  | "to-toml"
  | "to-yaml"
  | "to-jwt"
  | "to-number"
  | "string-utils"
  | "to-string"
  | "json-to-typescript"
  | "to-url"
  | "to-uuid"
  | "to-xml"
  | "unzip"
  | "extract-request"
  | "extract-response"
  | "extract-multipart"
  | "extract-email-mime"
  | "extract-jwt"
  | "autodetect"
  | "string-hmac"
  | "from-base64"
  | "smart-compare"
  | "extract-header"
  | "string-to-hash"
  | "mainipulate-date"
  | "generate-jwt"
  | "to-email-mime";

export type OptionsChange<T = object> = (options: T) => void;

export abstract class StateTransformation<
  TState extends State = State,
  TOptions extends object | undefined = undefined,
> {
  constructor(
    readonly id: TransformationId,
    readonly name: string,
    readonly tags: TransformationTag[] = []
  ) {}

  abstract canTransform(state: State): Promise<CanTransformResult>;

  icon(): ToolsIcons {
    return null;
  }

  abstract transform(state: State, options?: TOptions, snakeContext?: SnakeContext): Promise<TState | InvalidState>;
  async getOptions(_state: State, _options?: TOptions, _snakeContext?: SnakeContext): Promise<TOptions | undefined> {
    return undefined;
  }

  getOptionsReactNode(_options: TOptions, _onChange: OptionsChange<TOptions>): React.ReactNode {
    return undefined;
  }
}
