import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { MultipartState, StringState } from "../states";
import { BlobState } from "../states/blob.state";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type ExtractMulti = {
  selected: string;
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class ExtractFromMultipartTransformation extends StateTransformation<State, ExtractMulti> {
  constructor() {
    super("extract-multipart", "Extract", ["multi-part"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (MultipartState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: ExtractMulti): Promise<State> {
    if (MultipartState.is(state)) {
      const part = state.getPart(Number(options.selected));
      if (typeof part === "string") {
        return new StringState(part);
      }
      return new BlobState(part, { mime: part.type });
    }

    throw new Error("Not Multipart Blob");
  }

  async getOptions(state: MultipartState, options?: ExtractMulti): Promise<ExtractMulti | undefined> {
    if (!MultipartState.is(state)) return undefined;
    return {
      selected: options?.selected || "0",
      values: Object.fromEntries(
        state.parts.map((part, i) => [i, `${(part.filename ?? part.name) + (part.type ? " - " + part.type : "")}`])
      ),
      label: "extract from",
    };
  }

  getOptionsReactNode(options: ExtractMulti, onChange: (options: ExtractMulti) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange} options={options} />;
  }
}

export default new ExtractFromMultipartTransformation();
