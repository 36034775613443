import { WorkflowRunJobs } from "@/api/types/response";
import { intervalToDuration } from "date-fns";
import React from "react";

export const useWorkflowTimings = (run: WorkflowRunJobs, now: Date) => {
  const workflowStart = React.useMemo(() => {
    const startTimes = run.jobs
      .map((job) => job.started_at)
      .filter((time) => time !== null)
      .map((time) => new Date(time!).getTime());

    return startTimes.length > 0 ? new Date(Math.min(...startTimes)) : null;
  }, [run]);

  const workflowEnd = React.useMemo(() => {
    const endTimes = run.jobs.map((job) => (job.completed_at ? new Date(job.completed_at).getTime() : now.getTime()));

    return endTimes.length > 0 ? new Date(Math.max(...endTimes)) : null;
  }, [run, now]);

  const workflowDuration = React.useMemo(() => {
    return workflowStart && workflowEnd ? (workflowEnd.getTime() - workflowStart.getTime()) / 1000 : null;
  }, [workflowStart, workflowEnd]);

  const workflowDurationFormatted = React.useMemo(() => {
    if (!workflowDuration) {
      return "";
    }

    const duration = intervalToDuration({
      start: 0,
      end: workflowDuration * 1000,
    });

    return `${duration.hours ? `${duration.hours}h ` : ""}${duration.minutes ? `${duration.minutes}m ` : ""}${duration.seconds ? `${duration.seconds}s` : ""}`;
  }, [workflowDuration]);

  return { workflowStart, workflowEnd, workflowDuration, workflowDurationFormatted };
};
