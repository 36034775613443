import { QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type ApiRequest<TResponse, TVariables = void | Record<never, never> | undefined, TError = unknown> = (
  variables: TVariables,
  error?: TError
) => Promise<AxiosResponse<TResponse>>;

export type ApiFn<TRequest, TVariables = void | Record<never, never> | undefined, TError = unknown> = ApiRequest<
  TRequest,
  TVariables,
  TError
>;

export const createQuery = <TError = unknown, TData = unknown, TVariables = unknown>(
  queryKey: string | QueryKey,
  request: ApiRequest<TData, TVariables, TError>
) => {
  return (variables: TVariables, options?: Omit<UseQueryOptions<TData, TError, TData>, "queryKey">) => {
    return useQuery<TData, TError, TData>({
      queryKey: [queryKey, variables],
      queryFn: async () => await Promise.resolve(request(variables)).then((response) => response.data),
      ...options,
    });
  };
};

export const createMutation = <TError = unknown, TContext = unknown, TData = unknown, TVariables = unknown>(
  queryKey: string | QueryKey,
  request: ApiRequest<TData, TVariables, TError>
) => {
  return (options?: UseMutationOptions<TData, TError, TVariables, TContext>) => {
    return useMutation<TData, TError, TVariables, TContext>({
      mutationKey: typeof queryKey === "string" ? [queryKey] : queryKey,
      mutationFn: async (variables) => await Promise.resolve(request(variables)).then((response) => response.data),
      ...options,
    });
  };
};
