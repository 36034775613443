import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import classes from "./button.module.scss";
import { cn } from "@/utils/ui.util";

const buttonVariants = cva(classes["button"], {
  variants: {
    variant: {
      neu: classes["variant-neu"],
      "neu-tall": classes["variant-neu-tall"],
      "neu-flat": classes["variant-neu-flat"],
      ghost: classes["variant-ghost"],
    },
    scheme: {
      default: classes["scheme-default"],
      black: classes["scheme-black"],
      "white-black": classes["scheme-white-black"],
      ghost: classes["scheme-ghost"],
      link: classes["scheme-link"],
    },
    size: {
      default: "h-10 px-4 py-2 rounded-lg",
      sm: "h-9 px-3 rounded-lg text-sm",
      lg: "h-11 px-8 rounded-lg",
      icon: "h-10 w-10 rounded-lg [&>svg]:h-[24px] [&>img]:h-[24px]",
    },
  },
  defaultVariants: {
    variant: "neu",
    scheme: "default",
    size: "default",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, scheme, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, scheme, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

export { Button };
