import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { JwtValue, parseJwt } from "../utils/parse-jwt";
import { ExpiresInfo, StateExpires } from "../state.expires";
import stringUtils from "../utils/string-utils";
import { InvalidState } from "./invalid.state";

export class JwtState extends State<JwtValue> implements StateExpires {
  constructor(value: JwtValue) {
    super(value);
  }

  static fromString(value: string): State {
    try {
      const jwt = parseJwt(value);
      return new JwtState(jwt);
    } catch (e) {
      return new InvalidState("Not a valid JWT token");
    }
  }

  stringValue(): string {
    return (
      stringUtils.base64UrlEncode(JSON.stringify(this.value.header)) +
      "." +
      stringUtils.base64UrlEncode(JSON.stringify(this.value.payload)) +
      "." +
      this.value.signature
    );
  }
  config(): StateConfig {
    return {
      transformations: "tags-only",
      transformationTags: ["jwt"],
      validations: "all",
      validationTags: ["jwt", "expires"],
      stringValue: true,
      jsonValue: true,
      customDisplay: ["JWT", "Payload"],
      defaultDisplayMode: "JWT",
    };
  }

  getExpiresInfo(): ExpiresInfo {
    return {
      validFrom: this.value?.payload?.nbf ? new Date(this.value?.payload?.nbf * 1000) : undefined,
      validTo: this.value?.payload?.exp ? new Date(this.value?.payload?.exp * 1000) : undefined,
      resourceName: "JWT",
      expiresRequired: false,
    };
  }

  jsonValue(): object {
    return {
      header: this.value.header,
      payload: this.value.payload,
      signature: this.value.signature,
    };
  }

  toReactNode(customType: string): ReactNode {
    if (customType === "JWT")
      return (
        <>
          <div className="text-xs bg-secondary-200">header</div>
          <pre>
            <code className="">{JSON.stringify(this.value.header, null, 2)}</code>
          </pre>
          <div className="text-xs bg-primary-200">payload</div>
          <pre>
            <code className="">{JSON.stringify(this.value.payload, null, 2)}</code>
          </pre>
          <div className="text-xs bg-secondary-100">signature</div>
          <pre>
            <code className="select-all">{this.value.signature}</code>
          </pre>
        </>
      );

    if (customType === "Payload")
      return (
        <>
          <pre>
            <code>{JSON.stringify(this.value.payload, null, 2)}</code>
          </pre>
        </>
      );
  }
}
