import React from "react";

import "@xyflow/react/dist/style.css";
import { UpdatePipelineEvent, WorkflowRunJobs } from "@/api/types/response";
import useWebSocket from "react-use-websocket";
import { useQueryClient } from "@tanstack/react-query";

const socketUrl = `${import.meta.env.VITE_WEBSOCKET_URL}`;

export type WorkflowData = {
  title: string;
  owner: string;
  repo: string;
  workflowId: string;
  htmlUrl: string;
  workflowResourceJwt: string;
};

interface DynamicContentWrapperProps {
  run: WorkflowRunJobs;
  dependencies: Record<string, string[]>;
  latestRunResourceJwt: string;
  workflowData: WorkflowData;
  children: (props: {
    run: WorkflowRunJobs;
    dependencies: Record<string, string[]>;
    workflowData: WorkflowData;
  }) => React.ReactNode;
}

export const DynamicContentWrapper: React.FC<DynamicContentWrapperProps> = ({
  run,
  dependencies,
  latestRunResourceJwt,
  workflowData,
  children,
}) => {
  const queryClient = useQueryClient();

  const { lastMessage } = useWebSocket(socketUrl, {
    queryParams: { id: workflowData.workflowId, token: latestRunResourceJwt },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  React.useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data) as UpdatePipelineEvent;

      if (message.type === "workflow_job") {
        queryClient.invalidateQueries({
          queryKey: [
            ["workflows", "latest", "jobs"],
            { owner: workflowData.owner, repo: workflowData.repo, workflowId: workflowData.workflowId },
          ],
        });
      }
    }
  }, [lastMessage, queryClient, workflowData]);

  return <>{children({ run, dependencies, workflowData })}</>;
};
