import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Badge, BadgeVariant } from "./ui/badge";
import { cn } from "@/utils/ui.util";
import React from "react";

interface TooltipBadge {
  title: string;
  message: string;
  icon?: React.ReactNode;
  variant?: BadgeVariant;
  className?: string;
  delayDuration?: number;
  iconLocation?: "front" | "back";
}

export const TooltipBadge: React.FC<TooltipBadge> = ({
  title,
  message,
  icon,
  variant = "secondary",
  className,
  delayDuration = 200,
  iconLocation = "back",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip open={isOpen}>
        <TooltipTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleToggle}>
          <Badge variant={variant} className={cn("hover:cursor-default", className)}>
            <div className="flex gap-2 items-center">
              {iconLocation === "front" && icon}
              <p className="text-xs font-semibold">{title}</p>
              {iconLocation === "back" && icon}
            </div>
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <Badge variant="outline" className="bg-white max-w-[256px]">
            <p className="text-black text-xs font-semibold">{message}</p>
          </Badge>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
