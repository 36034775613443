import { Webhook } from "@/lib/external/webhooks/standard-webhook";
import { RequestState } from "..";
import type { SnakeContext, State } from "../state";
import { StateValidator, ValidationResult } from "../state-validator";

class RequestJsonValidator extends StateValidator {
  findStandardWebhook(
    bodyString: string,
    headers: any,
    secrets: SnakeContext["secrets"]
  ): [Webhook, string | null | undefined] {
    if (!secrets) return [new Webhook("", { format: "raw" }), undefined];
    for (const secret of secrets) {
      const stdWebhook = new Webhook(secret.value, { format: "raw" });
      const stdWebhookResult = stdWebhook.verify(bodyString, headers);
      if (stdWebhookResult.signature === true) {
        return [stdWebhook, secret.key];
      }
    }
    return [new Webhook("", { format: "raw" }), null];
  }

  checkStandardWebhook(bodyString: string, headers: any, result: ValidationResult[], snakeContext: SnakeContext) {
    const [stdWebhook, secretKey] = this.findStandardWebhook(bodyString, headers, snakeContext.secrets);
    if (secretKey === undefined) {
      result.push(
        this.createQueryResult(
          "No secrets",
          "Webhook signature will be validated once secrets are provided",
          "std-webhook-key"
        )
      );
    }

    const stdWebhookResult = stdWebhook.verify(bodyString, headers);

    if (stdWebhookResult.structure) {
      result.push(this.createSuccessResult("Std webhook", "This request confirmed to Standard Webhook format"));
    }
    if (stdWebhookResult.timestamp === "invalid_timestamp_header") {
      result.push(this.createFailureResult("Std webhook", "Invalid timestamp header"));
    } else if (stdWebhookResult.timestamp === "timestamp_too_old") {
      result.push(this.createFailureResult("Std webhook", "Timestamp is too old"));
    } else if (stdWebhookResult.timestamp === "timestamp_too_new") {
      result.push(this.createFailureResult("Std webhook", "Timestamp is too new"));
    } else {
      result.push(this.createSuccessResult("Std webhook timestamp", "Timestamp is valid"));
    }

    if (secretKey === null) {
      result.push(this.createFailureResult("Webhook signature", "No valid secrets found in secrets"));
      result.push(
        this.createQueryResult(
          "Invalid signature",
          "Once provided in secrets  standard webhook signature can be verified",
          "std-webhook-key"
        )
      );
    } else if (secretKey) {
      result.push(
        this.createSuccessResult("Std webhook signature", `Signature is valid with secret key "${secretKey}"`)
      );
    }
  }

  public async validate(state: State, context: SnakeContext) {
    if (RequestState.is(state)) {
      const isJsonContentType = state.getContentType() === "json";
      try {
        const bodyString = state.getBodyAsString();

        const isJsonContentType = state.getContentType() === "json";

        if (!bodyString) {
          if (isJsonContentType) return [this.createFailureResult("JSON body", "Request body is empty")];
          else return [];
        }

        JSON.parse(bodyString);

        if (!isJsonContentType)
          return [this.createFailureResult("body JSON", "Request body is JSON but content type is not")];

        const result: ValidationResult[] = [this.createSuccessResult("JSON body", "This is a valid JSON document")];
        try {
          this.checkStandardWebhook(bodyString, state.value.headers, result, context);
        } catch (e: unknown) {
          result.push(this.createFailureResult("Std webhook", String(e)));
        }
        return result;
      } catch (e) {
        if (isJsonContentType) return [this.createFailureResult("JSON body", `failed to parse JSON:${e}`)];
        else return [];
      }
    }
    return [];
  }
}

export default new RequestJsonValidator();
