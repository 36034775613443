import React from "react";
import classes from "./StatePreview.module.scss";
import { SnakeContext, State } from "./state";
import { getValidations } from "./state-utils";
import { useDisplayMode } from "./useDisplayMode";
import { ValidationResult } from "./state-validator";
import { ValidationsPopover } from "@/lib/tools/validations/validations-popover";

interface StatePreviewProps {
  state: State;
  snakeContext: SnakeContext;
}

export const StatePreview: React.FC<StatePreviewProps> = ({ state, snakeContext }) => {
  const [validations, setValidations] = React.useState<ValidationResult[]>([]);

  React.useEffect(() => {
    async function asyncCalcualteValidations() {
      setValidations(await getValidations(state, snakeContext));
    }
    asyncCalcualteValidations();
  }, [snakeContext, state]);

  const { displayMode, setDisplayMode, displayModeOptions, stateRender } = useDisplayMode(state);

  return (
    <div className="w-full">
      {/* Display Mode */}
      <div className="flex items-end gap-4 justify-between">
        <div className="flex items-end">
          {displayModeOptions.map((displayModeOption) => {
            return (
              <button
                key={`display-mode-${displayModeOption.value}`}
                className={classes["display-mode-option"]}
                data-active={displayMode === displayModeOption.value}
                onClick={() => setDisplayMode(displayModeOption.value)}
              >
                {displayModeOption.label}
              </button>
            );
          })}
        </div>

        {/* Desktop validations */}
        <div className="hidden sm:flex gap-1 mb-1">
          {validations.map((validation, i) => (
            <div key={i}>{validation.displayNode}</div>
          ))}
        </div>

        {/* Mobile validations */}
        <div className="sm:hidden mb-1">
          <ValidationsPopover validations={validations} />
        </div>
      </div>

      <div className="neu-flat bg-white px-3 py-2 rounded-md rounded-tl-none grid grid-cols-[1fr,min-content] gap-4 w-full relative [&:hover>*:not(:first-child)]:opacity-10">
        {/* Render */}
        <div className="break-words *:break-words overflow-x-auto">{stateRender}</div>
      </div>
    </div>
  );
};
