import { ReactNode } from "react";
import { SnakeContext, State } from "./state";
import { Check, MessageCircleQuestion, X } from "lucide-react";
import { TooltipBadge } from "@/components/tooltip-badge";

interface ValidationResultBase {
  name: string;
  description: string;
  displayNode: ReactNode;
}

export type ValidationQueryKey = "std-webhook-key" | "jwt-key";

export interface ValidationResultSuccess extends ValidationResultBase {
  result: "valid";
}

export interface ValidationResultFailure extends ValidationResultBase {
  result: "invalid";
  error: string;
}

export interface ValidationResultQuery extends ValidationResultBase {
  result: "query";
  validationType: ValidationQueryKey;
}

export type ValidationResult = ValidationResultSuccess | ValidationResultFailure | ValidationResultQuery;

export abstract class StateValidator {
  constructor() {}

  public abstract validate(state: State, context: SnakeContext): Promise<ValidationResult[]>;

  public createSuccessResult(tag: string, description: string): ValidationResultSuccess {
    return {
      name: tag,
      description: description || "",
      displayNode: (
        <TooltipBadge
          iconLocation="front"
          className="opacity-100 hover:opacity-60 cursor-default !bg-status-success"
          title={tag}
          message={description ?? ""}
          icon={<Check className="w-3 h-3" />}
        />
      ),
      result: "valid",
    };
  }

  public createFailureResult(tag: string, reason: string, description?: string): ValidationResultFailure {
    return {
      name: tag,
      description: reason ?? description,
      displayNode: (
        <TooltipBadge
          iconLocation="front"
          className="opacity-100 hover:opacity-60 cursor-default !bg-status-error"
          title={tag}
          message={reason ?? description ?? ""}
          icon={<X className="w-3 h-3" />}
        />
      ),
      result: "invalid",
      error: reason ?? "Validation failed",
    };
  }

  public createQueryResult(
    tag: string,
    description: string,
    validationType: ValidationQueryKey
  ): ValidationResultQuery {
    return {
      name: tag,
      validationType: validationType,
      description: description,
      result: "query",
      displayNode: (
        <TooltipBadge
          iconLocation="front"
          className="opacity-100 hover:opacity-60 cursor-default !bg-status-pending"
          title={tag}
          message={description ?? ""}
          icon={<MessageCircleQuestion className="w-3 h-3" />}
        />
      ),
    };
  }
}
