import { PanelWorkflowSelection, WorkflowSelection } from "@/api/types/common";

export interface PanelService {
  groupWorkflowsByOwnerRepo(workflows: WorkflowSelection[]): PanelWorkflowSelection;
}

export class PanelServiceImpl implements PanelService {
  groupWorkflowsByOwnerRepo(workflows: WorkflowSelection[]): PanelWorkflowSelection {
    return workflows.reduce((acc, workflow) => {
      const ownerRepo = `${workflow.owner}/${workflow.repo}`;

      if (!acc[ownerRepo]) {
        acc[ownerRepo] = [];
      }

      acc[ownerRepo].push(workflow);

      return acc;
    }, {} as PanelWorkflowSelection);
  }
}

export const panelService = new PanelServiceImpl();
