export type ElementHighlights = "input" | "argument" | "transformation" | "recommended" | "secret";

export const elementHighlightBg: Record<ElementHighlights, string> = {
  input: "bg-element-input",
  transformation: "bg-element-transformation",
  argument: "bg-element-argument",
  recommended: "bg-element-recommended",
  secret: "bg-element-secret",
};

export const getHighlightBg = (highlight: ElementHighlights) => {
  return elementHighlightBg[highlight] ?? "";
};
