import { useUser } from "@/api/queries";
import { AvatarProps } from "@radix-ui/react-avatar";
import clsx from "clsx";
import React from "react";
import { LoadingSpinner } from "./loading-spinner";
import { Avatar, AvatarFallback } from "./ui/avatar";

export const AuthAvatar = React.forwardRef<
  HTMLSpanElement,
  Omit<AvatarProps & React.RefAttributes<HTMLSpanElement>, "ref" | "children"> & React.RefAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => {
  const { data: user, isLoading } = useUser();

  const chars = React.useMemo(() => {
    if (!user) return "?";

    return user.name.charAt(0) ?? "?";
  }, [user]);

  const tierClass = React.useMemo(() => {
    switch (user?.tier) {
      case "pro":
      case "enterprise":
        return "bg-secondary-950 text-white border-2 border-secondary-950";

      default:
        return "bg-white border-2 border-secondary-950";
    }
  }, [user?.tier]);

  return (
    <div className="relative">
      <Avatar ref={ref} className={clsx("relative", tierClass, className)} {...props}>
        <AvatarFallback className="uppercase">{isLoading ? <LoadingSpinner size={14} /> : chars}</AvatarFallback>
      </Avatar>

      {user?.role === "admin" && <div className="absolute -top-0.5 -right-0.5 text-xs">🛠️</div>}
      {user?.role === "owner" && <div className="absolute -top-0.5 -right-0.5 text-xs">👑</div>}
    </div>
  );
});
