import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";

interface HexViewerProps {
  blob: Blob;
}

const HexViewer: React.FC<HexViewerProps> = ({ blob }) => {
  const [blobArrayBuffer, setBlobArrayBuffer] = useState<Uint8Array | null>(null);
  const [hoveredByteIndex, setHoveredByteIndex] = useState<number | null>(null);

  const toHex = (byte: number) => byte.toString(16).padStart(2, "0").toUpperCase();
  const toAscii = (byte: number) => (byte >= 32 && byte <= 126 ? String.fromCharCode(byte) : ".");

  useEffect(() => {
    const fetchArrayBuffer = async () => {
      const arrayBuffer = await blob.arrayBuffer();
      setBlobArrayBuffer(new Uint8Array(arrayBuffer));
    };

    fetchArrayBuffer();
  }, [blob]);

  if (blobArrayBuffer === null) return null;

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const start = index * 16;
    const end = Math.min(start + 16, blobArrayBuffer.length);
    const hexValues = [];
    const asciiValues = [];

    for (let i = start; i < end; i++) {
      const byte = blobArrayBuffer[i];
      const hex = toHex(byte);
      const ascii = toAscii(byte);
      hexValues.push(hex);
      asciiValues.push(ascii);
    }

    return (
      <div style={style} className="flex font-mono text-xs">
        <div className="w-16 text-gray-500">{toHex(start).padStart(8, "0")}</div>
        <div className="flex-1 flex">
          <div className="w-96 grid grid-cols-[repeat(16,1fr)] gap-0">
            {hexValues.map((hex, hexIndex) => (
              <div
                key={hexIndex}
                className={`w-4 text-center ${start + hexIndex === hoveredByteIndex ? "bg-yellow-200" : ""}`}
                onMouseEnter={() => setHoveredByteIndex(start + hexIndex)}
                onMouseLeave={() => setHoveredByteIndex(null)}
              >
                {hex}
              </div>
            ))}
          </div>
          <div className="ml-2 flex">
            {asciiValues.map((ascii, asciiIndex) => (
              <div
                key={asciiIndex}
                className={`w-4 text-center ${start + asciiIndex === hoveredByteIndex ? "bg-yellow-200" : ""}`}
              >
                {ascii}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const rowCount = Math.ceil(blobArrayBuffer.length / 16);

  const hoveredByte = hoveredByteIndex !== null ? blobArrayBuffer[hoveredByteIndex] : null;

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex-1 overflow-auto">
        <List height={600} itemCount={rowCount} itemSize={20} width="100%" overscanCount={5}>
          {Row}
        </List>
      </div>

      <div className="flex p-2 border-t border-gray-300 text-xs font-mono h-12 gap-x-2 text-nowrap">
        <>
          <div className="w-16">Hex: {hoveredByte && toHex(hoveredByte)}</div>
          <div className="w-16">ASCII: {hoveredByte && toAscii(hoveredByte)}</div>
          <div className="w-16"> Decimal: {hoveredByte && hoveredByte}</div>
        </>
      </div>
    </div>
  );
};

export default HexViewer;
