import { WorkflowRunJobs } from "@/api/types/response";
import { LoadingSpinner } from "@/components/loading-spinner";
import { CheckCircle2, CircleDot, XCircle } from "lucide-react";
import { ParentBadge } from "@/lib/pipelines/utils/group-node-manager/group-node-provider";
import React from "react";

type Status = WorkflowRunJobs["jobs"][0]["status"]; // "queued" | "in_progress" | "completed" | "waiting" | "requested" | "pending";
type Conclusion = WorkflowRunJobs["jobs"][0]["conclusion"]; // "failure" | "success" | "neutral" | "cancelled" | "skipped" | "timed_out" | "action_required" | null;

type JobStatusColors = Partial<Record<Exclude<Status, "completed">, string>> &
  Record<"completed", Partial<Record<Exclude<Conclusion, null>, string>>> &
  Record<"default", string>;

type JobStatusIcons = Partial<Record<Exclude<Status, "completed">, React.ReactNode>> &
  Record<"completed", Partial<Record<Exclude<Conclusion, null>, React.ReactNode>>> &
  Record<"default", React.ReactNode>;

type JobStatusBadge = Partial<Record<Exclude<Status, "completed">, ParentBadge>> &
  Record<"completed", Partial<Record<Exclude<Conclusion, null>, ParentBadge>>> &
  Record<"default", ParentBadge>;

const jobStatusColors: JobStatusColors = {
  completed: {
    success: "bg-green-400",
    failure: "bg-red-400",
  },
  in_progress: "bg-secondary-400",
  queued: "bg-primary-400",
  default: "bg-slate-300",
};

const jobStatusIcons = (size: number): JobStatusIcons => ({
  completed: {
    success: <CheckCircle2 size={size} />,
    failure: <XCircle size={size} />,
  },
  in_progress: <LoadingSpinner size={size} />,
  queued: <CircleDot size={size} />,
  default: null,
});

export const getJobStatusColor = (status: Status, conclusion: Conclusion): string => {
  if (status === "completed" && conclusion) {
    return jobStatusColors[status]?.[conclusion] ?? jobStatusColors.default;
  }

  return jobStatusColors[status as Exclude<Status, "completed">] ?? jobStatusColors.default;
};

export const getJobStatusIcon = (status: Status, conclusion: Conclusion, size: number = 20): React.ReactNode => {
  const icons = jobStatusIcons(size);

  if (status === "completed" && conclusion) {
    return icons[status]?.[conclusion] ?? icons.default;
  }
  return icons[status as Exclude<Status, "completed">] ?? icons.default;
};

const parentNodeColor: JobStatusColors = {
  completed: {
    success: "bg-green-200",
    failure: "bg-red-200",
  },
  in_progress: "bg-secondary-200",
  queued: "bg-primary-200",
  default: "bg-gray-400",
};

const parentBadge: JobStatusBadge = {
  completed: {
    success: { color: "none text-black", text: "Success" },
    failure: { color: "none text-black", text: "Failure" },
  },
  in_progress: { color: "bg-secondary-200", text: "In Progress" },
  queued: { color: "bg-primary-200", text: "Queued" },
  default: { color: "bg-gray-200", text: "Waiting" },
};

interface ParentNodeData {
  parentNodeColor: string;
  parentBadge: ParentBadge;
}

export const getParentNodeData = (jobs: WorkflowRunJobs["jobs"]): ParentNodeData => {
  const status = jobs.map((job) => job.status);
  const conclusion = jobs.map((job) => job.conclusion);

  let nodeType: "completed-success" | "completed-failure" | "in_progress" | "queued" | "default" = "default";

  if (status.every((s) => s === "completed") && conclusion.every((c) => c === "success")) {
    nodeType = "completed-success";
  } else if (status.some((s) => s === "completed") && conclusion.some((c) => c === "failure")) {
    nodeType = "completed-failure";
  } else if (status.some((s) => s === "in_progress")) {
    nodeType = "in_progress";
  } else if (status.some((s) => s === "queued")) {
    nodeType = "queued";
  }

  let color: string;
  let badge: ParentBadge;

  switch (nodeType) {
    case "completed-success":
      color = parentNodeColor.completed.success ?? parentNodeColor.default;
      badge = parentBadge.completed.success ?? parentBadge.default;
      break;
    case "completed-failure":
      color = parentNodeColor.completed.failure ?? parentNodeColor.default;
      badge = parentBadge.completed.failure ?? parentBadge.default;
      break;
    case "in_progress":
      color = parentNodeColor.in_progress ?? parentNodeColor.default;
      badge = parentBadge.in_progress ?? parentBadge.default;
      break;
    case "queued":
      color = parentNodeColor.queued ?? parentNodeColor.default;
      badge = parentBadge.queued ?? parentBadge.default;
      break;
    default:
      color = parentNodeColor.default;
      badge = parentBadge.default;
      break;
  }

  return { parentNodeColor: color, parentBadge: badge };
};
