import { ApiFn } from "@/api/utils";
import { apiClient } from "@/api/client";
import { Request, Response } from "@/api/types";
import { AxiosError, AxiosRequestConfig } from "axios";

enum ApiClientBasePath {
  AUTH = "/auth",
  PROFILE = "/profile",
  WEBHOOKS = "/webhooks",
  EMAILS = "/emails",
  MARKETPLACE = "/marketplace",
  ADMIN = "/admin",
  PIPELINES = "/pipelines",
}

const applyConfig = () => {
  const token = localStorage.getItem("token");

  const config: AxiosRequestConfig = {};

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return { config };
};

/* Auth */
export const loginUser: ApiFn<Response.JWT, Request.LoginRequest, AxiosError> = ({ email, password }) => {
  return apiClient.post<Response.JWT>(
    `${ApiClientBasePath.AUTH}/login`,
    {
      email,
      password,
    },
    { withCredentials: true }
  );
};

export const registerUser: ApiFn<Response.JWT, Request.RegisterRequest, AxiosError> = ({
  name,
  email,
  password,
  regToken,
}) => {
  return apiClient.post<Response.JWT>(`${ApiClientBasePath.AUTH}/register`, {
    name,
    email,
    password,
    regToken,
  });
};

export const triggerVerification: ApiFn<Response.JWT, Request.VerificationRequest, AxiosError> = ({ email, type }) => {
  return apiClient.post<Response.JWT>(`${ApiClientBasePath.AUTH}/trigger-verification`, {
    email,
    type,
  });
};

export const verifyUser: ApiFn<Response.JWT, Request.UserVerificationRequest, AxiosError> = ({ email, token }) => {
  return apiClient.post<Response.JWT>(
    `${ApiClientBasePath.AUTH}/verify`,
    {
      email,
      token,
    },
    { withCredentials: true }
  );
};

export const resetPassword: ApiFn<Response.JWT, Request.ResetPasswordRequest, AxiosError> = ({
  email,
  password,
  token,
}) => {
  return apiClient.post<Response.JWT>(
    `${ApiClientBasePath.AUTH}/reset-password`,
    {
      email,
      password,
      token,
    },
    { withCredentials: true }
  );
};

export const refreshToken: ApiFn<Response.JWT> = () => {
  return apiClient.post<Response.JWT>(`${ApiClientBasePath.AUTH}/refresh-token`, {}, { withCredentials: true });
};

export const signInWithGithub: ApiFn<Response.GithubOAuthResponseEnvelope, void, AxiosError> = () => {
  return apiClient.post<Response.GithubOAuthResponseEnvelope>(
    `${ApiClientBasePath.AUTH}/sign-in-with-github`,
    {},
    { withCredentials: true }
  );
};

export const verifyGithubUser: ApiFn<Response.JWT, Request.verifyGithubUserRequest, AxiosError> = ({ token }) => {
  return apiClient.post<Response.JWT>(
    `${ApiClientBasePath.AUTH}/verify-github-user?token=${token}`,
    {},
    { withCredentials: true }
  );
};

/* Profile */
export const fetchUser: ApiFn<Response.User> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.User>(`${ApiClientBasePath.PROFILE}/`, config);
};

export const fetchCustomerSubscription: ApiFn<Response.CustomerSubscription> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.CustomerSubscription>(`${ApiClientBasePath.PROFILE}/subscription`, config);
};

export const fetchAccount: ApiFn<Response.Account> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.Account>(`${ApiClientBasePath.PROFILE}/account`, config);
};

export const fetchAccountUsers: ApiFn<Response.User[]> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.User[]>(`${ApiClientBasePath.PROFILE}/account/users`, config);
};

export const updateUserCredentials: ApiFn<Response.User, Request.UpdatePasswordRequest> = ({
  oldPassword,
  newPassword,
}) => {
  const { config } = applyConfig();
  return apiClient.put<Response.User>(
    `${ApiClientBasePath.PROFILE}/credentials`,
    {
      oldPassword,
      newPassword,
    },
    config
  );
};

export const createAccountUser: ApiFn<Response.User, Request.RegisterRequest> = ({ name, email, password, role }) => {
  const { config } = applyConfig();
  return apiClient.post<Response.User>(
    `${ApiClientBasePath.PROFILE}/account/users`,
    {
      name,
      email,
      password,
      role,
    },
    config
  );
};

export const updateAccountUser: ApiFn<
  Response.User,
  Request.UserKey & Request.UpdateAccountUserRequest,
  AxiosError
> = ({ email, newName, newRole }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.User>(
    `${ApiClientBasePath.PROFILE}/account/users/${email}`,
    {
      newName,
      newRole,
    },
    config
  );
};

export const updateAccountUserPassword: ApiFn<
  Response.User,
  Request.UserKey & Request.UpdateAccountUserRequest,
  AxiosError
> = ({ email, newPassword }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.User>(
    `${ApiClientBasePath.PROFILE}/account/users/${email}`,
    {
      newPassword,
    },
    config
  );
};

export const deleteAccountUser: ApiFn<Response.Message, Request.UserKey, AxiosError> = ({ email }) => {
  const { config } = applyConfig();
  return apiClient.delete<Response.Message>(`${ApiClientBasePath.PROFILE}/account/users/${email}`, config);
};

export const joinWaitlist: ApiFn<Response.Message, Request.WaitlistRequest, AxiosError> = ({ email }) => {
  return apiClient.post<Response.Message>(`${ApiClientBasePath.PROFILE}/waitlist`, { email });
};

export const createCustomerPortalSession: ApiFn<
  Response.StripeBillingPortalSession,
  Request.CustomerPortalSessionRequest,
  AxiosError
> = ({ returnUrl }) => {
  const { config } = applyConfig();
  return apiClient.post<Response.StripeBillingPortalSession>(
    `${ApiClientBasePath.PROFILE}/create-customer-portal-session`,
    { returnUrl },
    config
  );
};

/* Webhooks */
export const generateWebhook: ApiFn<Response.NewWebhook> = () => {
  const { config } = applyConfig();
  return apiClient.post<Response.NewWebhook>(`${ApiClientBasePath.WEBHOOKS}/create`, null, config);
};

export const updateWebhook: ApiFn<Response.NoContent, Request.UpdateWebhookParams> = ({
  webhook_id: id,
  response: response,
}) => {
  const { config } = applyConfig();
  return apiClient.put<Response.NoContent>(`${ApiClientBasePath.WEBHOOKS}/${id}`, response, config);
};

export const generateDynamicWebhook: ApiFn<Response.NewWebhook, Request.DynamicWebhookRequest> = ({
  specifiedRetries,
  responseCodes,
  delayBetweenRetries,
  contentTypes,
  responseData,
}) => {
  const { config } = applyConfig();
  return apiClient.post<Response.NewWebhook>(
    `${ApiClientBasePath.WEBHOOKS}/create/dynamic`,
    {
      specifiedRetries,
      responseCodes,
      delayBetweenRetries,
      contentTypes,
      responseData,
    },
    config
  );
};

export const fetchReceivedRequests: ApiFn<Response.WebhookResponseEnvelope, Request.WebhookIdParams> = ({
  webhook_id: id,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.WebhookResponseEnvelope>(`${ApiClientBasePath.WEBHOOKS}/${id}/received`, config);
};

export const fetchRequestById: ApiFn<Response.WebhookRequest, Request.RequestByIdParams> = ({
  webhook_id: id,
  request_id: request_id,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.WebhookRequest>(`${ApiClientBasePath.WEBHOOKS}/${id}/${request_id}`, config);
};

export const fetchWebhookInfo: ApiFn<Response.WebhookInfoResponseEnvelope, Request.WebhookIdParams> = ({
  webhook_id: id,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.WebhookInfoResponseEnvelope>(`${ApiClientBasePath.WEBHOOKS}/${id}/info`, config);
};

export const fetchRecentWebhooks: ApiFn<Response.RecentWebhooksEnvelope, Request.RecentWebhooksRequest> = ({
  startKey: startKey,
  order: order,
  fromDate: fromDate,
  toDate: toDate,
}) => {
  const { config } = applyConfig();

  if (startKey) {
    config.params = { ...config.params, startKey: startKey };
  }

  if (order) {
    config.params = { ...config.params, order: order };
  }

  if (fromDate && toDate) {
    config.params = { ...config.params, fromDate: fromDate, toDate: toDate };
  }

  return apiClient.get<Response.RecentWebhooksEnvelope>(`${ApiClientBasePath.WEBHOOKS}/recent`, config);
};

/* Emails */
export const generateEmail: ApiFn<Response.NewEmail> = () => {
  const { config } = applyConfig();
  return apiClient.post<Response.NewEmail>(`${ApiClientBasePath.EMAILS}/create`, null, config);
};

export const fetchReceivedEmails: ApiFn<Response.EmailResponseEnvelope, Request.EmailAddressParams> = ({
  email_address: email,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.EmailResponseEnvelope>(`${ApiClientBasePath.EMAILS}/${email}/received`, config);
};

export const fetchEmailById: ApiFn<Response.RawEmail, Request.EmailByIdParams> = async ({
  email_address: email,
  email_id: id,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.RawEmail>(`${ApiClientBasePath.EMAILS}/${email}/${id}`, config);
};

export const fetchRecentEmails: ApiFn<Response.RecentEmailsEnvelope, Request.RecentEmailsRequest> = ({
  startKey: startKey,
  order: order,
  fromDate: fromDate,
  toDate: toDate,
}) => {
  const { config } = applyConfig();

  if (startKey) {
    config.params = { ...config.params, startKey: startKey };
  }

  if (order) {
    config.params = { ...config.params, order: order };
  }

  if (fromDate && toDate) {
    config.params = { ...config.params, fromDate: fromDate, toDate: toDate };
  }
  return apiClient.get<Response.RecentEmailsEnvelope>(`${ApiClientBasePath.EMAILS}/recent`, config);
};

/* Marketplace */
export const registerMarketplaceSubscriber: ApiFn<
  Response.MarketplaceSubscriber,
  Request.RegisterMarketplaceSubscriberRequest,
  AxiosError
> = ({ regToken, companyName, contactPerson, contactEmail, contactPhone }) => {
  return apiClient.post<Response.MarketplaceSubscriber>(`${ApiClientBasePath.MARKETPLACE}/subscriber`, {
    regToken,
    companyName,
    contactPerson,
    contactEmail,
    contactPhone,
  });
};

/* Admin */
export const adminFetchAccountUsers: ApiFn<Response.User[], Request.AdminRequestParams> = ({
  customerIdentifier: customerIdentifier,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.User[]>(`${ApiClientBasePath.ADMIN}/account/${customerIdentifier}/users`, config);
};

export const adminUpdateAccountUser: ApiFn<
  Response.User,
  Request.UserKey & Request.AdminRequestParams & Request.AdminUpdateAccountUserRequest,
  AxiosError
> = ({ email, customerIdentifier, newName, newRole, newStatus, newTier, newVerified }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.User>(
    `${ApiClientBasePath.ADMIN}/account/${customerIdentifier}/users/${email}`,
    {
      newName,
      newRole,
      newStatus,
      newTier,
      newVerified,
    },
    config
  );
};

export const adminUpdateAccountUserPassword: ApiFn<
  Response.User,
  Request.UserKey & Request.AdminRequestParams & Request.UpdateAccountUserRequest,
  AxiosError
> = ({ email, customerIdentifier, newPassword }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.User>(
    `${ApiClientBasePath.ADMIN}/account/${customerIdentifier}/users/${email}`,
    {
      newPassword,
    },
    config
  );
};

/* Pipelines */
export const fetchGithubAuth: ApiFn<Response.GithubOAuthResponseEnvelope> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.GithubOAuthResponseEnvelope>(`${ApiClientBasePath.PIPELINES}/github-auth`, config);
};

export const fetchRepositories: ApiFn<Response.RepositoriesEnvelope, void, AxiosError> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.RepositoriesEnvelope>(`${ApiClientBasePath.PIPELINES}/repositories`, config);
};

export const fetchWorkflows: ApiFn<Response.WorkflowsEnvelope, Request.WorkflowsRequest> = ({ owner, repo }) => {
  const { config } = applyConfig();
  return apiClient.get<Response.WorkflowsEnvelope>(
    `${ApiClientBasePath.PIPELINES}/repos/${owner}/${repo}/workflows`,
    config
  );
};

export const fetchLatestRunJobs: ApiFn<Response.WorkflowRunJobsEnvelope, Request.LatestRunJobsRequest> = ({
  owner,
  repo,
  workflowId,
  resourceJwt,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.WorkflowRunJobsEnvelope>(
    `${ApiClientBasePath.PIPELINES}/repos/${owner}/${repo}/workflows/${workflowId}/jobs/latest?token=${resourceJwt}`,
    config
  );
};

export const fetchPipelineInfo: ApiFn<Response.PipelineInfoResponse, Request.PipelineInfoRequest> = ({
  pipelineId,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.PipelineInfoResponse>(
    `${ApiClientBasePath.PIPELINES}/pipeline-info/${pipelineId}`,
    config
  );
};

export const updatePipelineInfo: ApiFn<
  Response.Message,
  Request.PipelineInfoKey & Request.UpdatePipelineInfoRequest,
  AxiosError
> = ({ owner, repo, defaultView, selectedWorkflows }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.Message>(
    `${ApiClientBasePath.PIPELINES}/info/${owner}/${repo}`,
    {
      defaultView,
      selectedWorkflows,
    },
    config
  );
};

export const updatePipelineVisibility: ApiFn<
  Response.Message,
  Request.PipelineInfoKey & Request.UpdatePipelineVisibilityReqeust,
  AxiosError
> = ({ owner, repo, isPublic }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.Message>(
    `${ApiClientBasePath.PIPELINES}/visibility/${owner}/${repo}`,
    {
      isPublic,
    },
    config
  );
};

/* Pipeline Panels */
export const fetchPipelinePanelList: ApiFn<Response.PipelinePanelListResponseEnvelope> = () => {
  const { config } = applyConfig();
  return apiClient.get<Response.PipelinePanelListResponseEnvelope>(`${ApiClientBasePath.PIPELINES}/panels`, config);
};

export const fetchPipelinePanel: ApiFn<Response.PipelinePanelResponseEnvelope, Request.PipelinePanelRequest> = ({
  panelId,
}) => {
  const { config } = applyConfig();
  return apiClient.get<Response.PipelinePanelResponseEnvelope>(
    `${ApiClientBasePath.PIPELINES}/panels/${panelId}`,
    config
  );
};

export const createPipelinePanel: ApiFn<Response.Message, Request.CreatePipelinePanelRequest, AxiosError> = ({
  panelName,
  defaultView,
  selectedWorkflows,
  isPublic,
}) => {
  const { config } = applyConfig();
  return apiClient.post<Response.Message>(
    `${ApiClientBasePath.PIPELINES}/panels`,
    {
      panelName,
      defaultView,
      selectedWorkflows,
      isPublic,
    },
    config
  );
};

export const updatePipelinePanelSettings: ApiFn<
  Response.Message,
  Request.PipelinePanelKey & Request.UpdatePipelinePanelSettingsRequest,
  AxiosError
> = ({
  panelId,

  panelName,
  defaultView,
  selectedWorkflows,
}) => {
  const { config } = applyConfig();
  return apiClient.put<Response.Message>(
    `${ApiClientBasePath.PIPELINES}/panels/${panelId}/settings`,
    {
      panelName,
      defaultView,
      selectedWorkflows,
    },
    config
  );
};

export const updatePipelinePanelVisibility: ApiFn<
  Response.Message,
  Request.PipelinePanelKey & Request.UpdatePipelineVisibilityReqeust,
  AxiosError
> = ({ panelId, isPublic }) => {
  const { config } = applyConfig();
  return apiClient.put<Response.Message>(
    `${ApiClientBasePath.PIPELINES}/panels/${panelId}/visibility`,
    {
      isPublic,
    },
    config
  );
};

export const deletePipelinePanel: ApiFn<Response.Message, Request.PipelinePanelKey, AxiosError> = ({ panelId }) => {
  const { config } = applyConfig();
  return apiClient.delete<Response.Message>(`${ApiClientBasePath.PIPELINES}/panels/${panelId}`, config);
};
