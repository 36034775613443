import { WorkflowRunJobs } from "@/api/types/response";
import React from "react";

export interface ParentBadge {
  color: string;
  text: string;
}

interface GroupNodeSettingsContextType {
  parentColor: string;
  updateParentColor: (color: string) => void;
  parentBadge: ParentBadge;
  updateParentBadge: (color: ParentBadge) => void;
  run: WorkflowRunJobs;
  updateRun: (run: WorkflowRunJobs) => void;
}

export const GroupNodeSettingsContext = React.createContext<GroupNodeSettingsContextType | null>(null);

interface GroupNodeSettingsProviderProps {
  children: React.ReactNode;
}

export const GroupNodeSettingsProvider: React.FC<GroupNodeSettingsProviderProps> = ({ children }) => {
  const [parentColor, setParentColor] = React.useState<string>("bg-secondary-100");
  const [parentBadge, setParentBadge] = React.useState<ParentBadge>({ color: "bg-secondary-950", text: "Loading" });
  const [run, setRun] = React.useState<WorkflowRunJobs>({
    total_count: 0,
    jobs: [],
  });

  const updateParentColor = (color: string) => {
    setParentColor(color);
  };

  const updateParentBadge = (parentBadge: ParentBadge) => {
    setParentBadge(parentBadge);
  };

  const updateRun = (run: WorkflowRunJobs) => {
    setRun(run);
  };

  return (
    <GroupNodeSettingsContext.Provider
      value={{
        parentColor,
        updateParentColor,
        parentBadge,
        updateParentBadge,
        run,
        updateRun,
      }}
    >
      {children}
    </GroupNodeSettingsContext.Provider>
  );
};
