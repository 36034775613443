/**
 * Checks if a value is undefined.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is `undefined`, false otherwise.
 */
export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

/**
 * Checks if a value is null.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is `null`, false otherwise.
 */
export function isNull(value: unknown): value is null {
  return value === null;
}

/**
 * Checks if a value is either `undefined` or `null`.
 *
 * @param value - The value to check.
 * @returns `true` if the value is `undefined` or `null`, otherwise `false`.
 */
export function isNullable(value: unknown): value is undefined | null {
  return isUndefined(value) || isNull(value);
}

/**
 * Checks if a value is not `undefined` and not `null`.
 *
 * @template T - The type of the value to check.
 * @param value - The value to check.
 * @returns `true` if the value is neither `undefined` nor `null`, otherwise `false`.
 */
export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return !isNullable(value);
}

/**
 * Checks if a value is a number.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is a `number`, false otherwise.
 */
export function isNumber(value: unknown): value is number {
  return typeof value === "number" && !Number.isNaN(value);
}

/**
 * Checks if a value is a string.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is a `string`, false otherwise.
 */
export function isString(value: unknown): value is string {
  return typeof value === "string";
}

/**
 * Checks if a value is an object.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is an `object`, false otherwise.
 */
export function isObject(value: unknown): value is object {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
}

/**
 * Checks if a value is a function.
 * @export
 * @template TArgs - Type of the function arguments.
 * @template TReturn - Return type of the function.
 * @param {unknown} value - The value to check.
 * @return True if the value is a `Function`, false otherwise.
 */
export function isFunction<TArgs extends any[] = any[], TReturn = any>(
  value: unknown
): value is (...args: TArgs) => TReturn {
  return typeof value === "function";
}

/**
 * Checks if a value is an empty string.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is an empty `string`, false otherwise.
 */
export function isEmptyString(value: unknown): value is "" {
  return isString(value) && value.length === 0;
}

/**
 * Checks if a value is an array.
 * @export
 * @template TType - Type of the array elements.
 * @param {unknown} value - The value to check.
 * @return True if the value is an `Array`, false otherwise.
 */
export function isArray<TType>(value: unknown): value is TType[] {
  return Array.isArray(value);
}

/**
 * Checks if a value is an array of a specific type.
 * @export
 * @template TType - Type of the array elements.
 * @param {unknown} value - The value to check.
 * @param {(element: unknown) => element is TType} isType - Type guard function for the array elements.
 * @return True if the value is an `Array` of the specified type, false otherwise.
 */
export function isArrayOfType<TType>(value: unknown, isType: (element: unknown) => element is TType): value is TType[] {
  return isArray<unknown>(value) && value.every((element) => isType(element));
}

/**
 * Checks if a value is a CustomEvent instance.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is a `CustomEvent` instance, false otherwise.
 */
export function isCustomEvent(value: unknown): value is CustomEvent {
  return value instanceof CustomEvent;
}

/**
 * Checks if a value is an Error instance.
 * @export
 * @param {unknown} value - The value to check.
 * @return True if the value is an `Error` instance, false otherwise.
 */
export function isError(value: unknown): value is Error {
  return value instanceof Error;
}
