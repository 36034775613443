import React from "react";
import { ApiMutation } from "@/api";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { RecentEmailsList } from "@/lib/emails/dashboard/private/elements/recent-emails-list";

export const PrivateDashboard: React.FC = () => {
  const navigate = useNavigate();

  const emailMutation = ApiMutation.useCreateEmail({
    onSuccess: (data) => {
      navigate(`/emails/${data.id}`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <div className="flex flex-col space-y-2">
        <h4 className="font-bold tracking-tight">Emails</h4>
        <small>
          Generate email addresses for testing purposes. Use the generated email addresses to test your application's
          email sending functionality.
        </small>
      </div>
      <div className="flex flex-col gap-2 2xl:gap-6">
        <div className="flex gap-2">
          <Button
            className="bg-primary-300"
            size="sm"
            onClick={emailMutation.mutate}
            disabled={emailMutation.isPending}
          >
            {emailMutation.isPending ? "Creating Email..." : "Generate Email Address"}
          </Button>
        </div>
        <div>
          <RecentEmailsList />
        </div>
      </div>
    </>
  );
};
