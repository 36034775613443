import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

interface JWTService {
  getToken(): string | null;
  saveToken(token: string): void;
  removeToken(): void;
  decodeToken(token: string): DecodedToken | null;
  isTokenValid(token: string): boolean;
  getTokenExpiration(token: string): number;
}

class JWTServiceImpl implements JWTService {
  private readonly TOKEN_KEY = "token";

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  saveToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  removeToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  decodeToken(token: string): DecodedToken | null {
    try {
      return jwtDecode<DecodedToken>(token);
    } catch (error) {
      console.error("Failed to decode token:", error);
      return null;
    }
  }

  isTokenValid(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken) return false;

    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  }

  getTokenExpiration(token: string): number {
    const decodedToken = this.decodeToken(token);
    return decodedToken?.exp || 0;
  }
}

export default new JWTServiceImpl();
