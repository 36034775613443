import { PanelWorkflowSelection, WorkflowSelection } from "@/api/types/common";
import { GridWorkflow } from "@/lib/pipelines/grid-view/elements/grid-workflow";
import { Link } from "react-router-dom";
import React from "react";
import { GroupNodeSettingsProvider } from "@/lib/pipelines/utils/group-node-manager/group-node-provider";
import { useGroupNodeSettings } from "@/lib/pipelines/utils/group-node-manager/useGroupNodeSettings";
import { cn } from "@/utils/ui.util";
import { useWorkflowTimings } from "@/lib/pipelines/utils/useWorkflowTimings";
import { format, formatDistanceToNow } from "date-fns";
import { CustomTooltip } from "@/components/custom-tooltip.tsx";
import { ExternalLink } from "lucide-react";

interface GridViewDashboardProps {
  title: string;
  selectedWorkflows: PanelWorkflowSelection;
  workflowResourceJwt: string;
}

export const GridViewDashboard: React.FC<GridViewDashboardProps> = ({ selectedWorkflows, workflowResourceJwt }) => {
  return (
    <div className="neu rounded-lg p-4 bg-secondary-50 space-y-6">
      {Object.keys(selectedWorkflows).map((ownerRepo) => (
        <div key={ownerRepo} className="space-y-2">
          <h6 className="text-sm font-semibold">{ownerRepo}</h6>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {selectedWorkflows[ownerRepo].map((workflow) => (
              <GroupNodeSettingsProvider key={workflow.workflowId.toString()}>
                <GridViewDashboardItem workflow={workflow} workflowResourceJwt={workflowResourceJwt} />
              </GroupNodeSettingsProvider>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

interface GridViewDashboardItemProps {
  workflow: WorkflowSelection;
  workflowResourceJwt: string;
}

export const GridViewDashboardItem: React.FC<GridViewDashboardItemProps> = ({ workflow, workflowResourceJwt }) => {
  const [now] = React.useState(new Date());
  const { parentColor, parentBadge, run } = useGroupNodeSettings();
  const { workflowStart, workflowEnd, workflowDurationFormatted } = useWorkflowTimings(run, now);

  const workflowDetails = {
    items: [
      {
        key: "Workflow",
        value: (
          <div className="flex items-center gap-1">
            <Link to={workflow.htmlUrl} target="_blank" className="text-primary-500 underline font-medium">
              {workflow.workflowName}
            </Link>
            <ExternalLink size={12} />
          </div>
        ),
      },
      {
        key: "Started At",
        value: workflowStart ? format(workflowStart, "yyyy-MM-dd HH:mm:ss") : null,
      },
      {
        key: "Completed At",
        value: workflowEnd ? format(workflowEnd, "yyyy-MM-dd HH:mm:ss") : "N/A",
      },
      {
        key: "Duration",
        value: workflowDurationFormatted,
      },
      {
        key: "Total Jobs",
        value: run.total_count,
      },
    ],
  };

  return (
    <div className={cn("neu rounded-lg min-h-40 bg-secondary-100", parentColor)}>
      <div className="flex w-full">
        <div className={cn("w-full rounded-none rounded-t  px-2.5 pt-2 text-lg font-extrabold")}>
          {parentBadge.text}
        </div>
      </div>
      <div className="p-2 pt-1 ">
        <div className="flex flex-col gap-2 py-1 2xl:py-2 items-center ">
          <div className="size-full px-1 flex flex-col 2xl:flex-row justify-between">
            <Link to={workflow.htmlUrl} target="_blank" className="no-underline hover:underline text-secondary-950">
              <CustomTooltip tooltipItems={workflowDetails.items}>
                <div className="text-xs font-semibold break-all line-clamp-1 text-wrap">{workflow.workflowName}</div>
              </CustomTooltip>
            </Link>
            {workflowStart && (
              <div className="text-xs text-gray-600">{formatDistanceToNow(workflowStart, { addSuffix: true })}</div>
            )}
          </div>
          <GridWorkflow
            title={workflow.workflowName}
            owner={workflow.owner}
            repo={workflow.repo}
            workflowId={workflow.workflowId.toString()}
            htmlUrl={workflow.htmlUrl}
            workflowResourceJwt={workflowResourceJwt}
          />
        </div>
      </div>
    </div>
  );
};
