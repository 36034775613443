import React from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";

export interface TooltipItem {
  key: string;
  value: React.ReactNode | string;
}

interface CustomTooltipProps {
  tooltipItems: TooltipItem[];
  children: React.ReactNode;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ tooltipItems, children }) => {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent align="start" hideWhenDetached>
          <Badge variant="outline" className="bg-white">
            <div className="flex flex-col gap-1 p-2">
              {tooltipItems.map(({ key, value }) => (
                <div key={key} className="flex gap-4 justify-between">
                  <p className="text-xs font-semibold">{key}</p>
                  <div className="text-xs font-normal text-gray-600">{value}</div>
                </div>
              ))}
            </div>
          </Badge>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
