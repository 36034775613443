import React from "react";
import { LocalStorageKeyType, LocalStorageService } from "@/utils/storage/localstorage-service";
import { SecretsLocalStorageData } from "@/lib/tools/secrets-manager/secrets-manager-utils";

interface SecretsContextType {
  secrets: SecretsLocalStorageData[];
  storeSecret: (secret: SecretsLocalStorageData) => void;
  deleteSecret: (secret: SecretsLocalStorageData) => void;
  updateSecret: (key: string, updatedSecret: SecretsLocalStorageData) => void;
}

export const SecretsContext = React.createContext<SecretsContextType | null>(null);

interface SecretsProviderProps {
  children: React.ReactNode;
}

export const SecretsProvider: React.FC<SecretsProviderProps> = ({ children }) => {
  const localStorageKey: LocalStorageKeyType = "secrets";

  const retrieveSecrets = () => {
    return LocalStorageService.retrieve<SecretsLocalStorageData>(localStorageKey);
  };

  const [secrets, setSecrets] = React.useState<SecretsLocalStorageData[]>(retrieveSecrets ?? []);

  const storeSecret = (secret: SecretsLocalStorageData) => {
    LocalStorageService.store<SecretsLocalStorageData>(localStorageKey, secret);
    setSecrets((s) => [...s, secret]);
  };

  const deleteSecret = (secret: SecretsLocalStorageData) => {
    const updatedSecrets = secrets.filter((s) => s.key !== secret.key);
    LocalStorageService.storeAll<SecretsLocalStorageData>(localStorageKey, updatedSecrets);
    setSecrets(updatedSecrets);
  };

  const updateSecret = (key: string, updatedSecret: SecretsLocalStorageData) => {
    const updatedSecrets = secrets.map((secret) => (secret.key === key ? updatedSecret : secret));
    LocalStorageService.storeAll<SecretsLocalStorageData>(localStorageKey, updatedSecrets);
    setSecrets(updatedSecrets);
  };

  return (
    <SecretsContext.Provider value={{ secrets, storeSecret, deleteSecret, updateSecret }}>
      {children}
    </SecretsContext.Provider>
  );
};
