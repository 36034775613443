import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { XMLState } from "../states";
import { toXML } from "jstoxml";
import { ToolsIcons } from "./common-options/options.transformations";

export class ToXMLTransofmation extends StateTransformation<XMLState> {
  constructor() {
    super("to-xml", "To XML", ["string", "json"]);
  }

  icon(): ToolsIcons {
    return "code";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().jsonValue) {
      try {
        return CanTransformResult.Yes;
      } catch {
        return CanTransformResult.No;
      }
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<XMLState> {
    if (state.config().jsonValue) {
      const xml = toXML({ root: state.jsonValue() }, { header: true, indent: "  " });
      return new XMLState(xml);
    }
    throw new Error("Invalid state.");
  }
}

export default new ToXMLTransofmation();
