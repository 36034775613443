import { APIGatewayProxyEvent } from "aws-lambda";
import { State, StateConfig } from "../state";
import { ReactNode } from "react";
import { HeadersTable } from "@/components/headers-table";
import { RequestHelper } from "../utils/request-helper";
import { keyValueTable } from "../utils/tools-display-utils";

export class RequestState extends State<APIGatewayProxyEvent> {
  readonly requestHelper: RequestHelper;
  constructor(value: APIGatewayProxyEvent) {
    super(value);
    this.requestHelper = new RequestHelper(value);
  }

  config(): StateConfig {
    const customDisplay = ["Request", "Headers"];

    if (this.requestHelper.hasBody() !== "none") customDisplay.push("Body");
    if (this.requestHelper.hasQueryParams()) customDisplay.push("Query");
    if (this.requestHelper.hasCookies()) customDisplay.push("Cookies");
    return {
      transformations: "tags-only",
      transformationTags: ["request"],
      validations: "all",
      stringValue: false,
      jsonValue: true,
      customDisplay: customDisplay,
      defaultDisplayMode: "Request",
    };
  }

  getRequest() {
    return this.requestHelper;
  }

  hasBody() {
    return this.requestHelper.hasBody();
  }
  getBodyAsString() {
    return this.requestHelper.getBodyAsString();
  }

  getBodyLength() {
    return this.requestHelper.getBodyLength();
  }
  getContentType() {
    return this.requestHelper.getContentType();
  }

  getMethod() {
    return this.value.httpMethod;
  }

  getPath() {
    return this.value.path;
  }

  jsonValue(): object {
    return {
      method: this.getMethod(),
      headers: this.value.headers,
      query: this.value.queryStringParameters,
      path: this.getPath(),
      body: this.requestHelper.hasBody() !== "none" ? this.getBodyAsString() : undefined,
      contentType: this.getContentType(),
    };
  }

  stringValue(): string {
    throw new Error("Method not implemented.");
  }
  toReactNode(contentType: string): ReactNode {
    if (contentType === "Request") {
      return (
        <span>
          <div className="text-prim flex text-nowrap text-ellipsis">
            {this.getMethod()} {this.getPath()}
            {this.value.queryStringParameters && (
              <>
                {Object.entries(this.value.queryStringParameters ?? {}).map(([key, value], index) => (
                  <>
                    {index === 0 ? "?" : "&"}
                    {key}={value}
                  </>
                ))}
              </>
            )}
          </div>

          <div>
            {" "}
            {this.hasBody() !== "none"
              ? `Body: ${this.getContentType() ?? ""} [${this.getBodyLength()} Bytes]`
              : "(No body)"}
          </div>
        </span>
      );
    } else if (contentType === "Headers") {
      return <HeadersTable title={"Headers"} headers={this.value.headers} type="http-headers" />;
    } else if (contentType === "Query") {
      return <HeadersTable title={"Query"} headers={this.value.queryStringParameters ?? {}} type="query-params" />;
    } else if (contentType === "Body") {
      return <pre>{this.getBodyAsString()}</pre>;
    } else if (contentType === "Cookies") {
      return keyValueTable(this.requestHelper.getCookies());
    }
  }
}
