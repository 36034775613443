import { State, StateConfig } from "../state";
import { stringify as stringifyYaml } from "yaml";
import { ReactNode } from "react";
import JsonView from "react18-json-view";
import { stringify } from "smol-toml";
import { checkType } from "../utils/json-utils";
type JsonType = "JSON" | "YAML" | "TOML";

export class JsonState extends State<object> {
  constructor(
    value: object,
    readonly jsonType: JsonType
  ) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      transformationTags: ["json"],
      validations: "all",
      stringValue: false,
      jsonValue: true,
      customDisplay: ["formatted JSON", "min JSON", "JSON", "YAML", "TOML"],
      defaultDisplayMode: this.jsonType,
    };
  }

  jsonValue(): object {
    return this.value;
  }

  toReactNode(mode: string): ReactNode {
    switch (mode) {
      case "JSON":
        return <JsonView displaySize={true} src={this.value} enableClipboard />;
      case "YAML":
        return <pre className="select-all">{stringifyYaml(this.value, { indent: 2 })}</pre>;
      case "TOML": {
        try {
          const tomlType = checkType(this.value);
          switch (tomlType) {
            case "array":
              return <pre className="select-none">TOML does not allow top-level arrays</pre>;
            case "string":
              return <pre className="select-none">TOML does not allow top-level string</pre>;
            case "number":
              return <pre className="select-none">TOML does not allow top-level number</pre>;
            default:
              return <pre className="select-all">{stringify(this.value)}</pre>;
          }
        } catch (e) {
          return <pre className="select-all">Invalid TOML value {String(e)}</pre>;
        }
      }
      case "formatted JSON":
        return <pre className="select-all">{JSON.stringify(this.value, null, 2)}</pre>;
      case "min JSON":
        return <pre className="select-all">{JSON.stringify(this.value, null, 0)}</pre>;
    }
  }

  stringValue(): string {
    throw new Error("Method not implemented.");
  }
}
