import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { EmailMimeState, KeyValueState, StringState } from "../states";
import { BlobState } from "../states/blob.state";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type ExtractMode = {
  selected: "headers" | "body plain" | "body html" | "attachment";
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class ExtractFromEmailMimeTransformation extends StateTransformation<State, ExtractMode> {
  constructor() {
    super("extract-email-mime", "Extract", ["email-mime"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (EmailMimeState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: ExtractMode): Promise<State> {
    if (EmailMimeState.is(state)) {
      const mode = options?.selected;
      switch (mode) {
        case "headers":
          return new KeyValueState(state.singleValueHeaders(), "MIME Headers");
        case "body plain":
          return new StringState(state.parsed.body.text ?? "");
        case "body html":
          return new StringState(state.parsed.body.html ?? "");
        default: {
          const a = state.attachments[Number(mode)];
          return new BlobState(a.blob, { filename: a.data.fileName, mime: a.data.contentType });
        }
      }
    }

    throw new Error("Not an email MIME");
  }

  async getOptions(state: State, options?: ExtractMode): Promise<ExtractMode> {
    const values: Record<string, string> = { headers: "headers" };

    if (EmailMimeState.is(state)) {
      if (state.hasTextBody()) {
        values["body plain"] = "body plain";
      }
      if (state.hasHtmlBody()) {
        values["body html"] = "body html";
      }
      for (let i = 0; i < state.attachments.length; i++) {
        const attachment = state.attachments[i];
        if (attachment.data.content.length === 0) continue;
        values[String(i)] = `Attachment ${i + 1}: ${attachment.data.fileName || attachment.data.contentType}`;
      }
    }

    return {
      selected: options?.selected ?? "headers",
      values: values,
      label: "extract from",
    };
  }

  getOptionsReactNode(options: ExtractMode, onChange: (options: ExtractMode) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange as any} options={options} />;
  }
}

export default new ExtractFromEmailMimeTransformation();
