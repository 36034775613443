import { Outlet } from "react-router-dom";
import { AuthMenu } from "@/layouts/elements/auth-menu";
import { useAuth } from "@/auth/useAuth";

export const AuthLayout: React.FC = () => {
  const { status, setPublicAccess } = useAuth();

  if (status === "redirect-to-auth") {
    setPublicAccess();
  }

  return (
    <div className="flex flex-col min-h-dvh bg-primary-100">
      <AuthMenu />
      <div className="flex flex-1 flex-col items-center justify-center p-4">
        <Outlet />
      </div>
    </div>
  );
};
