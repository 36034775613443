import { State, StateConfig } from "../state";
import { ReactNode } from "react";
import parse from "parse-color";

type Color = ReturnType<typeof parse>;

export class ColorState extends State<Color> {
  constructor(value: Color) {
    super(value);
  }

  stringValue(): string {
    return JSON.stringify(this.value);
  }

  jsonValue(): object {
    return this.value;
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      validations: "tags-only",
      stringValue: true,
      jsonValue: true,
      customDisplay: ["Color", "CSS", "RGB"],
      defaultDisplayMode: "Color",
    };
  }

  toReactNode(displayMode: string): ReactNode {
    if (displayMode === "Color")
      return (
        <div className={`flex items-center`}>
          <div className="w-6 h-6 mr-3 rounded-md" style={{ backgroundColor: this.value.hex }} />
          <code>{this.value.hex}</code>

          <span className="ml-2 text-xs "> alpha:{this.value.rgba?.[3] * 100}%</span>
        </div>
      );
    else if (displayMode === "CSS") {
      return <code>{this.value.hex}</code>;
    } else if (displayMode === "RGB") {
      return (
        <div>
          <div className="text-xs bg-primary-200">RGB</div>
          <div>
            <code>
              red: {this.value.rgb?.[0]}
              <br />
              green: {this.value.rgb?.[1]} <br />
              blue: {this.value.rgb?.[2]}
              <br />
              alpha: {this.value.rgba?.[3] * 100}%<br />
            </code>
          </div>
        </div>
      );
    }
  }
}
