import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { AuthMenu } from "@/layouts/elements/auth-menu";
import { Card } from "@/components/ui/card";

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-dvh bg-secondary-100">
      <AuthMenu />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex justify-center items-center w-full -mt-32 p-4">
          <Card className="flex flex-col gap-8 px-4 sm:px-10 py-10 w-[28rem] rounded-2xl shadow-md bg-tertiary-100">
            <div className="flex flex-col items-center gap-4">
              <h1 className="font-bold tracking-tight">Oops!</h1>
              <p className="text-lg tracking-tight">404 &ndash; Page Not Found</p>
              <small>The page you are looking for does not exist.</small>
            </div>
            <Button scheme="black" size="sm" onClick={() => navigate("/dashboard")}>
              Return To Homepage
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};
