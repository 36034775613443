import { cn } from "@/utils/ui.util";
import { useMemo, useState } from "react";
import {
  StaticTreeDataProvider,
  Tree,
  TreeInformation,
  TreeItem,
  TreeItemIndex,
  TreeItemRenderContext,
  UncontrolledTreeEnvironment,
} from "react-complex-tree";

export type TreeNodeInfo = {
  filename: string;
  name: string;
};

type FileTreeProps<T> = {
  options: T;
  data: Record<TreeItemIndex, TreeItem>;
  treeId: string;
  rootItem: string;
  onChange: (options: T) => void;
  getItemTitle: (item: TreeItem<TreeNodeInfo>) => string;
  classNames?: string;
};

export const TransformationFileTree: React.FC<FileTreeProps<any>> = ({
  data,
  treeId,
  rootItem = "/",
  onChange,
  options,
  getItemTitle,
  classNames,
}) => {
  const [dataState] = useState(data);
  const dataProvider = useMemo(
    () => new StaticTreeDataProvider(dataState, (item, data) => ({ ...item, data })),
    [dataState]
  );

  const onSelectItem = (items: TreeItemIndex[], updatedtreeId: string) => {
    if (treeId !== updatedtreeId) return;
    onChange({ ...options, selected: dataState[items?.[0]]?.data?.filename });
  };

  const renderItemTitle = (props: {
    title: string;
    item: TreeItem<TreeNodeInfo>;
    context: TreeItemRenderContext;
    info: TreeInformation;
  }) => {
    return <div>{props.title}</div>;
  };

  return (
    <div className={cn(classNames)}>
      <UncontrolledTreeEnvironment
        dataProvider={dataProvider}
        getItemTitle={getItemTitle}
        viewState={{}}
        disableMultiselect={true}
        onSelectItems={onSelectItem}
        renderItemTitle={renderItemTitle}
      >
        <Tree treeId={treeId} rootItem={rootItem} />
      </UncontrolledTreeEnvironment>
    </div>
  );
};
