import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import React from "react";
import classes from "./StateDisplay.module.scss";
import { StateEditor, StateEditorProps } from "./editor/StateEditor";

export const StatePopover: React.FC<StateEditorProps> = ({ input, initialTransformation }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon" className={`hover:bg-transparent h-fit text-md ${classes["icon-button"]}`}>
          <span>{"🥔"}</span>
          <span>{"🍟"}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[700px] overflow-x-hidden bg-primary-50">
        <StateEditor input={input} initialTransformation={initialTransformation} />
      </PopoverContent>
    </Popover>
  );
};
