import { EmailMimeState, InvalidState, RequestState } from "@/utils/parsing";
import { State } from "@/utils/parsing/state";
import { BlobState } from "@/utils/parsing/states/blob.state";
import { StringState } from "@/utils/parsing/states/string.state";
import { APIGatewayProxyEvent } from "aws-lambda";
import { FileTypeResult } from "file-type";

export type ParserInputString = {
  type: "string";
  value: string;
};

export type ParserInputBlob = {
  type: "blob";
  value: Blob;
  detectedType?: FileTypeResult;
  filename?: string;
  base64data?: string;
};

export type ParserInputHttpRequest = {
  type: "http-request";
  value: APIGatewayProxyEvent;
};

export type ParserInputState = {
  type: "state";
  state: State;
};

export type ParserInputEmailMime = {
  type: "email-mime";
  value: string;
};

export type ParserInput =
  | ParserInputString
  | ParserInputBlob
  | ParserInputHttpRequest
  | ParserInputState
  | ParserInputEmailMime
  | null;

export function parserInputToState(input: ParserInput): State {
  if (!input) return new InvalidState("No input");
  switch (input.type) {
    case "string":
      return new StringState(input.value);
    case "blob":
      return new BlobState(input.value, { mime: input.detectedType?.mime, filename: input.filename });
    case "http-request":
      return new RequestState(input.value);
    case "email-mime":
      return new EmailMimeState(input.value);
    case "state":
      return input.state;
    default:
      throw new Error(`Invalid input type ${input}`);
  }
}
