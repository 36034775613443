import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { UuidState } from "../states";
import { version, validate } from "uuid";

export class ToUuidTransformation extends StateTransformation<UuidState> {
  constructor() {
    super("to-uuid", "To UUID", ["string"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (UuidState.is(state)) return CanTransformResult.No;
    if (state.config().stringValue) {
      if (validate(state.stringValue())) return CanTransformResult.Recommended;
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<UuidState> {
    const v = version(state.stringValue());
    return new UuidState(state.stringValue(), v);
  }
}

export default new ToUuidTransformation();
