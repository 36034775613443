import { SnakeContext, State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { EmptyState, InvalidState, StringState } from "../states";

import { autodetect } from "../utils/autodetect-utils";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type AutoDetectSelection = {
  selected: string;
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class ToAutoDetectTransformation extends StateTransformation<State, AutoDetectSelection> {
  constructor() {
    super("autodetect", "Auto-detect", ["auto-detect"]);
  }

  icon(): ToolsIcons {
    return "autodetect";
  }

  async canTransform(_: State): Promise<CanTransformResult> {
    return CanTransformResult.Yes;
  }

  async transform(state: State, options: AutoDetectSelection, snakeContext: SnakeContext): Promise<State> {
    if (StringState.is(state) && state.value?.trim()?.length === 0) return new EmptyState("No input");
    const results = await autodetect(state);
    if (results.length === 0) return new InvalidState("Cannot auto-detect this input");

    const x = results.find((x) => x.transformId === options.selected);
    if (!x) return new InvalidState("Invalid transformation selected");

    return await x.transformation.transform(state, options as any, snakeContext);
  }

  async getOptions(state: State, options?: AutoDetectSelection): Promise<AutoDetectSelection | undefined> {
    const results = await autodetect(state);

    //console.log("Auto-detect results", results, options?.selected, options?.selected ?? results?.[0].transformId);
    let selectedResult = results.find((x) => x.transformId === options?.selected);

    if (!selectedResult) {
      selectedResult = results[0];
    }

    let label = "0 Auto-detections";
    if (results.length === 1) label = "1 Auto-detection";
    if (results.length > 1) label = `${results.length} Auto-detections`;

    const retVal = {
      selected: selectedResult?.transformId as any,
      values: Object.fromEntries(results.map((x) => [x.transformId, x.transformId])),
      label,
      icons: Object.fromEntries(results.map((x) => [x.transformId, x.transformation.icon()])),
    };
    //console.log("results", results, "Selected result", selectedResult, "Options", JSON.stringify(retVal));
    return retVal;
  }

  getOptionsReactNode(options: AutoDetectSelection, onChange: (options: AutoDetectSelection) => void): React.ReactNode {
    return Object.keys(options?.values).length === 0 ? (
      <div>No transformations available</div>
    ) : (
      <TransformationOptionsSelect onChange={onChange} options={options} />
    );
  }
}

export default new ToAutoDetectTransformation();
