import { Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ApiMutation } from "@/api";
import { toast } from "@/components/ui/use-toast";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { User } from "@/api/types/response";
import { Input } from "@/components/ui/input";
import { defaultError } from "@/lib/auth/utils/default-error-message";

interface DeleteAccountUserProps {
  user: User;
}

export const DeleteAccountUser: React.FC<DeleteAccountUserProps> = ({ user }) => {
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [deleteEmail, setDeleteEmail] = React.useState("");

  const deleteAccountUser = ApiMutation.useDeleteAccountUser({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [["account", "users"]] });
      toast({ title: "Success", description: data.message });
      setDialogOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  return (
    <div>
      <Button size="icon" variant="ghost" className="hover:hover:bg-red-300" onClick={() => setDialogOpen(!dialogOpen)}>
        <Trash2 size={16} />
      </Button>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Account User</DialogTitle>
            <small>
              Are you sure you want to delete <strong>{user.name}</strong>?
            </small>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-sm font-semibold">Confirm email</p>
              <Input
                type="email"
                value={deleteEmail}
                onChange={(e) => setDeleteEmail(e.target.value)}
                placeholder="Email Address"
                className="w-full"
              />
            </div>

            <div className="flex justify-end">
              <Button
                type="submit"
                size="sm"
                disabled={deleteAccountUser.isPending || deleteEmail !== user.email}
                className="bg-primary-300 min-w-32"
                onClick={() => deleteAccountUser.mutate({ email: user.email })}
              >
                {deleteAccountUser.isPending ? "Deleting..." : "Delete"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
