import { useWorkflows } from "@/api/queries";
import { WorkflowSelection } from "@/api/types/common";
import { Workflow } from "@/api/types/response";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Checkbox } from "@/components/ui/checkbox";
import React from "react";

interface WorkflowSelectionAccordionProps {
  owner: string;
  repo: string;
  selectedWorkflows: WorkflowSelection[];
  setFieldValue: (field: string, value: any) => void;
}

export const WorkflowSelectionAccordion: React.FC<WorkflowSelectionAccordionProps> = ({
  owner,
  repo,
  selectedWorkflows,
  setFieldValue,
}) => {
  const {
    isPending,
    isError,
    data: response,
  } = useWorkflows({
    owner: owner,
    repo: repo,
  });

  const handleCheckboxChange = (workflow: Workflow, isChecked: boolean) => {
    const workflowObject: WorkflowSelection = {
      workflowId: workflow.id,
      owner: owner,
      repo: repo,
      workflowName: workflow.name,
      htmlUrl: workflow.html_url,
    };

    const updatedWorkflows = isChecked
      ? [...selectedWorkflows, workflowObject]
      : selectedWorkflows.filter((item) => !(item.workflowId === workflow.id));

    setFieldValue("selectedWorkflows", updatedWorkflows);
  };

  return (
    <>
      {isPending && (
        <div className="flex items-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <div>Oops! Something went wrong</div>}
      {!isPending && !isError && (
        <>
          {response.status === "reject" && <div>Oops! Something went wrong</div>}
          {response.status === "success" && (
            <div className="grid grid-cols-[min-content,1fr] gap-x-5 gap-y-2 ml-1 items-center">
              {response.data.workflows.map((workflow) => (
                <React.Fragment key={workflow.id}>
                  <Checkbox
                    className="w-4 h-4"
                    checked={selectedWorkflows.some((selected) => selected.workflowId === workflow.id)}
                    onCheckedChange={(checked) => handleCheckboxChange(workflow, Boolean(checked))}
                  />

                  <p className="w-full font-mono text-sm">{workflow.name}</p>
                </React.Fragment>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
