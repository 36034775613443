import { State, StateConfig } from "../state";
import { ReactNode } from "react";

export class UuidState extends State<string> {
  constructor(
    value: string,
    readonly version: number | null
  ) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: false,
      customDisplay: ["UUID"],
      defaultDisplayMode: "UUID",
    };
  }

  stringValue(): string {
    return this.value;
  }

  toReactNode(_: string): ReactNode {
    return (
      <div className="flex items-center">
        <code>{this.stringValue()}</code>
        <span className="ml-2 text-xs ">version {this.version}</span>
      </div>
    );
  }
}
