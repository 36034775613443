import { LoadingSpinner } from "@/components/loading-spinner";
import { Toaster } from "@/components/ui/toaster";
import { SecretsProvider } from "@/lib/tools/secrets-manager/secrets-provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode } from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthProvider } from "@/auth/auth-provider";
import { useAuth } from "@/auth/useAuth";

import { useUser } from "@/api/queries";
import { fetchUser } from "@/api/requests.tsx";

import { AuthLayout } from "@/layouts/auth-layout";
import { MainLayout } from "@/layouts/main-layout";
import { PipelinesPublicLayout } from "@/layouts/pipelines-public-layout";

import { LandingPageLayout } from "@/layouts/landing-page-layout";
import { EmailPage } from "@/lib/emails/page/email-page";
import { WebhookPage } from "@/lib/webhooks/page/webhook-page";
import { PageNotFound } from "@/routes/404";
import { AdminPanel } from "@/routes/admin-panel";
import { AWS } from "@/routes/auth/aws";
import { ForgotPassword } from "@/routes/auth/forgot-password";
import { GithubVerify } from "@/routes/auth/github-verify";
import { Login } from "@/routes/auth/login";
import { Register } from "@/routes/auth/register";
import { ResetPassword } from "@/routes/auth/reset-password";
import { VerifyUser } from "@/routes/auth/verify-user";
import { Dashboard } from "@/routes/dashboard";
import { Emails } from "@/routes/emails/emails";
import { EmailsDashboard } from "@/routes/emails/webhooks-emails-testing-dashboard";
import { About } from "@/routes/landing/about";
import { CookiePolicy } from "@/routes/landing/cookie-policy";
import { PipelinesHome } from "@/routes/landing/homes/pipelines-home";
import { LandingPage as NewLandingPage } from "@/routes/landing/landing-page";
import { PrivacyPolicy } from "@/routes/landing/privacy-policy";
import { TermsAndConditions } from "@/routes/landing/terms-and-conditions";
import { PanelDashboardWrapper } from "@/routes/pipelines/panel-dashboard-wrapper";
import { Pipelines } from "@/routes/pipelines/pipelines";
import { PipelinesAuthDashboard } from "@/routes/pipelines/pipelines-auth-dashboard";
import { PrivatePipelinesDashboard } from "@/routes/pipelines/private-pipelines-dashboard";
import { PublicPipelinesDashboard } from "@/routes/pipelines/public-pipelines-dashboard";
import { UserReposDashboard } from "@/routes/pipelines/user-repos-dashboard";
import { Account } from "@/routes/settings/account";
import { PasswordAndCredentials } from "@/routes/settings/password-and-credentials";
import { PaymentsAndSubscriptions } from "@/routes/settings/payments-and-subscriptions.tsx";
import { Profile } from "@/routes/settings/profile";
import { Settings } from "@/routes/settings/settings";
import { AutodetectParserRoute } from "@/routes/tools/autodetect-parser";
import { ToolsRoute } from "@/routes/tools/tools";
import { Webhooks } from "@/routes/webhooks/webhooks";
import { WebhooksDashboard } from "@/routes/webhooks/webhooks-dashboard";
import { EmailsHome } from "./routes/landing/homes/emails-home";
import { WebhooksHome } from "./routes/landing/homes/webhooks-home";

const ENVIRONEMT = `${import.meta.env.VITE_ENVIRONMENT}`;

const queryClient = new QueryClient();

interface RouteProps {
  element: ReactNode;
}

const DashboardRoute = ({ element }: RouteProps) => {
  const { status } = useAuth();

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full h-dvh bg-secondary-200">
        <LoadingSpinner message={"Loading..."} />
      </div>
    );
  }

  if (status === "redirect-to-auth") {
    return <Navigate to="/auth" replace={true} />;
  }

  return element;
};

const ProtectedRoute = ({ element }: RouteProps) => {
  const { status } = useAuth();

  if (status === "public") {
    return <Navigate to="/auth" replace={true} />;
  }

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full">
        <LoadingSpinner message={"Loading..."} />
      </div>
    );
  }

  if (status === "redirect-to-auth") {
    return <Navigate to="/auth" replace={true} />;
  }

  return element;
};

const AdminProtectedRoute = ({ element }: RouteProps) => {
  const { status } = useAuth();
  const { isPending, isError, data } = useUser();

  if (status === "public" || isError) {
    return <Navigate to="/auth" replace={true} />;
  }

  if (status == "loading" || isPending) {
    return null;
  }

  if (data.role !== "admin") {
    return <Navigate to="/404" replace={true} />;
  }

  return element;
};

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    element: <LandingPageLayout />,
    children: [
      {
        index: true,
        element: <NewLandingPage />,
      },
      {
        path: "/home",
        children: [
          {
            index: true,
            element: <Navigate to="/" replace />,
          },
          {
            path: "pipelines",
            element: <PipelinesHome />,
          },
          {
            path: "webhooks",
            element: <WebhooksHome />,
          },
          {
            path: "emails",
            element: <EmailsHome />,
          },
        ],
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "cookie-policy",
        element: <CookiePolicy />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "about",
        element: <About />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardRoute element={<MainLayout />} />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "webhooks",
        children: [
          {
            index: true,
            element: <WebhooksDashboard />,
          },
          {
            path: ":id",
            element: <Webhooks />,
            children: [
              {
                path: ":requestId?",
                element: <WebhookPage />,
              },
            ],
          },
        ],
      },
      {
        path: "emails",
        children: [
          {
            index: true,
            element: <EmailsDashboard />,
          },
          {
            path: ":id",
            element: <Emails />,
            children: [
              {
                path: ":emailId?",
                element: <EmailPage />,
              },
            ],
          },
        ],
      },
      {
        path: "tools",
        element: <ToolsRoute />,
        children: [
          {
            path: ":sample?",
            element: <AutodetectParserRoute />,
          },
        ],
      },
      {
        path: "pipelines",
        element: <ProtectedRoute element={<Pipelines />} />,
        children: [
          {
            index: true,
            element: <UserReposDashboard />,
          },
          {
            path: "auth",
            element: <PipelinesAuthDashboard />,
          },
          {
            path: "repo/:owner/:repo",
            element: <PrivatePipelinesDashboard />,
          },
          {
            path: "panel/:panelId",
            element: <PanelDashboardWrapper readOnly={false} />,
          },
        ],
      },
      {
        path: "settings",
        loader: async () => {
          const { data } = await fetchUser();
          return data;
        },
        element: <ProtectedRoute element={<Settings />} />,
        children: [
          {
            index: true,
            element: <Profile />,
          },
          {
            path: "credentials",
            element: <PasswordAndCredentials />,
          },
          {
            path: "payments",
            element: <PaymentsAndSubscriptions />,
          },
          {
            path: "account",
            element: <Account />,
          },
        ],
      },
    ],
  },
  {
    path: "pipelines",
    element: <PipelinesPublicLayout />,
    children: [
      {
        path: "view/repo/:pipelineId",
        element: <PublicPipelinesDashboard />,
      },
      {
        path: "view/panel/:panelId",
        element: <PanelDashboardWrapper readOnly={true} />,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "verify",
        element: <VerifyUser />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "aws",
        element: <AWS />,
      },
      {
        path: "github/verify",
        element: <GithubVerify />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminProtectedRoute element={<MainLayout />} />,
    children: [
      {
        index: true,
        element: <AdminPanel />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SecretsProvider>
            <RouterProvider router={router} />
            <Toaster />
            {ENVIRONEMT !== "prod" && <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />}
          </SecretsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
