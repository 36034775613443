import { cn } from "@/utils/ui.util";
import { ChevronRight } from "lucide-react";
import React from "react";

const accountSetup = {
  steps: [
    {
      title: "Account Details",
      description: "Please enter your account details.",
    },
    {
      title: "Credentials",
      description: "Please enter your password.",
    },
    {
      title: "Confirmation",
      description: "Please confirm your account.",
    },
  ],
};

interface StepperIndicatorProps {
  activeStep: number;
}

const StepperIndicator: React.FC<StepperIndicatorProps> = ({ activeStep }) => {
  return (
    <div className="flex justify-center items-center gap-4">
      {accountSetup.steps.map((step, index) => (
        <React.Fragment key={index}>
          <p className={cn("text-xs font-medium", index === activeStep && "font-bold")}>{step.title}</p>
          {index < accountSetup.steps.length - 1 && <ChevronRight size={16} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepperIndicator;
