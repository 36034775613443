import React from "react";

import "@xyflow/react/dist/style.css";
import { UpdatePipelineEvent, WorkflowsEnvelopeData } from "@/api/types/response";
import { useQueryClient } from "@tanstack/react-query";
import useWebSocket from "react-use-websocket";

const socketUrl = `${import.meta.env.VITE_WEBSOCKET_URL}`;

interface DynamicViewWrapperProps {
  owner: string;
  repo: string;
  workflowsEnvelopeData: WorkflowsEnvelopeData;
  children: (props: { owner: string; repo: string; workflowsEnvelopeData: WorkflowsEnvelopeData }) => React.ReactNode;
}

export const DynamicViewWrapper: React.FC<DynamicViewWrapperProps> = ({
  owner,
  repo,
  workflowsEnvelopeData,
  children,
}) => {
  const queryClient = useQueryClient();

  const { lastMessage } = useWebSocket(socketUrl, {
    queryParams: { id: btoa(`${owner}#${repo}`), token: workflowsEnvelopeData.resourceJwt },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  React.useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data) as UpdatePipelineEvent;

      if (message.type === "update_pipeline") {
        queryClient.invalidateQueries({
          queryKey: [["workflows"], { owner: owner, repo: repo }],
        });
      }
    }
  }, [lastMessage, queryClient, owner, repo]);

  return <>{children({ owner, repo, workflowsEnvelopeData })}</>;
};
