import React from "react";
import { CopyableInput } from "@/components/copyable-input";
import { Separator } from "@/components/ui/separator";
import { RequestDisplay } from "@/lib/webhooks/page/elements/request/request-display";
import { WebhookInfo } from "@/lib/webhooks/page/elements/sidebar/webhook-info";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format } from "date-fns/format";
import { ResponseDisplay } from "@/lib/webhooks/page/elements/response/response-display";
import { AccessType, WebhookDetails, WebhookReceivedRequests } from "@/api/types/response";
import { DynamicResponsePipeline } from "@/routes/webhooks/webhooks";
import { RequestHelp } from "@/lib/webhooks/page/elements/help/request-help";
import { ScrollArea } from "@/components/ui/scroll-area";
import { WebhookStatus } from "@/lib/webhooks/page/webhook-page";
import { WebhookStatusCard } from "@/lib/webhooks/page/elements/help/webhook-status-card";
import { TooltipBadge } from "@/components/tooltip-badge";
import { AlignLeft, Lock, Unlock } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

interface DynamicWebhookPageCoreProps {
  id: string;
  requestId?: string;
  accessType: AccessType;
  resourceJwt: string;
  responsePipeline: DynamicResponsePipeline;
  received: WebhookDetails[];
  endpointStatus: WebhookStatus;
}

export const DynamicWebhookPageCore: React.FC<DynamicWebhookPageCoreProps> = ({
  id,
  requestId,
  accessType,
  resourceJwt,
  responsePipeline,
  received,
  endpointStatus,
}) => {
  const endpoint = `${import.meta.env.VITE_WEBHOOKS_URL}/${id}`;
  const navigate = useNavigate();

  const selected: WebhookDetails | undefined = React.useMemo(
    () => received.find((item) => item.id === requestId),
    [received, requestId]
  );

  const selectedIndex = React.useMemo(() => {
    return received.findIndex((item) => item.id === selected?.id);
  }, [selected, received]);

  const sidebar = (
    <Sidebar
      id={id}
      accessType={accessType}
      endpoint={endpoint}
      received={received}
      responsePipeline={responsePipeline}
      endpointStatus={endpointStatus}
      selected={selected}
    />
  );

  return (
    <div className="flex flex-col md:flex-row w-full">
      {/* Sidebar */}
      <div className="hidden sm:block md:w-3/12 bg-secondary-200">
        <div className="flex flex-col">{sidebar}</div>
      </div>

      {/* Content */}
      <ScrollArea className="flex flex-col md:w-9/12 sm:border-l border-secondary-950">
        <div className="flex flex-col">
          {(!received.length || !requestId) && (
            <>
              <div className="hidden sm:block">
                <div className="flex p-10 justify-center">
                  <div className="flex flex-col gap-8 w-full max-w-7xl">
                    <RequestHelp
                      id={id}
                      endpoint={endpoint}
                      resourceJwt={resourceJwt}
                      showRequestsHelp={true}
                      endpointStatus={endpointStatus}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:hidden">
                <div className="flex flex-col">{sidebar}</div>
              </div>
            </>
          )}
          {received.length > 0 && selectedIndex < received.length && selected && (
            <>
              <Tabs key={selectedIndex} defaultValue="request">
                <div className="flex justify-between items-center mt-2 px-4">
                  {/* Mobile Sidebar */}
                  <div className="sm:hidden">
                    <Button variant="ghost" size="icon" onClick={() => navigate(`/webhooks/${id}`)}>
                      <AlignLeft className="h-[1.2rem] w-[1.2rem]" />
                    </Button>
                  </div>
                  <TabsList className="grid grid-cols-3 bg-transparent p-0">
                    <TabsTrigger
                      value="request"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Request
                    </TabsTrigger>
                    <TabsTrigger
                      value="response"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Response
                    </TabsTrigger>
                    <TabsTrigger
                      value="help"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Help
                    </TabsTrigger>
                  </TabsList>
                  <p className="hidden sm:block text-xs">{format(new Date(received[selectedIndex].date), "PPpp")}</p>
                </div>
                <TabsContent value="request">
                  <Separator />
                  <RequestDisplay id={id} requestId={received[selectedIndex].id} />
                </TabsContent>
                <TabsContent value="response">
                  <Separator />
                  <ResponseDisplay id={id} requestId={received[selectedIndex].id} />
                </TabsContent>
                <TabsContent value="help">
                  <Separator />
                  <div className="flex p-4 sm:p-10 justify-center">
                    <div className="flex flex-col gap-8 w-full max-w-7xl">
                      <RequestHelp
                        id={id}
                        endpoint={endpoint}
                        resourceId={received[selectedIndex].id}
                        resourceJwt={resourceJwt}
                        showRequestsHelp={true}
                        endpointStatus={endpointStatus}
                      />
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            </>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

interface SidebarProps {
  id: string;
  accessType: AccessType;
  endpoint: string;
  received: WebhookReceivedRequests;
  responsePipeline: DynamicResponsePipeline;
  endpointStatus: WebhookStatus;
  selected: WebhookDetails | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({
  id,
  accessType,
  endpoint,
  received,
  responsePipeline,
  endpointStatus,
  selected,
}) => {
  return (
    <>
      <div className="space-y-4 pt-4">
        <div className="flex flex-col gap-2 py-2 px-4">
          <div className="flex gap-4 items-center justify-between">
            <h5 className="font-semibold tracking-tight">HTTP Endpoint</h5>
            <div className="flex gap-2">
              {accessType === "public" ? (
                <TooltipBadge
                  title="public"
                  message="Anyone can access requests sent to this endpoint."
                  icon={<Unlock className="w-3 h-3" />}
                />
              ) : (
                <TooltipBadge
                  title="private"
                  message="Only you can access requests sent to this endpoint."
                  icon={<Lock className="w-3 h-3" />}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <small className="text-sm">Send your requests here:</small>
              <CopyableInput value={endpoint} />
            </div>
          </div>
        </div>
        <Separator />
      </div>
      <ScrollArea>
        <div className="flex flex-col gap-2 py-4">
          <div className="flex justify-between mb-2 px-4">
            <h5 className="font-semibold tracking-tight">Response Sequence</h5>
            <div className="flex text-xs mt-2 gap-1 font-semibold mr-1">
              <div className="flex">
                <p className="font-mono text-xs font-semibold">
                  {Math.min(received.length, responsePipeline.specifiedRetries!)}
                </p>
                <p className="font-mono text-xs font-semibold">/</p>
                <p className="font-mono text-xs font-semibold">{responsePipeline.specifiedRetries}</p>
              </div>
              <p className="text-xs font-semibold">Received</p>
            </div>
          </div>
          <div className="px-4">
            <WebhookInfo
              id={id}
              retryCount={received.length}
              responsePipeline={responsePipeline}
              items={received}
              selectedRequest={selected}
            />
          </div>
          {(endpointStatus.isMaxItemsReached || !endpointStatus.isEndpointEnabled) && (
            <div className="px-4">
              <WebhookStatusCard
                message={endpointStatus.isMaxItemsReached ? `Max items reached.` : "Webhook is disabled."}
              />
            </div>
          )}
        </div>
      </ScrollArea>
    </>
  );
};
