import React from "react";
import { useCustomerSubscription } from "@/api/queries.tsx";
import { PaymentMethodItem } from "@/lib/settings/payment-and-subscriptions/payment-method-item.tsx";
import { PaymentPlanComponent } from "@/lib/settings/payment-and-subscriptions/payment-plan-component.tsx";
import { LoadingSpinner } from "@/components/loading-spinner";
import { CustomerPortal } from "@/lib/settings/payment-and-subscriptions/customer-portal";
import { Navigate, useOutletContext } from "react-router-dom";
import { SettingsOutletContext } from "./settings";
import { UpgradePlan } from "@/lib/stripe/stripe-dialog";
import Stripe from "stripe";
import { AccountDetails } from "@/lib/settings/account-details";

export const PaymentsAndSubscriptions: React.FC = () => {
  const { user } = useOutletContext<SettingsOutletContext>();

  return (
    <div className="space-y-6 px-4">
      <div>
        <h5 className="font-semibold tracking-tight">Payment methods and subscriptions</h5>
        <small>Update your payment information or change plans according to your needs</small>
      </div>
      <hr />

      {/* AWS Users */}
      {user.customerIdentifier !== "user" && (
        <>
          {user.role === "owner" && <AccountDetails />}

          {user.role !== "owner" && (
            <>
              <h6 className="font-semibold tracking-tight">Subscription</h6>
              <small>
                Your subscription is managed by the account owner. Please contact the account owner to manage your
                subscription plan, payment methods, and billing information.
              </small>
            </>
          )}
        </>
      )}

      {/* Free Users */}
      {user.customerIdentifier === "user" && user.tier === "free" && (
        <>
          <h6 className="font-semibold tracking-tight">Subscription</h6>
          <small>
            Subscribe to our paid plan to unlock premium features such as higher rate limit, permanent data persistence,
            webhook aliasing and S3 bucket data retention. You can cancel anytime.
          </small>

          <UpgradePlan email={user.email} />
        </>
      )}

      {/* Paid Users */}
      {user.tier === "pro" && <PaidUserInformation />}
    </div>
  );
};

const PaidUserInformation: React.FC = () => {
  const { isPending, isError, data } = useCustomerSubscription();

  const subscription = React.useMemo(() => {
    return data?.customer.subscriptions?.data[0];
  }, [data]);

  const defaultPaymentMethodId = React.useMemo(() => {
    const getDefaultPaymentMethodId = (source: string | Stripe.PaymentMethod | Stripe.CustomerSource) => {
      return typeof source === "string" ? source : source?.id;
    };

    if (subscription?.default_payment_method) {
      return getDefaultPaymentMethodId(subscription.default_payment_method);
    }

    if (data?.customer?.default_source) {
      return getDefaultPaymentMethodId(data.customer.default_source);
    }

    if (data?.customer?.invoice_settings.default_payment_method) {
      return getDefaultPaymentMethodId(data.customer.invoice_settings.default_payment_method);
    }

    return null;
  }, [data, subscription]);

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-between">
            <div className="flex flex-col gap-4">
              <div>
                <h6 className="font-semibold tracking-tight">My Plan</h6>
                <small>Change plan according to your needs</small>
              </div>
              {subscription && <PaymentPlanComponent currentPeriodEnd={subscription.current_period_end} />}
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <h6 className="font-semibold tracking-tight">Payment method</h6>
                <small>Currently used payment method for subscriptions</small>
              </div>

              {data.paymentMethods.map((paymentMethod: Stripe.PaymentMethod, index) => (
                <PaymentMethodItem
                  key={index}
                  paymentMethod={paymentMethod}
                  defaultPaymentMethodId={defaultPaymentMethodId}
                />
              ))}
            </div>
          </div>
          <div className="flex justify-end">
            <CustomerPortal />
          </div>
        </>
      )}
    </>
  );
};
