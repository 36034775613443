import { getContentSizeInKB } from "@/lib/webhooks/utils/webhook-utils";
import { Circle, CheckCircle } from "lucide-react";
import { RecentRequestItem } from "@/lib/webhooks/page/elements/sidebar/recent-request-item";
import { WebhookDetails } from "@/api/types/response";
import { DynamicResponsePipeline } from "@/routes/webhooks/webhooks";
import mime from "mime";

export interface WebhookInfoProps {
  id: string;
  retryCount: number;
  responsePipeline: DynamicResponsePipeline;
  items: WebhookDetails[];
  selectedRequest?: WebhookDetails;
}

export const WebhookInfo: React.FC<WebhookInfoProps> = ({
  id,
  retryCount,
  responsePipeline,
  items,
  selectedRequest,
}) => {
  const specifiedRetries = Number(responsePipeline.specifiedRetries);
  const lastRespose = getLastResponse(responsePipeline);

  return (
    <div className="flex flex-col gap-2">
      {responsePipeline.specifiedRetries &&
        Array.from({ length: Number(responsePipeline.specifiedRetries) }).map((_, index) => {
          const responseCode = responsePipeline.responseCodes![index];
          const contentType = responsePipeline.contentTypes![index];
          const delay = responsePipeline.delayBetweenRetries![index];
          const data = responsePipeline.responseData![index];

          const extension = mime.getExtension(contentType);
          const contentSize = getContentSizeInKB(data, contentType);

          const path = items[index] ? `/webhooks/${id}/${items[index].id}` : "";

          return (
            <div key={index} className="flex items-center">
              <RecentRequestItem
                label={createLabel(index, retryCount)}
                item={createItem(responseCode, delay, contentSize, extension)}
                path={path}
                height="h-full"
                isSelected={index < items.length && items[index] === selectedRequest}
                disabled={index >= retryCount}
              />
            </div>
          );
        })}

      {items.length > specifiedRetries && (
        <div className="flex flex-col gap-2">
          <h2 className="text-sm font-semibold tracking-tight mt-4">Further Responses</h2>
          {items.map((item, index) => {
            if (index < specifiedRetries) return;

            const extension = mime.getExtension(lastRespose.contentType);
            const contentSize = getContentSizeInKB(lastRespose.data, lastRespose.contentType);

            return (
              <div key={index} className="flex items-center gap-4">
                <RecentRequestItem
                  label={createLabel(index, retryCount)}
                  item={createItem(lastRespose.responseCode, lastRespose.delay, contentSize, extension)}
                  path={`/webhooks/${id}/${item.id}`}
                  height="h-full"
                  isSelected={item.id === selectedRequest?.id}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const createLabel = (index: number, retryCount: number) => {
  return index < retryCount ? <CheckCircle className="w-4 mr-2" /> : <Circle className="w-4 mr-2" />;
};

const createItem = (responseCode: number, delay: number, contentSize: string, extension: string | null) => {
  return {
    name: <p className="text-sm font-semibold">{responseCode}</p>,
    description: "",
    info: (
      <div className="flex items-center gap-1">
        <p className="text-xs">{delay} Sec</p>
        <p className="text-xs font-mono uppercase">{extension}</p>
        <p className="text-xs">({contentSize})</p>
      </div>
    ),
  };
};

const getLastResponse = (responsePipeline: DynamicResponsePipeline) => {
  const lastResponseIndex = Number(responsePipeline.specifiedRetries) - 1;

  return {
    responseCode: responsePipeline.responseCodes![lastResponseIndex],
    contentType: responsePipeline.contentTypes![lastResponseIndex],
    delay: responsePipeline.delayBetweenRetries![lastResponseIndex],
    data: responsePipeline.responseData![lastResponseIndex],
  };
};
