import { AlignRight, FileText, LogIn, LogOut, PencilRuler, Settings } from "lucide-react";

import { useUser } from "@/api/queries";
import { useAuth } from "@/auth/useAuth";
import { AuthAvatar } from "@/components/auth-avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { Link, useNavigate } from "react-router-dom";

const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";

export const ToggleMoreMobile: React.FC = () => {
  const { status } = useAuth();

  return <>{status === "authenticated" ? <AuthenticatedDropdown /> : <UnauthenticatedDropdown />}</>;
};

const AuthenticatedDropdown: React.FC = () => {
  const { logout } = useAuth();
  const { data: user } = useUser();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    navigate("/auth");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="bg-transparent">
          <AuthAvatar className="w-8 h-8" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {user && (
          <>
            <DropdownMenuLabel className="flex flex-col gap-1">
              <div className="flex justify-between">
                {user.name}
                <Badge variant="secondary" className="ml-2 uppercase">
                  {user.tier}
                </Badge>
              </div>
              <div className="font-light text-secondary-950/90">{user.email}</div>
            </DropdownMenuLabel>

            <Separator className="h-[2px]" />
          </>
        )}

        <div className="flex flex-col gap-1">
          <div>
            <DropdownMenuItem asChild>
              <Link to="/tools" className="no-underline text-secondary-950">
                <PencilRuler size={16} className="mr-2" />
                Tools
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem asChild>
              <Link to={docsURL} target="_blank" className="no-underline text-secondary-950">
                <FileText size={16} className="mr-2" />
                Docs
              </Link>
            </DropdownMenuItem>
          </div>

          <Separator className="h-[2px]" />

          <div>
            <DropdownMenuItem asChild>
              <Link to="/settings" className="no-underline text-secondary-950">
                <Settings size={16} className="mr-2" />
                <span>Settings</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleLogOut}>
              <LogOut size={16} className="mr-2" />
              <span>Log out</span>
            </DropdownMenuItem>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const UnauthenticatedDropdown: React.FC = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="bg-transparent">
          <AlignRight className="h-[1.2rem] w-[1.2rem]" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="flex flex-col gap-1">
          <DropdownMenuItem asChild>
            <Link to="/tools" className="no-underline text-secondary-950">
              <PencilRuler size={16} className="mr-2" />
              Tools
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <Link to={docsURL} target="_blank" className="no-underline text-secondary-950">
              <FileText size={16} className="mr-2" />
              Docs
            </Link>
          </DropdownMenuItem>

          <Separator className="h-[2px]" />

          <DropdownMenuItem asChild>
            <Link to="/auth" className="no-underline text-secondary-950">
              <LogIn size={16} className="mr-2" />
              Login
            </Link>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
