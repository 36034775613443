import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import "./index.scss";

Sentry.init({
  environment: import.meta.env.VITE_ENVIRONMENT,
  dsn: "https://545279d95005ea161670485a02f5f42d@o4507547980136448.ingest.de.sentry.io/4507548016771152",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/wt\.dev/, /^https:\/\/sand\.wt\.dev/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
