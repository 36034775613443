import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { keyValueTable } from "../utils/tools-display-utils";
// import { init, isPrime, getFactors } from "@sigma-js/primes";
import httpStatus from "../../../lib/external/spec/http-status-codes.json";

export class NumberState extends State<number> {
  readonly isInteger = Number.isInteger(this.value);
  readonly httpStatus: undefined | any;

  constructor(
    value: number,
    readonly type?: "int" | "float" | "bigint" | string
  ) {
    super(value);
    this.httpStatus = httpStatus.find((status) => status.code == this.value.toString());
  }

  config(): StateConfig {
    const customDisplay = this.isInteger ? ["Integer"] : ["Float"];
    if (this.httpStatus) {
      customDisplay.push("HTTP Status");
    }
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: false,
      customDisplay: customDisplay,
      defaultDisplayMode: customDisplay[0],
    };
  }

  stringValue(): string {
    return String(this.value);
  }

  jsonValue(): object {
    return { value: this.value, type: this.type };
  }

  toReactNode(mode: string): ReactNode {
    if (mode === "Integer") {
      const intObj: Record<string, string | number> = {
        Decimal: this.value,
        Hex: `0x${this.value.toString(16)}`,
        Octal: this.value.toString(8),
        Binary: this.value.toString(2),

        // "Prime": isPrime(this.value) ? "Yes" : "No",
        // "Factors": getFactors(this.value).join(", ")
      };
      if (this.value < 127) {
        intObj["ASCII"] = String.fromCharCode(this.value);
      }
      if (this.value <= 0x10ffff) {
        intObj["Unicode"] = String.fromCodePoint(this.value);
      }
      return keyValueTable(intObj);
    } else if (mode === "Float") {
      const floatObj = {
        Decimal: this.value,
        Exponential: this.value.toExponential(),
        Fixed: this.value.toFixed(4),
        Precision: this.value.toPrecision(4),
        Rounded: Math.round(this.value),
        Truncated: Math.trunc(this.value),
      };

      return keyValueTable(floatObj);
    } else if (mode === "HTTP Status" && this.httpStatus) {
      return (
        <div className="flex flex-col">
          <pre className="mb-1">
            {this.httpStatus.code}: {this.httpStatus.phrase}
          </pre>
          <span className="text-secondary-700">{this.httpStatus.description}</span>
          <a href={this.httpStatus.spec_href} target="_blank" rel="noreferrer" className="text-primary-500 underline">
            {this.httpStatus.spec_href}
          </a>
        </div>
      );
    }
  }
}
