import tailwindcssAnimate from "tailwindcss-animate";
import plugin from "tailwindcss/plugin";

/** @type {import('tailwindcss').Config} */
export default {
  darkMode: ["class"],
  content: ["./src/**/*.{ts,tsx}"],
  prefix: "",
  theme: {
    fontFamily: {
      sans: '"Roboto", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      serif: '"Roboto Serif", ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
      mono: '"Roboto Mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    },
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      boxShadow: {
        neu: "2px 2px 0px 0px #090919",
      },
      colors: {
        primary: {
          "50": "#fff5ed",
          "100": "#ffe9d4",
          "200": "#ffd0a9",
          "300": "#ff9f58", // Primary Logo
          "400": "#fe8139",
          "500": "#fc5e13",
          "600": "#ed4309",
          "700": "#c53009",
          "800": "#9c2710",
          "900": "#7e2310",
          "950": "#440f06",
        },
        secondary: {
          "50": "#ecf0ff",
          "100": "#dde3ff",
          "200": "#c2ccff",
          "300": "#9caaff",
          "400": "#757cff",
          "500": "#5c58ff", // SplitComplement
          "600": "#4a36f5",
          "700": "#3f2ad8",
          "800": "#3325ae",
          "900": "#2d2689",
          "950": "#090919", // Secondary Logo
        },
        tertiary: {
          "50": "#f1ffe5",
          "100": "#e0ffc7",
          "200": "#c1ff95",
          "300": "#97ff58", // SplitComplement
          "400": "#71f625",
          "500": "#50dd05",
          "600": "#3ab100",
          "700": "#2d8605",
          "800": "#28690b",
          "900": "#23590e",
          "950": "#0d3201",
        },
        red: {
          "50": "#fff0f0",
          "100": "#ffdddd",
          "200": "#ffc0c0",
          "300": "#ff9494",
          "400": "#ff5757",
          "500": "#ff2323",
          "600": "#f20000",
          "700": "#d70000",
          "800": "#b10303",
          "900": "#920a0a",
          "950": "#500000",
        },
        yellow: {
          "50": "#fffbeb",
          "100": "#fff4c6",
          "200": "#ffe888",
          "300": "#ffd958", // Default
          "400": "#ffc320",
          "500": "#f9a107",
          "600": "#dd7802",
          "700": "#b75406",
          "800": "#94400c",
          "900": "#7a360d",
          "950": "#461a02",
        },
        element: {
          input: "#27ccff",
          argument: "#79d280",
          transformation: "#d59fff",
          recommended: "#9ecb62",
          secret: "#e7b001",
        },
        status: {
          success: "#45bf04",
          error: "#bf0410",
          pending: "#9104bf",
        },
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
  plugins: [
    tailwindcssAnimate,
    plugin(function ({ matchUtilities, theme }) {
      matchUtilities(
        {
          /**
           * Neubrutalism box.
           *
           * Sass implementation in `styles/neu.scss:neu`
           *
           * @param value - `neu-[<width>+<color>]`
           */
          neu: (value: string) => {
            const split = value.split("+");

            const width = split[0] || "2px";
            const color = split[1] ? theme(`colors.${split[1]}`) : theme("colors.secondary.950");

            return {
              border: `${width} solid ${color}`,
              boxShadow: `${width} ${width} 0px 0px ${color}`,
            };
          },
          /**
           * Neubrutalism flat box.
           *
           * Sass implementation in `styles/neu.scss:neu`
           *
           * @param value - `neu-flat-[<width>+<color>]`
           */
          "neu-flat": (value: string) => {
            const split = value.split("+");

            const width = split[0] || "2px";
            const color = split[1] ? theme(`colors.${split[1]}`) : theme("colors.secondary.950");

            return {
              border: `${width} solid ${color}`,
            };
          },
          /**
           * Neubrutalism button.
           *
           * Sass implementation in `styles/neu.scss:neu-button`
           *
           * @param value - `neu-button-[<width>+<color>+<hoverColor>]`
           */
          "neu-button": (value: string) => {
            const split = value.split("+");

            const width = split[0] || "2px";
            const color = split[1] ? theme(`colors.${split[1]}`) : theme("colors.secondary.950");
            const hoverColor = split[2] ? theme(`colors.${split[2]}`) : color;

            return {
              border: `${width} solid ${color}`,
              boxShadow: `${width} ${width} 0px 0px ${color}`,
              "&:hover": {
                transform: `translateX(calc(${width} / 2)) translateY(calc(${width} / 2))`,
                boxShadow: `calc(${width} / 2) calc(${width} / 2) 0px 0px ${hoverColor}`,
              },
              "&:active": {
                transform: `translateX(${width}) translateY(${width})`,
                boxShadow: `none`,
              },
              "&:disabled": {
                transform: `translateX(${width}) translateY(${width})`,
                boxShadow: `none`,
              },
            };
          },
        },
        {
          values: {
            DEFAULT: "2px",
          },
        }
      );
    }),
  ],
};
