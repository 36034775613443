import React from "react";
import { PipelineStateContext } from "@/lib/pipelines/config/pipeline-state-provider";

export const usePipelineState = () => {
  const context = React.useContext(PipelineStateContext);
  if (!context) {
    throw new Error("usePipelineState must be used within a PipelineStateProvider");
  }
  return context;
};
