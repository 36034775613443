import { ExternalLink } from "lucide-react";
import React from "react";
import { JobTimer } from "@/lib/pipelines/utils/timer";
import { WorkflowRunJob } from "@/api/types/response";
import { cn } from "@/utils/ui.util";
import { Link } from "react-router-dom";
import { format, intervalToDuration } from "date-fns";
import { CustomTooltip } from "@/components/custom-tooltip";
import { getJobStatusColor, getJobStatusIcon } from "@/lib/pipelines/utils/pipeline.utils";

interface JobProgressProps {
  job: WorkflowRunJob;
  workflowStart: Date;
  workflowDuration: number;
  workflowEnd: Date | null;
}

export const JobProgress: React.FC<JobProgressProps> = ({ job, workflowStart, workflowDuration, workflowEnd }) => {
  const jobStart = job.started_at ? new Date(job.started_at) : null;
  const jobEnd = job.completed_at ? new Date(job.completed_at) : workflowEnd;

  const startOffset = jobStart ? (jobStart.getTime() - workflowStart.getTime()) / 1000 : 0;
  const jobDuration = jobStart && jobEnd ? (jobEnd.getTime() - jobStart.getTime()) / 1000 : 0;
  const jobProgress = (jobDuration / workflowDuration) * 100;
  const startPosition = (startOffset / workflowDuration) * 100;

  const jobDurationFormatted = React.useMemo(() => {
    if (!jobDuration) {
      return "";
    }

    const duration = intervalToDuration({
      start: 0,
      end: jobDuration * 1000,
    });

    return `${duration.hours ? `${duration.hours}h ` : ""}${duration.minutes ? `${duration.minutes}m ` : ""}${duration.seconds ? `${duration.seconds}s` : ""}`;
  }, [jobDuration]);

  const hoverDetails = {
    items: [
      {
        key: "Job Name",
        value: (
          <div className="flex items-center gap-1">
            <Link to={job.html_url ?? ""} target="_blank" className="text-primary-500 underline font-medium">
              {job.name}
            </Link>
            <ExternalLink size={12} />
          </div>
        ),
      },
      { key: "Status", value: job.status },
      { key: "Conclusion", value: job.conclusion },
      { key: "Started At", value: format(job.started_at, "yyyy-MM-dd HH:mm:ss") },
      { key: "Completed At", value: job.completed_at ? format(job.completed_at, "yyyy-MM-dd HH:mm:ss") : "N/A" },
      { key: "Duration", value: jobDurationFormatted },
    ],
  };

  return (
    <CustomTooltip tooltipItems={hoverDetails.items}>
      <div
        className={cn(
          "flex gap-2 items-center p-2 w-full rounded-lg neu bg-gray-500/60 hover:cursor-pointer",
          getJobStatusColor(job.status, job.conclusion)
        )}
        style={{ marginLeft: `${startPosition}%`, width: `${jobProgress}%` }}
      >
        <div className="flex gap-1 items-center">{getJobStatusIcon(job.status, job.conclusion, 16)}</div>
        <div className="text-xs text-wrap line-clamp-1 break-all">
          <JobTimer startTime={job.started_at} endTime={job.completed_at} />
        </div>
      </div>
    </CustomTooltip>
  );
};
