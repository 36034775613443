import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { StringState } from "../states";
import JsonToTS from "json-to-ts";

export class JsonToTypescriptTransformation extends StateTransformation<StringState> {
  constructor() {
    super("json-to-typescript", "To Typescript", ["json"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().jsonValue) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<StringState> {
    if (state.config().jsonValue) {
      let result = "";
      JsonToTS(state.jsonValue()).forEach((typeInterface: string) => {
        result += typeInterface + "\n";
      });
      return new StringState(result, "Typescript");
    }

    throw new Error("Input must be JSON");
  }
}

export default new JsonToTypescriptTransformation();
