import { fileTypeFromBuffer } from "file-type";
import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { HttpHeadersState, InvalidState, KeyValueState, MultipartState, RequestState, StringState } from "../states";
import { BlobState } from "../states/blob.state";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type ExtractMode = {
  selected: "body" | "headers" | "query" | "cookies";
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class ExtractFromRequestTransformation extends StateTransformation<State, ExtractMode> {
  constructor() {
    super("extract-request", "Extract", ["request"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (RequestState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: ExtractMode): Promise<State> {
    if (RequestState.is(state)) {
      const mode = options?.selected;
      switch (mode) {
        case "body": {
          if (state.getRequest().isMultipart())
            return new MultipartState(
              new Uint8Array(await state.getRequest().getBodyAsBlob().arrayBuffer()),
              state.getRequest().getContentTypeHeaderValue()
            );

          switch (state.hasBody()) {
            case "base64": {
              const blob = state.getRequest().getBodyAsBlob();
              return new BlobState(blob, { mime: (await fileTypeFromBuffer(await blob.arrayBuffer()))?.mime });
            }
            case "none":
              return new InvalidState("No body found.");
            case "string":
              return new StringState(state.getBodyAsString() ?? "");
            default:
              throw new Error("Invalid body type.");
          }
        }
        case "headers":
          if (state.value.headers === undefined) return new InvalidState("No headers found.");
          return new HttpHeadersState(state.value.headers);
        case "query":
          return new StringState(JSON.stringify(state.value.queryStringParameters));
        case "cookies":
          return new KeyValueState(state.getRequest().getCookies(), "Cookies");
      }
    }

    throw new Error("Not a HTTP request");
  }

  async getOptions(state: State, options?: ExtractMode): Promise<ExtractMode> {
    const values: Record<string, string> = { headers: "headers" };
    if (RequestState.is(state)) {
      if (state.hasBody() !== "none") {
        values.body = "body";
      }
      if (state.value.queryStringParameters !== null) {
        values.query = "query";
      }
      if (state.getRequest().hasCookies()) {
        values.cookies = "cookies";
      }
    }
    return {
      selected: options?.selected ?? "headers",
      values: values,
      label: "extract from",
    };
  }

  getOptionsReactNode(options: ExtractMode, onChange: (options: ExtractMode) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange as any} options={options} />;
  }
}

export default new ExtractFromRequestTransformation();
