import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { DateTime } from "luxon";
import { keyValueTable } from "../utils/tools-display-utils";

export class DateState extends State<DateTime> {
  constructor(
    value: DateTime,
    readonly source: string | null = null
  ) {
    super(value);
  }

  toReactNode(_: string): ReactNode {
    const local = this.value.toLocal();

    const localDates: Record<string, string | number | null> = {
      "Local Date": local.toLocal().toLocaleString(DateTime.DATETIME_FULL),
      Relative: local.toRelative({ round: false, style: "long" }),
      "Local timezone": local.zoneName,
      "Local offset": local.offsetNameShort,
      "Fixed offset": local.isOffsetFixed ? "Yes" : "No",
    };

    const possibleOffsets = local.getPossibleOffsets();
    if (possibleOffsets.length > 1) {
      localDates["AMBIGUOUS date, possible offsets"] = possibleOffsets.map((o) => o.toString()).join(", ");
    }

    const UnixDates = {
      "Unix integer": this.value.toUnixInteger().toString(),
      Unix: this.value.toSeconds().toString(),
      "Unix millis": this.value.toMillis().toString(),
    };
    const relativeDates = {
      "Relative (hours)": this.value.toRelative({ round: false, style: "long", unit: "hours" }),
      "Relative (minutes)": this.value.toRelative({ round: false, style: "long", unit: "minutes" }),
      "Relative (days)": this.value.toRelative({ round: false, style: "long", unit: "days" }),
    };

    return (
      <div>
        <div className="text-xs bg-primary-300 p-1">UTC</div>
        <div>
          {keyValueTable({
            UTC: this.value.toUTC().toLocaleString(DateTime.DATETIME_FULL),
            "UTC ISO8601": this.value.toUTC().toJSDate().toISOString(),
          })}
        </div>

        <div className="text-xs bg-lime-200 p-1">Local timezone ({local.zoneName})</div>
        <div>{keyValueTable(localDates)}</div>

        {this.value.zoneName && local.zoneName != this.value.zoneName && (
          <>
            <div className="text-xs bg-sky-200 p-1">Source ({this.value.zoneName})</div>
            <div>
              {keyValueTable({
                "Input zone": this.value.zoneName,
                "Local in this zone": this.value.setZone(this.value.zoneName).toLocaleString(DateTime.DATETIME_FULL),
                "Date Parsed by": this.source,
              })}
            </div>
          </>
        )}

        <div className="text-xs bg-secondary-200 p-1">Unix</div>
        <div>{keyValueTable(UnixDates)}</div>
        <div className="text-xs bg-secondary-200 p-1">Relative</div>
        <div>{keyValueTable(relativeDates)}</div>
      </div>
    );
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      transformationTags: ["date"],
      validationTags: ["date"],
      validations: "none",
      stringValue: true,
      jsonValue: true,
      customDisplay: ["Date"],
      defaultDisplayMode: "Date",
    };
  }

  jsonValue(): object {
    return this.value.toUTC().toObject({ includeConfig: true }) ?? {};
  }

  override stringValue(): string {
    return JSON.stringify(this.value) ?? "invalid";
  }
}
