import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { getStandardHeaderInfo } from "../utils/http-header-utils";

export class HttpHeaderState extends State<string> {
  constructor(
    value: string | undefined,
    readonly headerName: string
  ) {
    super(value ?? "");
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      transformationTags: ["string"],
      validations: "tags-only",
      validationTags: ["string"],
      stringValue: true,
      jsonValue: false,
      customDisplay: ["Header"],
      defaultDisplayMode: "Header",
    };
  }

  stringValue(): string {
    return `${this.value}`;
  }

  toReactNode(_: string): ReactNode {
    const stdHeader = getStandardHeaderInfo(this.headerName);

    return (
      <div className="flex flex-col">
        <pre className="mb-1">
          {this.headerName}: {this.value}
        </pre>
        {stdHeader && <span className="text-xs text-secondary-700">{stdHeader.Description}</span>}
      </div>
    );
  }
}
