import { Handle, Position, type Node, type NodeProps } from "@xyflow/react";
import { useFlowSettings } from "@/lib/pipelines/flow-view/flow-settings-manager/useFlowSettings";

type InitialNode = Node<{
  title: string;
  width?: number;
  height?: number;
}>;

export const InitialNode: React.FC<NodeProps<InitialNode>> = ({ data }) => {
  const { direction } = useFlowSettings();
  return (
    <>
      <div className="neu-flat rounded-lg w-64 bg-primary-200/50">
        <div className="flex p-2">
          <div className="font-semibold m-auto">{data.title}</div>
        </div>
      </div>
      <Handle type="source" position={direction === "TB" ? Position.Bottom : Position.Right} />
    </>
  );
};
