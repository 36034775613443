import { AccountUsers } from "@/lib/settings/account-users";
import React from "react";
import { Navigate, useOutletContext } from "react-router-dom";
import { SettingsOutletContext } from "@/routes/settings/settings";

export const Account: React.FC = () => {
  const { user } = useOutletContext<SettingsOutletContext>();

  if (user.role !== "owner") {
    return <Navigate to="/settings" replace={true} />;
  }

  return (
    <div className="space-y-6 px-4">
      <div>
        <h5 className="font-semibold tracking-tight">Account</h5>
        <small>Manage your account settings, credentials, and other preferences.</small>
      </div>
      <div className="space-y-8">
        <AccountUsers />
      </div>
    </div>
  );
};
