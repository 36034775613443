import { Button } from "@/components/ui/button";
import { ChevronRight, ListStartIcon } from "lucide-react";
import React from "react";
import { useCurrentTransformation } from "../useCurrentTransformation";
import { useStateEditorHistory } from "./useStateEditorHistory";
import { TransformationId } from "../state-transformation";
import { ParserInput } from "@/routes/tools/tools-input";
import { StatePreview } from "../StatePreview";
import { ToolsSnakeInputNode } from "../state-utils";
import { toToolIcon } from "../transformations/common-options/options.transformations";
import { useSecretsManager } from "@/lib/tools/secrets-manager/useSecretsManager";

export interface StateEditorProps {
  input: ParserInput;
  initialTransformation?: TransformationId;
}

export const StateEditor: React.FC<StateEditorProps> = ({ input, initialTransformation }) => {
  const [initialTransform] = React.useState<ToolsSnakeInputNode[]>(
    initialTransformation ? [{ transformationId: initialTransformation }] : []
  );
  const { secrets } = useSecretsManager();
  const history = useStateEditorHistory(input, secrets, initialTransform);

  const { validTransformations, optionsRender, state } = useCurrentTransformation({
    node: history.current,
    updateTransformation: (transformationId, transformationOptions) => {
      history.updateLastItem({ transformationId, options: transformationOptions });
    },
  });

  return (
    <div className="grid grid-cols-[1fr,135px] grid-rows-[min-content,1f] gap-x-4 max-h-full">
      <div className="flex flex-col gap-2">
        <div className="flex flex-wrap items-center gap-x-1 gap-y-2 mb-2">
          <Button variant="ghost" size="icon" onClick={() => history.setCurrent(-1)}>
            <ListStartIcon />
          </Button>

          {history.builtItems.map((item, i) => {
            return (
              <React.Fragment key={`history-item-${i}`}>
                {i < history.builtItems.length && <ChevronRight size={16} />}

                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => history.setCurrent(i)}
                  children={item.transformationId} /* name */
                  disabled={i >= history.builtItems.length - 1}
                />
              </React.Fragment>
            );
          })}
        </div>
        {/* Transformation Options */}
        {optionsRender && <div>{optionsRender}</div>}
        {state && <StatePreview state={state} snakeContext={history.snakeContext} />}
      </div>

      <div>
        <h5 className="font-bold text-center mb-2 border-b-2 border-b-secondary-950">Transformations</h5>

        {/* Transformations */}
        {!!validTransformations?.length && (
          <div className="flex flex-col gap-2">
            {validTransformations.map(({ valid, transformation }, i) => {
              return (
                <Button
                  key={`transformation-trigger-${i}`}
                  value={transformation.name}
                  disabled={!valid}
                  className="w-full block hover:bg-tertiary-200 truncate"
                  size="sm"
                  onClick={() => {
                    history.addItem({
                      transformationId: transformation.id,
                      options: undefined,
                    });
                  }}
                >
                  <div className="flex items-center">
                    {toToolIcon(transformation.icon())}
                    {transformation.name}
                    {valid < 1 && ` (${valid * 100}%)`}
                  </div>
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
