import { APIGatewayProxyEvent } from "aws-lambda";
import { base64StringToBlob } from "blob-util";
import mime from "mime";

export class RequestHelper {
  constructor(readonly value: APIGatewayProxyEvent) {}

  getBodyAsBlob() {
    switch (this.hasBody()) {
      case "none":
        throw new Error("No body found in request.");
      case "base64":
        return base64StringToBlob(this.value.body as string, this.getContentTypeHeaderValue());
      case "string":
        throw new Blob([this.value.body as string], { type: this.getContentTypeHeaderValue() });
    }
  }

  hasQueryParams() {
    return this.value.queryStringParameters !== null;
  }

  getCookiesHeaderValue() {
    if (this.value?.multiValueHeaders) {
      const headers = this.value.multiValueHeaders;

      // Filter out and merge all cookie headers (case insensitive)
      const cookieValues = Object.keys(headers)
        .filter((header) => header.toLowerCase() === "cookie")
        .map((header) => headers[header])
        .flat()
        .join("; ");

      return cookieValues;
    }
    return undefined;
  }

  hasCookies() {
    return this.getCookiesHeaderValue() !== undefined;
  }

  getCookies(): Record<string, string> {
    return Object.fromEntries(
      this.getCookiesHeaderValue()
        ?.split(";")
        .map((x) => x.split("=").map((y) => y.trim())) ?? []
    );
  }

  isMultipart(): boolean {
    return this.getContentTypeHeaderValue().indexOf("multipart") !== -1;
  }

  hasBody() {
    if (!this.value?.body) return "none";
    if (this.value.isBase64Encoded) return "base64";
    return "string";
  }

  getBodyAsString() {
    switch (this.hasBody()) {
      case "none":
        throw new Error("No body found in request.");
      case "base64":
        return Buffer.from(this.value.body as string, "base64").toString("binary");
      case "string":
        return this.value.body;
    }
  }

  getBodyLength() {
    switch (this.hasBody()) {
      case "none":
        return 0;
      case "base64":
        return this.getBodyAsBlob().size;
      case "string":
        return this.getBodyAsString()?.length ?? 0;
    }
  }

  getContentType() {
    return mime.getExtension(this.getContentTypeHeaderValue());
  }
  getContentTypeHeaderValue(): string {
    const headers = this.value?.headers;
    if (headers) {
      return Object.entries(headers).find(([key]) => key.toLowerCase() === "content-type")?.[1] ?? "";
    }
    return "";
  }

  getMethod() {
    return this.value.httpMethod;
  }

  getPath() {
    return this.value.path;
  }

  getHeaders() {
    return this.value.headers;
  }

  getSource() {
    return this.value.requestContext.identity.sourceIp;
  }

  getTime() {
    return new Date(this.value.requestContext.requestTimeEpoch).toUTCString();
  }

  getRequestId() {
    return this.value.requestContext.requestId;
  }

  getQueryParams() {
    if (this.hasQueryParams()) {
      return this.value.queryStringParameters!;
    }

    return {};
  }
}
