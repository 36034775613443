import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import stringUtils from "../utils/string-utils";
import { keyValueTable } from "../utils/tools-display-utils";

type BasicHttpAuth = {
  name: string;
  password: string;
};

export class BasicHttpAuthState extends State<BasicHttpAuth> {
  constructor(value: BasicHttpAuth) {
    super(value);
  }

  stringValue(): string {
    return `Basic ${stringUtils.base64Encode(`${this.value.name}:${this.value.password}`)}`;
  }
  config(): StateConfig {
    return {
      transformations: "tags-only",
      transformationTags: ["http-auth", "json"],
      validations: "tags-only",
      stringValue: true,
      jsonValue: true,
      customDisplay: ["Basic Auth"],
      defaultDisplayMode: "Basic Auth",
    };
  }

  jsonValue(): object {
    return {
      username: this.value.name,
      password: this.value.password,
    };
  }

  toReactNode(customType: string): ReactNode {
    if (customType === "Basic Auth")
      return keyValueTable({
        Username: this.value.name,
        Password: this.value.password,
      });
  }
}
