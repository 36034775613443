import { ReactNode } from "react";
import { State, StateConfig } from "../state";

export class SmartCompareResultState extends State<ReactNode> {
  constructor(value: ReactNode) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "none",
      validations: "none",
      stringValue: false,
      jsonValue: false,
      customDisplay: ["Smart-compare"],
      defaultDisplayMode: "Smart-compare",
    };
  }

  stringValue(): string {
    throw new Error("Method not implemented.");
  }

  toReactNode(_: string): ReactNode {
    return <>{this.value}</>;
  }
}
