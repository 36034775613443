import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ApiMutation } from "@/api";
import { useFormik } from "formik";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { VerificationType } from "@/api/types/request";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/auth/useAuth";
import { ResendVerificationCode } from "./elements/resend-verification-code";
import { LoadingSpinner } from "@/components/loading-spinner";
import * as Yup from "yup";
import { cn } from "@/utils/ui.util";
import { defaultError } from "./utils/default-error-message";

const ResetPasswordSchema = Yup.object().shape({
  token: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Must have at least 6 characters")
    .max(30, "Too long")
    .matches(/^(?=.*[A-Z])(?=.*\d)/, "Must contain at least one uppercase letter and one number")
    .required("Required"),
  repeatPassword: Yup.string().oneOf([Yup.ref("password"), undefined], "Passwords must match"),
});

interface ResetPasswordFormProps {
  title: string;
  description: string;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { toast } = useToast();
  const { login } = useAuth();

  const resetPasswordMutation = ApiMutation.useResetPassword({
    onSuccess: (data) => {
      if (data.token) {
        // Login if token is present
        login(data.token);
        navigate("/dashboard");
      } else {
        // Navigate to verify page if token is not present
        navigate("/auth/verify", { state: { email: state.email } });
      }
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
      token: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      resetPasswordMutation.mutate({ email: state.email, password: values.password, token: values.token });
    },
  });

  if (!state || !state.email) {
    return <Navigate to="/auth" replace={true} />;
  }

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex justify-center items-center gap-4">
          <div className="flex items-center gap-3">
            <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
            <h3 className="font-bold font-mono">wt.dev</h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small className="text-center">{description}</small>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1.5">
            <Input
              id="token"
              placeholder="Confirmation Code"
              type="text"
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
              required
              value={formik.values.token}
              onChange={formik.handleChange}
              className={cn(formik.touched.token && formik.errors.token && "bg-red-200")}
            />
            {formik.touched.token && formik.errors.token && <p className="text-xs">{formik.errors.token}</p>}

            <Input
              id="password"
              placeholder="New Password"
              type="password"
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
              required
              value={formik.values.password}
              onChange={formik.handleChange}
              className={cn(formik.touched.password && formik.errors.password && "bg-red-200")}
            />
            {formik.touched.password && formik.errors.password && <p className="text-xs">{formik.errors.password}</p>}

            <Input
              id="repeatPassword"
              placeholder="Repeat Password"
              type="password"
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
              required
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              className={cn(formik.touched.repeatPassword && formik.errors.repeatPassword && "bg-red-200")}
            />
            {formik.touched.repeatPassword && formik.errors.repeatPassword && (
              <p className="text-xs">{formik.errors.repeatPassword}</p>
            )}
          </div>
          <Button
            type="submit"
            scheme="black"
            size="sm"
            disabled={resetPasswordMutation.isPending}
            className="bg-secondary-950"
          >
            {resetPasswordMutation.isPending ? <LoadingSpinner message={"Submitting"} /> : "Submit"}
          </Button>
        </div>
      </form>
      <div className="flex justify-center gap-2 text-sm">
        <small>Didn't receive the code?</small>
        <ResendVerificationCode email={state.email} type={VerificationType.ResetPassword} />
      </div>
    </Card>
  );
};
