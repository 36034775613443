import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { HttpHeadersState, HttpHeaderState, InvalidState } from "../states";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type ExtractMode = {
  selected: string;
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class ExtractHeaderTransformation extends StateTransformation<State, ExtractMode> {
  constructor() {
    super("extract-header", "Extract", ["http-headers"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (HttpHeadersState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: ExtractMode): Promise<State> {
    const selected = options?.selected;
    if (HttpHeadersState.is(state)) {
      const selectedHeader = state.headers.find((header) => header.headerName === selected);
      if (selectedHeader) {
        return new HttpHeaderState(selectedHeader.value, selectedHeader.headerName);
      }
      return new InvalidState("Header not found");
    }

    throw new Error("Not a HTTP headers collection");
  }

  async getOptions(state: State, options?: ExtractMode): Promise<ExtractMode | undefined> {
    if (HttpHeadersState.is(state)) {
      return {
        selected: options?.selected || state.headers[0].headerName,
        values: state.headers.reduce((acc, header) => ({ ...acc, [header.headerName]: header.headerName }), {}),
        label: "extract header",
      };
    }
    return undefined;
  }

  getOptionsReactNode(options: ExtractMode, onChange: (options: ExtractMode) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange as any} options={options} />;
  }
}

export default new ExtractHeaderTransformation();
