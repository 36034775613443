import React from "react";
import { Outlet } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";

export const ToolsRoute: React.FC = () => {
  return (
    <div className="flex bg-secondary-200 w-full">
      <ScrollArea className="w-full">
        <div className="flex justify-center py-4">
          <div className="md:w-10/12 max-w-[1440px]">
            {/* Content */}
            <Outlet />
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
