import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { CircleOffIcon } from "lucide-react";

export class InvalidState extends State<string> {
  constructor(
    reason = "",
    readonly errorDetails = "",
    readonly viewType = "Invalid"
  ) {
    super(reason);
  }

  stringValue(): string {
    return `${this.value}: ${this.errorDetails}`;
  }

  config(): StateConfig {
    return {
      transformations: "none",
      validations: "none",
      stringValue: false,
      jsonValue: false,
      customDisplay: [this.viewType],
      defaultDisplayMode: this.viewType,
    };
  }

  toReactNode(_: string): ReactNode {
    return (
      <div className="flex flex-col text-red-500">
        <div className="flex font-bold">
          <CircleOffIcon className="w-6 h-6  mr-3" />
          {this.value}
        </div>
        <div className="text-sm">{this.errorDetails}</div>
      </div>
    );
  }
}
