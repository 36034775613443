import { AdvancedHeadersTable } from "@/components/advanced-headers-table";
import { HeadersTable } from "@/components/headers-table";
import { StatusIndicator } from "@/components/status-indicator";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Status } from "@/enums/status";
import { Headers } from "@protontech/jsmimeparser";

enum EmailValidationResult {
  PASS = "pass",
}

interface AuthenticationResults {
  spf: {
    details: React.ReactNode;
    value: string;
  };
  dkim: {
    details: React.ReactNode;
    value: string;
  };
  dmarc: {
    details: React.ReactNode;
    value: string;
  };
  [key: string]: {
    details: React.ReactNode | undefined;
    value: string | string[] | undefined;
  };
}

const parseAuthenticationResults = (results: string): AuthenticationResults => {
  const parts = results.split(";").map((part) => part.trim());

  const authenticationResults: AuthenticationResults = {
    spf: {
      details: undefined,
      value: "",
    },
    dkim: {
      details: undefined,
      value: "",
    },
    dmarc: {
      details: undefined,
      value: "",
    },
  };

  for (let i = 1; i < parts.length; i++) {
    const keyValue = parts[i].split("=").map((item) => item.trim());

    if (keyValue.length < 2) continue;

    const status = keyValue[1].split(" ")[0] === EmailValidationResult.PASS ? Status.SUCCESS : Status.WARNING;

    const authenticationResultItem = {
      details: <StatusIndicator status={status} />,
      value: keyValue[1],
    };

    switch (keyValue[0]) {
      case "spf":
        authenticationResults.spf = authenticationResultItem;
        break;

      case "dkim":
        authenticationResults.dkim = authenticationResultItem;
        break;

      case "dmarc":
        authenticationResults.dmarc = authenticationResultItem;
        break;
    }
  }

  return authenticationResults;
};

interface EmailRequestDetails {
  host: string;
  date: string;
  size: string;
  [key: string]: string | string[];
}

interface VerificationChecksProps {
  requestDetails: EmailRequestDetails;
  headers: Headers;
}

export const VerificationChecks: React.FC<VerificationChecksProps> = ({ requestDetails, headers }) => {
  const authenticationResults =
    headers["authentication-results"] && headers["authentication-results"][0]
      ? parseAuthenticationResults(headers["authentication-results"][0])
      : {};

  return (
    <ScrollArea className="h-full max-w-full">
      <HeadersTable title={"Request Details"} headers={requestDetails} type="custom" />
      <AdvancedHeadersTable title={"Authentication Results"} headers={authenticationResults} />
    </ScrollArea>
  );
};
