import { DownloadBlob } from "@/components/download-blob";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ParserInitialSetup } from "@/routes/tools/tools-samples-inputs";
import { StatePopover } from "@/utils/parsing";
import { RequestState } from "@/utils/parsing/states/request.state";
import { RequestHelper } from "@/utils/parsing/utils/request-helper";
import { ArrowUpRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface EmailHeaderProps {
  requestId: string;
  method: string;
  path: string;
  requestHelper: RequestHelper;
}

export const RequestHeader: React.FC<EmailHeaderProps> = ({ requestId, method, path, requestHelper }) => {
  const blob = new Blob([JSON.stringify(requestHelper.value)], { type: "application/json" });
  const requestState = new RequestState(requestHelper.value);

  const navigate = useNavigate();
  function navigateToTools(): void {
    const state: ParserInitialSetup = {
      input: { type: "http-request", value: requestHelper.value },
      initialSnake: [{ transformationId: "extract-request" }],
      name: "Imported HTTP Request",
    };
    navigate(`/tools`, { state: state });
  }

  return (
    <div className="flex items-center p-4 gap-2 justify-between">
      <div className="flex gap-4 text-sm">
        <div className="flex items-center gap-2">
          <div className="flex gap-4">
            <Badge variant="secondary">
              <div className="font-semibold">{method}</div>
            </Badge>
            <div className="break-all line-clamp-1 text-wrap">
              <p className="text-sm font-mono">{path}</p>
            </div>
          </div>
          <StatePopover input={{ type: "state", state: requestState }} />
        </div>
      </div>
      <div className="flex items-center gap-1">
        <Button
          variant="ghost"
          size="sm"
          className="hidden sm:flex items-center gap-1 bg-secondary-200"
          onClick={navigateToTools}
        >
          Explore
          <ArrowUpRight size={16} />
        </Button>

        <DownloadBlob blob={blob} filename={requestId} />
      </div>
    </div>
  );
};
