import { useLatestRunJobs } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate, useNavigate } from "react-router-dom";
import { WorkflowRunJobs } from "@/api/types/response";
import React from "react";
import { DynamicContentWrapper, WorkflowData } from "@/lib/pipelines/utils/dynamic-content-wrapper";
import { useWorkflowTimings } from "@/lib/pipelines/utils/useWorkflowTimings";
import { JobTimeline } from "@/lib/pipelines/list-view/elements/job-timeline";
import { JobProgress } from "@/lib/pipelines/list-view/elements/job-progress";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { pluralize } from "@/utils/ui.util";
import { CustomTooltip } from "@/components/custom-tooltip";
import { ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";

export const WorkflowStatus: React.FC<WorkflowData> = (workflowData) => {
  const navigate = useNavigate();

  const {
    isPending,
    isError,
    data: response,
  } = useLatestRunJobs({
    owner: workflowData.owner,
    repo: workflowData.repo,
    workflowId: workflowData.workflowId,
    resourceJwt: workflowData.workflowResourceJwt,
  });

  return (
    <>
      <div className="flex flex-col gap-4 bg-secondary-50 neu rounded-lg p-4">
        <>
          {isPending && (
            <div className="flex">
              <LoadingSpinner message={"Loading..."} />
            </div>
          )}
          {isError && (
            <div className="flex items-center gap-1">
              <div className="text-sm text-gray-600">Oops! Something went wrong.</div>
              <div className="text-sm text-gray-600">
                <Button
                  size="sm"
                  scheme="link"
                  variant="ghost"
                  className="hover:bg-transparent h-full p-0"
                  onClick={() => navigate(0)}
                >
                  Try Again
                </Button>
              </div>
            </div>
          )}
          {!isPending && !isError && (
            <>
              {response.status === "reject" && <Navigate to="/pipelines/auth" replace={true} />}
              {response.status === "success" && (
                <DynamicContentWrapper
                  run={response.data.runJobs}
                  dependencies={response.data.dependencies}
                  workflowData={workflowData}
                  latestRunResourceJwt={response.data.resourceJwt}
                >
                  {({ run, workflowData }) => <WorkflowStatusContent run={run} workflowData={workflowData} />}
                </DynamicContentWrapper>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

interface WorkflowStatusContentProps {
  workflowData: WorkflowData;
  run: WorkflowRunJobs;
}

const WorkflowStatusContent: React.FC<WorkflowStatusContentProps> = ({ workflowData, run }) => {
  const [now, setNow] = React.useState(new Date());

  React.useEffect(() => {
    if (run.jobs.some((job) => job.status === "in_progress" || job.status === "queued")) {
      const interval = setInterval(() => setNow(new Date()), 1000);
      return () => clearInterval(interval);
    }
  }, [run]);

  const { workflowStart, workflowEnd, workflowDuration, workflowDurationFormatted } = useWorkflowTimings(run, now);

  if (run.total_count === 0) {
    return (
      <div className="flex flex-col gap-2">
        <div>
          <Link
            to={workflowData.htmlUrl}
            target="_blank"
            className="text-sm font-semibold underline hover:cursor-pointer text-secondary-950"
          >
            {workflowData.title}
          </Link>
        </div>
        <div className="text-sm text-gray-600">No Runs Found</div>
      </div>
    );
  }

  if (!workflowStart || !workflowDuration) {
    return null;
  }

  const displayedWorkflowDuration = workflowDuration > 61 ? workflowDuration : 61;

  const workflowDetails = {
    items: [
      {
        key: "Workflow",
        value: (
          <div className="flex items-center gap-1">
            <Link to={workflowData.htmlUrl} target="_blank" className="text-primary-500 underline font-medium">
              {workflowData.title}
            </Link>
            <ExternalLink size={12} />
          </div>
        ),
      },
      {
        key: "Started At",
        value: format(workflowStart, "yyyy-MM-dd HH:mm:ss"),
      },
      {
        key: "Completed At",
        value: workflowEnd ? format(workflowEnd, "yyyy-MM-dd HH:mm:ss") : "N/A",
      },
      {
        key: "Duration",
        value: workflowDurationFormatted,
      },
      {
        key: "Total Jobs",
        value: run.total_count,
      },
    ],
  };

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-2 space-y-1">
          <div className="flex items-center gap-2">
            <CustomTooltip tooltipItems={workflowDetails.items}>
              <Link
                to={workflowData.htmlUrl}
                target="_blank"
                className="text-sm font-semibold no-underline hover:underline hover:cursor-pointer text-secondary-950"
              >
                {workflowData.title}
              </Link>
            </CustomTooltip>
          </div>
          <div>
            <div className="text-xs text-gray-600">
              Duration: {workflowDurationFormatted} ({run.total_count} {pluralize("job", run.total_count)})
            </div>
            <div className="text-xs text-gray-600">{formatDistanceToNow(workflowStart, { addSuffix: true })} </div>
          </div>
        </div>
        <div className="col-span-10">
          <JobTimeline workflowDuration={displayedWorkflowDuration} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        {run.jobs.map((job) => (
          <React.Fragment key={job.id}>
            <div className="col-span-2">
              <div className="flex">
                <Link
                  to={job.html_url ?? ""}
                  target="_blank"
                  className="no-underline hover:underline text-secondary-950"
                >
                  <p className="text-xs font-semibold">{job.name}</p>
                </Link>
              </div>
              <p className="text-xs text-gray-600">{job.status}</p>
            </div>
            <div className="col-span-10">
              <JobProgress
                job={job}
                workflowStart={workflowStart}
                workflowDuration={displayedWorkflowDuration}
                workflowEnd={workflowEnd}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
