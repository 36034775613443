import { CodeBlock } from "@/components/code-block";
import { ColorLegendPopover } from "@/components/color-legend";
import { ToolsPage } from "@/components/pages/tools-page";
import { Badge } from "@/components/ui/badge";
import { SecretsManagerPopover } from "@/lib/tools/secrets-manager/secrets-manager-popover";
import { useSecretsManager } from "@/lib/tools/secrets-manager/useSecretsManager";
import { ValidationsPopover } from "@/lib/tools/validations/validations-popover";
import { ToolsSamples } from "@/routes/tools/tools-samples";
import { useFileClipboard, useFileDrop } from "@/utils/file.util";
import { StateDisplay } from "@/utils/parsing";
import { useStateEditorHistory } from "@/utils/parsing/editor/useStateEditorHistory";
import { decode } from "@/utils/parsing/utils/compression-utils";
import { base64StringToBlob, blobToBase64String } from "blob-util";
import clsx from "clsx";
import { fileTypeFromBuffer } from "file-type";
import React from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ParserInput, ParserInputBlob } from "./tools-input";
import { ParserInitialSetup, samples } from "./tools-samples-inputs";

export const AutodetectParserRoute: React.FC = () => {
  const { sample } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const s: ParserInitialSetup = React.useMemo(() => {
    // Redirected input
    const redirectInput: ParserInitialSetup | undefined = location.state;

    if (redirectInput) {
      console.log("Redirected input", redirectInput);
      return redirectInput as unknown as ParserInitialSetup;
    }

    // Restore from URL
    const restore = searchParams.get("restore") ?? null;

    if (restore) {
      const decoded = decode(restore);

      if (decoded.input?.type === "blob") {
        const blob = base64StringToBlob(
          decoded.input.base64data,
          decoded.input.detectedType?.mime ?? "application/octet-stream"
        );

        decoded.input.value = blob;
      }

      return decoded;
    }

    // Sample input
    return (sample ? samples?.[sample] : null) || samples.default;
  }, [sample, location.state, searchParams]);

  const dropRef = React.useRef<HTMLDivElement>(null);

  const [input, setInput] = React.useState<ParserInput>(() => s.input);
  const [parserInput, setParserInput] = React.useState<ParserInput>(() => s.input);

  const debounced = useDebouncedCallback((value) => {
    setParserInput(value);
  }, 200);

  React.useEffect(() => {
    setInput(s.input);
    setParserInput(s.input);
    debounced.cancel();
  }, [debounced, s]);

  const { secrets } = useSecretsManager();
  const history = useStateEditorHistory(parserInput, secrets, s.initialSnake);

  const { activeDrag } = useFileDrop(dropRef, async (files) => {
    console.log("Files from drop", files);
    if (files.length === 0) setInput(null);
    const i: ParserInputBlob = {
      type: "blob",
      value: files[0],
      detectedType: await fileTypeFromBuffer(await files[0].arrayBuffer()),
      filename: files[0].name,
      base64data: await blobToBase64String(files[0]),
    };
    setInput(i);
    setParserInput(i);
  });

  useFileClipboard(async (files) => {
    console.log("Files from clipboard", files);
    if (files.length === 0) setInput(null);
    const i: ParserInputBlob = {
      type: "blob",
      value: files[0],
      detectedType: await fileTypeFromBuffer(await files[0].arrayBuffer()),
      filename: files[0].name,
      base64data: await blobToBase64String(files[0]),
    };
    setInput(i);
    setParserInput(i);
  });

  const snakeNodes = history.builtItems;

  const textMode = input?.type === "string";

  const resetHistory = () => {
    setInput(samples.default.input);
    setParserInput(samples.default.input);
    history.setInputNodes(samples.default.initialSnake);
  };

  return (
    <div className="flex-1 space-y-1">
      {/* Header */}
      <div className="px-4 md:px-6 md:pt-6">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-between items-end">
            <h4 className="font-bold tracking-tight">
              <div className="flex gap-1">
                Tools
                <sup>
                  <Badge className="p-0 px-1 text-xs bg-secondary-950 hover:no-underline hover:bg-secondary-950">
                    Beta
                  </Badge>
                </sup>
              </div>
            </h4>
            <ToolsSamples
              parserSetup={{
                input: parserInput,
                initialSnake: history.builtItems,
                name: "Restore from URL",
              }}
              resetHistory={resetHistory}
            />
          </div>
          <small>Use these tools to parse and inspect data from your systems.</small>
        </div>
      </div>

      <ToolsPage key={sample} title={s.name} description={s.description}>
        <div className="flex justify-between items-end">
          {/* Desktop validations */}
          <div className="hidden sm:flex gap-1">
            {textMode && history.inputValidations.map((validation, i) => <div key={i}>{validation.displayNode}</div>)}
          </div>

          {/* Mobile validations */}
          <div className="sm:hidden">
            <ValidationsPopover validations={history.inputValidations} />
          </div>

          <div className="flex gap-1">
            <SecretsManagerPopover />
            <ColorLegendPopover />
          </div>
        </div>

        <div ref={dropRef} className={clsx(activeDrag && "bg-primary-300")}>
          {textMode && (
            <CodeBlock
              code={input.value}
              className="mb-2 min-h-[128px] [&>*>*]:!text-element-input"
              minHeight={128}
              readonly={false}
              placeholder="Start typing or drop a file..."
              handleCodeChange={(code: string | undefined) => {
                const input: ParserInput = { type: "string", value: code ?? "" };
                setInput(input);
                debounced(input);
              }}
            />
          )}
          <>
            <div className="flex flex-col gap-2 items-center w-full">
              {snakeNodes &&
                snakeNodes.map((node, i) => {
                  return (
                    <StateDisplay
                      hideValue={textMode && i === 0}
                      key={`state-${i}`}
                      node={node}
                      nodeIndex={i}
                      updateItem={history.updateItem}
                      snakeContext={history.snakeContext}
                      classNames={{
                        tabs: '[&>*]:bg-white [&>[data-state="active"]]:bg-primary-300',
                      }}
                    />
                  );
                })}
            </div>
          </>
        </div>
      </ToolsPage>
    </div>
  );
};
