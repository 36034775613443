import React from "react";
import { Button } from "@/components/ui/button";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { cn } from "@/utils/ui.util";
import { ArrowRight } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { handleStripeCheckout } from "@/lib/stripe/stripe-checkout";
import { LoadingSpinner } from "@/components/loading-spinner";
import { usePricingTiers } from "@/lib/landing-page/pricing-tiers";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

interface StripeData {
  email: string;
}

interface StripeDialogProps {
  dialogTrigger: React.ReactNode;
  email?: string;
  defaultOpen?: boolean;
}

export const StripeDialog: React.FC<StripeDialogProps> = ({ dialogTrigger, email, defaultOpen = false }) => {
  return (
    <Dialog defaultOpen={defaultOpen}>
      <DialogTrigger asChild>{dialogTrigger}</DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        className="p-8"
      >
        <DialogHeader className="justify-start">
          <DialogTitle>Subscription</DialogTitle>

          <small>
            Subscribe to our paid plan to unlock premium features such as higher rate limit, permanent data persistence,
            webhook aliasing and S3 bucket data retention. You can cancel anytime.
          </small>

          <UpgradePlan email={email} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

interface UpgradePlanProps {
  email?: string;
}

export const UpgradePlan: React.FC<UpgradePlanProps> = ({ email }) => {
  const [loading, setLoading] = React.useState(false);
  const pricingTiers = usePricingTiers();

  const initialValues: StripeData = {
    email: email ?? "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: StripeData) => {
      setLoading(true);
      await handleStripeCheckout({ customerEmail: values.email });
      setLoading(false);
    },
  });

  const paidPricingTier = React.useMemo(
    () => pricingTiers.find((pricingTier) => pricingTier.name === "Paid"),
    [pricingTiers]
  );

  if (!paidPricingTier) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-2">
          {!email && (
            <div>
              <Input
                variant="neu-flat"
                id="email"
                placeholder="Email Address"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                className={cn(formik.touched.email && formik.errors.email && "bg-red-200")}
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-xs font-medium">{formik.errors.email}</p>
              )}
            </div>
          )}

          <div className="flex justify-between items-end my-2">
            <h2 className="font-semibold">
              <span>
                {paidPricingTier.currency}
                {paidPricingTier.price}
              </span>
              <span className="text-base ml-2 opacity-75">/year</span>
            </h2>

            <Button type="submit" variant="neu-flat" size="sm" className="bg-primary-300 gap-2">
              {loading ? (
                <LoadingSpinner message={"Redirecting..."} />
              ) : (
                <div className="flex gap-1 items-center">
                  Get Started
                  <ArrowRight size={16} />
                </div>
              )}
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};
