import React from "react";

import {
  Code2,
  Code,
  Link,
  Braces,
  Calculator,
  List,
  CaseSensitive,
  Calendar,
  Sparkles,
  ShieldCheck,
  Quote,
  Binary,
  Equal,
  CircleEllipsis,
  Hash,
  Mail,
} from "lucide-react";

export type ToolsIcons =
  | "code"
  | "code2"
  | "link"
  | "json"
  | "calculator"
  | "list"
  | "stringCase"
  | "date"
  | "autodetect"
  | "certificate"
  | "string"
  | "encode"
  | "compare"
  | "circleEllipsis"
  | "number"
  | "email"
  | null;

export function toToolIcon(icon: ToolsIcons, size = 16, mr = 2): React.ReactNode {
  const props = { size: size, className: `mr-${mr}` };
  if (icon === "code") return <Code {...props} />;
  if (icon === "code2") return <Code2 {...props} />;
  if (icon === "link") return <Link {...props} />;
  if (icon === "json") return <Braces {...props} />;
  if (icon === "calculator") return <Calculator {...props} />;
  if (icon === "list") return <List {...props} />;
  if (icon === "stringCase") return <CaseSensitive {...props} />;
  if (icon === "date") return <Calendar {...props} />;
  if (icon === "autodetect") return <Sparkles {...props} />;
  if (icon === "certificate") return <ShieldCheck {...props} />;
  if (icon === "string") return <Quote {...props} />;
  if (icon === "encode") return <Binary {...props} />;
  if (icon === "compare") return <Equal {...props} />;
  if (icon === "circleEllipsis") return <CircleEllipsis {...props} />;
  if (icon === "number") return <Hash {...props} />;
  if (icon === "email") return <Mail {...props} />;
  return null;
}

export type TransformationOptionsSelectBase = {
  selected: string;
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};
