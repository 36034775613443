import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { IpState } from "../states";
import { IPv4, IPv6 } from "ip-num/IPNumber";
import { IPv4CidrRange, IPv6CidrRange } from "ip-num/IPRange";
import { Validator } from "ip-num/Validator";

export class ToIpTransformation extends StateTransformation<IpState> {
  constructor() {
    super("to-ip", "To IP", ["string"]);
  }

  parseType(input: string): string | null {
    let [valid, _] = Validator.isValidIPv4String(input);
    if (valid) return "ipv4";
    [valid, _] = Validator.isValidIPv6String(input);
    if (valid) return "ipv6";
    [valid, _] = Validator.isValidIPv4CidrNotation(input);
    if (valid) return "ipv4-cidr";
    [valid, _] = Validator.isValidIPv6CidrNotation(input);
    if (valid) return "ipv6-cidr";
    return null;
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      if (this.parseType(state.stringValue()) != null) return CanTransformResult.Yes;
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<IpState> {
    if (state.config().stringValue) {
      const input = state.stringValue();
      const type = this.parseType(input);

      if (type === null) throw new Error("Invalid IP address.");
      switch (type) {
        case "ipv4":
          return new IpState({ data: IPv4.fromDecimalDottedString(input), type: "ipv4" });
        case "ipv6":
          return new IpState({ data: IPv6.fromString(input), type: "ipv6" });
        case "ipv4-cidr":
          return new IpState({ data: IPv4CidrRange.fromCidr(input), type: "ipv4-cidr" });
        case "ipv6-cidr":
          return new IpState({ data: IPv6CidrRange.fromCidr(state.stringValue()), type: "ipv6-cidr" });
      }
    }
    throw new Error("Invalid IP address.");
  }
}

export default new ToIpTransformation();
