import { Outlet } from "react-router-dom";
import { Menu } from "@/layouts/elements/menu";

export const MainLayout: React.FC = () => {
  return (
    <div className="flex flex-col h-dvh">
      <Menu />
      <div className="flex flex-1 overflow-hidden">
        <Outlet />
      </div>
      {/* <div className="flex">Footer</div> */}
    </div>
  );
};
