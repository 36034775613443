import { AddressOrGroup, Attachment, ParsedMessage, parseMail } from "@protontech/jsmimeparser";
import { State, StateConfig } from "../state";
import { keyValueTable } from "../utils/tools-display-utils";
import { DownloadBlob } from "@/components/download-blob";
import prettyBytes from "pretty-bytes";

export class EmailMimeState extends State<string> {
  readonly parsed: ParsedMessage;
  readonly attachments: { blob: Blob; data: Attachment }[];
  constructor(rawEmail: string) {
    super(rawEmail);
    this.parsed = parseMail(rawEmail);
    this.attachments = this.parsed.attachments.map((attachment) => {
      return {
        blob: new Blob([attachment.content], { type: attachment.contentType }),
        data: attachment,
      };
    });
    this.parsed.body.html = this.replaceHtmlBodyWithInlineAttachments();
  }

  private replaceHtmlBodyWithInlineAttachments(): string | null {
    if (!this.hasHtmlBody()) return this.parsed.body.html;

    const parser = new DOMParser();
    const doc = parser.parseFromString(this.parsed.body.html ?? "", "text/html");

    this.attachments.forEach((attachment) => {
      if (attachment.data.contentDisposition !== "inline" || !attachment.data.contentId) return;

      const contentId = attachment.data.contentId.replace(/[<>]/g, "");
      const imgElements = doc.querySelectorAll(`img[src="cid:${contentId}"]`);

      imgElements.forEach((imgElement) => {
        const attachmentElement = doc.createElement("img");
        attachmentElement.src = URL.createObjectURL(attachment.blob);
        attachmentElement.alt = attachment.data.fileName ?? "";
        attachmentElement.title = attachment.data.fileName ?? "";
        attachmentElement.style.maxWidth = "60%";
        imgElement.replaceWith(attachmentElement);
      });
    });

    return new XMLSerializer().serializeToString(doc);
  }

  getParsedMessage() {
    return this.parsed;
  }

  getAttachments() {
    return this.attachments;
  }

  getSize() {
    return this.value.length;
  }

  hasTextBody() {
    return this.parsed.body.text && this.parsed.body.text.trim().length > 0;
  }

  hasHtmlBody() {
    return this.parsed.body.html && this.parsed.body.html.trim().length > 0;
  }

  stringValue(): string {
    return this.value;
  }

  singleValueHeaders() {
    return Object.fromEntries(Object.entries(this.parsed.headers).map(([key, value]) => [key, value.join(",")]));
  }

  config(): StateConfig {
    const customDisplay = ["Email", "Headers"];
    if (this.hasTextBody()) customDisplay.push("Body text");
    if (this.hasHtmlBody()) customDisplay.push("Body HTML");
    if (this.attachments.length > 0) customDisplay.push("Attachments");

    return {
      transformations: "tags-only",
      transformationTags: ["email-mime"],
      validations: "none",
      stringValue: true,
      jsonValue: false,
      customDisplay: customDisplay,
      defaultDisplayMode: "Email",
    };
  }

  addressToString(address?: AddressOrGroup) {
    if (!address) return "";
    if ("group" in address) {
      return `"${address?.name}" ${address?.group.join(",")}`.trim();
    }
    return `"${address?.name}" <${address?.email}>`.trim();
  }
  addressesToString(addresses?: AddressOrGroup[]) {
    if (!addresses || addresses.length === 0) return "";
    return addresses.map(this.addressToString).join(", ");
  }

  toReactNode(mode: string) {
    switch (mode) {
      case "Email": {
        const obj: Record<string, string | number | undefined> = {
          date: this.parsed.date?.toISOString(),
          from: `${this.addressToString(this.parsed.from)}`,
          subject: this.parsed.subject,
        };
        if (this.parsed.to) obj.to = this.addressesToString(this.parsed.to);
        if (this.parsed.cc) obj.cc = this.addressesToString(this.parsed.cc);
        if (this.parsed.bcc) obj.bcc = this.addressesToString(this.parsed.bcc);
        if (this.parsed["reply-to"]) obj.replyTo = this.addressToString(this.parsed["reply-to"]);
        return keyValueTable(obj);
      }
      case "Headers":
        return keyValueTable(this.singleValueHeaders());
      case "Body text":
        return <pre>{this.parsed.body?.text}</pre>;
      case "Body HTML":
        return <pre> {this.parsed.body?.html}</pre>;
      case "Attachments":
        return (
          <pre>
            {this.attachments.map((attachment, index) => (
              <div key={index} className="flex items-center">
                <div>
                  {attachment.data.fileName} {attachment.data.contentType} {attachment.data.contentDisposition}{" "}
                  {prettyBytes(attachment.data.size)}
                </div>{" "}
                <DownloadBlob blob={attachment.blob} filename={attachment.data.fileName ?? "download.file"} />
              </div>
            ))}
          </pre>
        );
    }
  }
}
