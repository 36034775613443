import type { SnakeContext, State } from "../state";
import { StateValidator } from "../state-validator";
import { parseJwt } from "../utils/parse-jwt";
import * as jose from "jose";

class JwtValidator extends StateValidator {
  public override async validate(state: State, snakeContext: SnakeContext) {
    if (state.config().stringValue) {
      try {
        const retVal = [];
        let jwtPayload = null;
        let jwtHeader = null;
        const jwt = state.stringValue();
        try {
          const result = parseJwt(jwt);
          jwtPayload = result.payload;
          jwtHeader = result.header;
        } catch (_) {
          return [];
        }

        retVal.push(this.createSuccessResult("JWT Schema", `Valid JWT schema`));
        const now = Math.floor(Date.now() / 1000);

        if (jwtPayload.iat && jwtPayload.iat > now) {
          retVal.push(this.createFailureResult("JWT iat", `Token was issued in the future.`));
        }

        let foundKey = false;
        if (snakeContext.secrets) {
          for (const currentSecret of snakeContext.secrets) {
            let secret;

            const alg = jwtHeader.alg;

            if (alg.startsWith("HS")) secret = new TextEncoder().encode(currentSecret.value);
            else {
              try {
                secret = await jose.importSPKI(currentSecret.value, alg, { extractable: false });
              } catch (e) {
                continue;
              }
            }

            try {
              await jose.compactVerify(jwt, secret);
              foundKey = true;
              retVal.push(
                this.createSuccessResult("JWT signature", `Validated JWT signature with key "${currentSecret.key}"`)
              );
              break;
            } catch (e) {
              //console.error(e);
              //retVal.push(this.createFailureResult("JWT signature", `Invalid JWT signature`));
              //retVal.push(queryForKey);
            }
          }
          if (!foundKey) {
            retVal.push(
              this.createFailureResult("Invalid JWT secret", "No keys in secrets match the JWT signature.", "jwt-key")
            );
          }
        } else {
          retVal.push(
            this.createQueryResult(
              "No JWT secret",
              "JWT signature will be validated when secrets are provided",
              "jwt-key"
            )
          );
        }

        return retVal;
      } catch (e) {
        console.error(e);
        return [this.createFailureResult("no JWT", `failed to parse JWT: ${e}`)];
      }
    }

    //console.log(state, "no JWT");
    return [];
  }
}

export default new JwtValidator();
