import type { State } from "../state";
import { StateValidator } from "../state-validator";
import validator from "validator";
import { StringState } from "../states";

class XmlValidator extends StateValidator {
  public override async validate(state: State) {
    if (!StringState.is(state)) return [];

    const input = state.stringValue();
    const retVal = [];
    if (input.length === 0) return [];

    if (validator.isEmail(input)) {
      retVal.push(this.createSuccessResult("Email", `This is a valid email address`));
    }

    if (validator.isURL(input)) {
      retVal.push(this.createSuccessResult("URL", `This is a valid URL`));
    }

    if (validator.isUUID(input)) {
      retVal.push(this.createSuccessResult("UUID", `This is a valid UUID`));
    }

    // if (validator.isAlphanumeric(input)) {

    if (state.stringStats.isAlpha && !state.stringStats.isNumeric)
      retVal.push(this.createSuccessResult("Alphabetic", `This is an alphabetic string`));
    else if (state.stringStats.isNumeric && !state.stringStats.isAlpha)
      retVal.push(this.createSuccessResult("Numeric", `This is a numeric string`));

    // if (validator.isAscii(input)) {
    if (state.stringStats.isAscii) {
      retVal.push(this.createSuccessResult("ASCII", `This is an ASCII string`));
    }

    if (validator.isBase64(input)) {
      retVal.push(this.createSuccessResult("Base64", `This is a Base64 string`));
    }

    if (validator.isCreditCard(input)) {
      retVal.push(this.createSuccessResult("Credit Card", `This is a valid credit card number`));
    }

    if (validator.isDecimal(input)) {
      retVal.push(this.createSuccessResult("Decimal", `This is a decimal number`));
    }

    if (validator.isInt(input)) {
      retVal.push(this.createSuccessResult("Integer", `This is an integer`));
    }

    if (validator.isFQDN(input)) {
      retVal.push(this.createSuccessResult("FQDN", `This is a fully qualified domain name`));
    }

    if (validator.isHexColor(input)) {
      retVal.push(this.createSuccessResult("Hex Color", `This is a hex color`));
    }

    if (validator.isIP(input)) {
      retVal.push(this.createSuccessResult("IP", `This is an IP address`));
    }

    if (validator.isISBN(input)) {
      retVal.push(this.createSuccessResult("ISBN", `This is an ISBN`));
    }

    if (validator.isISIN(input)) {
      retVal.push(this.createSuccessResult("ISIN", `This is an ISIN`));
    }

    if (validator.isLatLong(input)) {
      retVal.push(this.createSuccessResult("Latitude/Longitude", `This is a latitude/longitude`));
    }

    if (validator.isMACAddress(input)) {
      retVal.push(this.createSuccessResult("MAC Address", `This is a MAC address`));
    }

    if (validator.isMimeType(input)) {
      retVal.push(this.createSuccessResult("MIME Type", `This is a MIME type`));
    }

    if (validator.isRgbColor(input)) {
      retVal.push(this.createSuccessResult("RGB Color", `This is an RGB color`));
    }

    if (validator.isISO31661Alpha3(input)) {
      retVal.push(this.createSuccessResult("ISO 3166-1 Alpha-3", `This is an ISO 3166-1 Alpha-3 code`));
    }

    if (validator.isISO31661Alpha2(input)) {
      retVal.push(this.createSuccessResult("ISO 3166-1 Alpha-2", `This is an ISO 3166-1 Alpha-2 code`));
    }

    if (validator.isMultibyte(input)) {
      retVal.push(this.createSuccessResult("Multibyte", `This is a multibyte string`));
    }

    if (validator.isVariableWidth(input)) {
      retVal.push(this.createSuccessResult("Variable Width", `String contains variable width characters`));
    }

    if (validator.isSurrogatePair(input)) {
      retVal.push(this.createSuccessResult("Surrogate Pair", `String contains surrogate pair`));
    }

    if (validator.isSemVer(input)) {
      retVal.push(this.createSuccessResult("SemVer", `This is a semantic version`));
    }
    if (state.stringStats.lines > 1) {
      retVal.push(this.createSuccessResult("List", `This is a list`));
    }

    return retVal;
  }
}

export default new XmlValidator();
