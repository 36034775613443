import type { State } from "../state";
import { StateValidator } from "../state-validator";

class XmlValidator extends StateValidator {
  public override async validate(state: State) {
    if (!state.config().stringValue) return [];
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(state.stringValue(), "text/xml");
      const isValid = xmlDoc.getElementsByTagName("parsererror").length === 0;
      if (isValid) return [this.createSuccessResult("XML", `This is valid XML document`)];
    } catch (e) {
      const errorMessage =
        e instanceof Error ? `failed to parse XML: ${e.message}` : "Unknown error occurred while parsing XML.";
      return [this.createFailureResult("XML", errorMessage)];
      return [];
    }
    return [];
  }
}

export default new XmlValidator();
