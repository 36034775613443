import { SnakeContext, State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, JwtState } from "../states";
import { checkType } from "../utils/json-utils";
import * as jose from "jose";
import { ToolsIcons } from "./common-options/options.transformations";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { TransformationOptionsSelectCustomizable } from "./common-options/options.select-customizable.transformation";

const jwtAlgorithms = [
  "HS256",
  "HS384",
  "HS512",
  "RS256",
  "RS384",
  "RS512",
  "ES256",
  "ES384",
  "ES512",
  "PS256",
  "PS384",
  "PS512",
] as const;

type Algorithm = (typeof jwtAlgorithms)[number];
type JwtGenerationMode = {
  selected: Algorithm;
  values: Record<Algorithm, Algorithm>;
  label: string;
  icons?: Record<string, ToolsIcons>;
  selectedSecret: string;
  secrets: Record<string, string>;
  timestamps: "unchanged" | "update-10-min" | "update-1h" | "add-10-min" | "add-1h";
  timestampsValues: Record<JwtGenerationMode["timestamps"], string>;
};

export class GenerateJwtTransformation extends StateTransformation<State, JwtGenerationMode> {
  constructor() {
    super("generate-jwt", "Generate JWT", ["string"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (JwtState.is(state)) return CanTransformResult.No;
    if (state.config().jsonValue) {
      const obj = state.jsonValue() as Record<string, any>;
      const objType = checkType(obj);
      if (objType === "object") {
        if (obj.iss || obj.sub || obj.aud || obj.exp || obj.iat) return CanTransformResult.Yes;
      }
    }

    return CanTransformResult.No;
  }

  async transform(state: State, options: JwtGenerationMode, snakeContext?: SnakeContext): Promise<State> {
    if (state.config().jsonValue) {
      const obj: Record<string, string | number> = { ...state.jsonValue() };

      const now = Math.floor(Date.now() / 1000);
      console.log(options.timestamps);
      switch (options.timestamps) {
        case "update-10-min":
          if (obj.nbf) obj.nbf = now;
          if (obj.iat) obj.iat = now;
          if (obj.exp) obj.exp = now + 10 * 60;
          break;
        case "update-1h":
          if (obj.nbf) obj.nbf = now;
          if (obj.iat) obj.iat = now;
          if (obj.exp) obj.exp = now + 60 * 60;
          break;
        case "add-10-min":
          obj.nbf = now;
          obj.iat = now;
          obj.exp = now + 10 * 60;
          break;
        case "add-1h":
          obj.nbf = now;
          obj.iat = now;
          obj.exp = now + 60 * 60;
          break;
      }

      if (!options.selectedSecret) return new InvalidState("Secret is required");
      const secretValue = snakeContext?.secrets?.find((s) => s.key === options.selectedSecret);
      if (!secretValue?.value) return new InvalidState(`Invalid secret "${options.selectedSecret}"`);

      const alg = options.selected;
      let secret;
      if (alg.startsWith("HS")) secret = new TextEncoder().encode(secretValue?.value);
      else {
        try {
          secret = await jose.importPKCS8(secretValue?.value, alg, { extractable: false });
        } catch (e) {
          return new InvalidState(`Invalid secret "${options.selectedSecret}": ${e}`);
        }
      }

      const jwt = await new jose.SignJWT(obj as any).setProtectedHeader({ alg, typ: "JWT" }).sign(secret);
      return JwtState.fromString(jwt);
    }
    return new InvalidState("Cannot create JWT from this input");
  }

  async getOptions(
    _: State,
    options?: JwtGenerationMode,
    snakeContext?: SnakeContext
  ): Promise<JwtGenerationMode | undefined> {
    return {
      selected: options?.selected ?? "HS256",
      values: Object.fromEntries(jwtAlgorithms.map((alg: Algorithm) => [alg, alg])) as Record<Algorithm, Algorithm>,
      label: "Alg",
      selectedSecret: options?.selectedSecret || snakeContext?.secrets?.[0]?.key || "",
      secrets: snakeContext?.secrets ? Object.fromEntries(snakeContext.secrets.map((s) => [s.key, s.key])) : {},
      timestamps: options?.timestamps ?? "add-10-min",
      timestampsValues: {
        unchanged: "Unchanged",
        "update-10-min": "Update (exp in 10 minutes)",
        "update-1h": "Update (exp in 1 hour)",
        "add-10-min": "All (exp in 10 minutes)",
        "add-1h": "All (exp in 1 hour)",
      },
    };
  }

  getOptionsReactNode(options: JwtGenerationMode, onChange: (options: JwtGenerationMode) => void): React.ReactNode {
    return (
      <>
        <div className="neu flex p-1 gap-3  bg-white items-center">
          <TransformationOptionsSelect onChange={onChange as any} options={options} />
          <TransformationOptionsSelectCustomizable
            onChange={(selectedSecret) => {
              onChange({ ...options, selectedSecret: selectedSecret });
            }}
            label="Key"
            selected={options.selectedSecret}
            values={options.secrets}
          />
          <TransformationOptionsSelectCustomizable
            onChange={(timestamps) => {
              onChange({ ...options, timestamps: timestamps as JwtGenerationMode["timestamps"] });
            }}
            label="Timestamps"
            selected={options.timestamps}
            values={options.timestampsValues}
          />
        </div>
      </>
    );
  }
}

export default new GenerateJwtTransformation();
