import { Status } from "@/enums/status";
import { cn } from "@/utils/ui.util";
import { AlertCircle, CheckCircle2, Info, XCircle } from "lucide-react";

interface StatusIndicatorProps {
  status: Status;
  value?: string;
  className?: string;
}

const getStatusIcon = (status: Status): React.ReactNode | null => {
  switch (status) {
    case Status.SUCCESS:
      return <CheckCircle2 className="text-green-400 w-5" />;
    case Status.ERROR:
      return <XCircle className="text-red-500 w-5" />;
    case Status.WARNING:
      return <AlertCircle className="text-yellow-500 w-5" />;
    case Status.INFO:
      return <Info />;
    default:
      return null;
  }
};

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, value, className }) => {
  const icon = getStatusIcon(status);

  return (
    <div className="flex gap-2 items-center">
      {icon && <span>{icon}</span>}
      {value && <p className={cn("text-sm font-medium", className)}>{value}</p>}
    </div>
  );
};
