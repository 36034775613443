import React from "react";

export type WorkflowSize = {
  height?: number;
  width?: number;
};

export type FlowSize = Record<string, WorkflowSize>;

export type FlowDirection = "TB" | "LR";

interface FlowSettingsContextType {
  flowSize: FlowSize;
  updateFlowSize: (key: string, newSize: WorkflowSize) => void;
  direction: FlowDirection;
  updateDirection: (direction: FlowDirection) => void;
}

export const FlowSettingsContext = React.createContext<FlowSettingsContextType | null>(null);

interface FlowSizeProviderProps {
  children: React.ReactNode;
}

export const FlowSizeProvider: React.FC<FlowSizeProviderProps> = ({ children }) => {
  const [flowSize, setFlowSize] = React.useState<FlowSize>({});
  const [direction, setDirection] = React.useState<FlowDirection>("TB");

  const updateFlowSize = React.useCallback((key: string, newSize: WorkflowSize) => {
    setFlowSize((s) => ({ ...s, [key]: newSize }));
  }, []);

  const updateDirection = React.useCallback((newDirection: FlowDirection) => {
    setDirection(newDirection);
  }, []);

  return (
    <FlowSettingsContext.Provider value={{ flowSize, updateFlowSize, direction, updateDirection }}>
      {children}
    </FlowSettingsContext.Provider>
  );
};
