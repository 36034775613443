import { usePipelinePanelList, useRepositories } from "@/api/queries";
import { HoverInfo } from "@/components/hover-info";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { DeletePipelinePanelDialog } from "@/lib/pipelines/panel-builder/delete-panel-dialog";
import { PipelinePanelBuilderDialog } from "@/lib/pipelines/panel-builder/panel-builder-dialog";
import { formatCount } from "@/utils";
import { formatDistanceToNow } from "date-fns";
import { ArrowUpRight, Settings } from "lucide-react";
import React from "react";
import { Link, Navigate } from "react-router-dom";

export const manageInstallationUrl = `${import.meta.env.VITE_GITHUB_APP_URL}/installations/new`;

export const UserReposDashboard: React.FC = () => {
  return (
    <div className="flex flex-col gap-6 2xl:gap-12 w-full max-w-[1920px] m-auto 2xl:px-4 2xl:py-6">
      <div className="flex flex-col space-y-2">
        <h4 className="font-bold tracking-tight">Pipelines</h4>
        <small>
          Display and monitor your deployment workflows in real-time. Stay informed and ensure smooth and efficient
          continuous integration and deployment with up-to-the-minute updates.
        </small>
      </div>
      <div className="space-y-4">
        <ConnectedRepositories />
        <CustomPanels />
      </div>
    </div>
  );
};

const ConnectedRepositories: React.FC = () => {
  const { isPending, isError, data: response } = useRepositories();
  return (
    <Card>
      <CardHeader>
        <CardTitle className="font-semibold text-base">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              Connected Repositories
              <Button asChild variant="ghost" size="sm" className="p-1">
                <Link to={manageInstallationUrl} target="_blank" className="no-underline">
                  <Settings size={16} />
                </Link>
              </Button>
            </div>
            <HoverInfo message="To modify repository access, navigate to the installed GitHub App and update permissions." />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isPending && (
          <div className="flex items-center justify-center">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && (
          <>
            {response.status === "reject" && <Navigate to="/pipelines/auth" replace={true} />}
            {response.status === "success" && (
              <>
                {response.data.repositories && response.data.repositories.length === 0 && (
                  <Button asChild variant="neu" size="sm" className="bg-primary-300">
                    <Link to={manageInstallationUrl} className="no-underline">
                      Select Repositories
                    </Link>
                  </Button>
                )}
                {response.data.repositories && response.data.repositories.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <Table className="whitespace-nowrap">
                      <TableHeader>
                        <TableRow>
                          <TableHead></TableHead>
                          <TableHead>Visibility</TableHead>
                          <TableHead>Name</TableHead>
                          <TableHead>Organization</TableHead>
                          <TableHead className="text-right">Last push</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {response.data.repositories.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <Badge variant="secondary">
                                  {item.visibility === "private" ? "Private" : "Public"}
                                </Badge>
                              </TableCell>
                              <TableCell className="font-medium font-mono">
                                <div className="flex gap-1 items-center">
                                  <Button asChild variant="ghost" size="sm" className="p-0 hover:underline">
                                    <Link
                                      to={`/pipelines/repo/${item.owner.login}/${item.name}`}
                                      className="no-underline"
                                    >
                                      {item.owner.login}/{item.name}
                                    </Link>
                                  </Button>
                                  <ArrowUpRight size={16} />
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="flex gap-2 items-center">
                                  <img src={item.owner.avatarUrl} className="w-6 rounded-full" />
                                  <p className="text-sm font-mono">{item.owner.login}</p>
                                </div>
                              </TableCell>
                              <TableCell className="text-right">
                                {formatDistanceToNow(item.pushedAt ?? "")} ago
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const CustomPanels: React.FC = () => {
  const { isPending, isError, data: response } = usePipelinePanelList();

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="font-semibold text-base">
            <div className="flex items-center justify-between gap-2 h-9">
              <div>Custom Panels</div>
              <PipelinePanelBuilderDialog />
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {isPending && (
              <div className="flex items-center">
                <LoadingSpinner message={"Loading..."} />
              </div>
            )}
            {isError && <div>Error</div>}
            {!isPending && !isError && (
              <>
                {response.panels && response.panels.length === 0 && <small>No custom panels created yet.</small>}
                {response.panels && response.panels.length > 0 && (
                  <Table className="whitespace-nowrap">
                    <TableHeader>
                      <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Visibility</TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Organization(s)</TableHead>
                        <TableHead>Workflows</TableHead>
                        <TableHead className="text-right">Created</TableHead>
                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {response.panels.map((panel, index) => {
                        const owners = panel.selectedWorkflows.reduce((p, c) => {
                          p.add(c.owner);
                          return p;
                        }, new Set<string>());

                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <Badge variant="secondary">{panel.isPublic ? "Public" : "Private"}</Badge>
                            </TableCell>
                            <TableCell className="font-medium font-mono">
                              <div className="flex gap-1 items-center">
                                <Button asChild variant="ghost" size="sm" className="p-0 hover:underline">
                                  <Link to={`/pipelines/panel/${panel.panelId}`} className="no-underline">
                                    {panel.panelName}
                                  </Link>
                                </Button>
                                <ArrowUpRight size={16} />
                              </div>
                            </TableCell>
                            <TableCell className="font-medium font-mono">
                              <div className="flex gap-2 flex-wrap">{[...owners].join(", ")}</div>
                            </TableCell>
                            <TableCell className="font-medium font-mono">
                              {formatCount(panel.selectedWorkflows.length, "workflow")}
                            </TableCell>
                            <TableCell className="text-right">{formatDistanceToNow(panel.created ?? "")} ago</TableCell>
                            <TableCell className="text-right">
                              <DeletePipelinePanelDialog panelName={panel.panelName} panelId={panel.panelId} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
};
