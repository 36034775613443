interface ToolsPageProps {
  children?: React.ReactNode;
  title?: string;
  description?: string;
}

export const ToolsPage: React.FC<ToolsPageProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-1 w-full pb-6 px-4 md:px-6">
      {/* Revert back if needed */}
      {/* <div className="flex flex-col sm:flex-row sm:items-center gap-2">
        <h5 className="font-bold">{title}</h5>
        <small className="text-sm italic text-wrap">{description}</small>
      </div> */}
      {children}
    </div>
  );
};
