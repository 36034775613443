import React from "react";
import { useSignInWithGithub } from "@/api/mutations";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { defaultError } from "../utils/default-error-message";
import { useToast } from "@/components/ui/use-toast";

export const SignInWithGithub: React.FC = () => {
  const { toast } = useToast();
  const [redirecting, setRedirecting] = React.useState(false);

  const signInWithGithubMutation = useSignInWithGithub({
    onSuccess: (data) => {
      if (data.githubOauthUrl) {
        setRedirecting(true);
        window.location.href = data.githubOauthUrl;
        setRedirecting(false);
      }
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  return (
    <Button
      variant="neu"
      size="sm"
      className="bg-primary-300 gap-2 items-center px-10"
      onClick={() => {
        signInWithGithubMutation.mutate();
      }}
      disabled={signInWithGithubMutation.isPending || redirecting}
    >
      {(signInWithGithubMutation.isPending || redirecting) && <LoadingSpinner message={"Loading..."} />}

      {!signInWithGithubMutation.isPending && !redirecting && (
        <>
          <img src={"/github-svgrepo-com.svg"} alt={"GitHub"} className="w-6" />
          Sign in with Github
        </>
      )}
    </Button>
  );
};
