import { Navigate, Outlet, useLoaderData, useLocation } from "react-router-dom";
import { User } from "@/api/types/response";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/utils/ui.util";
import { Settings2, CreditCardIcon, ShieldEllipsis, UserIcon } from "lucide-react";
import React, { Suspense } from "react";
import { Link } from "react-router-dom";

const settingsNav = {
  items: [
    {
      title: "Personal Profile",
      path: "/settings",
      icon: <UserIcon size={16} />,
      ownersOnly: false,
      awsOnly: false,
    },
    {
      title: "Password & Credentials",
      path: "/settings/credentials",
      icon: <ShieldEllipsis size={16} />,
      ownersOnly: false,
      awsOnly: false,
      nonOauthOnly: true,
    },
    {
      title: "Account Settings",
      path: "/settings/account",
      icon: <Settings2 size={16} />,
      ownersOnly: true,
      awsOnly: false,
    },
    {
      title: "Payments and Subscriptions",
      path: "/settings/payments",
      icon: <CreditCardIcon size={16} />,
      awsOnly: false,
    },
  ],
};

export interface SettingsOutletContext {
  user: User;
}

export const Settings: React.FC = () => {
  const location = useLocation();

  const data = useLoaderData() as User;

  const customerIdentifier = React.useMemo(() => data?.customerIdentifier, [data]);

  const awsOnlyRoutes = settingsNav.items.filter((item) => item.awsOnly);

  // Redirect to 404 if user is basic user and trying to access AWS only routes
  if (customerIdentifier === "user" && awsOnlyRoutes.some((item) => location.pathname.startsWith(item.path))) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <ScrollArea className="w-full bg-secondary-200">
      <div className="flex p-2 md:p-10 justify-center">
        <div className="w-full max-w-7xl">
          <Suspense
            fallback={
              <div className="flex items-center justify-center">
                <LoadingSpinner message={"Loading..."} />
              </div>
            }
          >
            <div className="flex flex-col gap-4">
              <div className="flex flex-col space-y-2">
                <h4 className="font-bold tracking-tight">Settings</h4>
                <small>Manage your account settings, credentials, and other preferences.</small>
              </div>

              <div className="grid md:grid-cols-12 gap-8">
                {/* Sidebar */}
                <div className="grid col-span-12 lg:col-span-3 grid-rows-[min-content,1fr] overflow-hidden ">
                  <div className="py-4">
                    <div className="flex flex-col gap-1">
                      {settingsNav.items.map((item) => {
                        if (item.awsOnly && customerIdentifier === "user") {
                          return null;
                        }

                        if (item.ownersOnly && data.role !== "owner") {
                          return null;
                        }

                        if (item.nonOauthOnly && data.registrationType === "github") {
                          return null;
                        }

                        return (
                          <SidebarNavItem
                            key={item.path}
                            icon={item.icon}
                            isSelected={window.location.pathname === item.path}
                            title={item.title}
                            path={item.path}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="grid col-span-12 lg:col-span-9 border-secondary-950 overflow-hidden">
                  <div className="py-4 px-1">
                    <div className="neu rounded-lg bg-white py-8 px-2 sm:px-4">
                      <Outlet
                        context={
                          {
                            user: data,
                          } as SettingsOutletContext
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Suspense>
        </div>
      </div>
    </ScrollArea>
  );
};

interface SidebarNavItemProps {
  title: string;
  icon: React.ReactNode;
  isSelected: boolean;
  path: string;
}

const SidebarNavItem: React.FC<SidebarNavItemProps> = ({ title, icon, isSelected, path }) => {
  return (
    <Button
      asChild
      variant={"neu-flat"}
      scheme={isSelected ? "black" : "white-black"}
      size="sm"
      className={cn("justify-start", !isSelected && "bg-white-")}
    >
      {/* {title} */}
      <Link to={path} className="flex gap-2 items-center no-underline">
        <div>{icon}</div>
        <p className="text-sm">{title}</p>
      </Link>
    </Button>
  );
};
