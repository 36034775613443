import React from "react";
import { State, ValidTransformation } from "./state";
import { ToolsSnakeNode, getAllTransformations, getTransformationById, getValidTransformations } from "./state-utils";
import { StateTransformation, TransformationId } from "./state-transformation";

interface UseTransformationProps {
  node: ToolsSnakeNode;
  updateTransformation: (transformationName: TransformationId, options: object) => void;
}

type UseTransformationReturn = {
  validTransformations: ValidTransformation[];
  optionsRender?: React.ReactNode;
  state: State;
  transformationId: TransformationId | undefined;
  options: object | undefined;
  allTransformations: StateTransformation[];
};

export function useCurrentTransformation({
  node,
  updateTransformation,
}: UseTransformationProps): UseTransformationReturn {
  const { state, transformationId, options } = node;

  const [validTransformations, setValidTransformations] = React.useState<ValidTransformation[]>([]);

  React.useEffect(() => {
    async function build() {
      const validTransformations = await getValidTransformations(state, transformationId);
      setValidTransformations(validTransformations);
    }
    build();
  }, [state, transformationId]);

  const optionsRender = React.useMemo<React.ReactNode>(() => {
    const transformation = transformationId ? getTransformationById(transformationId) : null;

    const optionsRender =
      transformationId &&
      transformation &&
      options !== undefined &&
      transformation.getOptionsReactNode(options as any, (value) => {
        return updateTransformation(transformationId, value as unknown as object);
      });
    return optionsRender;
  }, [options, transformationId, updateTransformation]);

  return {
    validTransformations,
    optionsRender,
    options,
    transformationId,
    state,
    allTransformations: getAllTransformations(),
  };
}
