import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { LocalStorageService, WebhooksLocalStorageData } from "@/utils/storage/localstorage-service";
import { cn } from "@/utils/ui.util";
import { ArrowUpRight } from "lucide-react";
import React from "react";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import { BoomBox } from "../components/boom-box";
import { KeyFeatures } from "../components/key-features";
import { LayeredTitle } from "../components/layered-title";
import { LandingSection } from "./landing-section";

export const WebhooksHome: React.FC = () => {
  return <LandingPageWrapper head={<WebhooksHomeHead />} children={<WebhooksHomeContent />} />;
};

export const WebhooksHomeHead: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);

  const navigate = useNavigate();

  const webhookMutation = ApiMutation.useCreateWebhook({
    onSuccess: (data) => {
      LocalStorageService.store<WebhooksLocalStorageData>("webhooks", {
        ...data,
        created: new Date().toISOString(),
        isDynamic: false,
      });
      navigate(`/webhooks/${data.id}`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="container px-4 sm:px-8 pt-8 2xl:pt-16">
      <div className="flex flex-col gap-8 md:flex-row items-center">
        <div className="flex flex-col gap-6 w-full">
          <div className="space-y-4">
            <div className="flex mb-12">
              <LayeredTitle
                title="Webhooks"
                className="text-6xl lg:text-8xl/none font-black font-[system-ui] tracking-wide"
              />
            </div>
            <p className="mx-auto text-muted-foreground md:text-xl">
              Test, debug, and automate your webhooks with ease. Get instant feedback on your webhook events and ensure
              everything works as expected—before it hits production.
            </p>
          </div>

          <div className="flex gap-2">
            <Button
              className="bg-primary-300 flex gap-2"
              disabled={webhookMutation.isPending}
              onClick={webhookMutation.mutate}
            >
              Create Webhook {webhookMutation.isPending && <LoadingSpinner size={18} />}
            </Button>

            <Button asChild id="get-started-button">
              <Link to="/webhooks" className="no-underline">
                <div className="flex items-center gap-1">
                  Get Started
                  <ArrowUpRight size={20} />
                </div>
              </Link>
            </Button>
          </div>
        </div>

        <div className={cn("relative block w-full overflow-hidden", loaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/01.webm"}
            width="100%"
            height="100%"
            muted={true}
            playing={true}
            loop={true}
            onReady={() => setLoaded(true)}
          />
        </div>
      </div>
    </div>
  );
};

export const WebhooksHomeContent: React.FC = () => {
  const [quickPreviewLoaded, setQuickPreviewLoaded] = React.useState(false);

  return (
    <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
      <LandingSection className="bg-white flex flex-col gap-y-8 lg:flex-row lg:gap-24">
        <div>
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Overview</h2>
          <div className="text-muted-foreground md:text-xl text-justify">
            Our <b>Webhooks Tool</b> is built to make testing and debugging webhooks as simple as possible. Whether
            you’re handling complex workflows or just need a quick way to simulate events, our tool provides a{" "}
            <b>clear</b>, <b>real-time view</b> of what’s happening. It’s about making sure your webhooks work exactly
            as intended without the guesswork.
          </div>
        </div>

        <KeyFeatures
          className="whitespace-nowrap row-span-2 self-center"
          features={[
            "Instant Webhook Endpoints",
            "Real-Time Monitoring",
            "Payload Validation",
            "Retry Simulation",
            "Detailed Logs",
          ]}
        />
      </LandingSection>

      <LandingSection className="bg-secondary-200">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">
          Why you should use our Webhooks Tool?
        </h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Dealing with webhooks shouldn’t be stressful, but sometimes things break, or data goes missing. Our tool is
          designed to take the hassle out of testing and debugging webhooks, giving you <b>real-time insights</b> and
          full control over your events.
        </p>

        <div className="grid grid-cols-2 gap-6">
          <BoomBox
            title="Catch Errors Before They Happen"
            boom="Instant feedback on payloads and events, catching issues before they go live."
            description={
              <>
                There’s nothing worse than finding out your webhook integration failed after it’s already in production.
                Our tool gives you the ability to see exactly how your webhooks are working, allowing you to catch
                validation errors or misconfigurations early in the process.
              </>
            }
          />
          <BoomBox
            title="Simulate Complex Scenarios"
            boom="Test retries, simulate errors, and mimic real-world scenarios in seconds."
            description={
              <>
                Whether you're handling a simple trigger or a multi-step workflow, our tool lets you simulate complex
                scenarios with just a few clicks. Test how your webhook responds to retries or timeouts to make sure
                you’re covered in every situation.
              </>
            }
          />
          <BoomBox
            title="Real-Time Visibility"
            boom="Get live updates on webhook events without refreshing or waiting for logs."
            description={
              <>
                Stop refreshing dashboards or digging into logs—our tool gives you real-time updates on every event.
                Watch your webhook requests come through, and see responses instantly, making debugging faster and more
                efficient.
              </>
            }
          />
          <BoomBox
            title="Full Control Over What You See"
            boom="Customize which events and data are visible to you."
            description={
              <>
                Whether you need to see every tiny detail or just want a high-level overview, you can customize your
                view to fit your needs. Filter events, view payloads, and focus on the data that’s most relevant to your
                testing.
              </>
            }
          />
          <BoomBox
            title="Detailed Logs Without the Noise"
            boom="Easy-to-read logs that show exactly what went wrong (or right)."
            description={
              <>
                Logs don’t have to be overwhelming. Our clean, detailed logs make it easy to trace the lifecycle of an
                event, from the moment it’s triggered to the final response. No more wading through irrelevant data to
                find the source of an issue.
              </>
            }
          />
          <BoomBox
            title="Ready for Automation"
            boom="Automate testing with our API for faster, smoother development cycles."
            description={
              <>
                For those who love efficiency, our API gives you the ability to automate your webhook tests. Set up
                automated tests to run in your CI/CD pipeline and ensure your webhooks are always working, even after
                changes to your codebase.
              </>
            }
          />
        </div>
      </LandingSection>

      <LandingSection className="bg-white">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">Quick Preview</h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Take a quick look at how our tool simplifies the process of testing and debugging webhook events.
        </p>

        <div className={cn("overflow-hidden", quickPreviewLoaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/01.webm"}
            width="100%"
            height="100%"
            controls={true}
            muted={true}
            onReady={() => setQuickPreviewLoaded(true)}
          />
        </div>
      </LandingSection>
    </div>
  );
};
