import { cn } from "@/utils/ui.util";
import { FolderGit2, Workflow } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

interface PipelinesDashbordInfoProps {
  owner: string;
  repo: string;
  workflowCount: number;
}

export const PipelinesDashbordInfo: React.FC<PipelinesDashbordInfoProps> = ({ owner, repo, workflowCount }) => {
  const infoCards = {
    data: [
      {
        title: "Workflows",
        content: (
          <div className="flex gap-2 items-center">
            <Workflow size={16} />
            <div className="text-sm font-semibold">{workflowCount}</div>
          </div>
        ),
      },
      {
        title: "Repository",
        content: (
          <div className="flex gap-2 items-center">
            <Link
              to={`https://github.com/${owner}/${repo}`}
              className="no-underline hover:underline font-mono text-sm text-secondary-950 flex gap-1 items-center"
              target="_blank"
            >
              <FolderGit2 size={16} />
              https://github.com/{owner}/{repo}
            </Link>
          </div>
        ),
      },
    ],
  };

  return (
    <div className="space-y-2">
      <PathBreadcrumb owner={owner} repo={repo} />
      <div className="flex gap-2">
        {infoCards.data.map((infoCard, index) => (
          <InfoCard key={index} title={infoCard.title} content={infoCard.content} />
        ))}
      </div>
    </div>
  );
};

interface PathBreadcrumbProps {
  owner: string;
  repo: string;
}

const PathBreadcrumb: React.FC<PathBreadcrumbProps> = ({ owner, repo }) => {
  const segments = [owner, "/", repo];

  return (
    <div className="flex gap-2 items-center">
      {segments.map((segment, index) => (
        <p key={index} className={cn("text-sm font-mono font-bold", index < segments.length - 1 && "text-gray-700")}>
          {segment}
        </p>
      ))}
    </div>
  );
};

interface InfoCardProps {
  title: string;
  content: string | React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, content }) => {
  return (
    <div className="neu-flat rounded-lg min-w-56 bg-secondary-400/50">
      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-center justify-between gap-10">
          <p className="text-xs">{title}</p>
        </div>
        {content}
      </div>
    </div>
  );
};
