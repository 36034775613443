import clsx from "clsx";
import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Button } from "./ui/button";
import { PopoverProps } from "@radix-ui/react-popover";

interface ColorCircleProps {
  className?: string;
}

const ColorCircle: React.FC<ColorCircleProps> = ({ className }) => {
  return <div className={clsx("rounded-md h-4 aspect-[3/2]", className)} />;
};

interface ColorLegendProps {
  className?: string;
}

export const ColorLegend: React.FC<ColorLegendProps> = ({ className }) => {
  return (
    <div
      className={clsx(
        "grid grid-cols-[min-content,1fr] items-center gap-x-2 gap-y-1 text-sm whitespace-nowrap",
        className
      )}
    >
      <h4 className="col-span-2 mb-2 border-b-2 border-current pb-1 font-bold">Color Legend</h4>

      <ColorCircle className="bg-element-input" />
      <span>Input</span>

      <ColorCircle className="bg-element-argument" />
      <span>Argument</span>

      <ColorCircle className="bg-element-transformation" />
      <span>Transformation</span>

      <ColorCircle className="bg-transparent border-2 border-element-recommended" />
      <span>Recommended</span>

      <ColorCircle className="bg-element-secret" />
      <span>Secret</span>

      <h5 className="col-span-2 text-normal mb-1 border-b border-current">Status</h5>

      <ColorCircle className="bg-status-success" />
      <span>Success</span>

      <ColorCircle className="bg-status-error" />
      <span>Error</span>

      <ColorCircle className="bg-status-pending" />
      <span>Pending</span>
    </div>
  );
};

export const ColorLegendPopover: React.FC<PopoverProps> = (props) => {
  return (
    <Popover {...props}>
      <PopoverTrigger asChild>
        <Button variant="neu-flat" size="sm" className="bg-white">
          Color Legend
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-fit overflow-x-hidden bg-white">
        <ColorLegend />
      </PopoverContent>
    </Popover>
  );
};
