import { DateTime } from "luxon";
import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { DateState, InvalidState, JsonState, JwtState, StringState } from "../states";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type ExtractMode = {
  selected: "header" | "payload" | "signature" | "iat" | "exp" | "nbf";
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class ExtractFromJwtTransformation extends StateTransformation<State, ExtractMode> {
  constructor() {
    super("extract-jwt", "Extract", ["jwt"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (JwtState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: ExtractMode): Promise<State> {
    if (JwtState.is(state)) {
      const mode = options?.selected;
      switch (mode) {
        case "header":
          return new JsonState(state.value.header, "JSON");
        case "payload":
          return new JsonState(state.value.payload, "JSON");
        case "signature":
          return new StringState(state.value.signature);
        case "iat":
          return new DateState(DateTime.fromSeconds(state.value.payload.iat as number));
        case "exp":
          return new DateState(DateTime.fromSeconds(state.value.payload.exp as number));
        case "nbf":
          return new DateState(DateTime.fromSeconds(state.value.payload.nbf as number));
      }
    }

    return new InvalidState("Not a JWT");
  }

  async getOptions(state: State, options?: ExtractMode): Promise<ExtractMode | undefined> {
    const values: Record<string, string> = { header: "header", payload: "payload", signature: "signature" };
    if (JwtState.is(state)) {
      if (state.value.payload.iat) values["iat"] = "iat (Issued At)";
      if (state.value.payload.exp) values["exp"] = "exp (Expiration Time)";
      if (state.value.payload.nbf) values["nbf"] = "nbf (Not Before)";
    }
    return {
      selected: options?.selected || "payload",
      values: values,
      label: "extract from",
    };
  }

  getOptionsReactNode(options: ExtractMode, onChange: (options: ExtractMode) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange as any} options={options} />;
  }
}

export default new ExtractFromJwtTransformation();
