import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils/ui.util";
import { AlignJustify, LayoutGrid, Network, Settings } from "lucide-react";
import React from "react";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useQueryClient } from "@tanstack/react-query";
import { QuickOption } from "@/components/quick-option";
import { PipelinePanel } from "@/api/types/response";
import { useFormik } from "formik";
import { useRepositories } from "@/api/queries";
import { UpdatePipelinePanelSettingsRequest } from "@/api/types/request";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { WorkflowSelectionAccordion } from "../panel-builder/workflow-selection-accordion";

const settingsOptions = {
  views: [
    {
      id: "flow",
      name: "Flow",
      icon: <Network size={20} />,
    },
    {
      id: "list",
      name: "List",
      icon: <AlignJustify size={20} />,
    },
    {
      id: "grid",
      name: "Grid",
      icon: <LayoutGrid size={20} />,
    },
  ],
};

interface PanelSettingsPopoverProps {
  pipelinePanel: PipelinePanel;
}

export const PanelSettingsPopover: React.FC<PanelSettingsPopoverProps> = ({ pipelinePanel }) => {
  const { isPending, isError, data: response } = useRepositories();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const pipelinePanelSettingsMutation = ApiMutation.useUpdatePipelinePanelSettings({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [["pipelines", "panel"], { panelId: pipelinePanel.panelId }] });
      toast({ title: "Success!", description: data.message });
      setOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const updatePipelinePanelSettings: UpdatePipelinePanelSettingsRequest = {
    panelName: pipelinePanel.panelName,
    defaultView: pipelinePanel.defaultView ?? "flow",
    selectedWorkflows: pipelinePanel.selectedWorkflows,
  };

  const formik = useFormik({
    initialValues: updatePipelinePanelSettings,
    onSubmit: (values) => {
      pipelinePanelSettingsMutation.mutate({
        panelId: pipelinePanel.panelId,
        ...values,
      });
    },
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="neu-flat" size="sm" className="gap-1 bg-secondary-300">
          Settings
          <Settings size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[420px] p-2"
        side="bottom"
        align="end"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="flex gap-2 items-center mt-2">
                <p className="text-sm font-semibold">Default view</p>
              </div>
              <div className="flex gap-2">
                {settingsOptions.views.map((view, index) => (
                  <QuickOption
                    key={index}
                    name={view.name}
                    icon={view.icon}
                    className={cn(
                      "min-h-28",
                      formik.values.defaultView === view.id ? "bg-secondary-200" : "bg-transparent"
                    )}
                    handleOnClick={() => {
                      formik.setFieldValue("defaultView", view.id);
                    }}
                  />
                ))}
              </div>

              <div className="flex gap-2 items-center mt-2">
                <p className="text-sm font-semibold">Selected workflows</p>
              </div>

              <Accordion type="single" collapsible>
                {!isPending && !isError && (
                  <>
                    {response.status === "success" && (
                      <>
                        {response.data.repositories.map((repository, index) => (
                          <AccordionItem key={index} value={`item-${index}`}>
                            <AccordionTrigger>
                              <div className="flex gap-4 items-center">
                                <img src={repository.owner.avatarUrl} className="w-6 rounded-full" />
                                <p className="text-sm font-mono">
                                  {repository.owner.login}/{repository.name}
                                </p>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent>
                              <WorkflowSelectionAccordion
                                owner={repository.owner.login}
                                repo={repository.name}
                                selectedWorkflows={formik.values.selectedWorkflows}
                                setFieldValue={formik.setFieldValue}
                              />
                            </AccordionContent>
                          </AccordionItem>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Accordion>
            </div>

            <Button
              type="submit"
              variant="neu-flat"
              size="sm"
              disabled={pipelinePanelSettingsMutation.isPending}
              className="bg-tertiary-300 gap-1"
            >
              {pipelinePanelSettingsMutation.isPending ? <LoadingSpinner message={"Updating"} /> : "Update"}
            </Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  );
};
