import { useAuth } from "@/auth/useAuth";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/utils/ui.util";
import { ArrowRight, Flag, GitBranch, Link, Lock, LucideIcon, Mail, PencilRuler, Webhook } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export type ToolboxItem = {
  name: string;
  description: string;
  icon: LucideIcon;
  handleOnClick: () => void;
  tags?: string[];
  superscript?: {
    text: string;
    className: string;
  };
  disabled?: boolean;
  authRequired?: boolean;
  noAuth?: boolean;
  hidden?: boolean;
};

export const Dashboard: React.FC = () => {
  const { status } = useAuth();
  const navigate = useNavigate();

  const toolbox: ToolboxItem[] = [
    {
      name: "Pipelines",
      description: "Create and manage your CI pipelines.",
      icon: GitBranch,
      handleOnClick: () => {
        navigate("/pipelines");
      },
      tags: ["CI/CD", "GitHub Actions"],
      superscript: {
        text: "New",
        className: "bg-tertiary-600 hover:bg-tertiary-600",
      },
      authRequired: status !== "authenticated",
    },
    {
      name: "Tools",
      description: "Parse and inspect data from your systems.",
      icon: PencilRuler,
      handleOnClick: () => {
        navigate("/tools");
      },
      tags: ["Transformations"],
      superscript: {
        text: "Beta",
        className: "bg-secondary-950 hover:bg-secondary-950",
      },
      noAuth: true,
    },
    {
      name: "Webhooks",
      description: "Monitor, test, and debug your system's webhook responses and payloads in real-time.",
      icon: Webhook,
      handleOnClick: () => {
        navigate("/webhooks");
      },
      tags: ["Testing", "Monitoring"],
      noAuth: true,
    },
    {
      name: "Emails",
      description: "Generate email addresses to test your email templates, and inspect the data sent.",
      icon: Mail,
      handleOnClick: () => {
        navigate("/emails");
      },
      tags: ["Testing", "Template Validation"],
      noAuth: true,
    },
    {
      name: "Secrets Manager",
      description: "Store and manage your secrets. Easy access from all your team members.",
      icon: Lock,
      handleOnClick: () => {},
      disabled: true,
      tags: ["Productivity"],
      superscript: {
        text: "Coming Soon",
        className: "bg-gray-500 hover:bg-gray-500",
      },
    },
    {
      name: "Hyperlinks Board",
      description: "Unify all your links in one place.",
      icon: Link,
      handleOnClick: () => {},
      disabled: true,
      tags: ["Productivity"],
      superscript: {
        text: "Coming Soon",
        className: "bg-gray-500 hover:bg-gray-500",
      },
    },
    {
      name: "Feature Flags",
      description: "Control your features and remote configurations with ease. No more redeployments.",
      icon: Flag,
      handleOnClick: () => {},
      disabled: true,
      tags: ["Release Management", "Configuration"],
      superscript: {
        text: "Coming Soon",
        className: "bg-gray-500 hover:bg-gray-500",
      },
      hidden: true,
    },
  ];

  return (
    <div className="flex w-full bg-secondary-200">
      <ScrollArea className="w-full">
        <div className="flex justify-center py-8 2xl:py-10">
          <div className="w-full max-w-[1400px]">
            <div className="flex flex-col gap-10 2xl:gap-12 px-4">
              <div className="flex flex-col space-y-4 items-center justify-center">
                <h3 className="font-bold tracking-tight">Developer Toolbox</h3>
                <small className="max-w-[640px] text-center">
                  Welcome to your toolbox. From here you can quickly access the tools you need to manage your pipelines,
                  test your webhooks and emails, and parse and inspect data from your systems.
                </small>
              </div>
              <div className="flex flex-col gap-2 2xl:gap-6">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {toolbox.map((item, index) => {
                    if (item.hidden) return null;

                    return (
                      <Card
                        key={index}
                        className={cn(
                          "w-full flex flex-col p-2 relative overflow-hidden",
                          item.disabled && "opacity-70"
                        )}
                      >
                        {item.noAuth && (
                          <div className="absolute w-[214px] h-[36px] top-[45px] -right-[44px] rotate-45 flex items-center justify-center bg-tertiary-300 border-t-2 border-b-2 border-secondary-950 z-20 shadow-sm text-sm font-medium">
                            No account required!
                          </div>
                        )}

                        <CardHeader className="p-4 border-b">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2">
                              <h4 className="font-semibold text-lg">{item.name}</h4>
                              {item.superscript && (
                                <sup>
                                  <Badge className={cn("p-0 px-1", item.superscript.className)}>
                                    {item.superscript.text}
                                  </Badge>
                                </sup>
                              )}
                            </div>
                            <item.icon size={20} />
                          </div>
                          {item.tags && (
                            <div className="flex flex-wrap gap-2 mt-2">
                              {item.tags.map((tag, index) => (
                                <Badge key={index} variant="outline" className="text-xs font-medium px-2 py-1">
                                  {tag}
                                </Badge>
                              ))}
                            </div>
                          )}
                        </CardHeader>
                        <CardContent className="p-4 flex flex-grow flex-col">
                          <p className="text-sm text-gray-600 mb-4">{item.description}</p>
                          <Button
                            size="sm"
                            variant="neu"
                            onClick={item.handleOnClick}
                            className="bg-secondary-300 self-start mt-auto"
                            disabled={item.disabled}
                          >
                            {item.authRequired && <Lock size={16} className="mr-1" />} Launch {item.name}{" "}
                            {!item.authRequired && <ArrowRight size={16} className="ml-1" />}
                          </Button>
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
