import React from "react";
import "@xyflow/react/dist/style.css";
import { PipelinePanel, UpdatePipelineEvent } from "@/api/types/response";
import { useQueryClient } from "@tanstack/react-query";
import useWebSocket from "react-use-websocket";

const socketUrl = `${import.meta.env.VITE_WEBSOCKET_URL}`;

interface DynamicPanelWrapperProps {
  wsId: string;
  resourceJwt: string;
  pipelinePanel: PipelinePanel;
  children: (props: { pipelinePanel: PipelinePanel; workflowResourceJwt: string }) => React.ReactNode;
}

export const DynamicPanelWrapper: React.FC<DynamicPanelWrapperProps> = ({
  wsId,
  resourceJwt,
  pipelinePanel,
  children,
}) => {
  const queryClient = useQueryClient();

  const { lastMessage } = useWebSocket(socketUrl, {
    queryParams: { id: wsId, token: resourceJwt },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  React.useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data) as UpdatePipelineEvent;

      if (message.type === "update_panel") {
        queryClient.invalidateQueries({
          queryKey: [["pipelines", "panel"], { panelId: pipelinePanel.panelId }],
        });
      }
    }
  }, [lastMessage, queryClient, pipelinePanel.panelId]);

  return <>{children({ pipelinePanel, workflowResourceJwt: resourceJwt })}</>;
};
