import React from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Background,
  BackgroundVariant,
  Controls,
  Node,
  Edge,
  Panel,
  useReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { GroupNode } from "@/lib/pipelines/flow-view/elements/group-node";
import { InitialNode } from "@/lib/pipelines/flow-view/elements/initial-node";
import { layoutService } from "@/utils/pipelines/layout-service";
import { Button } from "@/components/ui/button";
import { GalleryHorizontalEnd, GalleryVerticalEnd } from "lucide-react";
import { cn } from "@/utils/ui.util";
import { useFlowSettings } from "@/lib/pipelines/flow-view/flow-settings-manager/useFlowSettings";
import {
  FlowDirection,
  FlowSizeProvider,
} from "@/lib/pipelines/flow-view/flow-settings-manager/flow-settings-provider";
import { usePipelineState } from "@/lib/pipelines/config/usePipelineState";
import { PanelWorkflowSelection } from "@/api/types/common";

const defaultNodeHeight = 300;
const defaultNodeWidth = 240;

const nodeTypes = {
  initialNode: InitialNode,
  groupNode: GroupNode,
};

interface FlowViewDashboardProps {
  title: string;
  selectedWorkflows: PanelWorkflowSelection;
  workflowResourceJwt: string;
}

export const FlowViewDashboard: React.FC<FlowViewDashboardProps> = ({
  title,
  selectedWorkflows,
  workflowResourceJwt,
}) => {
  return (
    <FlowSizeProvider>
      <FlowViewDashboardContent
        title={title}
        selectedWorkflows={selectedWorkflows}
        workflowResourceJwt={workflowResourceJwt}
      />
    </FlowSizeProvider>
  );
};

const FlowViewDashboardContent: React.FC<FlowViewDashboardProps> = ({ selectedWorkflows, workflowResourceJwt }) => {
  const { flowSize, direction } = useFlowSettings();
  const { readOnly } = usePipelineState();

  const initialNodes: Node[] = React.useMemo(() => {
    const nodes: Node[] = [];

    Object.keys(selectedWorkflows).forEach((ownerRepo) => {
      nodes.push({
        id: ownerRepo,
        type: "initialNode",
        data: { title: ownerRepo },
        position: { x: 0, y: 0 },
      });

      selectedWorkflows[ownerRepo].forEach((workflow) => {
        nodes.push({
          id: workflow.workflowId.toString(),
          type: "groupNode",
          data: {
            title: workflow.workflowName,
            owner: workflow.owner,
            repo: workflow.repo,
            workflowId: workflow.workflowId,
            workflowResourceJwt: workflowResourceJwt,
          },
          position: { x: 0, y: 0 },
          width: flowSize[workflow.workflowId]?.width ?? defaultNodeWidth,
          height: flowSize[workflow.workflowId]?.height ?? defaultNodeHeight,
        });
      });
    });

    return nodes;
  }, [selectedWorkflows, workflowResourceJwt, flowSize]);

  const initialEdges: Edge[] = React.useMemo(() => {
    const edges: Edge[] = [];

    Object.keys(selectedWorkflows).forEach((ownerRepo) => {
      selectedWorkflows[ownerRepo].forEach((workflow) => {
        edges.push({
          id: `e-${ownerRepo}-${workflow.workflowId}`,
          source: ownerRepo,
          target: workflow.workflowId.toString(),
        });
      });
    });

    return edges;
  }, [selectedWorkflows]);

  const { nodes: layoutedNodes, edges: layoutedEdges } = layoutService.getLayoutedElements(
    initialNodes,
    initialEdges,
    direction
  );

  return (
    <div
      className={cn(
        "neu flex flex-col gap-6 2xl:gap-12 rounded-lg overflow-hidden bg-white",
        readOnly ? "h-[96dvh]" : "h-[70dvh]"
      )}
    >
      <ReactFlowProvider>
        <PipelinesFlowCore layoutedNodes={layoutedNodes} layoutedEdges={layoutedEdges} />
      </ReactFlowProvider>
    </div>
  );
};

interface PipelinesFlowCoreProps {
  layoutedNodes: Node[];
  layoutedEdges: Edge[];
}

const PipelinesFlowCore: React.FC<PipelinesFlowCoreProps> = ({ layoutedNodes, layoutedEdges }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const { fitView } = useReactFlow();
  const { updateDirection } = useFlowSettings();

  const updateNodes = React.useCallback(
    (newNodes: Node[]) => {
      setNodes((prevNodes) => {
        return newNodes.map((newNode) => {
          const existingNode = prevNodes.find((node) => node.id === newNode.id);
          return existingNode ? { ...existingNode, ...newNode } : newNode;
        });
      });
    },
    [setNodes]
  );

  const updateEdges = React.useCallback(
    (newEdges: Edge[]) => {
      setEdges((prevEdges) => {
        return newEdges.map((newEdge) => {
          const existingEdge = prevEdges.find((edge) => edge.id === newEdge.id);
          return existingEdge ? { ...existingEdge, ...newEdge } : newEdge;
        });
      });
    },
    [setEdges]
  );

  const onLayout = React.useCallback(
    (direction: FlowDirection) => {
      updateDirection(direction);
    },
    [updateDirection]
  );

  // Update nodes while preserving existing ones
  React.useEffect(() => {
    updateNodes(layoutedNodes);
  }, [layoutedNodes, updateNodes]);

  // Update edges while preserving existing ones
  React.useEffect(() => {
    updateEdges(layoutedEdges);
  }, [layoutedEdges, updateEdges]);

  // Fit view every time the component rerenders
  fitView({
    duration: 1000,
  });

  return (
    <div className="w-full h-full">
      <ReactFlow
        id="parent-flow"
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        proOptions={{
          hideAttribution: true,
        }}
        nodeTypes={nodeTypes}
        fitView={true}
        minZoom={0.01}
      >
        <Background color="#777" variant={BackgroundVariant.Dots} />
        <Controls />
        <Panel position="top-right" className="flex gap-1">
          <Button variant="neu-flat" scheme="white-black" size="sm" onClick={() => onLayout("TB")} className="gap-1">
            <GalleryVerticalEnd size={16} />
            {/* Vertical Layout */}
          </Button>
          <Button variant="neu-flat" scheme="white-black" size="sm" onClick={() => onLayout("LR")} className="gap-1">
            <GalleryHorizontalEnd size={16} />
            {/* Horizontal Layout */}
          </Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};
