import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, KeyValueState, StringState } from "../states";
import { BlobState } from "../states/blob.state";
import { ToolsIcons } from "./common-options/options.transformations";

export class ToStringTransformation extends StateTransformation<State> {
  constructor() {
    super("to-string", "To String", ["string", "blob"]);
  }
  icon(): ToolsIcons {
    return "string";
  }

  createMultiByteState(segment: string) {
    const codePoints = Array.from(segment).map((char) => char.codePointAt(0)!);
    const byteLength = new TextEncoder().encode(segment).length;

    return {
      string: segment,
      "Code points": codePoints.join(" "),
      "Code points (hex)": codePoints.map((cp) => "0x" + cp.toString(16)).join(" "),
      "UTF-8 bytes": byteLength,
      Unicode: codePoints.map((cp) => `U+${cp.toString(16).toUpperCase()}`).join(" "),
    };
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) return CanTransformResult.Yes;
    if (BlobState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<State> {
    if (state.config().stringValue) {
      const str = state.stringValue();
      if (str.length === 1) {
        const char = str[0];
        const charCode = str.charCodeAt(0);
        return new KeyValueState(
          {
            ASCII: char,
            "Char code": charCode,
            "Hex char code": "0x" + charCode.toString(16),
            "UTF-8 Bytes": new TextEncoder().encode(str).length,
          },
          "Character"
        );
      }
      if (str.length < 20) {
        const segmenter = new Intl.Segmenter();
        const segments = [...segmenter.segment(str)].map((s) => s.segment);

        if (segments.length === 1) {
          const segmentInfo = this.createMultiByteState(segments[0]);
          return new KeyValueState(segmentInfo, "Multi-byte character");
        }
      }
      return new StringState(state.stringValue());
    }
    if (BlobState.is(state)) {
      const text = await state.value.text();
      return new StringState(text);
    }
    return new InvalidState("Cannot convert to string");
  }
}

export default new ToStringTransformation();
