import { Badge } from "@/components/ui/badge";
import { Pencil } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ApiMutation } from "@/api";
import { toast } from "@/components/ui/use-toast";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { User } from "@/api/types/response";
import { Input } from "@/components/ui/input";
import { cn } from "@/utils/ui.util";
import { defaultError } from "@/lib/auth/utils/default-error-message";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, "Must have at least 2 characters").max(20, "Too long").required("Required"),
  role: Yup.string().oneOf(["owner", "user"]).required("Required"),
});

interface UpdateAccountUser {
  name: string;
  role: User["role"];
}
interface EditAccountUserProps {
  user: User;
}

export const EditAccountUser: React.FC<EditAccountUserProps> = ({ user }) => {
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const updateAccountUser = ApiMutation.useUpdateAccountUser({
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "User updated.",
      });
      queryClient.invalidateQueries({ queryKey: [["account", "users"]] });
      setDialogOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const updateUserDetails: UpdateAccountUser = {
    name: user.name,
    role: user.role,
  };

  const formik = useFormik({
    initialValues: updateUserDetails,
    validationSchema: validationSchema,
    onSubmit: (values: UpdateAccountUser) => {
      updateAccountUser.mutate({
        email: user.email,
        newName: values.name,
        newRole: values.role,
      });
    },
  });

  const roles: User["role"][] = ["user", "owner"];

  return (
    <div>
      <Button size="icon" variant="ghost" className="hover:bg-secondary-100" onClick={() => setDialogOpen(!dialogOpen)}>
        <Pencil size={16} />
      </Button>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Account User</DialogTitle>
            <small>Change your account user details here.</small>
          </DialogHeader>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-semibold">Name</p>
                  <Input
                    variant={"neu-flat"}
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.name && formik.errors.name && "bg-red-200")}
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm font-semibold">Role</p>
                  <div className="flex gap-1">
                    {roles.map((role) => (
                      <Badge
                        variant={formik.values.role === role ? "default" : "outline"}
                        className="flex justify-center w-16 hover:bg-secondary-950 hover:text-white hover:cursor-pointer"
                        onClick={() => formik.setFieldValue("role", role)}
                      >
                        {role.toUpperCase()}
                      </Badge>
                    ))}
                  </div>
                </div>

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    size="sm"
                    disabled={updateAccountUser.isPending}
                    className="bg-primary-300 min-w-32"
                  >
                    {updateAccountUser.isPending ? "Updating..." : "Update"}
                  </Button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};
