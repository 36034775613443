import React from "react";
import { WorkflowRunJob } from "@/api/types/response";
import { cn } from "@/utils/ui.util";
import { Handle, Position, type Node, type NodeProps } from "@xyflow/react";
import { JobTimer } from "@/lib/pipelines/utils/timer";
import { useFlowSettings } from "@/lib/pipelines/flow-view/flow-settings-manager/useFlowSettings";
import { getJobStatusIcon } from "@/lib/pipelines/utils/pipeline.utils";

type PipelineNode = Node<{
  job: WorkflowRunJob;
  handle?: "source" | "target" | "both" | "none";
}>;

export const PipelineNode: React.FC<NodeProps<PipelineNode>> = ({ data }) => {
  const { direction } = useFlowSettings();
  const { job, handle } = data;
  return (
    <>
      <div
        className={cn(
          "neu rounded-lg w-full h-full",
          job.status === "completed" && job.conclusion === "success" && "bg-tertiary-200/50",
          job.status === "completed" && job.conclusion === "failure" && "bg-red-400/50",
          job.status === "in_progress" && "bg-secondary-300/50",
          job.status === "queued" && "bg-primary-200/50"
        )}
      >
        <div className="flex flex-col gap-2 p-2">
          <div className="flex items-center justify-between gap-10">
            <div className="flex items-center gap-2">
              {getJobStatusIcon(job.status, job.conclusion)}
              <small className="text-xs">
                <JobTimer startTime={job.started_at} endTime={job.completed_at} />
              </small>
            </div>
          </div>
          <p className="font-bold">{job.name}</p>
        </div>
      </div>
      {(handle === "source" || handle === "both") && (
        <Handle type="target" position={direction === "TB" ? Position.Top : Position.Left} />
      )}
      {(handle === "target" || handle === "both") && (
        <Handle type="source" position={direction === "TB" ? Position.Bottom : Position.Right} />
      )}
    </>
  );
};
