import React from "react";

import "@xyflow/react/dist/style.css";
import { usePipelineInfo } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate, useParams } from "react-router-dom";
import { PipelinesDashboard } from "@/lib/pipelines/pipelines-dashboard";
import { PipelineStateProvider } from "@/lib/pipelines/config/pipeline-state-provider";

export const PublicPipelinesDashboard: React.FC = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const {
    isPending,
    isError,
    data: pipelineInfo,
  } = usePipelineInfo({
    pipelineId: pipelineId ?? "",
  });

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <PipelineStateProvider readOnly={true}>
          <PipelinesDashboard owner={pipelineInfo.owner} repo={pipelineInfo.repo} />
        </PipelineStateProvider>
      )}
    </>
  );
};
