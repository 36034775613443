import { formatBytes } from "@/helpers/helpers";
import { State, StateConfig } from "../state";
import { BlobImage } from "@/components/blob-image";
import { DownloadBlob } from "@/components/download-blob";
import HexViewer from "./hex-view";

export type BlobStateOptions = {
  mime: string | undefined;
  filename?: string;
};

export class BlobState extends State<Blob> {
  readonly blobMimeType: string | undefined;
  readonly filename: string | undefined;
  constructor(value: Blob, options?: BlobStateOptions) {
    super(value);

    const { filename, mime } = options || {};
    this.filename = filename;
    this.blobMimeType = mime ?? value.type;
  }

  stringValue(): string {
    throw new Error("Method not implemented.");
  }

  config(): StateConfig {
    const customDisplay = ["blob", "hex"];
    if (this.blobMimeType?.startsWith("image/")) customDisplay.push("image");

    return {
      transformations: "tags-only",
      transformationTags: ["blob", "image"],
      validations: "all",
      stringValue: false,
      jsonValue: false,
      customDisplay: customDisplay,
      defaultDisplayMode: "blob",
    };
  }

  toReactNode(display: string) {
    switch (display) {
      case "blob":
        return (
          <div className="flex items-center">
            <pre>
              {this.blobMimeType} size: {formatBytes(this.value.size * 1)}{" "}
              {this.filename && `filename: ${this.filename}`}
            </pre>
            <DownloadBlob blob={this.value} filename={this.filename ?? "download.file"}></DownloadBlob>
          </div>
        );
      case "image":
        return <BlobImage blob={this.value} />;
      case "hex":
        return (
          <div>
            <HexViewer blob={this.value} />
          </div>
        );
      default:
        throw new Error(`Invalid display mode ${display}`);
    }
  }
}
