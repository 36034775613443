import { Download } from "lucide-react";
import { Button } from "@/components/ui/button";

interface DownloadBlobProps {
  blob: Blob;
  filename: string;
}

export const DownloadBlob: React.FC<DownloadBlobProps> = ({ blob, filename }) => {
  const downloadBlob = () => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button variant="ghost" size="icon" onClick={downloadBlob}>
      <Download size={16} />
    </Button>
  );
};
