import React from "react";
import { EmailDetails } from "@/api/types/response";
import { useEmail } from "@/api/queries";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { HeadersTable } from "@/components/headers-table";
import { EmailRender } from "@/lib/emails/page/elements/email-render";
import { EmailHeader } from "@/lib/emails/page/elements/email-header";
import { Separator } from "@/components/ui/separator";
import { LoadingSpinner } from "@/components/loading-spinner";
import { VerificationChecks } from "@/lib/emails/page/elements/verification-checks";
import { formatBytes } from "@/helpers/helpers";
import { Attachments } from "@/lib/emails/page/elements/attachments";
import { EmailMimeState } from "@/utils/parsing";
import { AlignLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface EmailDisplayProps {
  id: string;
  mailDetails: EmailDetails;
}

export const EmailDisplay: React.FC<EmailDisplayProps> = ({ id, mailDetails }) => {
  const navigate = useNavigate();
  const [mail, setMail] = React.useState<EmailMimeState>();

  const { isPending, isError, data } = useEmail({
    email_address: mailDetails.to,
    email_id: mailDetails.id,
  });

  React.useEffect(() => {
    if (data) {
      setMail(new EmailMimeState(data));
    }
  }, [data]);

  return (
    <div>
      {isPending && (
        <div className="p-8 text-center text-sm font-medium">
          <LoadingSpinner message="Loading..." />
        </div>
      )}
      {isError && <p className="p-8 text-center text-sm font-medium">Error loading message...</p>}
      {!isPending && !isError && mail && (
        <div>
          <div className="flex justify-between items-center gap-4 p-4">
            <div className="sm:hidden">
              <Button variant="ghost" size="icon" onClick={() => navigate(`/emails/${id}`)}>
                <AlignLeft className="h-[1.2rem] w-[1.2rem]" />
              </Button>
            </div>
            <EmailHeader emailId={mailDetails.id} rawEmail={data} email={mail} />
          </div>
          <Separator />

          <ContentPanel email={mail} mailDetails={mailDetails} />

          {mail.attachments.length > 0 ? (
            <div>
              <Separator />
              <Attachments attachments={mail.getAttachments()} />
            </div>
          ) : (
            <></>
          )}

          <Separator />
          <EmailRender rawMail={data} email={mail} />
        </div>
      )}
    </div>
  );
};

interface ContentPanelProps {
  email: EmailMimeState;
  mailDetails: EmailDetails;
}

const ContentPanel: React.FC<ContentPanelProps> = ({ email, mailDetails }) => {
  const parsedMessage = email.getParsedMessage();
  const mailSize = email.getSize();

  return (
    <>
      <div className="sm:hidden">
        <div className="flex flex-col gap-2">
          <VerificationChecks
            requestDetails={{
              host: parsedMessage.from?.email.split("@")[1] ?? "",
              date: parsedMessage.date?.toISOString() ?? "",
              size: formatBytes(mailSize) ?? "",
              id: mailDetails.id ?? "",
            }}
            headers={parsedMessage.headers}
          />
          <Separator />
          <HeadersTable title={"Headers"} headers={parsedMessage.headers} type="mail-headers" />
        </div>
      </div>
      <div className="hidden sm:block">
        <ResizablePanelGroup direction="horizontal" className="max-h-[500px]">
          <ResizablePanel defaultSize={50} minSize={25}>
            <VerificationChecks
              requestDetails={{
                host: parsedMessage.from?.email.split("@")[1] ?? "",
                date: parsedMessage.date?.toISOString() ?? "",
                size: formatBytes(mailSize) ?? "",
                id: mailDetails.id ?? "",
              }}
              headers={parsedMessage.headers}
            />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={50} minSize={25}>
            <HeadersTable title={"Headers"} headers={parsedMessage.headers} type="mail-headers" />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </>
  );
};
