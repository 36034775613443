import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Menubar } from "@/components/ui/menubar";
import { Separator } from "@/components/ui/separator";
import { AlignRight, ArrowUpRight, BookOpenText, Contact2, FileText } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";

export const Navbar: React.FC = () => {
  return (
    <div className="container px-4 sm:px-8">
      <Menubar className="flex justify-between border-none rounded-none shadow-none py-8">
        <div className="flex items-center gap-2 flex-1 justify-between">
          <Link
            to="/"
            className="flex items-center gap-3 hover:cursor-pointer pt-[2px] text-secondary-950 no-underline hover:underline"
          >
            <img src={"/logo-circle-hollow-dark.svg"} alt={"wt.dev"} className="w-8" />
            <h6 className="font-bold font-mono">wt.dev</h6>
          </Link>

          <div className="flex flex-row pt-[2px]">
            {/* Small screens */}
            <div className="block sm:hidden">
              <MobileNavbar />
            </div>

            {/* Large Screens */}
            <div className="hidden sm:flex">
              <DesktopNavbar />
            </div>
          </div>
        </div>
      </Menubar>
    </div>
  );
};

export const DesktopNavbar: React.FC = () => {
  return (
    <>
      <Button asChild variant="ghost" size="sm" className="text-sm font-medium bg-transparent hover:underline">
        <Link to={docsURL} target="_blank" className="no-underline">
          Docs
        </Link>
      </Button>

      <Button
        asChild
        variant="ghost"
        size="sm"
        className="text-sm font-medium bg-transparent no-underline hover:underline"
      >
        <Link to={docsURL + "/blog"} target="_blank" className="no-underline">
          Blog
        </Link>
      </Button>

      <Button asChild variant="ghost" size="sm" className="text-sm font-medium bg-transparent hover:underline">
        <Link to="/about" className="no-underline">
          About Us
        </Link>
      </Button>

      <Button asChild variant="ghost" size="sm" className="text-sm font-medium bg-transparent hover:underline">
        <Link to="/home/pipelines" className="flex gap-1 no-underline">
          Pipelines
          <sup>
            <Badge className="p-0 px-1 text-xs bg-secondary-950 hover:no-underline hover:bg-secondary-950">New</Badge>
          </sup>
        </Link>
      </Button>

      <Button asChild variant="neu-flat" size="sm" className="font-medium ml-2 bg-primary-300" id="dashboard-button">
        <Link to="/dashboard" className="flex items-center gap-1 no-underline">
          Toolbox
          <ArrowUpRight size={18} />
        </Link>
      </Button>
    </>
  );
};

export const MobileNavbar: React.FC = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="bg-transparent">
          <AlignRight className="h-[1.2rem] w-[1.2rem]" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="flex flex-col gap-1">
          <div>
            <DropdownMenuItem asChild>
              <Link to={docsURL} target="_blank" className="no-underline text-secondary-950">
                <FileText size={16} className="mr-2" />
                <span>Docs</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link to={docsURL + "/blog"} target="_blank" className="no-underline text-secondary-950">
                <BookOpenText size={16} className="mr-2" />
                <span>Blog</span>
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem asChild>
              <Link to="/about" className="no-underline text-secondary-950">
                <Contact2 size={16} className="mr-2" />
                <span>About Us</span>
              </Link>
            </DropdownMenuItem>
          </div>

          <Separator className="h-[2px]" />

          <div>
            <DropdownMenuItem asChild>
              <Link to="/tools" className="no-underline text-secondary-950">
                <span>Tools</span>
                <ArrowUpRight size={16} className="ml-1" />
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link to="/dashboard" className="no-underline text-secondary-950">
                <span>Toolbox</span>
                <ArrowUpRight size={16} className="ml-1" />
              </Link>
            </DropdownMenuItem>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
