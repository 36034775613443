import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, JsonState } from "../states";
import { ToolsIcons } from "./common-options/options.transformations";
import { calculateTransformResult, parseStringToJson } from "../utils/json-utils";
import { parse as parseYaml } from "yaml";

export class ToYamlTransformation extends StateTransformation<JsonState | InvalidState> {
  constructor() {
    super("to-yaml", "To YAML", ["string"]);
  }

  icon(): ToolsIcons {
    return "json";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().jsonValue) return 0.5 * calculateTransformResult(state.jsonValue());

    if (state.config().stringValue) {
      try {
        const input = state.stringValue();
        if (parseStringToJson(input) !== null) return CanTransformResult.No;
        const res = parseYaml(input, {});
        return calculateTransformResult(res);
      } catch {
        /* empty */
      }
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<JsonState | InvalidState> {
    if (state.config().jsonValue) return new JsonState(state.jsonValue(), "YAML");
    try {
      const yaml = parseYaml(state.stringValue(), {});
      if (yaml) return new JsonState(yaml, "YAML");
    } catch (_) {
      /* empty */
    }
    return new InvalidState("Invalid YAML value");
  }
}

export default new ToYamlTransformation();
