import { Input } from "@/components/ui/input";
import { cn } from "@/utils/ui.util";
import { Label } from "@/components/ui/label";
import React from "react";
import { ElementHighlights, getHighlightBg } from "@/components/highlight-element";
type TransformationOptionsStringInputProps<T extends object> = {
  options: T;
  defaultValue: string;
  placeholder?: string;
  selectedKey: keyof T;
  onChange: (options: T) => void;
  className?: string;
  label?: string;
  highlight?: ElementHighlights;
};

export const TransformationOptionsStringInput: React.FC<TransformationOptionsStringInputProps<any>> = ({
  options,
  defaultValue,
  selectedKey,
  placeholder,
  onChange,
  className,
  label,
  highlight,
}) => {
  const checkboxId = React.useId();
  return (
    <div className="flex min-h-11">
      {label && (
        <div className="flex items-center neu-flat px-2 rounded-l-md bg-zinc-300 text-nowrap !border-r-0">
          <Label className={className} htmlFor={checkboxId}>
            {label}
          </Label>
        </div>
      )}
      <Input
        type="text"
        variant={"neu-flat"}
        className={cn(
          "whitespace-nowrap font-mono rounded-l-none h-full",
          highlight && getHighlightBg(highlight),
          className
        )}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onFocus={(e) => e.target.select()}
        onChange={(e) => onChange({ ...options, [selectedKey]: e.target.value })}
      />
    </div>
  );
};
