import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useRecentEmails } from "@/api/queries";
import { Button } from "@/components/ui/button";
import { formatDistanceToNow } from "date-fns";
import { DatePickerWithRange } from "@/components/date-picker-with-range";
import React from "react";
import { DateRange } from "react-day-picker";
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight } from "lucide-react";
import { getIdFromEmail } from "@/lib/emails/dashboard/utils";
import { Link } from "react-router-dom";

export const RecentEmailsList: React.FC = () => {
  const [startKey, setStartKey] = React.useState<string | undefined>(undefined);
  const [history, setHistory] = React.useState<string[]>([]);
  const [ascending, setAscending] = React.useState<boolean>(false);

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: new Date(Date.now() - 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  React.useEffect(() => {
    // Reset the start key when the date range changes
    setStartKey(undefined);
    setHistory([]);
  }, [date]);

  const { isPending, isError, data } = useRecentEmails({
    startKey: startKey,
    order: ascending ? "asc" : "desc",
    fromDate: date?.from?.toISOString(),
    toDate: date?.to?.toISOString(),
  });

  return (
    <div>
      <div className="flex-1 space-y-4 pt-6">
        <div className="flex-1 items-center space-y-2">
          <Card>
            <CardHeader>
              <CardTitle className="font-semibold text-base">
                <div className="flex items-center justify-between">
                  Latest Email Addresses
                  <DatePickerWithRange date={date} setDate={setDate} />
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              {isPending && (
                <div className="flex items-center justify-center">
                  <LoadingSpinner message={"Loading..."} />
                </div>
              )}
              {isError && <div>error</div>}
              {!isPending && !isError && (
                <>
                  {data.items && data.items.length === 0 && <small>No emails found for the selected date range.</small>}
                  {data.items && data.items.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <Table className="whitespace-nowrap">
                        <TableHeader>
                          <TableRow>
                            <TableHead></TableHead>
                            <TableHead>Receive address</TableHead>
                            <TableHead># Received</TableHead>
                            <TableHead>
                              <div
                                className="flex items-center gap-2 hover:cursor-pointer select-none"
                                onClick={() => setAscending(!ascending)}
                              >
                                Created
                                {ascending ? <ArrowUp className="w-3 h-3" /> : <ArrowDown className="w-3 h-3" />}
                              </div>
                            </TableHead>
                            <TableHead className="text-right">Expires In</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data.items.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell className="font-medium font-mono">
                                <Button asChild variant="ghost" size="sm" className="p-0 hover:underline">
                                  <Link to={"/emails/" + getIdFromEmail(item.email)} className="no-underline">
                                    {item.email}
                                  </Link>
                                </Button>
                              </TableCell>
                              <TableCell>{item.receivedEmails.length}</TableCell>
                              <TableCell>{formatDistanceToNow(item.created)} ago</TableCell>
                              <TableCell className="text-right">
                                {item.ttl && formatDistanceToNow(new Date(item.ttl * 1000))}
                                {!item.ttl && "Never"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {(data.lastEvaluatedKey || history.length > 0) && (
                        <div className="flex justify-end gap-2">
                          <Button
                            variant="neu-flat"
                            scheme="white-black"
                            size="sm"
                            onClick={() => {
                              if (history.length < 2) {
                                setHistory([]);
                                setStartKey(undefined);
                                return;
                              }

                              const lastKey = JSON.parse(history[history.length - 2]);
                              setHistory(history.slice(0, -1));
                              setStartKey(JSON.stringify(lastKey));
                            }}
                            disabled={history.length === 0}
                          >
                            <ChevronLeft size={16} />
                          </Button>
                          <Button
                            variant="neu-flat"
                            scheme="white-black"
                            size="sm"
                            onClick={() => {
                              const newKey = JSON.stringify(data.lastEvaluatedKey);
                              setHistory([...history, newKey]);
                              setStartKey(newKey);
                            }}
                            disabled={!data.lastEvaluatedKey}
                          >
                            <ChevronRight size={16} />
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
