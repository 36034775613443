import { LockKeyhole } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ApiMutation } from "@/api";
import { toast } from "@/components/ui/use-toast";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { User } from "@/api/types/response";
import { Input } from "@/components/ui/input";
import { cn } from "@/utils/ui.util";
import { defaultError } from "@/lib/auth/utils/default-error-message";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Must have at least 6 characters")
    .max(30, "Too long")
    .matches(/^(?=.*[A-Z])(?=.*\d)/, "Must contain at least one uppercase letter and one number")
    .required("Required"),
  repeatPassword: Yup.string().oneOf([Yup.ref("password"), undefined], "Passwords must match"),
});

interface UpdateAccountUserPassword {
  password: string;
  repeatPassword: string;
}

interface EditAccountUserPasswordProps {
  user: User;
}

export const EditAccountUserPassword: React.FC<EditAccountUserPasswordProps> = ({ user }) => {
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const updateAccountUser = ApiMutation.useUpdateAccountUserPassword({
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "User updated.",
      });
      queryClient.invalidateQueries({ queryKey: [["account", "users"]] });
      setDialogOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const updateUserDetails: UpdateAccountUserPassword = {
    password: "",
    repeatPassword: "",
  };

  const formik = useFormik({
    initialValues: updateUserDetails,
    validationSchema: validationSchema,
    onSubmit: (values: UpdateAccountUserPassword) => {
      updateAccountUser.mutate({
        email: user.email,
        newPassword: values.password,
      });
    },
  });

  return (
    <div>
      <Button size="icon" variant="ghost" className="hover:bg-secondary-100" onClick={() => setDialogOpen(!dialogOpen)}>
        <LockKeyhole size={16} />
      </Button>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Password</DialogTitle>
            <small>Change your account user's password here.</small>
          </DialogHeader>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-semibold">New Password</p>
                  <Input
                    variant={"neu-flat"}
                    id="password"
                    type="password"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    required
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.password && formik.errors.password && "bg-red-200")}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <p className="text-xs">{formik.errors.password}</p>
                  )}
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm font-semibold">Repeat Password</p>
                  <Input
                    variant={"neu-flat"}
                    id="repeatPassword"
                    type="password"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    required
                    value={formik.values.repeatPassword}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.repeatPassword && formik.errors.repeatPassword && "bg-red-200")}
                  />
                  {formik.touched.repeatPassword && formik.errors.repeatPassword && (
                    <p className="text-xs">{formik.errors.repeatPassword}</p>
                  )}
                </div>

                <div className="flex justify-end mt-2">
                  <Button
                    type="submit"
                    size="sm"
                    disabled={updateAccountUser.isPending}
                    className="bg-primary-300 min-w-32"
                  >
                    {updateAccountUser.isPending ? "Updating..." : "Update"}
                  </Button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};
