import React from "react";
import { Input } from "@/components/ui/input";
import { Check, Copy } from "lucide-react";

export interface CopyableInputProps {
  value: string;
}

export const CopyableInput: React.FC<CopyableInputProps> = ({ value }) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <div className="flex flex-row items-center gap-4">
      <Input className="font-mono" value={value} readOnly />
      {!isCopied && <Copy className="hover:cursor-pointer h-4" onClick={handleCopy} />}
      {isCopied && <Check className="h-4" />}
    </div>
  );
};
