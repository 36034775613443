import { formatBytes } from "@/helpers/helpers";
import { State, StateConfig } from "../state";
import { getBoundary, parse } from "parse-multipart-data";

type Parts = {
  filename?: string;
  name?: string;
  type: string;
  data: Buffer;
};

export class MultipartState extends State<Uint8Array> {
  readonly parts: Parts[];

  constructor(
    value: Uint8Array,
    readonly contentType: string
  ) {
    super(value);
    const boundary = getBoundary(contentType);
    this.parts = parse(Buffer.from(value), boundary);
  }

  stringValue(): string {
    return JSON.stringify(this.parts.map((x) => x.type));
  }

  getPart(index: number) {
    const part = this.parts[index];
    if (!part.type) {
      return part.data.toString();
    }
    return new Blob([part.data], { type: part.type });
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      transformationTags: ["multi-part"],
      validations: "none",
      stringValue: true,
      jsonValue: false,
      customDisplay: ["multi-part"],
      defaultDisplayMode: "multi-part",
    };
  }

  toReactNode(_: string) {
    return (
      <pre>
        {this.parts.map(
          (x) =>
            `${x.name ? `Name:${x.name}` : ""} ${x.filename ? `filename: ${x.filename}` : ""} ${x.type ? `Type: ${x.type}` : ""} Length: ${formatBytes(x.data.length)}\n`
        )}
      </pre>
    );
  }
}
