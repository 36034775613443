import React from "react";
import { FlowSettingsContext } from "@/lib/pipelines/flow-view/flow-settings-manager/flow-settings-provider";

export const useFlowSettings = () => {
  const context = React.useContext(FlowSettingsContext);
  if (!context) {
    throw new Error("useFlowSize must be used within a FlowSizeProvider");
  }
  return context;
};
