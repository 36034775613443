import React from "react";
import { RecentRequests } from "@/lib/webhooks/page/elements/sidebar/recent-requests";
import { CopyableInput } from "@/components/copyable-input";
import { Separator } from "@/components/ui/separator";
import { RequestDisplay } from "@/lib/webhooks/page/elements/request/request-display";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format } from "date-fns/format";
import { ResponseDisplay } from "@/lib/webhooks/page/elements/response/response-display";
import { AccessType, WebhookDetails, WebhookReceivedRequests, WebhookResponse } from "@/api/types/response";
import { RequestHelp } from "@/lib/webhooks/page/elements/help/request-help";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TooltipBadge } from "@/components/tooltip-badge";
import { GroupByOption, SortOption, WebhookService } from "@/utils/webhooks/webhook-service";
import { OptionsPopover } from "@/lib/webhooks/page/elements/sidebar/options-dropdown";
import { EditResponsePopover } from "@/lib/webhooks/page/elements/response/edit-response";
import { WebhookStatus } from "@/lib/webhooks/page/webhook-page";
import { WebhookStatusCard } from "@/lib/webhooks/page/elements/help/webhook-status-card";
import { AlignLeft, Lock, Unlock } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

interface WebhookPageCoreProps {
  id: string;
  requestId?: string;
  accessType: AccessType;
  resourceJwt: string;
  received: WebhookReceivedRequests;
  response: WebhookResponse;
  endpointStatus: WebhookStatus;
}

export const WebhookPageCore: React.FC<WebhookPageCoreProps> = ({
  id,
  requestId,
  accessType,
  resourceJwt,
  received,
  response,
  endpointStatus,
}) => {
  const endpoint = `${import.meta.env.VITE_WEBHOOKS_URL}/${id}`;
  const navigate = useNavigate();

  const selected: WebhookDetails | undefined = React.useMemo(
    () => received.find((item) => item.id === requestId),
    [received, requestId]
  );

  const selectedIndex = React.useMemo(() => {
    return received.findIndex((item) => item.id === selected?.id);
  }, [selected, received]);

  const sidebar = (
    <Sidebar
      id={id}
      accessType={accessType}
      endpoint={endpoint}
      received={received}
      response={response}
      endpointStatus={endpointStatus}
      selected={selected}
    />
  );

  return (
    <div className="flex flex-col md:flex-row w-full">
      {/* Sidebar */}
      <div className="hidden sm:flex flex-col md:w-3/12 bg-secondary-200">{sidebar}</div>

      {/* Content */}
      <ScrollArea className="flex flex-col md:w-9/12 sm:border-l border-secondary-950">
        <div className="flex flex-col">
          {(!received.length || !requestId) && (
            <>
              <div className="hidden sm:flex p-10 justify-center">
                <div className="flex flex-col gap-8 w-full max-w-7xl">
                  <RequestHelp
                    id={id}
                    endpoint={endpoint}
                    resourceJwt={resourceJwt}
                    showRequestsHelp={true}
                    endpointStatus={endpointStatus}
                  />
                </div>
              </div>
              <div className="sm:hidden flex flex-col">{sidebar}</div>
            </>
          )}
          {received.length > 0 && selectedIndex < received.length && selected && (
            <>
              <Tabs key={selectedIndex} defaultValue="request">
                <div className="flex justify-between items-center mt-2 px-4 gap-2">
                  {/* Mobile Sidebar */}
                  <div className="sm:hidden">
                    <Button variant="ghost" size="icon" onClick={() => navigate(`/webhooks/${id}`)}>
                      <AlignLeft className="h-[1.2rem] w-[1.2rem]" />
                    </Button>
                  </div>
                  <TabsList className="grid grid-cols-3 bg-transparent">
                    <TabsTrigger
                      value="request"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Request
                    </TabsTrigger>
                    <TabsTrigger
                      value="response"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Response
                    </TabsTrigger>
                    <TabsTrigger
                      value="help"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Help
                    </TabsTrigger>
                  </TabsList>
                  <p className="hidden sm:block text-xs">{format(new Date(selected.date), "PPpp")}</p>
                </div>
                <TabsContent value="request">
                  <Separator />
                  <RequestDisplay id={id} requestId={selected.id} />
                </TabsContent>
                <TabsContent value="response">
                  <Separator />
                  <ResponseDisplay id={id} requestId={selected.id} />
                </TabsContent>
                <TabsContent value="help">
                  <Separator />
                  <div className="flex p-4 sm:p-10 justify-center">
                    <div className="flex flex-col gap-8 w-full max-w-7xl">
                      <RequestHelp
                        id={id}
                        endpoint={endpoint}
                        resourceId={selected.id}
                        resourceJwt={resourceJwt}
                        showRequestsHelp={true}
                        endpointStatus={endpointStatus}
                      />
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            </>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

interface SidebarProps {
  id: string;
  accessType: AccessType;
  endpoint: string;
  received: WebhookReceivedRequests;
  response: WebhookResponse;
  endpointStatus: WebhookStatus;
  selected: WebhookDetails | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({
  id,
  accessType,
  endpoint,
  received,
  response,
  endpointStatus,
  selected,
}) => {
  const [selectedGroupOption, setSelectedGroupOption] = React.useState<GroupByOption>("method");
  const [selectedSortOption, setSelectedSortOption] = React.useState<SortOption>("date");
  const [ascending, setAscending] = React.useState<boolean>(false);

  const group = React.useMemo(() => {
    return WebhookService.sortAndGroup(received, selectedSortOption, ascending, selectedGroupOption);
  }, [received, selectedSortOption, selectedGroupOption, ascending]);

  return (
    <>
      <div className="space-y-4 pt-4">
        <div className="flex flex-col gap-4 py-2 px-4">
          <div className="flex gap-2 items-start justify-between">
            <div className="flex items-center">
              <h5 className="font-semibold tracking-tight">HTTP Endpoint</h5>
              <EditResponsePopover id={id} response={response} />
            </div>
            <div className="flex gap-1">
              {accessType === "public" ? (
                <TooltipBadge
                  title="public"
                  message="Anyone can access requests sent to this endpoint."
                  icon={<Unlock className="w-3 h-3" />}
                />
              ) : (
                <TooltipBadge
                  title="private"
                  message="Only you can access requests sent to this endpoint."
                  icon={<Lock className="w-3 h-3" />}
                />
              )}
              <TooltipBadge
                title={`Limit: ${endpointStatus.maxItems}`}
                message="The maximum number of requests that can be sent to this endpoint."
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <small className="text-sm">Send your requests here:</small>
              <CopyableInput value={endpoint} />
            </div>
          </div>
        </div>
        <Separator />
      </div>
      <ScrollArea>
        <div className="py-4">
          <div className="flex items-center mb-2 px-4">
            <h5 className="font-semibold tracking-tight">Requests</h5>
            <OptionsPopover
              selectedGroupOption={selectedGroupOption}
              setSelectedGroupOption={setSelectedGroupOption}
              selectedSortOption={selectedSortOption}
              setSelectedSortOption={setSelectedSortOption}
              ascending={ascending}
              setAscending={setAscending}
            />
          </div>
          <div className="flex flex-col gap-4 px-4">
            {received.length === 0 && (
              <div className="mt-4 m-auto">
                <small>Received requests will appear here.</small>
              </div>
            )}
            {received.length > 0 && <RecentRequests id={id} group={group} selectedRequest={selected} />}
            {(endpointStatus.isMaxItemsReached || !endpointStatus.isEndpointEnabled) && (
              <WebhookStatusCard
                message={endpointStatus.isMaxItemsReached ? `Max items reached.` : "Webhook is disabled."}
              />
            )}
          </div>
        </div>
      </ScrollArea>
    </>
  );
};
