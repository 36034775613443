import { ApiMutation } from "@/api";
import { VerificationType } from "@/api/types/request";
import { toast } from "@/components/ui/use-toast";
import React from "react";

interface ResendVerificationCodeProps {
  email: string;
  type: VerificationType.VerifyUser | VerificationType.ResetPassword;
}

export const ResendVerificationCode: React.FC<ResendVerificationCodeProps> = ({ email, type }) => {
  const triggerVerification = ApiMutation.useTriggerVerification({
    onSuccess: () => {
      toast({
        title: "Verification code sent!",
        description: "Please check your email for the verification code.",
      });
    },
    onError: () => {
      toast({
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request. Please try again.",
      });
    },
  });

  const handleResendCode = () => {
    triggerVerification.mutate({ email, type });
  };

  return (
    <>
      {triggerVerification.isPending ? (
        <small>Sending...</small>
      ) : (
        <small className="underline hover:cursor-pointer" onClick={handleResendCode}>
          Resend Code
        </small>
      )}
    </>
  );
};
