import React from "react";
import { useAccount } from "@/api/queries";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns/format";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { LoadingSpinner } from "@/components/loading-spinner";

interface Entitlement {
  CustomerIdentifier: string;
  Dimension: string;
  ExpirationDate: string;
  ProductCode: string;
  Value: Record<string, string>;
}

enum Dimension {
  webhookRequests = "webhook_requests",
  emailsReceived = "emails_received",
}

export const AccountDetails: React.FC = () => {
  const { isPending, isError, data } = useAccount();

  const [entitlements, setEntitlements] = React.useState<Entitlement[]>([]);

  React.useEffect(() => {
    if (data) {
      setEntitlements(JSON.parse(data.entitlement).Entitlements);
    }
  }, [data]);

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center">
          <LoadingSpinner message="Loading..." />
        </div>
      )}
      {isError && <div>error</div>}
      {!isPending && !isError && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-between">
          <div className="flex flex-col gap-4">
            <div>
              <h6 className="font-semibold tracking-tight">Account</h6>
              <small>Account information and contact person.</small>
            </div>
            <div className="neu p-6 rounded-lg">
              <div className="flex justify-between">
                <div className="flex items-end gap-4">
                  <div>
                    <h5 className="font-bold tracking-tight">{data.companyName}</h5>
                    <small>{data.customerIdentifier}</small>
                  </div>
                </div>
                <div className="flex items-center">
                  <Badge variant="secondary">active</Badge>
                </div>
              </div>
              <div className="grid gap-2 py-4">
                <div className="flex flex-col gap-2">
                  <div>
                    <Label htmlFor="contact_person">Contact Person</Label>
                    <Input
                      variant="neu-flat"
                      id="contact_person"
                      className="col-span-2"
                      value={data.contactPerson}
                      readOnly
                    />
                  </div>
                  <div>
                    <Label htmlFor="contact_email">Contact Email</Label>
                    <Input
                      variant="neu-flat"
                      id="contact_email"
                      className="col-span-2"
                      value={data.contactEmail}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div>
              <h6 className="font-semibold tracking-tight">Contract</h6>
              <small>Current contract and entitlements.</small>
            </div>
            <div className="neu p-6 rounded-lg">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[100px]">Dimension</TableHead>
                    <TableHead>Value</TableHead>
                    <TableHead className="text-right">Expires</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {entitlements.map((entitlement, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">
                        <Badge variant="secondary">{entitlement.Dimension}</Badge>
                      </TableCell>
                      <TableCell className="text-nowrap">
                        {entitlement.Dimension === Dimension.webhookRequests && (
                          <>
                            {parseInt(entitlement.Value.IntegerValue)} <span>&#10005;</span> 10k
                          </>
                        )}
                        {entitlement.Dimension === Dimension.emailsReceived && (
                          <>
                            {parseInt(entitlement.Value.IntegerValue)} <span>&#10005;</span> 1000
                          </>
                        )}
                      </TableCell>
                      <TableCell className="text-right text-nowrap">
                        {format(new Date(entitlement.ExpirationDate), "PP")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
