import { Label } from "@/components/ui/label";
import { cn } from "@/utils/ui.util";

type LabelProps = {
  label: string;
  className?: string;
  htmlFor?: string;
};

export const TransformationOptionsLabel: React.FC<LabelProps> = ({ label, className, htmlFor }) => {
  return (
    <Label className={cn("flex items-center min-h-11", className)} htmlFor={htmlFor}>
      {label}
    </Label>
  );
};
