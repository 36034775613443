import { Case } from "change-case-all";
import validator from "validator";

const stringUtils: Record<string, (input: string) => string> = {
  camelCase: Case.camel,
  capitalCase: Case.capital,
  constantCase: Case.constant,
  dotCase: Case.dot,
  noCase: Case.no,
  pascalCase: Case.pascal,
  pathCase: Case.path,
  sentenceCase: Case.sentence,
  snakeCase: Case.snake,
  trainCase: Case.train,
  kebapCase: Case.kebab,
  swapCase: Case.swap,
  titleCase: Case.title,
  upperCase: Case.upper,
  lowerCase: Case.lower,
  lowerCaseFirst: Case.lowerFirst,
  UpperCaseFirst: Case.upperFirst,
  trim: (s: string) => s.trim(),
  normalizeWhitespace: (s: string) => s.replace(/\s+/g, " "),
  normalizeWhitespaceWithTrim: (s: string) => s.trim().replace(/\s+/g, " "),
  quote: (s: string) => `"${s}"`,
  unquote: (s: string) => {
    return s.trim().startsWith(`"`) && s.trim().endsWith(`"`) ? s.trim().slice(1, -1) : s;
  },
  stringify: (s: string) => JSON.stringify(s),
  length: (s: string) => s.length.toString(),
  identity: (s: string) => s,
  toLower: (s: string) => s.toLowerCase(),
  toUpper: (s: string) => s.toUpperCase(),
  base64Encode: (s: string) => btoa(s),
  base64Decode: (s: string) => atob(s),
  base64UrlEncode: (s: string) => btoa(s).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ""),
  base64UrlDecode: (s: string) => atob(s.replace(/-/g, "+").replace(/_/g, "/")),
  urlEncode: (s: string) => encodeURIComponent(s),
  urlDecode: (s: string) => decodeURIComponent(s),
  appendSpaceFront: (s: string) => " " + s,
  appendSpaceBack: (s: string) => s + " ",
} as const;

export const analyzeString = (input: string) => {
  let isAscii = true;
  let hasWhitespace = false;

  let lines = 1;
  const countParentheses = {
    "(": 0,
    ")": 0,
    "[": 0,
    "]": 0,
    "{": 0,
    "}": 0,
    ":": 0,
    ";": 0,
  };

  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    const charCode = input.charCodeAt(i);

    if (charCode > 0x7f) {
      isAscii = false;
    }

    if (
      char == "(" ||
      char == ")" ||
      char == "[" ||
      char == "]" ||
      char == "{" ||
      char == "}" ||
      char == ":" ||
      char == ";"
    ) {
      countParentheses[char]++;
    }

    if (!hasWhitespace && (charCode === 32 || charCode === 9 || charCode === 10 || charCode === 13)) {
      hasWhitespace = true;
    }

    if (charCode === 10) {
      lines++;
    }
  }

  const isNumeric = validator.isNumeric(input);
  const multiByte = validator.isMultibyte(input);
  const isAlpha = validator.isAlpha(input);
  const isAlphanumeric = validator.isAlphanumeric(input);

  return {
    length: input.length,
    lines,
    isAscii: isAscii && input.length > 0,
    hasWhitespace,
    isNumeric,
    isAlpha,
    isAlphanumeric,
    countParentheses,
    multiByte,
  };
};

export default stringUtils;
