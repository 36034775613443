import { EyeOff } from "lucide-react";
import React from "react";
import { State } from "./state";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

type DisplayMode = "string" | "json" | "hidden" | "context" | string;

type DisplayModeOption = {
  label: React.ReactNode;
  value: DisplayMode;
};
export function useDisplayMode(state: State) {
  const [displayMode, setDisplayMode] = React.useState<DisplayMode>(state.config().defaultDisplayMode ?? "hidden");

  const [safeDisplayMode, displayModeOptions] = React.useMemo(() => {
    const customDisplay = state.config().customDisplay;
    const hasString = state.config().stringValue;
    const hasJson = state.config().jsonValue;
    const hasCustom = !!customDisplay;
    const hasContext = state?.stateContext && Object.keys(state.stateContext).length > 0;

    function getValidDisplayMode() {
      if (hasCustom) {
        if (!customDisplay?.includes(state.config().defaultDisplayMode))
          throw new Error(
            `Invalid default display mode  ${state.config().defaultDisplayMode} for customDisplay ${customDisplay}`
          );
        return state.config().defaultDisplayMode || "hidden";
      }
      if (hasJson) return "json";
      if (hasString) return "string";
      return "hidden";
    }
    let safeDisplayMode = displayMode;

    if (displayMode === "json" && !hasJson) safeDisplayMode = getValidDisplayMode();
    else if (displayMode === "string" && !hasString) safeDisplayMode = getValidDisplayMode();
    else {
      // custom mode
      if (!["json", "string", "hidden", "context"].includes(displayMode)) {
        if (!customDisplay?.includes(displayMode)) safeDisplayMode = getValidDisplayMode();
      }
    }

    const displayModeOptions: Array<DisplayModeOption | false | undefined> = [
      hasJson && { label: "JSON", value: "json" },
      hasString && { label: "String", value: "string" },
      hasContext && { label: "Context", value: "context" },
    ];

    if (hasCustom) {
      customDisplay?.reverse().map((display) => {
        if (["json", "string", "hidden", "context"].includes(display)) return;
        displayModeOptions.unshift({ label: display, value: display });
      });
    }

    return [safeDisplayMode, displayModeOptions.filter(Boolean) as DisplayModeOption[]];
  }, [displayMode, state]);

  const stateRender = React.useMemo(() => {
    switch (safeDisplayMode) {
      case "string":
        if (!state.config().customDisplay?.includes("string"))
          return <pre className="max-h-[75vh] overflow-y-auto select-all">{state.stringValue()}</pre>;
        break;
      case "json":
        if (!state.config().customDisplay?.includes("json"))
          return <JsonView displaySize={true} src={state.jsonValue()} enableClipboard />;
        break;
      case "hidden":
        return <EyeOff />;
      case "context":
        return <JsonView src={state?.stateContext} enableClipboard />;
    }
    return <div className="max-h-[75vh] overflow-y-auto" children={state.toReactNode(safeDisplayMode)}></div>;
  }, [safeDisplayMode, state]);

  return {
    displayMode: safeDisplayMode,
    setDisplayMode,
    stateRender,
    displayModeOptions,
  };
}
