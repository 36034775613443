import { Button } from "@/components/ui/button";
import { Menubar } from "@/components/ui/menubar";
import { Separator } from "@/components/ui/separator";
import { useLocation, useNavigate } from "react-router-dom";
import { ToggleMore } from "@/layouts/elements/toggle-more";
import React from "react";
import { useUser } from "@/api/queries";
import { ArrowUpRight, ChevronLeft } from "lucide-react";
import { useAuth } from "@/auth/useAuth";
import { ToggleMoreMobile } from "@/layouts/elements/toggle-more-mobile";
import { cn } from "@/utils/ui.util";
import { UpgradePlan } from "@/layouts/elements/upgrade-plan";
import { Link } from "react-router-dom";

export const Menu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { status } = useAuth();

  const pathname = React.useMemo(() => {
    if (location.pathname.endsWith("/")) {
      return location.pathname.slice(0, -1);
    }

    if (location.pathname.indexOf("/") !== -1) {
      let pathname = location.pathname;

      const pathnames = pathname.split("/");
      for (const p of pathnames) {
        if (p) {
          pathname = p;
          break;
        }
      }

      return pathname;
    }

    return location.pathname;
  }, [location.pathname]);

  const navigablePaths = ["pipelines", "tools", "webhooks", "emails"];
  const isSubPathNavigable = navigablePaths.includes(pathname);

  return (
    <div className="w-full">
      <Menubar className="flex justify-between border-none rounded-none shadow-none py-6 px-4 bg-primary-300/90 h-[52px]">
        <div className="flex items-center gap-4 sm:gap-6">
          <div className="flex items-center gap-3">
            <Link to="/">
              <img src={"/logo-circle-hollow-dark.svg"} alt={"wt.dev"} className="w-8 hover:cursor-pointer" />
            </Link>
            <div className="flex gap-1 items-center">
              <Link to="/" className="no-underline text-secondary-950">
                <h6 className="font-bold font-mono hover:underline hover:cursor-pointer">wt.dev</h6>
              </Link>
              {pathname !== "dashboard" && (
                <>
                  <h6 className="font-semibold font-mono">/</h6>
                  <h6
                    onClick={() => {
                      if (isSubPathNavigable) {
                        navigate(pathname);
                      }
                    }}
                    className={cn(
                      "font-semibold font-mono",
                      isSubPathNavigable && "bg-secondary hover:underline hover:cursor-pointer"
                    )}
                  >
                    {pathname}
                  </h6>
                </>
              )}
            </div>
          </div>
          <div className="hidden sm:flex gap-4 items-center">
            <Button
              variant="ghost"
              size="sm"
              className="text-sm font-medium bg-transparent hover:underline p-0 items-center"
              onClick={() => navigate(-1)}
            >
              <ChevronLeft size={18} />
              Back
            </Button>
          </div>
        </div>
        <div className="hidden sm:flex gap-2 items-center">
          {status === "authenticated" && <UpgradePlan />}
          {status === "authenticated" && <AdminPanelButton />}
          <ToggleMore />
        </div>

        <div className="flex sm:hidden gap-2 items-center">
          {status === "authenticated" && <UpgradePlan />}
          {status === "authenticated" && <AdminPanelButton />}
          <ToggleMoreMobile />
        </div>
      </Menubar>
      <Separator />
    </div>
  );
};

const AdminPanelButton: React.FC = () => {
  const navigate = useNavigate();
  const { isPending, isError, data } = useUser();

  if (isPending || isError || !data) {
    return null;
  }

  if (data.role === "admin") {
    return (
      <Button
        variant="neu-flat"
        size="sm"
        className="bg-transparent gap-2"
        onClick={() => {
          navigate("/admin");
        }}
      >
        Admin Panel
        <ArrowUpRight size={18} />
      </Button>
    );
  }

  return null;
};
