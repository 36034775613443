import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, KeyValueState, StringListState, StringState } from "../states";
import stringUtils from "../utils/string-utils";
import { TransformationOptionsCheckbox } from "./common-options/options.checkbox.transformation";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

const utilsMap: Record<string, (input: string) => string> = {
  camelCase: stringUtils.camelCase,
  CapitalCase: stringUtils.capitalCase,
  CONSTANT_CASE: stringUtils.constantCase,
  "dot.case": stringUtils.dotCase,
  noCase: stringUtils.noCase,
  PascalCase: stringUtils.pascalCase,
  "path/case": stringUtils.pathCase,
  sentenceCase: stringUtils.sentenceCase,
  snake_case: stringUtils.snakeCase,
  "Train-Case": stringUtils.trainCase,
  "kebap-case": stringUtils.kebapCase,
  swapCase: stringUtils.swapCase,
  TitleCase: stringUtils.titleCase,
  "UPPER CASE": stringUtils.upperCase,
  "lower case": stringUtils.lowerCase,
  lowerCaseFirst: stringUtils.lowerCaseFirst,
  UpperCaseFirst: stringUtils.UpperCaseFirst,
  trim: stringUtils.trim,
  normalizeWhitespace: stringUtils.normalizeWhitespace,
  normalizeWhitespaceWithTrim: stringUtils.normalizeWhitespaceWithTrim,
  quote: stringUtils.quote,
  unquote: stringUtils.unquote,
  stringify: stringUtils.stringify,
  length: stringUtils.length,
} as const;

type StringCaseAlgorithms = keyof typeof utilsMap;

type StringCaseMode = {
  selected: StringCaseAlgorithms;
  values: Record<StringCaseAlgorithms, StringCaseAlgorithms>;
  label: string;
  icons?: Record<string, ToolsIcons>;
  listMode: boolean;
  listModePossible: boolean;
};

export class StringUtilsTransformation extends StateTransformation<State, StringCaseMode> {
  constructor() {
    super("string-utils", "String-utils", ["string"]);
  }

  icon(): ToolsIcons {
    return "stringCase";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      const input = state.stringValue();
      if (input.length < 512) return CanTransformResult.Yes;
      else return CanTransformResult.Maybe;
    }

    return CanTransformResult.No;
  }

  async transform(state: State, options: StringCaseMode): Promise<State> {
    const input = state.stringValue();
    const c = options.selected;

    if (c === "All") {
      const result = Object.fromEntries(
        Object.entries(utilsMap).map(([key, func]) => {
          return [key, func(input)];
        })
      );

      return new KeyValueState(result, "String Cases");
    }

    if (options.listMode) {
      const list = state.asList();
      if (!list || list.type !== "string[]") return new InvalidState(`List mode is not possible ${list?.type}`);
      const newValues = list.value.map((x) => utilsMap[c](x));
      return new StringListState(newValues, c);
    }

    const transformed = utilsMap[c](input);
    return new StringState(transformed);
  }

  async getOptions(state: State, options?: StringCaseMode): Promise<StringCaseMode | undefined> {
    const list = state.asList();
    const listModePossible = list && list.type === "string[]" ? true : false;

    return {
      selected: options?.selected ?? "All",
      values: Object.fromEntries(
        Object.entries(utilsMap)
          .map(([key, _func]) => [key, key])
          .concat([["All", "All"]])
      ),
      label: "Utils",
      listModePossible: listModePossible,
      listMode: options?.listMode ?? listModePossible,
    };
  }

  getOptionsReactNode(options: StringCaseMode, onChange: (options: StringCaseMode) => void): React.ReactNode {
    return (
      <div className="flex gap-1">
        <TransformationOptionsSelect onChange={onChange as any} options={options} />
        {options.listModePossible && (
          <TransformationOptionsCheckbox
            options={options}
            selectedKey="listMode"
            onChange={onChange as any}
            defaultValue={options.listModePossible}
            label="List mode"
          />
        )}
      </div>
    );
  }
}

export default new StringUtilsTransformation();
