import { HoverInfo } from "@/components/hover-info";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Settings } from "lucide-react";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import { manageInstallationUrl } from "@/routes/pipelines/user-repos-dashboard";
import { useConnectGithub } from "@/api/queries";

export const PipelinesAuthDashboard: React.FC = () => {
  const { isPending, isError, data: response } = useConnectGithub();

  return (
    <div className="flex flex-col gap-6 2xl:gap-12 w-full max-w-[1920px] m-auto 2xl:px-4 2xl:py-6">
      <div className="flex flex-col space-y-2">
        <h4 className="font-bold tracking-tight">Pipelines</h4>
        <small>
          Display and monitor your deployment workflows in real-time. Stay informed and ensure smooth and efficient
          continuous integration and deployment with up-to-the-minute updates.
        </small>
      </div>
      <div className="space-y-4">
        <Card>
          <CardHeader>
            <CardTitle className="font-semibold text-base">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  Connect Github Account
                  <Button asChild variant="ghost" size="sm" className="p-1">
                    <Link to={manageInstallationUrl} target="_blank" className="no-underline">
                      <Settings size={16} />
                    </Link>
                  </Button>
                </div>
                <HoverInfo message="To access your repositories, you need to connect your GitHub account." />
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isPending && (
              <div className="flex items-center justify-center">
                <LoadingSpinner message={"Loading..."} />
              </div>
            )}
            {isError && <Navigate to="/404" replace={true} />}
            {!isPending && !isError && (
              <Button asChild variant="neu" size="sm" className="bg-primary-300 gap-2 items-center px-10">
                <Link to={response.githubOauthUrl} className="no-underline">
                  <img src={"/github-svgrepo-com.svg"} alt={"GitHub"} className="w-6" />
                  Sign in with Github
                </Link>
              </Button>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
