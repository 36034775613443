import { Button } from "@/components/ui/button";
import React from "react";
import { useUser } from "@/api/queries";
import { Sparkles } from "lucide-react";
import { StripeDialog } from "@/lib/stripe/stripe-dialog";

export const UpgradePlan: React.FC = () => {
  const { isPending, isError, data } = useUser();

  if (isPending || isError || !data) {
    return null;
  }

  if (data.role === "user" && data.customerIdentifier === "user" && data.tier === "free") {
    return (
      <>
        <StripeDialog
          dialogTrigger={
            <Button
              size="sm"
              variant="neu-flat"
              className="bg-secondary-950 text-white gap-1 text-xs font-semibold h-7 rounded-md"
            >
              Upgrade
              <Sparkles size={18} />
            </Button>
          }
          email={data.email}
        />
      </>
    );
  }

  return null;
};
