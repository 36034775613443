import { State } from "../state";
import { CanTransformResult, StateTransformation, TransformationId } from "../state-transformation";
import {
  ToBasicAuthTransformation,
  ToColor,
  ToDate,
  ToIpv4,
  ToJson,
  ToJwt,
  ToUrl,
  ToUuid,
  ToString,
  ToToml,
  ToYaml,
  ToNumber,
  ToEmailMimeTransformation,
} from "../transformations";
import toCertificateTransformation from "../transformations/to-certificate.transformation";

export type AutodetectResult = {
  transformId: string | null;
  result: CanTransformResult;
  transformation: StateTransformation;
};

export async function autodetect(
  state: State,
  enabledTransformations?: TransformationId[]
): Promise<AutodetectResult[]> {
  const results: AutodetectResult[] = [];

  async function testTransformation(transformation: StateTransformation) {
    if (!enabledTransformations || enabledTransformations.includes(transformation.id)) {
      const canTransformResult = await transformation.canTransform(state);

      if (canTransformResult >= CanTransformResult.Likely) {
        results.push({ transformId: transformation.id, result: canTransformResult, transformation });
      }
    }
  }

  if (state.config().stringValue) {
    if (state.stringValue().length === 1) {
      if (!enabledTransformations || enabledTransformations.includes(ToString.id)) {
        results.push({ transformId: ToString.id, result: CanTransformResult.Recommended, transformation: ToString });
      }
    }
    await testTransformation(ToBasicAuthTransformation);
    await testTransformation(ToUrl);
    await testTransformation(ToDate);
    await testTransformation(ToNumber);
    await testTransformation(ToColor);
    await testTransformation(ToIpv4);

    await testTransformation(ToJwt);
    await testTransformation(ToUuid);
    await testTransformation(toCertificateTransformation);
    await testTransformation(ToToml);
    await testTransformation(ToYaml);
    await testTransformation(ToEmailMimeTransformation);
  }

  if (state.config().jsonValue) {
    await testTransformation(ToJson);
  } else if (state.config().stringValue) {
    await testTransformation(ToJson);
  }

  if (state.config().stringValue) {
    if (results.length === 0) {
      if (!enabledTransformations || enabledTransformations.includes(ToString.id)) {
        results.push({ transformId: ToString.id, result: CanTransformResult.Yes, transformation: ToString });
      }
    } else if (results.length === 1) {
      if (results[0].transformId === ToJson.id && results[0].result < CanTransformResult.Yes) {
        if (!enabledTransformations || enabledTransformations.includes(ToString.id)) {
          results.push({ transformId: ToString.id, result: CanTransformResult.Yes, transformation: ToString });
        }
      }
    }
  }
  results.sort((a, b) => b.result - a.result);
  return results;
}
