import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import "@xyflow/react/dist/style.css";
import { Outlet } from "react-router-dom";

export const Pipelines: React.FC = () => {
  return (
    <div className="flex w-full bg-secondary-200">
      <ScrollArea className="w-full">
        <div className="flex justify-center py-4">
          <div className="w-full px-4">
            <Outlet />
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
