import { timingSafeEqual } from "./timing_safe_equal";
import HmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

function base64ToUInt8Array(base64: string) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

const WEBHOOK_TOLERANCE_IN_SECONDS = 5 * 60; // 5 minutes

type VerifyTimestampResult =
  | { date: undefined; error: "invalid_timestamp_header" }
  | { date: Date; error: "timestamp_too_old" | "timestamp_too_new" | null };

export type StdWebhookVerifyResponse = {
  structure: boolean | null;
  timestamp: null | "invalid_timestamp_header" | "timestamp_too_old" | "timestamp_too_new";
  signature: boolean | null;
};

class ExtendableError extends Error {
  constructor(message: any) {
    super(message);
    Object.setPrototypeOf(this, ExtendableError.prototype);
    this.name = "ExtendableError";
    this.stack = new Error(message).stack;
  }
}

export class WebhookVerificationError extends ExtendableError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, WebhookVerificationError.prototype);
    this.name = "WebhookVerificationError";
  }
}

export interface WebhookUnbrandedRequiredHeaders {
  "webhook-id": string;
  "webhook-timestamp": string;
  "webhook-signature": string;
}

export interface WebhookOptions {
  format?: "raw";
}

export class Webhook {
  private static prefix = "whsec_";
  private readonly key: Uint8Array | undefined;

  constructor(secret: string | Uint8Array | undefined, options?: WebhookOptions) {
    if (secret === undefined) {
      this.key = undefined;
      return;
    }
    if (options?.format === "raw") {
      if (secret instanceof Uint8Array) {
        this.key = secret;
      } else {
        this.key = Uint8Array.from(secret, (c) => c.charCodeAt(0));
      }
    } else {
      if (typeof secret !== "string") {
        throw new Error("Expected secret to be of type string");
      }
      if (secret.startsWith(Webhook.prefix)) {
        secret = secret.substring(Webhook.prefix.length);
      }
      this.key = base64ToUInt8Array(secret);
    }
  }

  public verify(
    payload: string | Buffer,
    headers_: WebhookUnbrandedRequiredHeaders | Record<string, string>
  ): StdWebhookVerifyResponse {
    const result: StdWebhookVerifyResponse = {
      structure: null,
      timestamp: null,
      signature: null,
    };

    const headers: Record<string, string> = {};
    for (const key of Object.keys(headers_)) {
      headers[key.toLowerCase()] = (headers_ as Record<string, string>)[key];
    }

    const msgId = headers["webhook-id"];
    const msgSignature = headers["webhook-signature"];
    const msgTimestamp = headers["webhook-timestamp"];

    if (!msgSignature || !msgId || !msgTimestamp) {
      result.structure = false;
      return result;
    }
    result.structure = true;

    const timestampResult = this.verifyTimestamp(msgTimestamp);
    if (timestampResult.error !== null) {
      result.timestamp = timestampResult.error;
      if (timestampResult.error === "invalid_timestamp_header") return result;
    }

    const computedSignature = this.sign(msgId, timestampResult.date, payload);
    if (computedSignature === undefined) {
      return result;
    }

    const expectedSignature = computedSignature.split(",")[1];

    const passedSignatures = msgSignature.split(" ");

    const encoder = new globalThis.TextEncoder();
    for (const versionedSignature of passedSignatures) {
      const [version, signature] = versionedSignature.split(",");
      if (version !== "v1") {
        continue;
      }

      if (timingSafeEqual(encoder.encode(signature), encoder.encode(expectedSignature))) {
        result.signature = true;
        return result;
      }
    }
    result.signature = false;
    return result;
  }

  public sign(msgId: string, timestamp: Date, payload: string | Buffer): string | undefined {
    if (this.key === undefined) return undefined;
    if (typeof payload === "string") {
      // Do nothing, already a string
    } else if (payload.constructor.name === "Buffer") {
      payload = payload.toString();
    } else {
      throw new Error("Expected payload to be of type string or Buffer.");
    }

    const timestampNumber = Math.floor(timestamp.getTime() / 1000);
    const toSign = `${msgId}.${timestampNumber}.${payload}`;
    const expectedSignature = Base64.stringify(HmacSHA256(toSign, this.key?.toString())); // TODO: Check if this is the correct
    return `v1,${expectedSignature}`;
  }

  private verifyTimestamp(timestampHeader: string): VerifyTimestampResult {
    const now = Math.floor(Date.now() / 1000);
    const timestamp = parseInt(timestampHeader, 10);
    if (isNaN(timestamp)) {
      return { date: undefined, error: "invalid_timestamp_header" };
    }

    const date = new Date(timestamp * 1000);
    if (now - timestamp > WEBHOOK_TOLERANCE_IN_SECONDS) {
      return { date, error: "timestamp_too_old" };
    }
    if (timestamp > now + WEBHOOK_TOLERANCE_IN_SECONDS) {
      return { date, error: "timestamp_too_new" };
    }
    return { date, error: null };
  }
}
