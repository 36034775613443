const base64UrlDecode = (input: string): string => {
  return decodeURIComponent(escape(atob(input)));
};

export interface JwtHeader {
  alg: string; // Algorithm
  typ: string; // Type
}

export interface JwtPayload {
  // Registered claims
  iss?: string; // Issuer
  sub?: string; // Subject
  aud?: string; // Audience
  exp?: number; // Expiration Time
  nbf?: number; // Not Before
  iat?: number; // Issued At
  jti?: string; // JWT ID

  [key: string]: unknown;
}

export interface JwtValue {
  header: JwtHeader;
  payload: JwtPayload;
  signature: string;
}

export const parseJwt = (token: string): JwtValue => {
  const parts = token.replace(/^Bearer\s+/, "").split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT: The token must consist of three parts.");
  }

  const [headerEncoded, payloadEncoded, signature] = parts;
  try {
    const header: JwtHeader = JSON.parse(base64UrlDecode(headerEncoded));
    const payload: JwtPayload = JSON.parse(base64UrlDecode(payloadEncoded));
    return {
      header,
      payload,
      signature,
    };
  } catch (e) {
    throw new Error("Error parsing JWT:" + e);
  }
};
