import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/utils/ui.util";
import { useFormik } from "formik";
import * as Yup from "yup";

const UpdatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .min(6, "Must have at least 6 characters")
    .max(30, "Too long")
    .matches(/^(?=.*[A-Z])(?=.*\d)/, "Must contain at least one uppercase letter and one number")
    .required("Required"),
  repeatPassword: Yup.string().oneOf([Yup.ref("newPassword"), undefined], "Passwords must match"),
});

export const PasswordAndCredentials: React.FC = () => {
  const updateUserCredentials = ApiMutation.useUpdateUserCredentials({
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Your password has been updated.",
      });
    },
    onError: () => {
      toast({
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request. Please try again.",
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
    validationSchema: UpdatePasswordSchema,
    onSubmit: (values) => {
      updateUserCredentials.mutate({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
    },
  });

  return (
    <div className="space-y-6 px-4">
      <div>
        <h5 className="font-semibold tracking-tight">Password & Credentials</h5>
        <small>Update your credentials.</small>
      </div>
      <div className="max-w-xl">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div>
                <Label htmlFor="oldPassword">Old password</Label>
                <Input
                  id="oldPassword"
                  type="password"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  required
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  className={cn(formik.touched.oldPassword && formik.errors.oldPassword && "bg-red-200")}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <p className="text-xs">{formik.errors.oldPassword}</p>
                )}
              </div>

              <div>
                <Label htmlFor="newPassword">New password</Label>
                <Input
                  id="newPassword"
                  type="password"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  required
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  className={cn(formik.touched.newPassword && formik.errors.newPassword && "bg-red-200")}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <p className="text-xs">{formik.errors.newPassword}</p>
                )}
              </div>

              <div>
                <Label htmlFor="repeatPassword">Confirm new password</Label>
                <Input
                  id="repeatPassword"
                  type="password"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  required
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  className={cn(formik.touched.repeatPassword && formik.errors.repeatPassword && "bg-red-200")}
                />
                {formik.touched.repeatPassword && formik.errors.repeatPassword && (
                  <p className="text-xs">{formik.errors.repeatPassword}</p>
                )}
              </div>
            </div>
            <div>
              <Button
                type="submit"
                scheme="black"
                size="sm"
                className="bg-secondary-950"
                disabled={updateUserCredentials.isPending}
              >
                {updateUserCredentials.isPending ? <LoadingSpinner message={"Updating"} /> : "Update"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
