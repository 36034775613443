import React from "react";

function filesFromList(list?: null | FileList | DataTransferItemList) {
  if (!list) return [];

  const files: File[] = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (!item) continue;

    if (item instanceof File) {
      files.push(item);
    } else if (item.kind === "file") {
      const file = item.getAsFile();
      if (file) {
        files.push(file);
      }
    }
  }

  return files;
}

export function useFileClipboard(callback: (files: File[]) => void) {
  React.useEffect(() => {
    let pasteEnabled = true;

    const handlePaste = (event: ClipboardEvent) => {
      if (!pasteEnabled) return;
      pasteEnabled = false;

      const files = filesFromList(event.clipboardData?.items);

      if (files.length) {
        callback(files);
      }

      pasteEnabled = true;
    };

    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [callback]);
}

export function useFileDrop(ref: React.RefObject<HTMLElement>, callback: (files: File[]) => void) {
  const [activeDrag, setActiveDrag] = React.useState(false);

  const handleDrag = React.useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === "dragenter" || event.type === "dragover") {
      setActiveDrag(true);
    } else if (event.type === "dragleave") {
      setActiveDrag(false);
    }
  }, []);

  const handleDrop = React.useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setActiveDrag(false);

      if (event?.dataTransfer?.files) {
        const files = filesFromList(event.dataTransfer.files);

        if (files.length) {
          callback(files);
        }
      }
    },
    [callback]
  );

  React.useEffect(() => {
    const element = ref.current;
    if (!element) return console.error("Missing ref.");

    element.addEventListener("dragenter", handleDrag);
    element.addEventListener("dragleave", handleDrag);
    element.addEventListener("dragover", handleDrag);
    element.addEventListener("drop", handleDrop);

    return () => {
      element.removeEventListener("dragenter", handleDrag);
      element.removeEventListener("dragleave", handleDrag);
      element.removeEventListener("dragover", handleDrag);
      element.removeEventListener("drop", handleDrop);
    };
  }, [ref, handleDrag, handleDrop]);

  React.useEffect(() => {
    return () => {
      setActiveDrag(false);
    };
  }, []);

  return {
    activeDrag,
  };
}
