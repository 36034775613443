import React from "react";
import { ApiMutation } from "@/api";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { cn } from "@/utils/ui.util";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, "Must have at least 2 characters").max(20, "Too long").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "Must have at least 6 characters")
    .max(30, "Too long")
    .matches(/^(?=.*[A-Z])(?=.*\d)/, "Must contain at least one uppercase letter and one number")
    .required("Required"),
  repeatPassword: Yup.string().oneOf([Yup.ref("password"), undefined], "Passwords must match"),
});

interface CreateUserDetails {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

export const CreateAccountUser: React.FC = () => {
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const createAccountUserMutation = ApiMutation.useCreateAccountUser({
    onSuccess: () => {
      setDialogOpen(false);
      queryClient.invalidateQueries({ queryKey: [["account", "users"]] });
    },
    onError: () => {
      toast({
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request. Please try again.",
      });
    },
  });

  const createUserDetails: CreateUserDetails = {
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
  };

  const formik = useFormik({
    initialValues: createUserDetails,
    validationSchema: validationSchema,
    onSubmit: (values: CreateUserDetails) => {
      createAccountUserMutation.mutate({
        name: values.name,
        email: values.email,
        password: values.password,
      });
    },
  });

  return (
    <>
      <Button variant="neu-flat" className="bg-primary-300" size="sm" onClick={() => setDialogOpen(true)}>
        Create User
      </Button>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent className="sm:max-w-[500px]">
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <DialogHeader>
                <DialogTitle>Create User</DialogTitle>
                <small>Add new account user here. Click create when you're done.</small>
              </DialogHeader>
              <div className="flex flex-col gap-3 py-4">
                <div className="flex flex-col gap-1">
                  <Label htmlFor="name">Name</Label>
                  <Input
                    variant="neu-flat"
                    id="name"
                    name="name"
                    autoCapitalize="none"
                    autoComplete="email"
                    autoCorrect="off"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.name && formik.errors.name && "bg-red-200")}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Label htmlFor="email_address">Email Address</Label>
                  <Input
                    variant="neu-flat"
                    id="email"
                    type="email"
                    autoCapitalize="none"
                    autoComplete="email"
                    autoCorrect="off"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.email && formik.errors.email && "bg-red-200")}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Label htmlFor="password">Password</Label>
                  <Input
                    variant="neu-flat"
                    id="password"
                    type="password"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    required
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.password && formik.errors.password && "bg-red-200")}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Label htmlFor="repeat_password">Repeat Password</Label>
                  <Input
                    variant="neu-flat"
                    id="repeatPassword"
                    type="password"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    required
                    value={formik.values.repeatPassword}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.repeatPassword && formik.errors.repeatPassword && "bg-red-200")}
                  />
                </div>
              </div>
              <DialogFooter>
                <Button
                  type="submit"
                  variant="neu-flat"
                  className="bg-secondary-200"
                  size="sm"
                  disabled={createAccountUserMutation.isPending}
                >
                  {createAccountUserMutation.isPending ? <LoadingSpinner message={"Creating"} /> : "Create"}
                </Button>
              </DialogFooter>
            </form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
