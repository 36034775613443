import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils/ui.util";

interface AdvancedHeadersTableProps {
  title: string;
  headers: {
    [key: string]: {
      details: React.ReactNode | undefined;
      value: string | string[] | undefined;
    };
  };
}

export const AdvancedHeadersTable: React.FC<AdvancedHeadersTableProps> = ({ title, headers }) => {
  const { toast, dismiss } = useToast();
  const [clickedCell, setClickedCell] = React.useState<string | null>(null);

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      const { id } = toast({
        description: "Copied to clipboard!",
      });

      setTimeout(() => {
        dismiss(id);
      }, 1500);
    });
  };

  const handleMouseDown = (key: string) => {
    setClickedCell(key);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setClickedCell(null);
    }, 50);
  };

  return (
    <ScrollArea className="h-full max-w-full">
      <div className="flex flex-col gap-4 h-full p-4">
        <p className="text-sm font-semibold">{title}</p>
        <Table>
          <TableBody>
            {Object.entries(headers ?? {}).map(([key, obj], index) => (
              <TableRow key={index}>
                <TableCell className="align-top">
                  <p className="text-sm font-medium">{key}</p>
                </TableCell>
                <TableCell className="align-top">
                  <div className="flex justify-end">{obj.details}</div>
                </TableCell>
                <TableCell
                  className={cn(
                    clickedCell === key && "text-secondary-700",
                    "font-mono max-w-[500px] break-all hover:cursor-pointer *:hover:line-clamp-none *:hover:font-semibold"
                  )}
                  onMouseDown={() => handleMouseDown(key)}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  onClick={() => obj.value && handleCopy(obj.value.toString())}
                >
                  <p className="text-sm line-clamp-1">{obj.value && obj.value}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </ScrollArea>
  );
};
