import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { StringState } from "../states";

export class FromBase64Transformation extends StateTransformation<StringState> {
  constructor() {
    super("from-base64", "From Base64", ["string", "number"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      // TODO: base64 vs base64url
      const value = state.stringValue();
      try {
        if (state.value === "") return CanTransformResult.No;
        const base64Pattern = /^[A-Za-z0-9+/-_,]+={0,3}$/;
        if (!base64Pattern.test(value)) return CanTransformResult.No;

        this.parse(value);

        const minLength = 10;
        if (value.length < minLength) return CanTransformResult.Maybe;

        return CanTransformResult.Yes;
      } catch (_) {
        return CanTransformResult.No;
      }
    }

    return 0;
  }

  async transform(state: State): Promise<StringState> {
    if (StringState.is(state)) {
      const parsed = this.parse(state.value);

      return new StringState(parsed);
    }

    throw new Error("Invalid state.");
  }

  private parse(value: string) {
    return atob(value);
  }
}

export default new FromBase64Transformation();
