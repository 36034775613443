import clsx from "clsx";

const styles = ["text-primary-200", "text-primary-300", "text-primary-400", "text-primary-500", "text-white"];

interface LayeredTitleProps {
  title?: React.ReactNode;
  className?: string;
}

export const LayeredTitle: React.FC<LayeredTitleProps> = ({ title, className }) => {
  return (
    <div className={clsx("relative block w-fit", className)}>
      <div className="text-secondary-950">{title}</div>

      {styles.map((v, i) => {
        const index = styles.length - i - 1;

        return (
          <div
            key={`style-${i}`}
            style={{
              textShadow:
                "2px 0 #090919, -2px 0 #090919, 0 2px #090919, 0 -2px #090919,2px 2px #090919, -2px -2px #090919, 2px -2px #090919, -2px 2px #090919",
              top: `${index * 4}px`,
              right: `-${index * 5}px`,
            }}
            className={clsx("absolute", v)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};
