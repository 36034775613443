import React from "react";

import "@xyflow/react/dist/style.css";
import { useWorkflows } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { PipelinesDashbordInfo } from "@/lib/pipelines/pipelines-flow-info";
import { AlignJustify, LayoutGrid, Network } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { GridViewDashboard } from "@/lib/pipelines/grid-view/grid-view-dashboard";
import { ListViewDashboard } from "@/lib/pipelines/list-view/list-view-dashboard";
import { FlowViewDashboard } from "@/lib/pipelines/flow-view/flow-view-dashboard";
import { PipelineSettingsPopover } from "@/lib/pipelines/config/pipelines-settings-popover";
import { Workflow, WorkflowsEnvelopeData } from "@/api/types/response";
import { PipelineVisibilityPopover } from "@/lib/pipelines/config/pipeline-visibility-popover";
import { DynamicViewWrapper } from "@/lib/pipelines/utils/dynamic-view-wrapper";
import { usePipelineState } from "@/lib/pipelines/config/usePipelineState";
import { PanelWorkflowSelection } from "@/api/types/common";
import { panelService } from "@/lib/pipelines/services/panel.service";

interface PipelinesDashboardProps {
  owner: string;
  repo: string;
}

export const PipelinesDashboard: React.FC<PipelinesDashboardProps> = ({ owner, repo }) => {
  const { readOnly } = usePipelineState();

  const {
    isPending,
    isError,
    data: response,
  } = useWorkflows(
    {
      owner: owner,
      repo: repo,
    },
    // Refresh every 30 minutes in public view
    readOnly
      ? {
          refetchInterval: 1000 * 60 * 30, // 30 minutes
          refetchIntervalInBackground: true,
        }
      : {}
  );

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <>
          {response.status === "reject" && <Navigate to="/pipelines/auth" replace={true} />}
          {response.status === "success" && (
            <div className="space-y-2">
              <DynamicViewWrapper owner={owner} repo={repo} workflowsEnvelopeData={response.data}>
                {({ owner, repo, workflowsEnvelopeData }) =>
                  readOnly ? (
                    <PublicView owner={owner} repo={repo} workflowsEnvelopeData={workflowsEnvelopeData} />
                  ) : (
                    <PrivateView owner={owner} repo={repo} workflowsEnvelopeData={workflowsEnvelopeData} />
                  )
                }
              </DynamicViewWrapper>
            </div>
          )}
        </>
      )}
    </>
  );
};

interface ViewProps {
  owner: string;
  repo: string;
  workflowsEnvelopeData: WorkflowsEnvelopeData;
}

const PrivateView: React.FC<ViewProps> = ({ owner, repo, workflowsEnvelopeData }) => {
  const [selectedView, setSelectedView] = React.useState<string>(
    workflowsEnvelopeData.pipelineInfo.defaultView ?? "flow"
  );

  const groupedWorkflows: PanelWorkflowSelection = React.useMemo(() => {
    const selected = workflowsEnvelopeData.pipelineInfo.selectedWorkflows ?? "all";
    const workflows = workflowsEnvelopeData.workflows;

    const getWorkflowData = (workflow: Workflow) => ({
      workflowId: workflow.id,
      owner,
      repo,
      workflowName: workflow.name,
      htmlUrl: workflow.html_url,
    });

    const filteredWorkflows =
      selected === "all"
        ? workflows.map(getWorkflowData)
        : workflows.filter((workflow) => selected.includes(workflow.id)).map(getWorkflowData);

    return panelService.groupWorkflowsByOwnerRepo(filteredWorkflows);
  }, [workflowsEnvelopeData, owner, repo]);

  React.useEffect(() => {
    setSelectedView(workflowsEnvelopeData.pipelineInfo.defaultView ?? "flow");
  }, [workflowsEnvelopeData.pipelineInfo.defaultView]);

  return (
    <>
      <div className="flex justify-between">
        <PipelinesDashbordInfo owner={owner!} repo={repo!} workflowCount={workflowsEnvelopeData.total_count} />
        <div className="flex gap-1">
          <PipelineSettingsPopover owner={owner!} repo={repo!} workflowsEnvelopeData={workflowsEnvelopeData} />
          <PipelineVisibilityPopover
            owner={owner}
            repo={repo}
            isPublic={workflowsEnvelopeData.pipelineInfo.isPublic}
            pipelineId={workflowsEnvelopeData.pipelineInfo.pipelineId}
          />
        </div>
      </div>

      <Tabs value={selectedView} onValueChange={(value) => setSelectedView(value)}>
        <div className="flex justify-between items-center">
          <TabsList className="grid grid-cols-3 bg-transparent">
            <TabsTrigger
              value="flow"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              <Network size={14} />
            </TabsTrigger>
            <TabsTrigger
              value="list"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              <AlignJustify size={14} />
            </TabsTrigger>
            <TabsTrigger
              value="grid"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              <LayoutGrid size={14} />
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="flow">
          <FlowViewDashboard
            title={repo}
            selectedWorkflows={groupedWorkflows}
            workflowResourceJwt={workflowsEnvelopeData.resourceJwt}
          />
        </TabsContent>
        <TabsContent value="grid">
          <GridViewDashboard
            title={repo}
            selectedWorkflows={groupedWorkflows}
            workflowResourceJwt={workflowsEnvelopeData.resourceJwt}
          />
        </TabsContent>
        <TabsContent value="list">
          <ListViewDashboard
            title={repo}
            selectedWorkflows={groupedWorkflows}
            workflowResourceJwt={workflowsEnvelopeData.resourceJwt}
          />
        </TabsContent>
      </Tabs>
    </>
  );
};

const PublicView: React.FC<ViewProps> = ({ owner, repo, workflowsEnvelopeData }) => {
  const selectedView = React.useMemo(() => {
    return workflowsEnvelopeData.pipelineInfo.defaultView ?? "flow";
  }, [workflowsEnvelopeData]);

  const groupedWorkflows: PanelWorkflowSelection = React.useMemo(() => {
    const selected = workflowsEnvelopeData.pipelineInfo.selectedWorkflows ?? "all";
    const workflows = workflowsEnvelopeData.workflows;

    const getWorkflowData = (workflow: Workflow) => ({
      workflowId: workflow.id,
      owner,
      repo,
      workflowName: workflow.name,
      htmlUrl: workflow.html_url,
    });

    const filteredWorkflows =
      selected === "all"
        ? workflows.map(getWorkflowData)
        : workflows.filter((workflow) => selected.includes(workflow.id)).map(getWorkflowData);

    return panelService.groupWorkflowsByOwnerRepo(filteredWorkflows);
  }, [workflowsEnvelopeData, owner, repo]);

  return (
    <>
      {selectedView === "flow" && (
        <FlowViewDashboard
          title={repo}
          selectedWorkflows={groupedWorkflows}
          workflowResourceJwt={workflowsEnvelopeData.resourceJwt}
        />
      )}

      {selectedView === "grid" && (
        <GridViewDashboard
          title={repo}
          selectedWorkflows={groupedWorkflows}
          workflowResourceJwt={workflowsEnvelopeData.resourceJwt}
        />
      )}

      {selectedView === "list" && (
        <ListViewDashboard
          title={repo}
          selectedWorkflows={groupedWorkflows}
          workflowResourceJwt={workflowsEnvelopeData.resourceJwt}
        />
      )}
    </>
  );
};
