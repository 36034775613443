import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, KeyValueState, NumberState, StringState } from "../states";
import { analyzeString } from "../utils/string-utils";
import { ToolsIcons } from "./common-options/options.transformations";
import xbytes, { parseBytes } from "xbytes";

export class ToNumberTransformation extends StateTransformation<State> {
  constructor() {
    super("to-number", "To Number", ["string"]);
  }

  icon(): ToolsIcons {
    return "number";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (NumberState.is(state)) return CanTransformResult.No;
    if (state.config().stringValue) {
      const str = state.stringValue();
      const strStats = analyzeString(str);
      try {
        parseBytes(str);
        if (strStats.isAscii && !strStats.isNumeric) return CanTransformResult.Recommended;
      } catch {
        /* empty */
      }
      const val = this.parse(str);

      if (!isNaN(val.value)) return CanTransformResult.Yes;
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<State> {
    if (StringState.is(state)) {
      const str = state.stringValue();
      const strStats = analyzeString(str);
      try {
        const byteSize = parseBytes(str);
        if (strStats.isAscii && !strStats.isNumeric) {
          const result: Record<string, string | number> = {
            Prefix: byteSize.prefix,
            Value: byteSize.value,
            Size: byteSize.size,
            Bytes: byteSize.bytes,
            IEC: byteSize.iec ? "Yes" : "No",
          };

          if (!byteSize.iec) {
            const iecString = xbytes(byteSize.bytes, { iec: true });
            result["IEC format"] = iecString;
            result["IEC Bytes"] = parseBytes(iecString).bytes;
          }
          return new KeyValueState(result, "Bytes");
        }
      } catch {
        /* empty */
      }

      const parsed = this.parse(str);

      if (isNaN(parsed.value)) return new InvalidState("Invalid number.");
      return new NumberState(parsed.value, parsed.type);
    }

    return new InvalidState("Not a number");
  }

  private parse(value: string) {
    const parsedNum = Number(value);

    return {
      value: parsedNum,
      type: "float",
    };
  }
}

export default new ToNumberTransformation();
