import { objectKeys } from "@/utils";
import type { StateValidator, ValidationResult } from "./state-validator";
import * as Transformations from "./transformations";
import * as Validations from "./validations";
import { SnakeContext, State, ValidTransformation } from "./state";
import { StateTransformation, TransformationId } from "./state-transformation";
import { InvalidState } from "./states";
import equals from "fast-deep-equal/es6";

const transformations = objectKeys(Transformations).map((key) => Transformations[key]) as StateTransformation[];
const validations: StateValidator[] = objectKeys(Validations).map((key) => Validations[key]);

export type UpdateSnakeItemWithIndexFunction = (index: number, updatedItem: ToolsSnakeInputNode) => void;

export type ToolsSnakeInputNode = {
  transformationId: TransformationId | undefined;
  options?: object;
};

export type ToolsSnakeNode = {
  transformationId: TransformationId | undefined;
  options?: object;
  state: State;
};

export const getAllTransformations = (): StateTransformation[] => {
  return [...transformations];
};

export const getValidTransformations = async (
  state: State | undefined,
  selectedTransformationId?: TransformationId
): Promise<ValidTransformation[]> => {
  if (!state) return [];

  const transformationMode = state.config().transformations;
  if (transformationMode === "none") return [];

  const calculatedTransformations = await Promise.all(
    transformations.map(async (transformation) => {
      try {
        if (transformationMode === "tags-only") {
          const transformationTags = state.config().transformationTags;
          if (!transformationTags || !transformation.tags.some((t) => transformationTags.includes(t))) return false;
        }
        const transformationValid = await transformation.canTransform(state);

        if (!transformationValid) return false;

        return {
          valid: transformationValid,
          transformation,
        };
      } catch (e) {
        console.warn("error in canTransform", e);
        return false;
      }
    })
  );
  const validTransformations = calculatedTransformations.filter(Boolean) as ValidTransformation[];

  if (selectedTransformationId) {
    const isSelectedTransformValid = validTransformations.some((t) => t.transformation.id === selectedTransformationId);
    if (!isSelectedTransformValid) {
      const selectedTransform = getTransformationById(selectedTransformationId);
      if (selectedTransform) validTransformations.push({ valid: 1, transformation: selectedTransform });
    }
  }

  return validTransformations.sort((a, b) => {
    const diff = b.valid - a.valid;
    return diff === 0 ? a.transformation.id.localeCompare(b.transformation.id) : diff;
  });
};

export const getTransformations = (): StateTransformation[] => {
  return transformations;
};

export const getTransformationById = (id: TransformationId): StateTransformation | undefined => {
  if (!id) return undefined;
  if (id === "none") return undefined;
  return transformations.find((t) => t.id === id);
};

export const getValidations = async (
  state: State | undefined,
  snakeContext: SnakeContext
): Promise<ValidationResult[]> => {
  if (!state) return [];
  const results: ValidationResult[] = [];
  for (const v of validations) {
    const newValidations = await v.validate(state, snakeContext);
    results.push(...newValidations);
  }
  return results;
};

const transform = async (
  state: State,
  transformation: StateTransformation | undefined,
  values: object,
  snakeContext: SnakeContext
) => {
  try {
    if (!transformation) return new InvalidState("No transformation selected");

    return await transformation.transform(state, values as any, snakeContext);
  } catch (e) {
    return new InvalidState("Invalid transformation", String(e));
  }
};

export const buildToolsSnake = async (
  startState: State,
  inputNodes: ToolsSnakeInputNode[],
  snakeContext: SnakeContext
): Promise<{ nodes: ToolsSnakeNode[]; optionsChanged: boolean }> => {
  const retVal: ToolsSnakeNode[] = [];
  let optionsChanged = false;

  let currentState = startState;
  for (let i = 0; i < inputNodes.length; i++) {
    const { transformationId, options } = inputNodes[i];
    if (!transformationId) {
      retVal.push({ state: currentState, transformationId: undefined, options: undefined });
      return {
        optionsChanged: true,
        nodes: retVal,
      };
    }
    const lastState = currentState;
    if (currentState instanceof InvalidState) currentState = new InvalidState("Invalid state");
    else {
      const currentTransformation = getTransformationById(transformationId);

      const updatedOptions = await currentTransformation?.getOptions(currentState, { ...options } as any, snakeContext);

      if (!equals(options, updatedOptions)) {
        optionsChanged = true;
      }
      inputNodes[i].options = updatedOptions;
      currentState =
        (await transform(lastState, getTransformationById(transformationId), updatedOptions as any, snakeContext)) ||
        new InvalidState("Invalid transformation");
    }
    retVal.push({ state: lastState, transformationId, options: inputNodes[i]?.options });
  }

  retVal.push({ state: currentState, transformationId: undefined, options: undefined });
  //console.log("optionsChanged", optionsChanged, "retVal", retVal);
  return { nodes: retVal, optionsChanged: optionsChanged };
};
