import { StringState } from "@/utils/parsing";
import { TransformationId } from "@/utils/parsing/state-transformation";
import { autodetect } from "@/utils/parsing/utils/autodetect-utils";

export const secretTypes = ["custom", "token", "certificate"] as const;

export type SecretType = (typeof secretTypes)[number];

export const secretTypeTransformations: Record<SecretType, TransformationId> = {
  custom: "to-string",
  token: "to-jwt",
  certificate: "to-crypto-keys",
};

export interface SecretsLocalStorageData {
  key: string;
  value: string;
  type: SecretType;
  created: string;
}

export const autodetectSecretType = async (value: string): Promise<SecretType> => {
  const autodetected = await autodetect(new StringState(value), Object.values(secretTypeTransformations));

  const detectedTransformationId = autodetected.length > 0 ? autodetected[0].transformId : "to-string";

  const detectedType = (Object.entries(secretTypeTransformations) as [SecretType, TransformationId][]).find(
    ([, transformationId]) => transformationId === detectedTransformationId
  );

  return detectedType ? detectedType[0] : "custom";
};
