import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { UrlState } from "../states";
import { ToolsIcons } from "./common-options/options.transformations";

export class ToUrlTransformation extends StateTransformation<UrlState> {
  constructor() {
    super("to-url", "To URL", ["string"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      const url = this.parse(state.stringValue());
      if (url === null) return CanTransformResult.No;
      if (url.protocol.startsWith("http") || url.protocol.startsWith("https")) return CanTransformResult.Recommended;
      return CanTransformResult.Maybe;
    }

    return CanTransformResult.No;
  }

  async transform(state: State): Promise<UrlState> {
    if (state.config().stringValue) {
      const url = this.parse(state.stringValue());
      if (url === null) throw new Error("Invalid URL");
      return new UrlState(url);
    }

    throw new Error("URL not detected");
  }

  icon(): ToolsIcons {
    return "link";
  }
  private parse(value: string) {
    try {
      return new URL(value);
    } catch {
      return null;
    }
  }
}

export default new ToUrlTransformation();
