import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { BasicHttpAuthState } from "../states";
import { parse } from "basic-auth";

export class ToBasicAuthTransformation extends StateTransformation<BasicHttpAuthState> {
  constructor() {
    super("basic-http-auth", "To HTTP Basic Auth", ["string", "request"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      if (!/^\s*BASIC/i.test(state.stringValue())) return CanTransformResult.No;
      const creds = parse(state.stringValue());
      if (creds) return CanTransformResult.Yes;
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<BasicHttpAuthState> {
    const creds = parse(state.stringValue());
    if (!creds) throw new Error("Invalid Basic Auth header.");
    return new BasicHttpAuthState({ name: creds.name, password: creds.pass });
  }
}
export default new ToBasicAuthTransformation();
