import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { BrandingVideosCarousel } from "@/lib/landing-page/branding-videos";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { usePricingTiers } from "@/lib/landing-page/pricing-tiers";
import { isNumber } from "@/utils";
import { cn } from "@/utils/ui.util";
import { ArrowUpRight, CheckCircle2 } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { KeyFeatures } from "./components/key-features";
import { LayeredTitle } from "./components/layered-title";
import { LandingSection } from "./homes/landing-section";

export const LandingPage: React.FC = () => {
  return <LandingPageWrapper head={<LandingPageHead />} children={<LandingPageContent />} />;
};

const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";
const LandingPageHead: React.FC = () => {
  return (
    <div className="flex flex-col items-center space-y-6 text-center pt-20 2xl:pt-32">
      <div className="flex flex-col gap-8 items-center">
        <LayeredTitle
          title="Opinionated Developer"
          className="text-5xl md:text-6xl/none lg:text-7xl/none xl:text-8xl/none font-black font-[system-ui] tracking-wide whitespace-nowrap"
        />

        <LayeredTitle
          title="Toolbox"
          className="text-5xl md:text-6xl/none lg:text-7xl/none xl:text-8xl/none  font-black font-[system-ui] tracking-wide whitespace-nowrap"
        />

        <p className="mx-auto text-muted-foreground md:text-xl max-w-[900px]">
          Streamline your workflow with simple, powerful tools for testing webhooks, automating email validation, and
          visualizing CI/CD pipelines.
        </p>
      </div>

      <div className="flex gap-2">
        <Button asChild>
          <Link to={docsURL} target="_blank" className="no-underline">
            Documentation
          </Link>
        </Button>
        <Button className="bg-primary-300" asChild id="get-started-button">
          <Link to="/dashboard" className="no-underline">
            <div className="flex items-center gap-1">
              Get Started
              <ArrowUpRight size={20} />
            </div>
          </Link>
        </Button>
      </div>
    </div>
  );
};

const LandingPageContent: React.FC = () => {
  const pricingTiers = usePricingTiers();
  const [clicks, setClicks] = React.useState(0);

  /** TODO: Keep? */

  // const webhookMutation = ApiMutation.useCreateWebhook({
  //   onSuccess: (data) => {
  //     LocalStorageService.store<WebhooksLocalStorageData>("webhooks", {
  //       ...data,
  //       created: new Date().toISOString(),
  //       isDynamic: false,
  //     });
  //     navigate(`/webhooks/${data.id}`);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  // const emailMutation = ApiMutation.useCreateEmail({
  //   onSuccess: (data) => {
  //     LocalStorageService.store<EmailsLocalStorageData>("emails", {
  //       ...data,
  //       created: new Date().toISOString(),
  //     });
  //     navigate(`/emails/${data.id}`);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  return (
    <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
      <LandingSection
        className="bg-white flex flex-col gap-y-8 lg:flex-row lg:gap-24"
        style={{
          filter: `blur(${clicks}px)`,
        }}
      >
        <div>
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Overview</h2>
          <div className="text-muted-foreground md:text-lg text-justify">
            The <b>Opinionated Developer Toolbox</b> is for devs who want things to just work without endless tweaking.
            We’ve put together a collection of tools to make <b>testing</b>, <b>debugging</b> and <b>monitoring</b>{" "}
            easier, so you can get back to building your project faster.
          </div>
        </div>

        <KeyFeatures
          className="whitespace-nowrap row-span-2 self-center"
          features={["Strong Defaults, Less Config", "Built for Speed", "No Bloat, Just What You Need"]}
          onClick={() => setClicks((c) => (c + 1) % 6)}
        />
      </LandingSection>

      <LandingSection className="bg-secondary-200">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">
          Highlighted Tools
        </h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Our toolbox covers the essentials to <b>simplify your workflow</b>. Whether it’s <b>pipelines</b>,{" "}
          <b>webhooks</b> or <b>emails</b>, we’ve got you covered with tools that are easy to use and powerful enough to
          get the job done.
        </p>

        <BrandingVideosCarousel />
      </LandingSection>

      <LandingSection className="bg-white flex flex-col lg:flex-row gap-6 lg:gap-8 items-center">
        <div className="flex flex-col gap-6">
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none">Explore Our API</h2>
          <p className="text-muted-foreground md:text-lg text-justify">
            If you’re more hands-on and love to <b>automate</b>, our <b>API</b> is perfect for you. It’s
            well-documented, easy to use, and gives you full control over your <b>webhook</b> and <b>email testing</b>.
            Whether you’re validating data or integrating with your current workflows, our API makes it straightforward.
          </p>

          <div>
            <Button asChild>
              <Link to={docsURL + "/openapi"} target="_blank" className="no-underline bg-secondary-300">
                <div className="flex items-center gap-1">
                  Explore API
                  <ArrowUpRight size={20} />
                </div>
              </Link>
            </Button>
          </div>
        </div>
        <div className="w-full min-w-[50%]">
          <img src={"/createWebhook.png"} />
        </div>
      </LandingSection>

      <LandingSection className="bg-tertiary-100">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-12">Pricing plans</h2>

        <div
          className="neu rounded-lg bg-white/80 flex flex-col lg:flex-row min-h-[600px]"
          style={{ gridTemplateColumns: `repeat(${pricingTiers.length}, 1fr)` }}
        >
          {pricingTiers.map((pricingTier, index) => {
            const isPopular = pricingTier.options?.popular;

            const parsePrice = (price: (typeof pricingTier)["price"]) => {
              if (!isNumber(price)) {
                return price;
              }

              if (!price) {
                return "Free";
              }

              return (
                <>
                  <span className="line-through">
                    {pricingTier.currency}
                    {price}
                  </span>
                  <span className="text-base ml-2 opacity-75">/year</span>
                  <span className="text-2xl ml-2">Free</span>
                </>
              );
            };

            return (
              <div
                className={cn(
                  "[&:not(:last-of-type)]:border-b lg:[&:not(:last-of-type)]:border-r-2 border-secondary-950 w-full",
                  isPopular && "bg-white"
                )}
                key={index}
              >
                <div className="py-6 px-6 flex flex-col gap-4">
                  <h4 className={"flex items-center justify-between font-semibold"}>
                    {pricingTier.name}
                    {isPopular && (
                      <Badge className="bg-secondary-700 hover:bg-secondary-700 text-white">Most popular</Badge>
                    )}
                  </h4>

                  <h2 className="my-2 font-semibold">{parsePrice(pricingTier.price)}</h2>

                  {pricingTier.actionButton}

                  <small>{pricingTier.description}</small>

                  <ul className="space-y-2 list-none">
                    {pricingTier.features.map((feature, index) => {
                      return (
                        <li className="flex items-center gap-2" key={index}>
                          <CheckCircle2 className="h-6 w-6" />
                          <p className="w-full">{feature}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </LandingSection>

      <LandingSection className="bg-white">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Why Choose Us?</h2>
        <p className="text-muted-foreground md:text-lg">
          We built this toolbox to make life easier for <b>developers</b>. No extra fluff, no unnecessary
          complexity—just solid tools that work. Whether you’re working <b>solo</b> or in a team, our tools will help
          you <b>streamline your workflow</b>
          and get more done with less hassle.
          <br />
          <br />
          <span className="inline-flex">
            <a target="_blank" href={"https://discord.gg/6hXaw8sATm"}>
              <img
                alt="Discord"
                className="mb-[-4px]"
                src="https://img.shields.io/discord/1257985752000757852?style=flat&logo=discord&logoColor=%23ff9f58&label=Join%20a%20community&labelColor=090919&color=%23ff9f58&link=https%3A%2F%2Fdiscord.gg%2F6hXaw8sATm"
              />
            </a>
          </span>{" "}
          of developers and QA engineers who trust our platform for their testing needs.
        </p>
      </LandingSection>
    </div>
  );
};
