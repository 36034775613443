import { ToolsIcons, toToolIcon } from "./options.transformations";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import React from "react";

type TransformationOptionsSelectProps = {
  selected: string;
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
  onChange: (selected: string) => void;
};

// Define the component as a React.FC
export const TransformationOptionsSelectCustomizable: React.FC<TransformationOptionsSelectProps> = ({
  selected,
  values,
  label,
  icons,
  onChange,
}) => {
  const selectId = React.useId();
  if (!values) return null; // Handling the case where values might be undefined or empty

  return (
    <div className="flex min-h-11">
      <div className="flex neu-flat px-2 rounded-l-md bg-zinc-300 items-center text-nowrap" key={selected}>
        <Label htmlFor={selectId}>{label}</Label>
      </div>
      <Select onValueChange={(value) => onChange(value)} value={selected}>
        <SelectTrigger id={selectId} className="rounded-l-none border-l-0">
          <SelectValue placeholder={label} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.entries(values).map(([value, label]) => (
              <SelectItem key={value} value={value}>
                <div className="flex items-center">
                  {toToolIcon(icons?.[value] ?? null)}
                  {label || value}
                </div>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
