import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState } from "../states";
import { BlobState } from "../states/blob.state";

import { ToolsIcons } from "./common-options/options.transformations";
import mime from "mime";
import { TreeItemIndex, TreeItem } from "react-complex-tree";
import "react-complex-tree/lib/style-modern.css";

import { BlobReader, BlobWriter, Entry, ZipReader } from "@zip.js/zip.js";
import { TransformationFileTree, TreeNodeInfo } from "./common-options/options.filetree.transformation";

type UnzipFileMode = {
  selected: string;
  tree: Record<TreeItemIndex, TreeItem<TreeNodeInfo>>;
  icons?: Record<string, ToolsIcons>;
};

export class UnzipTransformation extends StateTransformation<State, UnzipFileMode> {
  constructor() {
    super("unzip", "Unzip", ["blob"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (BlobState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  buildTree = (entries: Entry[]): Record<TreeItemIndex, TreeItem<TreeNodeInfo>> => {
    const items: Record<TreeItemIndex, TreeItem<TreeNodeInfo>> = {};

    items["/"] = {
      index: "/",
      isFolder: true,
      children: [],
      data: {
        filename: "/",
        name: "/",
      },
    };

    for (const entry of entries) {
      const segments = entry.filename.split("/").filter((segment) => segment.length > 0);
      let currentPath = "/";

      for (let i = 0; i < segments.length; i++) {
        const path = segments[i];
        const oldPath = currentPath;
        currentPath = `${currentPath}/${path}`;
        const isLastSegment = i === segments.length - 1;

        if (!items[currentPath]) {
          items[oldPath].children?.push(currentPath);
          items[oldPath].isFolder = true;

          if (!isLastSegment) {
            items[currentPath] = {
              index: currentPath,
              isFolder: true,
              children: [],
              data: {
                filename: entry.filename,
                name: path,
              },
            };
          } else {
            items[currentPath] = {
              index: currentPath,
              children: [],
              data: {
                filename: entry.filename,
                name: path,
              },
            };
          }
        }
      }
    }

    return items;
  };

  async getOptions(state: BlobState, options?: UnzipFileMode): Promise<UnzipFileMode> {
    try {
      const zipFileReader = new BlobReader(state.value);
      const zipReader = new ZipReader(zipFileReader);
      const entries = await zipReader.getEntries();
      const tree = this.buildTree(entries);

      return {
        selected: options?.selected || "",
        tree: tree,
      };
    } catch (e) {
      return { selected: "", tree: {} };
    }
  }

  async transform(state: State, options: UnzipFileMode): Promise<State> {
    if (BlobState.is(state)) {
      try {
        const zipFileReader = new BlobReader(state.value);
        const zipReader = new ZipReader(zipFileReader);

        const filename = options.selected;
        const entries = await zipReader.getEntries();
        const entry = entries.find((entry) => entry.filename === filename);

        if (entry === undefined) return new InvalidState("File not found in zip");
        const blobWriter = new BlobWriter();
        const data = await entry.getData?.(blobWriter);
        if (data === undefined) return new InvalidState("Failed to extract file from zip");

        const type = mime.getType(filename);
        return new BlobState(data, { filename: entry.filename, mime: type as any });
      } catch (e) {
        return new InvalidState(String(e));
      }
    }
    return new InvalidState("Input must be a blob with a zip file.");
  }

  getOptionsReactNode(options: UnzipFileMode, onChange: (options: UnzipFileMode) => void): React.ReactNode {
    return (
      <TransformationFileTree
        classNames="max-h-60 px-2 overflow-y-auto bg-white  "
        data={options.tree}
        rootItem="/"
        treeId="filetree"
        onChange={onChange}
        options={options}
        getItemTitle={(item) => item.data.name}
      />
    );
  }
}

export default new UnzipTransformation();
