import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { CircleOff, Check } from "lucide-react";

export class SuccessFailState extends State<boolean> {
  constructor(
    value: boolean,
    readonly details: string | ReactNode
  ) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "none",
      validations: "none",
      stringValue: false,
      jsonValue: false,
      customDisplay: ["Status"],
      defaultDisplayMode: "Status",
    };
  }

  stringValue(): string {
    throw new Error("Method not implemented.");
  }

  toReactNode(_: string): ReactNode {
    return (
      <div>
        {this.value ? <Check className="w-6 h-6 text-green-500" /> : <CircleOff className="w-6 h-6 text-red-500" />}
        {typeof this.details === "string" ? <pre>{this.details}</pre> : this.details}
      </div>
    );
  }
}
