import React from "react";
import { Navbar } from "@/lib/landing-page/navbar";
import "./landing-page-wrapper.scss";

interface LandingPageWrapperProps {
  head?: React.ReactNode;
  children?: React.ReactNode;
}

export const LandingPageWrapper: React.FC<LandingPageWrapperProps> = ({ head, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <>
      <div className="bg-gradient-to-br from-secondary-300/70 to-primary-200/70 pb-16">
        {/* <div className="bg-primary-100 pb-16"> */}
        <header ref={ref}>
          <Navbar />
        </header>
        {head}
        {/* <div className="divider">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div> */}
      </div>
      <main className="bg-gradient-to-tr from-secondary-300/70 to-primary-200/70 border-b-2 border-secondary-950">
        {children}
      </main>
    </>
  );
};
