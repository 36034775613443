import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { IPv4, IPv6 } from "ip-num/IPNumber";
import { IPv4CidrRange, IPv6CidrRange } from "ip-num/IPRange";
import * as IPv6Utils from "ip-num/IPv6Utils";
import { HeadersTable } from "@/components/headers-table";

type IPAddresses = Ip4Address | Ip6Address | Ip4Cidr | Ip6Cidr;

type Ip4Address = {
  type: "ipv4";
  data: IPv4;
};

type Ip6Address = {
  type: "ipv6";
  data: IPv6;
};
type Ip4Cidr = {
  type: "ipv4-cidr";
  data: IPv4CidrRange;
};

type Ip6Cidr = {
  type: "ipv6-cidr";
  data: IPv6CidrRange;
};

export class IpState extends State<IPAddresses> {
  constructor(value: IPAddresses) {
    super(value);
  }

  stringValue(): string {
    switch (this.value.type) {
      case "ipv6":
      case "ipv4":
        return this.value.data.toString();
      case "ipv6-cidr":
      case "ipv4-cidr":
        return this.value.data.toCidrString();
    }
  }

  typeToString(data: IPAddresses) {
    switch (data.type) {
      case "ipv6":
        return "IPv6";
      case "ipv4":
        return "IPv4";
      case "ipv6-cidr":
        return "IPv6 CIDR";
      case "ipv4-cidr":
        return "IPv4 CIDR";
    }
  }

  config(): StateConfig {
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: true,
      customDisplay: [this.typeToString(this.value)],
      defaultDisplayMode: this.typeToString(this.value),
    };
  }

  jsonValue(): object {
    switch (this.value.type) {
      case "ipv6":
        return {
          ipv6: this.value.data.toString(),
          ipv6Expanded: IPv6Utils.expandIPv6Number(this.value.data.toString()),
          ipv6Collapsed: IPv6Utils.collapseIPv6Number(this.value.data.toString()),
          type: this.value.type,
        };
      case "ipv4":
        return {
          ipv4: this.value.data.toString(),
          ipv6: this.value.data.toIPv4MappedIPv6().toString(),
          type: this.value.type,
          hex: this.value.data
            .getOctets()
            .map((x) => x.getValue().toString(16))
            .join(""),
        };
      case "ipv6-cidr":
        return {
          cidr: this.value.data.toCidrString(),
          size: this.value.data.getSize().toString(),
          firstAddress: this.value.data.getFirst().toString(),
          lastAddress: this.value.data.getLast().toString(),

          range: this.value.data.toRangeString(),
          type: this.value.type,
        };

      case "ipv4-cidr":
        return {
          cidr: this.value.data.toCidrString(),
          size: this.value.data.getSize().toString(),
          range: this.value.data.toRangeString(),
          firstAddress: this.value.data.getFirst().toString(),
          lastAddress: this.value.data.getLast().toString(),
          type: this.value.type,
        };
    }
  }

  toReactNode(_: string): ReactNode {
    return <HeadersTable headers={this.jsonValue() as any} type="custom" />;
  }
}
