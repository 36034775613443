import { Navigate, Outlet, useParams } from "react-router-dom";
import { useReceivedEmails } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";

export const Emails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const email = `${id}@${import.meta.env.VITE_EMAILS_DOMAIN}`;

  const { isPending, isError, data } = useReceivedEmails({
    email_address: email,
  });

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <Outlet
          context={{
            id: id,
            email: email,
            data: data,
          }}
        />
      )}
    </>
  );
};
