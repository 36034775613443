import React from "react";
import { Button } from "@/components/ui/button";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { cn } from "@/utils/ui.util";
import { Check, Edit } from "lucide-react";
import { useSecretsManager } from "@/lib/tools/secrets-manager/useSecretsManager";
import { HoverInfo } from "@/components/hover-info";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { SecretsLocalStorageData, autodetectSecretType } from "@/lib/tools/secrets-manager/secrets-manager-utils";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface UpdateSecretDialog {
  keyProp: string;
  value: string;
}

const validationSchema = Yup.object().shape({
  key: Yup.string().required("Value is required"),
  value: Yup.string().required("Value is required"),
});

interface Value {
  key: string;
  value: string;
}

export const UpdateSecretDialog: React.FC<UpdateSecretDialog> = ({ keyProp, value }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { secrets, updateSecret } = useSecretsManager();
  const { toast } = useToast();

  const initialValues: Value = {
    key: keyProp,
    value: value,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: Value, { setFieldError }) => {
      if (secrets.some((secret) => secret.key === values.key && secret.key !== keyProp)) {
        setFieldError("key", "A secret with this key already exists.");
        return;
      }

      const type = await autodetectSecretType(values.value);

      const updatedSecret: SecretsLocalStorageData = {
        key: values.key,
        value: values.value,
        type: type,
        created: new Date().toISOString(),
      };

      updateSecret(keyProp, updatedSecret);
      setDialogOpen(false);
      toast({ title: "Success!", description: "Secret updated successfully." });
    },
  });

  return (
    <div>
      <Button variant="ghost" size="icon" onClick={() => setDialogOpen(!dialogOpen)}>
        <Edit size={16} />
      </Button>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>
              <div className="flex items-center gap-2">
                Update Secret
                <HoverInfo
                  message={
                    "Editing a secret will update the value. This action cannot be undone. Please proceed with caution."
                  }
                />
              </div>
            </DialogTitle>
          </DialogHeader>

          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-2">
                <div>
                  <Label htmlFor="key">Key</Label>
                  <Input
                    variant={"neu-flat"}
                    id="key"
                    name="key"
                    type="text"
                    defaultValue={formik.values.key}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.key && formik.errors.key && "bg-red-200", "font-mono font-bold")}
                  />
                  {formik.touched.key && formik.errors.key && (
                    <div className="text-red-600 text-xs font-medium">{formik.errors.key}</div>
                  )}
                </div>

                <div>
                  <Label htmlFor="value">Value</Label>
                  <Textarea
                    variant={"neu-flat"}
                    placeholder="Secret"
                    id="value"
                    name="value"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    className={cn(formik.touched.value && formik.errors.value && "bg-red-200")}
                  />
                </div>

                <div className="flex justify-end">
                  <Button type="submit" variant={"neu-flat"} size="sm" className="bg-tertiary-300 w-12">
                    <Check size={16} />
                  </Button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};
