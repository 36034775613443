import React from "react";

import "@xyflow/react/dist/style.css";
import { useParams } from "react-router-dom";
import { PipelineStateProvider } from "@/lib/pipelines/config/pipeline-state-provider";
import { PanelDashboard } from "@/lib/pipelines/panel-dashboard";

interface PanelDashboardWrapperProps {
  readOnly: boolean;
}

export const PanelDashboardWrapper: React.FC<PanelDashboardWrapperProps> = ({ readOnly }) => {
  const { panelId } = useParams<{ panelId: string }>();

  return (
    <PipelineStateProvider readOnly={readOnly}>
      <PanelDashboard panelId={panelId!} />
    </PipelineStateProvider>
  );
};
