import { Button } from "@/components/ui/button";
import clsx from "clsx";
import { BookOpenText } from "lucide-react";
import React from "react";

interface BoomBoxProps {
  title?: React.ReactNode;
  boom?: React.ReactNode;
  description?: React.ReactNode;
}

export const BoomBox: React.FC<BoomBoxProps> = ({ title, boom, description }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="neu rounded-md py-8 px-16 bg-tertiary-100 overflow-hidden relative">
      {!open && (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-white flex items-center flex-col justify-center py-8 px-16">
          <h2 className="text-2xl font-extrabold text-center mb-2">{title}</h2>
          <h5 className="text-center">{boom}</h5>
        </div>
      )}

      <div className="flex items-center justify-center h-full w-full">
        <div className="text-justify text-md">{description}</div>
      </div>

      <Button
        variant="ghost"
        size="icon"
        className="absolute top-[-2px] right-2 rounded-none rounded-b-md bg-secondary-950 active:translate-y-0 active:pt-1 active:h-11"
        onClick={() => setOpen((o) => !o)}
      >
        <BookOpenText className={clsx(open ? "text-tertiary-200" : "text-white")} />
      </Button>
    </div>
  );
};
