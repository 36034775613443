import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useToast } from "@/components/ui/use-toast";
import { Card } from "@/components/ui/card";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { cn } from "@/utils/ui.util";
import { defaultError } from "../utils/default-error-message";
import { Link } from "react-router-dom";

const RegistrationSchema = Yup.object().shape({
  name: Yup.string().min(2, "Must have at least 2 characters").max(20, "Too song").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "Must have at least 6 characters")
    .max(30, "Too long")
    .matches(/^(?=.*[A-Z])(?=.*\d)/, "Must contain at least one uppercase letter and one number")
    .required("Required"),
  repeatPassword: Yup.string().oneOf([Yup.ref("password"), undefined], "Passwords must match"),
});

interface RegisterFormProps {
  namePrefill: string;
  emailPrefill: string;
  regToken: string;
}

export const AWSMarketplaceUserForm: React.FC<RegisterFormProps> = ({ namePrefill, emailPrefill, regToken }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [email, setEmail] = React.useState("");

  const registerMutation = ApiMutation.useRegisterUser({
    onSuccess: () => {
      navigate("/auth/verify", { state: { email: email } });
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: namePrefill,
      email: emailPrefill,
      password: "",
      repeatPassword: "",
    },
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      setEmail(values.email);
      registerMutation.mutate({
        name: values.name,
        email: values.email,
        password: values.password,
        regToken: regToken,
      });
    },
  });

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex justify-center items-center gap-4">
          <div className="flex items-center gap-3">
            <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
            <h3 className="font-bold font-mono">wt.dev</h3>
          </div>
          <p className="text-sm mt-1">&#x2715;</p>
          <div className="flex items-center gap-2">
            <h4 className="mt-1">AWS Marketplace</h4>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">Create an Account</h5>
        <small>Please enter your password.</small>
      </div>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1.5">
              <Input
                id="password"
                placeholder="Password"
                type="password"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                required
                value={formik.values.password}
                onChange={formik.handleChange}
                className={cn(formik.touched.password && formik.errors.password && "bg-red-200")}
              />
              {formik.touched.password && formik.errors.password && <p className="text-xs">{formik.errors.password}</p>}
              <Input
                id="repeatPassword"
                placeholder="Repeat Password"
                type="password"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                required
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                className={cn(formik.touched.repeatPassword && formik.errors.repeatPassword && "bg-red-200")}
              />
              {formik.touched.repeatPassword && formik.errors.repeatPassword && (
                <p className="text-xs">{formik.errors.repeatPassword}</p>
              )}
            </div>
            <Button
              type="submit"
              scheme="black"
              size="sm"
              disabled={registerMutation.isPending}
              className="bg-secondary-950"
            >
              {registerMutation.isPending ? <LoadingSpinner message={"Signing up"} /> : "Sign Up"}
            </Button>
          </div>
        </form>
      </FormikProvider>
      <div className="flex justify-center gap-2 text-sm">
        <small>Already have an account?</small>
        <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign In</small>
        </Link>
      </div>
    </Card>
  );
};
