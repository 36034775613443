import pako from "pako";

const toObject = (obj: any) => {
  // JSON stringify does not support BigInt, so we need to convert it to string
  return JSON.parse(JSON.stringify(obj, (_, value) => (typeof value === "bigint" ? value.toString() : value)));
};

const gzip = (data: string): Uint8Array => {
  const encoder = new TextEncoder();
  const encoded = encoder.encode(data);

  return pako.gzip(encoded);
};

const gzipDecode = (data: Uint8Array): string => {
  return pako.ungzip(data, { to: "string" });
};

const base64Encode = (data: Uint8Array): string => {
  return Buffer.from(data).toString("base64");
};

const base64Decode = (data: string): Uint8Array => {
  return Buffer.from(data, "base64");
};

export const encode = (data: any) => {
  return encodeURIComponent(base64Encode(gzip(JSON.stringify(toObject(data)))));
};

export const decode = (data: string) => {
  return JSON.parse(gzipDecode(base64Decode(decodeURIComponent(data))));
};
