import React from "react";
import useWebSocket from "react-use-websocket";
import { WebhookDetails, WebhookInfoResponseEnvelope, WebhookReceivedRequests } from "@/api/types/response";
import { DynamicResponsePipeline } from "@/routes/webhooks/webhooks";
import { WebhookPageCore } from "@/lib/webhooks/page/webhook-page-core";
import { DynamicWebhookPageCore } from "./dynamic-webhook-page-core";
import { useOutletContext, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const socketUrl = `${import.meta.env.VITE_WEBSOCKET_URL}`;

export interface WebhookStatus {
  isEndpointEnabled: boolean;
  isMaxItemsReached: boolean;
  numberOfRequests: number;
  maxItems: number;
}

interface WebhookPageOutletContext {
  id: string;
  data: WebhookInfoResponseEnvelope;
}

export const WebhookPage: React.FC = () => {
  const { id, data } = useOutletContext<WebhookPageOutletContext>();
  const { requestId } = useParams<{ requestId: string }>();
  const queryClient = useQueryClient();

  const { lastMessage } = useWebSocket(socketUrl, {
    queryParams: { id: id, token: data.resourceJwt },
  });

  const [received, setReceived] = React.useState<WebhookReceivedRequests>(data.webhookInfo.receivedRequests);
  const responsePipeline: DynamicResponsePipeline = data.webhookInfo.isDynamic
    ? {
        specifiedRetries: data.webhookInfo.specifiedRetries,
        contentTypes: data.webhookInfo.contentTypes,
        responseData: data.webhookInfo.responseData,
        responseCodes: data.webhookInfo.responseCodes,
        delayBetweenRetries: data.webhookInfo.delayBetweenRetries,
      }
    : {};

  React.useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data) as WebhookDetails;
      setReceived((prev) => [...prev, message]);
      queryClient.invalidateQueries({ queryKey: [["webhook", "info"], { webhook_id: id }] });
    }
  }, [lastMessage, queryClient, id]);

  const endpointStatus = React.useMemo<WebhookStatus>(() => {
    const isEndpointEnabled = data.webhookInfo.enabled;
    const isMaxItemsReached = data.webhookInfo.maxItems <= received.length;
    const numberOfRequests = received.length;
    const maxItems = data.webhookInfo.maxItems;

    return { isEndpointEnabled, isMaxItemsReached, numberOfRequests, maxItems };
  }, [data, received]);

  return (
    <>
      {!data.webhookInfo.isDynamic && (
        <WebhookPageCore
          id={id}
          requestId={requestId}
          accessType={data.accessType}
          resourceJwt={data.resourceJwt}
          received={received}
          response={data.webhookInfo.response}
          endpointStatus={endpointStatus}
        />
      )}
      {data.webhookInfo.isDynamic && (
        <DynamicWebhookPageCore
          id={id}
          requestId={requestId}
          accessType={data.accessType}
          resourceJwt={data.resourceJwt}
          responsePipeline={responsePipeline}
          received={received}
          endpointStatus={endpointStatus}
        />
      )}
    </>
  );
};
