import { loadStripe } from "@stripe/stripe-js";

interface StripeCheckoutOptions {
  customerEmail: string;
}

export async function handleStripeCheckout(options: StripeCheckoutOptions): Promise<void> {
  const stripePublishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
  const stripePriceId = import.meta.env.VITE_STRIPE_PRICE_ID;

  if (!stripePublishableKey || !stripePriceId) {
    console.info("Stripe keys are missing");
    return;
  }

  const stripe = await loadStripe(stripePublishableKey);

  if (!stripe) {
    return;
  }

  const successUrl = window.location.href;
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      {
        price: stripePriceId,
        quantity: 1,
      },
    ],
    mode: "subscription",
    successUrl: successUrl,
    cancelUrl: window.location.href,
    customerEmail: options.customerEmail,
  });

  console.warn(error.message);
}
