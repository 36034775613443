import React from "react";

interface PipelineStateContext {
  readOnly: boolean;
}

export const PipelineStateContext = React.createContext<PipelineStateContext | null>(null);

interface PipelineStateProviderProps {
  readOnly: boolean;
  children: React.ReactNode;
}

export const PipelineStateProvider: React.FC<PipelineStateProviderProps> = ({ readOnly, children }) => {
  return <PipelineStateContext.Provider value={{ readOnly }}>{children}</PipelineStateContext.Provider>;
};
