import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { EmailMimeState, InvalidState } from "../states";
import { ToolsIcons } from "./common-options/options.transformations";
import { parseMail } from "@protontech/jsmimeparser";

export class ToEmailMimeTransformation extends StateTransformation<EmailMimeState | InvalidState> {
  constructor() {
    super("to-email-mime", "To Email", ["string"]);
  }

  icon(): ToolsIcons {
    return "email";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      const result = parseMail(state.stringValue());
      if (result.from && result.to) {
        if (result.subject) return CanTransformResult.Recommended;
        return CanTransformResult.Yes;
      }
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<EmailMimeState | InvalidState> {
    if (state.config().stringValue) {
      return new EmailMimeState(state.stringValue());
    }
    return new InvalidState("Invalid input for email MIME parsing");
  }
}
export default new ToEmailMimeTransformation();
