import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { isString } from "@/utils";
import { HttpHeaderState, StatePopover, StringState } from "@/utils/parsing";
import { cn } from "@/utils/ui.util";

interface HeadersTableProps {
  title?: string;
  headers: { [key: string]: string | string[] | React.ReactNode | undefined };
  type: "http-headers" | "custom" | "mail-headers" | "query-params";
}

export const HeadersTable: React.FC<HeadersTableProps> = ({ title, headers, type }) => {
  return (
    <ScrollArea className="h-full max-w-full">
      <div className="flex flex-col gap-4 h-full p-4">
        {title && <p className="text-sm font-semibold">{title}</p>}
        <Table>
          <TableBody>
            {Object.entries(headers ?? {}).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell className="align-top">
                  <p className="text-sm font-medium">{key}</p>
                </TableCell>
                <TableCell className="max-w-[500px]">
                  <HeaderCell headerName={type === "http-headers" ? key : undefined} value={value} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </ScrollArea>
  );
};

interface HeaderCellProps {
  value: React.ReactNode | string[];
  headerName?: string;
}

const HeaderCell: React.FC<HeaderCellProps> = ({ headerName, value }) => {
  const { toast, dismiss } = useToast();
  const [clicked, setClicked] = React.useState(false);

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      const { id } = toast({
        description: "Copied to clipboard!",
      });

      setTimeout(() => {
        dismiss(id);
      }, 1500);
    });
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setClicked(false);
    }, 50);
  };

  const state = React.useMemo(() => {
    if (isString(value)) {
      if (headerName) return new HttpHeaderState(value, headerName);
      else return new StringState(value);
    }

    if (Array.isArray(value) && isString(value[0])) {
      const stringVal = value.join(" ");
      if (headerName) return new HttpHeaderState(stringVal, headerName);
      else return new StringState(stringVal);
    }

    return undefined;
  }, [value, headerName]);

  const valueRender = (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        clicked && "text-secondary-700",
        "font-mono break-all cursor-pointer *:hover:line-clamp-none *:hover:font-semibold"
      )}
      onMouseDown={() => setClicked(true)}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={() => value && handleCopy(value.toString())}
    >
      <p className="text-sm line-clamp-1">{value}</p>
    </div>
  );

  if (!state) {
    return valueRender;
  }

  return (
    <div className="grid grid-cols-[1fr,min-content] gap-x-4">
      {valueRender}

      <StatePopover input={{ type: "state", state }} />
    </div>
  );
};
