import React from "react";
import { ToolsSnakeInputNode, ToolsSnakeNode, buildToolsSnake, getValidations } from "../state-utils";
import { ParserInput, parserInputToState } from "@/routes/tools/tools-input";
import { ValidationResult } from "../state-validator";
import { SnakeContext } from "../state";
import { SecretsLocalStorageData } from "@/lib/tools/secrets-manager/secrets-manager-utils";

export function useStateEditorHistory(
  initialState: ParserInput,
  secrets: SecretsLocalStorageData[],
  initialInputNodes?: ToolsSnakeInputNode[]
) {
  const [inputNodes, setInputNodes] = React.useState<ToolsSnakeInputNode[]>(() => {
    if (initialInputNodes) {
      return initialInputNodes;
    }
    return [];
  });

  const snakeContext: SnakeContext = React.useMemo(() => {
    return { secrets };
  }, [secrets]);

  const [builtItems, setBuiltItems] = React.useState<ToolsSnakeNode[]>([]);
  const [inputValidations, setInputValidations] = React.useState<ValidationResult[]>([]);

  const current: ToolsSnakeNode | undefined = React.useMemo(() => {
    if (!inputNodes.length) return { state: parserInputToState(initialState), transformationId: undefined };

    return {
      transformationId: builtItems[inputNodes.length - 1]?.transformationId,
      options: inputNodes[inputNodes.length - 1].options,
      state: builtItems[builtItems.length - 1]?.state,
    };
  }, [inputNodes, initialState, builtItems]);

  React.useEffect(() => {
    if (initialInputNodes) {
      setInputNodes(initialInputNodes);
    } else {
      setInputNodes([]);
    }
  }, [initialInputNodes]);

  const updateItem = (index: number, updatedItem: ToolsSnakeInputNode) => {
    return setInputNodes((h) => {
      const clone = [...h];
      clone[index] = { ...updatedItem };
      return clone;
    });
  };

  const isRunning = React.useRef(false);
  const queue = React.useRef<any[]>([]);
  const [trigger, setTrigger] = React.useState(0); // A state to re-trigger useEffect

  React.useEffect(() => {
    async function build() {
      if (isRunning.current) {
        queue.current.push({ initialState, inputNodes, secrets, snakeContext });
        return;
      }

      isRunning.current = true;

      let current = { initialState, inputNodes, secrets, snakeContext };
      while (current) {
        const state = parserInputToState(current.initialState);
        const nodes = await buildToolsSnake(state, current.inputNodes, snakeContext);
        const inputValidations = await getValidations(state, { secrets: current.secrets });

        setBuiltItems(nodes.nodes);
        setInputValidations(inputValidations);

        if (queue.current.length === 0) {
          break;
        }
        current = queue.current[queue.current.length - 1];
        queue.current = [];
      }

      isRunning.current = false;
    }

    build();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]); // Only dependent on trigger

  // This useEffect will update the trigger state whenever any of the dependencies change
  React.useEffect(() => {
    setTrigger((prev) => prev + 1);
  }, [initialState, inputNodes, secrets, snakeContext]);

  return {
    current,
    builtItems,
    inputNodes,
    inputValidations,
    snakeContext,
    addItem: (item: ToolsSnakeInputNode) => {
      setInputNodes((h) => [...h, item]);
    },
    updateLastItem: (updatedItem: ToolsSnakeInputNode) => {
      return setInputNodes((h) => {
        const clone = [...h];
        clone[Math.max(0, clone.length - 1)] = { ...updatedItem };
        return clone;
      });
    },
    updateItem,
    setCurrent: (index: number) => {
      setInputNodes((h) => h.slice(0, index + 1));
    },
    setInputNodes,
  };
}
