import { ScrollArea } from "@/components/ui/scroll-area";
import { AdminAccountUsers } from "@/lib/admin/admin-account-users";
import { ChevronDown } from "lucide-react";
import React from "react";

export const AdminPanel: React.FC = () => {
  return (
    <div className="flex w-full bg-secondary-200">
      <ScrollArea className="w-full">
        <div className="flex justify-center py-4 2xl:py-10">
          <div className="w-full max-w-[1920px]">
            <div className="flex flex-col gap-6 2xl:gap-12 px-4">
              <div className="flex flex-col space-y-2">
                <h4 className="font-bold tracking-tight">Admin Panel</h4>
                <small>
                  Manage user accounts, view logs, and other administrative tasks. Create and manage webhooks and email
                  addresses.
                </small>
              </div>
              <div className="flex flex-col gap-2 2xl:gap-6">
                <div className="flex overflow-hidden neu bg-white rounded-lg p-6 pb-2">
                  <div className="flex flex-col w-full gap-4 items-center">
                    <ScrollArea className="max-h-[600px] 2xl:max-h-[800px] w-full">
                      <AdminAccountUsers />
                    </ScrollArea>
                    <ChevronDown size={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
