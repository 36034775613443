import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { JwtState } from "../states";
import { parseJwt } from "../utils/parse-jwt";

export class ToJwtTransformation extends StateTransformation<JwtState> {
  constructor() {
    super("to-jwt", "To JWT", ["string"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (JwtState.is(state)) return CanTransformResult.No;
    if (state.config().stringValue) {
      try {
        parseJwt(state.stringValue());
        return CanTransformResult.Recommended; // this is JWT for sure, we want to put it in the first place
      } catch {
        return CanTransformResult.No;
      }
    }

    return CanTransformResult.No;
  }

  async transform(state: State): Promise<JwtState> {
    const parsed = parseJwt(state.stringValue());
    return new JwtState(parsed);
  }
}

export default new ToJwtTransformation();
