import { formatDistanceToNow } from "date-fns/formatDistanceToNow";

import { EmailDetails } from "@/api/types/response";
import { RecentRequestItem } from "@/lib/webhooks/page/elements/sidebar/recent-request-item";

interface RecentEmailsProps {
  id: string;
  items: EmailDetails[];
  selected?: EmailDetails;
}

export const RecentEmails: React.FC<RecentEmailsProps> = ({ id, items, selected }) => {
  return (
    <div className="flex flex-col gap-2">
      {items.map((item, index) => (
        <div key={index}>
          <RecentRequestItem
            item={createItem(
              item.from,
              item.subject,
              formatDistanceToNow(new Date(item.date), {
                addSuffix: true,
              })
            )}
            path={`/emails/${id}/${item.id}`}
            height="h-full"
            isSelected={item === selected}
          />
        </div>
      ))}
    </div>
  );
};

const createItem = (from: string, subject: string, info: string) => {
  return {
    name: (
      <div className="flex flex-col justify-start items-start">
        <p className="text-sm font-semibold break-all line-clamp-1 text-wrap">{from}</p>
        <small className="text-xs break-all line-clamp-1 text-wrap">{subject}</small>
      </div>
    ),
    description: "",
    info: (
      <div className="flex items-center gap-1">
        <p className="text-xs">{info}</p>
      </div>
    ),
  };
};
