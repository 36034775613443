import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";

export const About: React.FC = () => {
  const head = (
    <>
      <div className="container px-6 sm:px-10 md:mt-12 min-h-[calc(100dvh-30rem)]">
        <div className="flex flex-col md:flex-row gap-12 md:px-4 py-4 2xl:py-6">
          <div className="flex flex-col gap-6 md:w-6/12">
            <h1 className="text-3xl md:text-5xl font-extrabold">About Us</h1>
            <p>
              We are a small team of developers who are passionate about building tools that help developers and
              businesses. Our goal is to build tools that make it easier for developers to test and debug their
              webhooks, APIs and emails.
            </p>
            <p>
              We are constantly working on improving our tools and adding new features to make them more useful for our
              users.
            </p>
            <p>
              If you have any feedback or suggestions, please feel free to{" "}
              <a href="mailto:info@wt.dev" className="text-secondary-950">
                email us
              </a>
              .
            </p>
          </div>

          <div className="max-w-2xl md:w-6/12">
            <img src="about-us-01.svg" />
          </div>
        </div>
      </div>
    </>
  );

  return <LandingPageWrapper head={head} />;
};
