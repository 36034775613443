import { Edge, Handle, Position, ReactFlowProvider, useReactFlow, type Node, type NodeProps } from "@xyflow/react";
import React from "react";
import { ReactFlow, useNodesState, useEdgesState } from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { PipelineNode } from "@/lib/pipelines/flow-view/elements/pipeline-node";
import { InitialNode } from "@/lib/pipelines/flow-view/elements/initial-node";
import { useLatestRunJobs } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";
import { WorkflowRunJobs } from "@/api/types/response";
import { Navigate, useNavigate } from "react-router-dom";
import { layoutService } from "@/utils/pipelines/layout-service";
import { useFlowSettings } from "@/lib/pipelines/flow-view/flow-settings-manager/useFlowSettings";
import { DynamicContentWrapper, WorkflowData } from "@/lib/pipelines/utils/dynamic-content-wrapper";
import { Button } from "@/components/ui/button";

const nodeTypes = {
  initialNode: InitialNode,
  pipelineNode: PipelineNode,
};

type GroupNode = Node<WorkflowData>;

export const GroupNode: React.FC<NodeProps<GroupNode>> = ({ data: workflowData }) => {
  const navigate = useNavigate();

  const {
    isPending,
    isError,
    data: response,
  } = useLatestRunJobs({
    owner: workflowData.owner,
    repo: workflowData.repo,
    workflowId: workflowData.workflowId,
    resourceJwt: workflowData.workflowResourceJwt,
  });
  const { direction } = useFlowSettings();

  return (
    <>
      <Handle type="target" position={direction === "TB" ? Position.Top : Position.Left} />
      <div className="neu-flat rounded-lg w-full h-full bg-secondary-200/50">
        <div className="flex flex-col h-full gap-2 py-2">
          <div className="font-semibold mx-auto break-all line-clamp-1 text-wrap">{workflowData.title}</div>
          <>
            {isPending && (
              <div className="flex items-center justify-center w-full">
                <LoadingSpinner message={"Loading..."} />
              </div>
            )}
            {isError && (
              <div className="flex flex-col items-center gap-1 justify-center text-center">
                <div className="text-sm text-gray-600">Oops! Something went wrong.</div>
                <div className="text-sm text-gray-600">
                  <Button
                    size="sm"
                    scheme="link"
                    variant="ghost"
                    className="hover:bg-transparent h-full p-0"
                    onClick={() => navigate(0)}
                  >
                    Try Again
                  </Button>
                </div>
              </div>
            )}
            {!isPending && !isError && (
              <>
                {response.status === "reject" && <Navigate to="/pipelines/auth" replace={true} />}
                {response.status === "success" && (
                  <DynamicContentWrapper
                    run={response.data.runJobs}
                    dependencies={response.data.dependencies}
                    latestRunResourceJwt={response.data.resourceJwt}
                    workflowData={workflowData}
                  >
                    {({ run, dependencies, workflowData }) => (
                      <GroupNodeContentWrapper run={run} dependencies={dependencies} workflowData={workflowData} />
                    )}
                  </DynamicContentWrapper>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

interface GroupNodeContentProps {
  run: WorkflowRunJobs;
  dependencies: Record<string, string[]>;
  workflowData: WorkflowData;
}

const GroupNodeContentWrapper: React.FC<GroupNodeContentProps> = ({ run, dependencies, workflowData }) => {
  if (run.total_count === 0) {
    return <div className="text-sm text-gray-600 mx-auto">No Runs Found</div>;
  }

  return <GroupNodeContent run={run} dependencies={dependencies} workflowData={workflowData} />;
};

const GroupNodeContent: React.FC<GroupNodeContentProps> = ({ run, dependencies, workflowData }) => {
  const jobCardHeight = 90;

  const { updateFlowSize, direction } = useFlowSettings();

  const getNodeHandle = (
    name: string,
    dependencies?: Record<string, string[]>
  ): "none" | "source" | "target" | "both" => {
    if (!dependencies) {
      return "none";
    }

    const jobDependencies = dependencies[name] || [];
    let handle: "none" | "source" | "target" | "both" = jobDependencies.length > 0 ? "source" : "none";

    for (const key in dependencies) {
      if (dependencies[key].includes(name)) {
        if (handle === "source") {
          return "both";
        }
        handle = "target";
      }
    }

    return handle;
  };

  const initialNodes = React.useMemo(() => {
    const jobs = run.jobs;

    if (!jobs) {
      return [];
    }

    const nodes: Node[] = jobs.map((job) => {
      const handle = getNodeHandle(job.name, dependencies);

      return {
        id: job.name,
        type: "pipelineNode",
        data: {
          job: job,
          handle: handle,
        },
        position: { x: 0, y: 0 },
        height: jobCardHeight,
        width: 220,
      };
    });

    return nodes;
  }, [run, dependencies]);

  const initialEdges: Edge[] = React.useMemo(() => {
    const jobs = run.jobs;

    if (!jobs) {
      return [];
    }

    const edges: Edge[] = [];

    jobs.map((job) => {
      const jobDependencies = dependencies[job.name];

      if (!jobDependencies) {
        return;
      }

      jobDependencies.map((dependency) => {
        const dependentJob = jobs.find((job) => job.name === dependency);

        if (!dependentJob) {
          return;
        }

        edges.push({
          id: `e-${job.name}-${dependentJob.name}`,
          source: dependentJob.name,
          target: job.name,
        });
      });
    });

    return edges;
  }, [run, dependencies]);

  const { nodes, edges, totalWidth, totalHeight } = layoutService.getLayoutedElements(
    initialNodes,
    initialEdges,
    direction
  );

  React.useEffect(() => {
    const px = 40;
    const py = 80;

    updateFlowSize(workflowData.workflowId, {
      height: totalHeight + py,
      width: totalWidth + px,
    });
  }, [workflowData.workflowId, updateFlowSize, totalHeight, totalWidth]);

  return (
    <ReactFlowProvider>
      <GroupNodeFlow id={workflowData.workflowId} layoutedNodes={nodes} layoutedEdges={edges} />
    </ReactFlowProvider>
  );
};

interface GroupNodeFlowProps {
  id: string;
  layoutedNodes: Node[];
  layoutedEdges: Edge[];
}

const GroupNodeFlow: React.FC<GroupNodeFlowProps> = ({ id, layoutedNodes, layoutedEdges }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const { fitView } = useReactFlow();

  const updateNodes = React.useCallback(
    (newNodes: Node[]) => {
      setNodes((prevNodes) => {
        return newNodes.map((newNode) => {
          const existingNode = prevNodes.find((node) => node.id === newNode.id);
          return existingNode ? { ...existingNode, ...newNode } : newNode;
        });
      });
    },
    [setNodes]
  );

  const updateEdges = React.useCallback(
    (newEdges: Edge[]) => {
      setEdges((prevEdges) => {
        return newEdges.map((newEdge) => {
          const existingEdge = prevEdges.find((edge) => edge.id === newEdge.id);
          return existingEdge ? { ...existingEdge, ...newEdge } : newEdge;
        });
      });
    },
    [setEdges]
  );

  // Update nodes while preserving existing ones
  React.useEffect(() => {
    updateNodes(layoutedNodes);
  }, [layoutedNodes, updateNodes]);

  // Update edges while preserving existing ones
  React.useEffect(() => {
    updateEdges(layoutedEdges);
  }, [layoutedEdges, updateEdges]);

  // Fit view every time the component rerenders
  React.useEffect(() => {
    fitView();
  });

  const isLocked = true;
  return (
    <ReactFlow
      id={id}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      proOptions={{
        hideAttribution: true,
      }}
      nodeTypes={nodeTypes}
      zoomOnScroll={!isLocked}
      zoomOnPinch={!isLocked}
      zoomOnDoubleClick={!isLocked}
      edgesFocusable={!isLocked}
      nodesDraggable={!isLocked}
      nodesConnectable={!isLocked}
      nodesFocusable={!isLocked}
      elementsSelectable={!isLocked}
      maxZoom={1}
      minZoom={1}
      fitView={true}
    ></ReactFlow>
  );
};
