import React from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { supportedContentTypes } from "@/enums/supported-content-types";
import { DynamicWebhookBuilderContent } from "@/lib/webhooks/builder/dynamic-webhook-builder-content";

export type ContentType = keyof typeof supportedContentTypes;

export interface DynamicWebhookResponse {
  responseCode: string;
  delay: string;
  contentType: ContentType;
  responseData: string;
  repeat: number;
}

export interface DynamicWebhookBuilderProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

export const DynamicWebhookBuilder: React.FC<DynamicWebhookBuilderProps> = ({ dialogOpen, setDialogOpen }) => {
  return (
    <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
      <DialogContent
        className="max-w-2xl 2xl:max-w-2xl bg-white flex flex-col max-h-[90%] overflow-y-auto"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <div className="flex justify-center">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <h5 className="font-semibold tracking-tight">Webhook Builder</h5>
              <small>
                Sequential webhooks handle requests in a predefined order, delivering structured responses with
                specified response codes, content types and delay.
              </small>
            </div>
            <DynamicWebhookBuilderContent />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
