import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils/ui.util";
import { AlignJustify, LayoutGrid, Network, Settings } from "lucide-react";
import React from "react";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useQueryClient } from "@tanstack/react-query";
import { QuickOption } from "@/components/quick-option";
import { WorkflowsEnvelopeData } from "@/api/types/response";
import { useFormik } from "formik";
import { Checkbox } from "@/components/ui/checkbox";

const settingsOptions = {
  views: [
    {
      id: "flow",
      name: "Flow",
      icon: <Network size={20} />,
    },
    {
      id: "list",
      name: "List",
      icon: <AlignJustify size={20} />,
    },
    {
      id: "grid",
      name: "Grid",
      icon: <LayoutGrid size={20} />,
    },
  ],
};

interface UpdatePipelineSettings {
  defaultView: "flow" | "list" | "grid";
  selectedWorkflows: number[];
}

interface PipelineSettingsPopoverProps {
  owner: string;
  repo: string;
  workflowsEnvelopeData: WorkflowsEnvelopeData;
}

export const PipelineSettingsPopover: React.FC<PipelineSettingsPopoverProps> = ({
  owner,
  repo,
  workflowsEnvelopeData,
}) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const pipelineInfoMutation = ApiMutation.useUpdatePipelineInfo({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [["workflows"], { owner: owner, repo: repo }] });
      toast({ title: "Success!", description: data.message });
      setOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const selectedWorkflows = React.useMemo(() => {
    const selected = workflowsEnvelopeData.pipelineInfo.selectedWorkflows ?? "all";

    if (selected === "all") {
      return workflowsEnvelopeData.workflows.map((workflow) => workflow.id);
    }

    return selected;
  }, [workflowsEnvelopeData]);

  const updatePipelineSettings: UpdatePipelineSettings = {
    defaultView: workflowsEnvelopeData.pipelineInfo.defaultView ?? "flow",
    selectedWorkflows: selectedWorkflows,
  };

  const formik = useFormik({
    initialValues: updatePipelineSettings,
    onSubmit: (values) => {
      pipelineInfoMutation.mutate({
        owner: owner,
        repo: repo,
        defaultView: values.defaultView,
        selectedWorkflows:
          values.selectedWorkflows.length === workflowsEnvelopeData.workflows.length ? "all" : values.selectedWorkflows,
      });
    },
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="neu-flat" size="sm" className="gap-1 bg-secondary-300">
          Settings
          <Settings size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[420px] p-2"
        side="bottom"
        align="end"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-4">
            <div className="flex gap-2 items-center mt-2">
              <p className="text-sm font-semibold">Default view</p>
            </div>
            <div className="flex gap-2">
              {settingsOptions.views.map((view, index) => (
                <QuickOption
                  key={index}
                  name={view.name}
                  icon={view.icon}
                  className={cn(
                    "min-h-28",
                    formik.values.defaultView === view.id ? "bg-secondary-200" : "bg-transparent"
                  )}
                  handleOnClick={() => {
                    formik.setFieldValue("defaultView", view.id);
                  }}
                />
              ))}
            </div>

            <div className="flex gap-2 items-center mt-2">
              <p className="text-sm font-semibold">Selected workflows</p>
            </div>

            <div className="flex flex-col gap-2">
              {workflowsEnvelopeData.workflows.map((workflow, index) => (
                <div key={index} className="flex justify-between gap-2 items-center">
                  <p className="w-full font-mono text-xs">{workflow.name}</p>
                  <Checkbox
                    className="w-5 h-5"
                    checked={formik.values.selectedWorkflows.includes(workflow.id)}
                    onCheckedChange={
                      formik.values.selectedWorkflows.includes(workflow.id)
                        ? () =>
                            formik.setFieldValue(
                              "selectedWorkflows",
                              formik.values.selectedWorkflows.filter((id) => id !== workflow.id)
                            )
                        : () =>
                            formik.setFieldValue("selectedWorkflows", [...formik.values.selectedWorkflows, workflow.id])
                    }
                  />
                </div>
              ))}
            </div>

            <Button
              type="submit"
              variant="neu-flat"
              size="sm"
              disabled={pipelineInfoMutation.isPending}
              className="bg-tertiary-300 gap-1"
            >
              {pipelineInfoMutation.isPending ? <LoadingSpinner message={"Updating"} /> : "Update"}
            </Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  );
};
