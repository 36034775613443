import React from "react";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { cn } from "@/utils/ui.util";
import { Check, Trash2 } from "lucide-react";
import { useSecretsManager } from "@/lib/tools/secrets-manager/useSecretsManager";
import { HoverInfo } from "@/components/hover-info";
import { UpdateSecretDialog } from "@/lib/tools/secrets-manager/update-secret-dialog";
import { SecretsLocalStorageData, autodetectSecretType } from "@/lib/tools/secrets-manager/secrets-manager-utils";
import { Badge } from "@/components/ui/badge";
import { Textarea } from "@/components/ui/textarea";

const validationSchema = Yup.object().shape({
  value: Yup.string().required("Value is required"),
});

interface Secret {
  value: string;
}

export const SecretsManagerPopover: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const { secrets, storeSecret, deleteSecret } = useSecretsManager();

  const initialValues: Secret = {
    value: "",
  };

  const findUniqueKey = (prefix: string, keys: string[]): string => {
    let key = prefix + "_01";
    let count = 1;

    while (keys.includes(key)) {
      key = prefix + "_" + count.toString().padStart(2, "0");
      count++;
    }

    return key;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: Secret, { resetForm }) => {
      const type = await autodetectSecretType(values.value);

      const key = findUniqueKey(
        "SECRET",
        secrets.map((secret) => secret.key)
      );

      const newSecret: SecretsLocalStorageData = {
        key: key,
        value: values.value,
        type: type,
        created: new Date().toISOString(),
      };

      storeSecret(newSecret);
      resetForm();
    },
  });

  return (
    <div className="flex items-center space-x-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="neu-flat" size="sm" className="bg-secondary-300">
            Secrets ({secrets.length})
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[400px] p-2"
          side="bottom"
          align="end"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <div className="flex flex-col gap-2">
            {secrets.length > 0 && <p className="text-sm font-semibold mt-2">Your stored secrets</p>}
            <div className="text-xs">
              Secrets stay don't leave your browser and are used for signing, verifying and hashing
            </div>
            <div>
              {secrets.map((secret) => (
                <div className="flex justify-between items-center" key={secret.key}>
                  <div key={secret.key} className="font-mono font-semibold text-sm">
                    {secret.key}
                  </div>
                  <div className="flex items-center">
                    <div key={secret.type}>
                      <Badge variant="outline">{secret.type.toUpperCase()}</Badge>
                    </div>
                    <UpdateSecretDialog keyProp={secret.key} value={secret.value} />
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        deleteSecret(secret);
                      }}
                      className="pl-0"
                    >
                      <Trash2 size={16} className="text-red-600" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div className={cn("flex flex-col gap-2 w-full", secrets.length > 0 && "mt-2")}>
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold">New Secret</p>
                  <HoverInfo
                    message={
                      "Secrets are stored locally in your browser. They are not shared with anyone and are not stored on our servers. Please do not use production secrets."
                    }
                  />
                </div>
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-1">
                      <Textarea
                        variant={"neu-flat"}
                        placeholder="Secret"
                        id="value"
                        name="value"
                        value={formik.values.value}
                        onChange={formik.handleChange}
                        className={cn(formik.touched.value && formik.errors.value && "bg-red-200")}
                      />

                      <div className="flex justify-end">
                        <Button type="submit" variant={"neu-flat"} size="sm" className="bg-tertiary-300 w-12">
                          <Check size={16} />
                        </Button>
                      </div>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
