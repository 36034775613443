import React from "react";
import { StateTransformation } from "./state-transformation";
import { SecretsLocalStorageData } from "@/lib/tools/secrets-manager/secrets-manager-utils";

export type ValidTransformation = {
  transformation: StateTransformation;
  valid: number;
};

type ValidationTag =
  | "string"
  | "number"
  | "request"
  | "response"
  | "http-auth"
  | "date"
  | "url"
  | "expires"
  | "expires-multi"
  | "jwt";
export type TransformationTag =
  | "string"
  | "number"
  | "request"
  | "response"
  | "http-auth"
  | "http-headers"
  | "email-mime"
  | "json"
  | "xml"
  | "url"
  | "blob"
  | "image"
  | "jwt"
  | "multi-part"
  | "date"
  | "auto-detect";
type DefaultDisplayMode = "string" | "json" | "hidden" | string;

export type StateConfig = {
  stringValue: boolean;
  jsonValue: boolean;
  customDisplay?: null | string[];
  transformations: "all" | "tags-only" | "none";
  validations: "all" | "none" | "tags-only";
  validationTags?: ValidationTag[];
  transformationTags?: TransformationTag[];
  defaultDisplayMode: DefaultDisplayMode;
};

type ListStringState = {
  type: "string[]";
  value: string[];
};

export type StateContext = {
  key?: string; // key for jwt
};

export type SnakeContext = {
  secrets?: SecretsLocalStorageData[];
};

export type ListState = ListStringState;

export abstract class State<TValue = unknown> {
  stateContext?: StateContext;
  constructor(readonly value: TValue) {}

  abstract stringValue(): string;

  asList(): ListState | undefined {
    return undefined;
  }

  jsonValue(): object {
    throw new Error("Method not implemented.");
  }

  toReactNode(displayMode: string): React.ReactNode {
    throw new Error(`Method not implemented for displayMode ${displayMode}`);
  }

  editMode(_: (value: TValue) => void): null | React.ReactNode {
    return null;
  }

  config(): StateConfig {
    return {
      transformations: "all", // what type of transformations are available for this state
      validations: "all", // what type of validations are available for this state
      stringValue: true, // whether the state can be represented as a string
      jsonValue: false, // whether the state can be represented as a json object
      customDisplay: null, // whether the state has a custom display,
      defaultDisplayMode: "hidden", // the default display mode for the state
    };
  }

  isValid(): boolean {
    return true;
  }

  static is<T extends new (...args: never[]) => State>(this: T, state: State): state is InstanceType<T> {
    return state instanceof this;
  }
}
