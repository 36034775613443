import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useToast } from "@/components/ui/use-toast";
import { Globe, Group, Lock } from "lucide-react";
import React from "react";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useQueryClient } from "@tanstack/react-query";
import { CopyableInput } from "@/components/copyable-input";
import { HoverInfo } from "@/components/hover-info";

interface PipelineVisibilityPopoverProps {
  owner: string;
  repo: string;
  isPublic: boolean;
  pipelineId?: string;
}

export const PipelineVisibilityPopover: React.FC<PipelineVisibilityPopoverProps> = ({
  owner,
  repo,
  isPublic,
  pipelineId,
}) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const pipelineVisibilityMutation = ApiMutation.useUpdatePipelineVisibility({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [["workflows"], { owner: owner, repo: repo }] });
      toast({ title: "Success!", description: data.message });
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <div className="flex gap-1">
        <PopoverTrigger asChild>
          <Button variant="neu-flat" size="sm" scheme="black" className="gap-1">
            Share
            <Group size={16} />
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent
        className="w-[360px] p-2"
        side="bottom"
        align="end"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center mt-2">
            <p className="text-sm font-semibold">Share pipeline</p>
            <HoverInfo
              message={
                "Read-only access to the pipeline. Anyone with the link can view the pipeline and its workflows."
              }
            />
          </div>

          {isPublic && (
            <>
              <CopyableInput value={`${window.location.origin}/pipelines/view/repo/${pipelineId}`} />
              <Button
                variant="neu-flat"
                size="sm"
                className="bg-tertiary-300 gap-1"
                onClick={() => pipelineVisibilityMutation.mutate({ owner, repo, isPublic: false })}
                disabled={pipelineVisibilityMutation.isPending}
              >
                {pipelineVisibilityMutation.isPending ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    Make Private
                    <Lock size={16} />
                  </>
                )}
              </Button>
            </>
          )}

          {!isPublic && (
            <Button
              variant="neu-flat"
              size="sm"
              className="bg-tertiary-300 gap-1"
              onClick={() => pipelineVisibilityMutation.mutate({ owner, repo, isPublic: true })}
              disabled={pipelineVisibilityMutation.isPending}
            >
              {pipelineVisibilityMutation.isPending ? (
                <LoadingSpinner />
              ) : (
                <>
                  Make Public
                  <Globe size={16} />
                </>
              )}
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
