import { CanTransformResult } from "../state-transformation";

export const parseStringToJson = (value: string): object | null => {
  try {
    return JSON.parse(value);
  } catch (_) {
    return null;
  }
};

export const checkType = (variable: any): string => {
  if (typeof variable === "string") {
    return "string";
  } else if (typeof variable === "number") {
    return "number";
  } else if (Array.isArray(variable)) {
    return "array";
  } else if (typeof variable === "object" && variable !== null) {
    return "object";
  } else {
    return "unknown";
  }
};

export const calculateTransformResult = (result: any) => {
  const t = checkType(result);
  switch (t) {
    case "string":
      return CanTransformResult.Maybe;
    case "number":
      return CanTransformResult.Maybe;
    case "array":
      return CanTransformResult.Likely;
    case "object":
      return CanTransformResult.Yes;
    default:
      return CanTransformResult.No;
  }
};
