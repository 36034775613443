import React from "react";
import { format } from "date-fns/format";
import { useAccountUsers } from "@/api/queries";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { CreateAccountUser } from "@/lib/settings/elements/create-account-user";
import { LoadingSpinner } from "@/components/loading-spinner";
import { EditAccountUser } from "@/lib/settings/elements/edit-account-user";
import { EditAccountUserPassword } from "@/lib/settings/elements/edit-account-user-password";
import { DeleteAccountUser } from "@/lib/settings/elements/delete-account-user";
import { CheckCircle2, XCircle } from "lucide-react";

export const AccountUsers: React.FC = () => {
  const { isPending, isError, data } = useAccountUsers();

  return (
    <>
      <div className="space-y-2">
        <div className="flex justify-end">
          <CreateAccountUser />
        </div>
        {isPending && (
          <div className="text-center text-sm font-medium">
            <LoadingSpinner message="Loading..." />
          </div>
        )}
        {isError && <div>error</div>}
        {!isPending && !isError && (
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">Role</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Verified</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead className="text-right"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((user) => (
                  <TableRow key={user.email}>
                    <TableCell className="font-medium">
                      {user.role === "owner" ? (
                        <Badge variant="default" className="flex justify-center w-16">
                          {user.role.toUpperCase()}
                        </Badge>
                      ) : (
                        <Badge variant="secondary" className="flex justify-center w-16">
                          {user.role.toUpperCase()}
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.verified ? (
                        <CheckCircle2 size={18} className="text-green-500" />
                      ) : (
                        <XCircle size={18} className="text-red-500" />
                      )}
                    </TableCell>
                    <TableCell className="text-nowrap">{format(new Date(user.created), "PP")}</TableCell>
                    <TableCell>
                      <div className="flex justify-end">
                        <EditAccountUser user={user} />
                        <EditAccountUserPassword user={user} />
                        <DeleteAccountUser user={user} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};
