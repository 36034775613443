import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ApiMutation } from "@/api";
import { useToast } from "@/components/ui/use-toast";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useFormik } from "formik";
import { defaultError } from "@/lib/auth/utils/default-error-message";

export const WaitlistForm: React.FC = () => {
  const { toast } = useToast();

  const loginMutation = ApiMutation.useJoinWaitlist({
    onSuccess: (data) => {
      toast({ title: "Success!", description: data.message });
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      loginMutation.mutate({
        email: values.email,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex gap-2">
        <Input
          id="email"
          placeholder="Email Address"
          type="email"
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect="off"
          required
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Button type="submit" disabled={loginMutation.isPending} className="bg-primary-300">
          {loginMutation.isPending ? <LoadingSpinner message={"Joining"} /> : "Join waitlist"}
        </Button>
      </div>
    </form>
  );
};
