import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-react";
import { ValidationResult } from "@/utils/parsing/state-validator";
import { cn } from "@/utils/ui.util";

type ValidationStatus = "valid" | "query" | "invalid";

function getValidationStatus(objects: ValidationResult[]): ValidationStatus {
  const results = objects.map((obj) => obj.result);

  if (results.includes("invalid")) {
    return "invalid";
  } else if (results.includes("query")) {
    return "query";
  } else {
    return "valid";
  }
}

interface ValidationsPopoverProps {
  validations: ValidationResult[];
}

export const ValidationsPopover: React.FC<ValidationsPopoverProps> = ({ validations }) => {
  const status = getValidationStatus(validations);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="neu-flat"
          size="sm"
          className={cn(
            status === "valid" && "bg-status-success",
            status === "query" && "bg-status-pending",
            status === "invalid" && "bg-status-error",
            "gap-1"
          )}
        >
          Validations
          <ChevronDown className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start">
        <div className="flex flex-col gap-1">
          {validations.map((validation, i) => (
            <div key={i}>{validation.displayNode}</div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
