import React from "react";

import "@xyflow/react/dist/style.css";
import { useLatestRunJobs } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";
import { WorkflowRunJobs } from "@/api/types/response";
import { Navigate, useNavigate } from "react-router-dom";
import { DynamicContentWrapper, WorkflowData } from "@/lib/pipelines/utils/dynamic-content-wrapper";
import { JobTimer } from "@/lib/pipelines/utils/timer";
import { cn } from "@/utils/ui.util";
import { Link } from "react-router-dom";
import { getJobStatusColor, getJobStatusIcon, getParentNodeData } from "@/lib/pipelines/utils/pipeline.utils";
import { useGroupNodeSettings } from "@/lib/pipelines/utils/group-node-manager/useGroupNodeSettings";
import { Button } from "@/components/ui/button";

export const GridWorkflow: React.FC<WorkflowData> = (workflowData) => {
  const navigate = useNavigate();

  const {
    isPending,
    isError,
    data: response,
  } = useLatestRunJobs({
    owner: workflowData.owner,
    repo: workflowData.repo,
    workflowId: workflowData.workflowId,
    resourceJwt: workflowData.workflowResourceJwt,
  });

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && (
        <div className="flex flex-col items-center gap-1 justify-center text-center">
          <div className="text-sm text-gray-600">Oops! Something went wrong.</div>
          <div className="text-sm text-gray-600">
            <Button
              size="sm"
              scheme="link"
              variant="ghost"
              className="hover:bg-transparent h-full p-0"
              onClick={() => navigate(0)}
            >
              Try Again
            </Button>
          </div>
        </div>
      )}
      {!isPending && !isError && (
        <>
          {response.status === "reject" && <Navigate to="/pipelines/auth" replace={true} />}
          {response.status === "success" && (
            <DynamicContentWrapper
              run={response.data.runJobs}
              dependencies={response.data.dependencies}
              workflowData={workflowData}
              latestRunResourceJwt={response.data.resourceJwt}
            >
              {({ run, workflowData }) => <GroupNodeContent run={run} workflowData={workflowData} />}
            </DynamicContentWrapper>
          )}
        </>
      )}
    </>
  );
};

interface GroupNodeContentProps {
  run: WorkflowRunJobs;
  workflowData: WorkflowData;
}

const GroupNodeContent: React.FC<GroupNodeContentProps> = ({ run }) => {
  const { updateParentColor, updateParentBadge, updateRun } = useGroupNodeSettings();

  React.useEffect(() => {
    const { parentNodeColor, parentBadge } = getParentNodeData(run.jobs);
    updateParentColor(parentNodeColor);
    updateParentBadge(parentBadge);
    updateRun(run);
  }, [run, updateParentColor, updateParentBadge, updateRun]);

  return (
    <>
      {run.total_count === 0 && <div className="text-sm text-gray-600 mx-auto">No Runs Found</div>}
      {run.total_count > 0 && (
        <>
          {run.jobs.map((job, index) => (
            <Link
              key={index}
              to={job.html_url ?? ""}
              target="_blank"
              className="no-underline text-secondary-950 w-full"
            >
              <div
                className={cn(
                  "flex gap-2 items-center p-2 w-full rounded-lg justify-between neu",
                  getJobStatusColor(job.status, job.conclusion)
                )}
              >
                <div className="flex gap-2 items-center">
                  {getJobStatusIcon(job.status, job.conclusion)}
                  <p className="font-bold">{job.name}</p>
                </div>
                <div className="text-xs">
                  <JobTimer startTime={job.started_at} endTime={job.completed_at} />
                </div>
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};
