interface JobTimelineProps {
  workflowDuration: number;
}

export const JobTimeline: React.FC<JobTimelineProps> = ({ workflowDuration }) => {
  return (
    <div className="relative w-full">
      {Array.from({ length: Math.ceil(workflowDuration / 60) }, (_, index) => {
        const time = index * 60;
        const leftPosition = (time / workflowDuration) * 100;

        return (
          <div key={index} className="absolute border-l border-gray-400" style={{ left: `${leftPosition}%` }}>
            <div className="text-xs text-gray-600 transform -translate-x-1/2">{time}s</div>
          </div>
        );
      })}
    </div>
  );
};
