import { Input } from "@/components/ui/input";
import { cn } from "@/utils/ui.util";
import React from "react";
import { Label } from "@/components/ui/label";

type TransformationOptionsCheckboxProps<T extends object> = {
  options: T;
  defaultValue: boolean;
  selectedKey: keyof T;
  onChange: (options: T) => void;
  className?: string;
  label?: string;
};

export const TransformationOptionsCheckbox: React.FC<TransformationOptionsCheckboxProps<any>> = ({
  options,
  defaultValue,
  selectedKey,
  onChange,
  className,
  label,
}) => {
  const checkboxId = React.useId();
  return (
    <div className="neu-flat flex p-2 gap-2 rounded-md bg-zinc-300 items-center min-h-11">
      {label && (
        <Label className={cn("text-nowrap", className)} htmlFor={checkboxId}>
          {label}
        </Label>
      )}

      <Input
        type="checkbox"
        variant={"neu-flat"}
        id={checkboxId}
        className={cn("h-6 w-6 whitespace-nowrap", className)}
        checked={options[selectedKey] as boolean}
        defaultChecked={defaultValue}
        onChange={(e) => onChange({ ...options, [selectedKey]: e.target.checked })}
      />
    </div>
  );
};
