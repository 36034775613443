import React from "react";

import "@xyflow/react/dist/style.css";
import { AlignJustify, LayoutGrid, Network } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { GridViewDashboard } from "@/lib/pipelines/grid-view/grid-view-dashboard";
import { ListViewDashboard } from "@/lib/pipelines/list-view/list-view-dashboard";
import { FlowViewDashboard } from "@/lib/pipelines/flow-view/flow-view-dashboard";
import { PipelinePanel } from "@/api/types/response";
import { usePipelinePanel } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { PanelVisibilityPopover } from "@/lib/pipelines/config/panel-visibility-popover";
import { usePipelineState } from "@/lib/pipelines/config/usePipelineState";
import { DynamicPanelWrapper } from "@/lib/pipelines/utils/dynamic-panel-wrapper";
import { panelService } from "@/lib/pipelines/services/panel.service";
import { PanelSettingsPopover } from "@/lib/pipelines/config/panel-settings-popover";

interface PanelDashboardProps {
  panelId: string;
}

export const PanelDashboard: React.FC<PanelDashboardProps> = ({ panelId }) => {
  const { readOnly } = usePipelineState();

  const {
    isPending,
    isError,
    data: response,
  } = usePipelinePanel(
    {
      panelId: panelId,
    },
    // Refresh every 30 minutes in public view
    readOnly
      ? {
          refetchInterval: 1000 * 60 * 30, // 30 minutes
          refetchIntervalInBackground: true,
        }
      : {}
  );

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <DynamicPanelWrapper
          wsId={response.panel.panelId}
          resourceJwt={response.resourceJwt}
          pipelinePanel={response.panel}
        >
          {({ pipelinePanel, workflowResourceJwt }) =>
            readOnly ? (
              <PublicView pipelinePanel={pipelinePanel} workflowResourceJwt={workflowResourceJwt} />
            ) : (
              <PrivateView pipelinePanel={pipelinePanel} workflowResourceJwt={workflowResourceJwt} />
            )
          }
        </DynamicPanelWrapper>
      )}
    </>
  );
};

interface ViewProps {
  pipelinePanel: PipelinePanel;
  workflowResourceJwt: string;
}

const PrivateView: React.FC<ViewProps> = ({ pipelinePanel, workflowResourceJwt }) => {
  const [selectedView, setSelectedView] = React.useState<string>(pipelinePanel.defaultView ?? "flow");

  const groupedWorkflows = React.useMemo(() => {
    return panelService.groupWorkflowsByOwnerRepo(pipelinePanel.selectedWorkflows);
  }, [pipelinePanel.selectedWorkflows]);

  React.useEffect(() => {
    setSelectedView(pipelinePanel.defaultView ?? "flow");
  }, [pipelinePanel.defaultView]);

  return (
    <>
      <div className="flex justify-between">
        <h6 className="font-bold font-mono">{pipelinePanel.panelName}</h6>
        <div className="flex gap-1">
          <PanelSettingsPopover pipelinePanel={pipelinePanel} />
          <PanelVisibilityPopover isPublic={pipelinePanel.isPublic} panelId={pipelinePanel.panelId} />
        </div>
      </div>
      <Tabs value={selectedView} onValueChange={(value) => setSelectedView(value)}>
        <div className="flex justify-between items-center">
          <TabsList className="grid grid-cols-3 bg-transparent">
            <TabsTrigger
              value="flow"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              <Network size={14} />
            </TabsTrigger>
            <TabsTrigger
              value="list"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              <AlignJustify size={14} />
            </TabsTrigger>
            <TabsTrigger
              value="grid"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              <LayoutGrid size={14} />
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="flow">
          <FlowViewDashboard
            title={pipelinePanel.panelName}
            selectedWorkflows={groupedWorkflows}
            workflowResourceJwt={workflowResourceJwt}
          />
        </TabsContent>
        <TabsContent value="grid">
          <GridViewDashboard
            title={pipelinePanel.panelName}
            selectedWorkflows={groupedWorkflows}
            workflowResourceJwt={workflowResourceJwt}
          />
        </TabsContent>
        <TabsContent value="list">
          <ListViewDashboard
            title={pipelinePanel.panelName}
            selectedWorkflows={groupedWorkflows}
            workflowResourceJwt={workflowResourceJwt}
          />
        </TabsContent>
      </Tabs>
    </>
  );
};

const PublicView: React.FC<ViewProps> = ({ pipelinePanel, workflowResourceJwt }) => {
  const selectedView = React.useMemo(() => {
    return pipelinePanel.defaultView ?? "flow";
  }, [pipelinePanel]);

  const groupedWorkflows = React.useMemo(() => {
    return panelService.groupWorkflowsByOwnerRepo(pipelinePanel.selectedWorkflows);
  }, [pipelinePanel.selectedWorkflows]);

  return (
    <>
      {selectedView === "flow" && (
        <FlowViewDashboard
          title={pipelinePanel.panelName}
          selectedWorkflows={groupedWorkflows}
          workflowResourceJwt={workflowResourceJwt}
        />
      )}

      {selectedView === "grid" && (
        <GridViewDashboard
          title={pipelinePanel.panelName}
          selectedWorkflows={groupedWorkflows}
          workflowResourceJwt={workflowResourceJwt}
        />
      )}

      {selectedView === "list" && (
        <ListViewDashboard
          title={pipelinePanel.panelName}
          selectedWorkflows={groupedWorkflows}
          workflowResourceJwt={workflowResourceJwt}
        />
      )}
    </>
  );
};
