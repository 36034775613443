import { cn } from "@/utils/ui.util";
import { ListState, State, StateConfig } from "../state";
import { analyzeString } from "../utils/string-utils";

export class StringState extends State<string> {
  readonly json: object | null;

  constructor(
    value: string,
    readonly stringType: string | null = null
  ) {
    super(value);

    try {
      this.json = JSON.parse(value);
    } catch {
      this.json = null;
    }

    this.stringStats = analyzeString(this.value);
  }

  asList(): ListState | undefined {
    if (this.stringStats.length > 0) return { type: "string[]", value: this.value.split("\n") };
    return undefined;
  }

  stringStats: ReturnType<typeof analyzeString>;

  jsonValue(): object {
    if (this.json === null) throw new Error("Not JSON");
    return this.json;
  }

  stringValue(): string {
    return this.value;
  }

  config(): StateConfig {
    const isString = this.stringType === null || this.stringType === "string";
    const defaultName = isString ? "string" : this.stringType;
    const customDisplay = isString ? ["string"] : [defaultName];
    return {
      transformations: "tags-only",
      transformationTags: ["string", "auto-detect"],
      validations: "none",
      stringValue: true,
      jsonValue: this.json !== null,
      customDisplay: customDisplay,
      defaultDisplayMode: defaultName,
    };
  }

  toReactNode(_: string) {
    return (
      <div>
        <pre
          className={cn(
            "whitespace-pre-line select-all",
            this.stringStats.lines === 1 && this.stringStats.length < 70 ? "text-xl" : ""
          )}
        >
          {this.stringValue()}
        </pre>
        <div className="my-1 p-1 bg-secondary-100 text-xs">
          <span className="mr-2">chars: {this.stringStats.length}</span>
          {this.stringStats.lines > 1 && <span>lines: {this.stringStats.lines}</span>}
        </div>
      </div>
    );
  }
}
