import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { StringState } from "../states";
import { BlobState } from "../states/blob.state";
import { encode as encodeBase64 } from "base64-arraybuffer";
import { ToolsIcons } from "./common-options/options.transformations";
import { encode as encodeHtml } from "html-entities";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";

const optionNames = ["url", "html", "base64", "base64url"] as const;
type EncodingAlgorithm = (typeof optionNames)[number];

type EncodingMode = {
  selected: EncodingAlgorithm;
  values: Record<string, EncodingAlgorithm>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class EncodeTransformation extends StateTransformation<StringState, EncodingMode> {
  constructor() {
    super("encode", "Encode", ["string", "number", "blob", "image"]);
  }

  async getOptions(_: State, options?: EncodingMode): Promise<EncodingMode | undefined> {
    return {
      selected: options?.selected || "base64",
      values: Object.fromEntries(optionNames.map((name) => [name, name])),
      label: "Encode",
    };
  }

  toBase64Url(base64: string) {
    return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
  }

  icon(): ToolsIcons {
    return "encode";
  }

  async blobToBase64(blob: Blob) {
    return encodeBase64(await blob.arrayBuffer());
  }
  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) return CanTransformResult.Yes;
    if (BlobState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: EncodingMode): Promise<StringState> {
    if (state.config().stringValue) {
      switch (options.selected) {
        case "base64":
          return new StringState(btoa(state.stringValue()), "base64");
        case "base64url":
          return new StringState(this.toBase64Url(btoa(state.stringValue())), "base64-url");
        case "url":
          return new StringState(encodeURIComponent(state.stringValue()), "url-encoded");
        case "html":
          return new StringState(encodeHtml(state.stringValue()), "html-encoded");
      }
    }

    if (BlobState.is(state)) {
      switch (options.selected) {
        case "base64":
          return new StringState(await this.blobToBase64(state.value));
        case "base64url":
          return new StringState(this.toBase64Url(await this.blobToBase64(state.value)));
        case "url":
          return new StringState(encodeURIComponent(await state.value.text()));
        case "html":
          return new StringState(encodeHtml(await state.value.text(), { mode: "extensive", level: "xml" }));
      }
    }

    return new StringState(btoa(state.stringValue()));
  }

  getOptionsReactNode(options: EncodingMode, onChange: (options: EncodingMode) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange as any} options={options} />;
  }
}
export default new EncodeTransformation();
