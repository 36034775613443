import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { CircleOffIcon } from "lucide-react";

export class EmptyState extends State<string> {
  constructor(readonly reason = "") {
    super(reason);
  }

  stringValue(): string {
    return "";
  }

  config(): StateConfig {
    return {
      transformations: "none",
      validations: "none",
      stringValue: false,
      jsonValue: false,
      customDisplay: ["Empty"],
      defaultDisplayMode: "Empty",
    };
  }

  toReactNode(_: string): ReactNode {
    return (
      <div className="flex flex-col text-orange-400">
        <div className="flex font-bold">
          <CircleOffIcon className="w-6 h-6  mr-3" />
          {this.reason}
        </div>
      </div>
    );
  }
}
