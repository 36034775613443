import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { DateTime } from "luxon";
import { HeadersTable } from "@/components/headers-table";
import { ExpiresInfo, StateExpires } from "../state.expires";

type UrlDetails = {
  url: string;
  hash?: string;
  host?: string;
  username?: string;
  password?: string;
  pathname: string;
  port?: string;
  protocol: string;
  searchParamsParsed: Record<string, string>;
  expires?: Date;
};

type UrlDetailsJson = Omit<UrlDetails, "searchParams">;

export class UrlState extends State<URL> implements StateExpires {
  constructor(value: URL) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "all",
      transformationTags: ["url"],
      validations: "all",
      validationTags: ["url", "expires"],
      stringValue: true,
      jsonValue: true,
      customDisplay: ["URL"],
      defaultDisplayMode: "URL",
    };
  }

  getExpiresInfo(): ExpiresInfo {
    return {
      validFrom: undefined,
      validTo: this.internalObject()?.expires,
      resourceName: "AWS URL",
      expiresRequired: false,
    };
  }

  internalObject(): UrlDetails {
    const awsExpires = this.value.searchParams.get("X-Amz-Expires");
    const awsDate = this.value.searchParams.get("X-Amz-Date");

    const obj: UrlDetails = {
      url: this.value.toString(),
      hash: this.value.hash,
      host: this.value.host,
      username: this.value.username,
      password: this.value.password,
      pathname: this.value.pathname,
      port: this.value.port,
      protocol: this.value.protocol,
      searchParamsParsed: Object.fromEntries(this.value.searchParams),
    };

    if (awsExpires && awsDate) {
      const expires = DateTime.fromISO(awsDate).plus({ seconds: parseInt(awsExpires) });
      obj.expires = expires.toJSDate();
    }

    return obj;
  }
  stringValue(): string {
    return this.value.toString();
  }

  jsonValue(): UrlDetailsJson {
    return this.internalObject();
  }
  toReactNode(_: string): ReactNode {
    const val = this.internalObject();
    const displayObj: any = {
      pathname: val.pathname,
      protocol: val.protocol,
    };

    if (val.hash) displayObj.hash = val.hash;
    if (val.host) displayObj.host = val.host;
    if (val.username) displayObj.username = val.username;
    if (val.password) displayObj.password = val.password;
    if (val.port) displayObj.port = val.port;

    if (val.expires) {
      displayObj["AWS expires"] = val.expires?.toISOString();
      displayObj["AWS expires relative"] = DateTime.fromJSDate(val.expires).toRelative();
    }

    return (
      <>
        <a href={val.url} target="_blank" rel="noreferrer" className="text-blue-500 block  mx-5 truncate">
          {val.url}
        </a>
        <HeadersTable headers={displayObj} type="custom" />
        {this.value.searchParams.toString().length > 0 && (
          <HeadersTable title="Query" headers={val.searchParamsParsed} type="custom" />
        )}
      </>
    );
  }
}
