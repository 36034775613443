import { Separator } from "@/components/ui/separator";
import { WorkflowStatus } from "@/lib/pipelines/list-view/elements/workflow-status";
import React from "react";
import { PanelWorkflowSelection } from "@/api/types/common";

interface ListViewDashboardProps {
  title: string;
  selectedWorkflows: PanelWorkflowSelection;
  workflowResourceJwt: string;
}

export const ListViewDashboard: React.FC<ListViewDashboardProps> = ({ selectedWorkflows, workflowResourceJwt }) => {
  return (
    <div className="neu rounded-lg bg-white py-4">
      <div className="flex flex-col gap-4">
        {Object.keys(selectedWorkflows).map((ownerRepo, index) => (
          <div key={ownerRepo} className="flex flex-col gap-4">
            <div className="w-full px-4">
              <h6 className="text-sm font-semibold">{ownerRepo}</h6>
            </div>
            {selectedWorkflows[ownerRepo].map((workflow) => (
              <div key={workflow.workflowId} className="w-full px-4">
                <WorkflowStatus
                  title={workflow.workflowName}
                  workflowId={workflow.workflowId.toString()}
                  owner={workflow.owner}
                  repo={workflow.repo}
                  htmlUrl={workflow.htmlUrl}
                  workflowResourceJwt={workflowResourceJwt}
                />
              </div>
            ))}
            {index < Object.keys(selectedWorkflows).length - 1 && <Separator />}
          </div>
        ))}
      </div>
    </div>
  );
};
