import { Edge, Node, Position } from "@xyflow/react";
import dagre from "dagre";

const defaultNodeWidth = 220;
const defaultNodeHeight = 42;

export interface LayoutService {
  getLayoutedElements: (nodes: Node[], edges: Edge[], direction?: string) => { nodes: Node[]; edges: Edge[] };
}

export class LayoutServiceImpl implements LayoutService {
  getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "TB") => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      const width = node.width ?? defaultNodeWidth;
      const height = node.height ?? defaultNodeHeight;
      dagreGraph.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const newNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const newNode: Node = {
        ...node,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        position: {
          x: nodeWithPosition.x - (node.width ?? defaultNodeWidth) / 2,
          y: nodeWithPosition.y - (node.height ?? defaultNodeHeight) / 2,
        },
      };

      return newNode;
    });

    const totalWidth = Math.max(...newNodes.map((node) => node.position.x + (node.width ?? defaultNodeWidth)));
    const totalHeight = Math.max(...newNodes.map((node) => node.position.y + (node.height ?? defaultNodeHeight)));

    return { nodes: newNodes, edges, totalWidth, totalHeight };
  };
}

export const layoutService = new LayoutServiceImpl();
