import React from "react";

import "@xyflow/react/dist/style.css";
import { useParams } from "react-router-dom";
import { PipelinesDashboard } from "@/lib/pipelines/pipelines-dashboard";
import { PipelineStateProvider } from "@/lib/pipelines/config/pipeline-state-provider";

export const PrivatePipelinesDashboard: React.FC = () => {
  const { owner, repo } = useParams<{ owner: string; repo: string }>();

  return (
    <PipelineStateProvider readOnly={false}>
      <PipelinesDashboard owner={owner!} repo={repo!} />
    </PipelineStateProvider>
  );
};
