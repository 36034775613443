import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 } from "lucide-react";
import React from "react";

interface DeletePipelinePanelDialogProps {
  panelName: string;
  panelId: string;
}

export const DeletePipelinePanelDialog: React.FC<DeletePipelinePanelDialogProps> = ({ panelName, panelId }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const deletePipelinePanelMutation = ApiMutation.useDeletePipelinePanel({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [["pipelines", "panel", "list"]] });
      toast({ title: "Success!", description: data.message });
      setOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <Trash2 size={18} className="text-red-600" />
        </Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        className="max-w-[400px]"
      >
        <DialogTitle>{panelName}</DialogTitle>
        <DialogDescription>Are you sure you want to delete this panel?</DialogDescription>
        <Button
          variant="neu-flat"
          size="sm"
          scheme="black"
          className="hover:bg-red-800"
          disabled={deletePipelinePanelMutation.isPending}
          onClick={() => {
            deletePipelinePanelMutation.mutate({
              panelId: panelId,
            });
          }}
        >
          {deletePipelinePanelMutation.isPending ? <LoadingSpinner message="Deleting" /> : "Delete"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
