import React from "react";
import { GroupNodeSettingsContext } from "@/lib/pipelines/utils/group-node-manager/group-node-provider";

export const useGroupNodeSettings = () => {
  const context = React.useContext(GroupNodeSettingsContext);
  if (!context) {
    throw new Error("useGroupNodeSettings must be used within a GroupNodeSettingsProvider");
  }
  return context;
};
