import React from "react";
import { format } from "date-fns/format";
import { useAdminAccountUsers } from "@/api/queries";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { LoadingSpinner } from "@/components/loading-spinner";
import { CheckCircle2, XCircle } from "lucide-react";
import { AdminEditAccountUser } from "@/lib/admin/elements/admin-edit-account-user";
import { AdminEditAccountUserPassword } from "@/lib/admin/elements/admin-edit-account-user-password";
import { AdminDeleteAccountUser } from "@/lib/admin/elements/admin-delete-account-user";

const customerId = "user"; // Fix this! We should list all customers first

export const AdminAccountUsers: React.FC = () => {
  const { isPending, isError, data } = useAdminAccountUsers({
    customerIdentifier: customerId,
  });

  return (
    <>
      <div className="space-y-2">
        <div className="flex justify-between">
          <h6 className="font-semibold tracking-tight">Active Users</h6>
        </div>
        {isPending && (
          <div className="text-center text-sm font-medium">
            <LoadingSpinner message="Loading..." />
          </div>
        )}
        {isError && <div>error</div>}
        {!isPending && !isError && (
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">Role</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Customer ID</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Verified</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Tier</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead className="text-right"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((user) => (
                  <TableRow key={user.email}>
                    <TableCell className="font-medium">
                      {user.role === "admin" ? (
                        <Badge variant="default" className="flex justify-center w-16">
                          {user.role.toUpperCase()}
                        </Badge>
                      ) : (
                        <Badge variant="secondary" className="flex justify-center w-16">
                          {user.role.toUpperCase()}
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.customerIdentifier}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.verified ? (
                        <CheckCircle2 size={18} className="text-green-500" />
                      ) : (
                        <XCircle size={18} className="text-red-500" />
                      )}
                    </TableCell>
                    <TableCell>
                      {user.status === "active" && (
                        <Badge variant="outline" className="w-20 flex justify-center bg-tertiary-300">
                          {user.status.toUpperCase()}
                        </Badge>
                      )}
                      {user.status === "inactive" && (
                        <Badge variant="outline" className="w-20 flex justify-center bg-secondary-300">
                          {user.status.toUpperCase()}
                        </Badge>
                      )}
                      {user.status === "banned" && (
                        <Badge variant="outline" className="w-20 flex justify-center bg-red-400 text-white">
                          {user.status.toUpperCase()}
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      {user.tier === "free" && (
                        <Badge variant="outline" className="w-24 flex justify-center bg-secondary-100">
                          {user.tier.toUpperCase()}
                        </Badge>
                      )}
                      {user.tier === "pro" && (
                        <Badge variant="outline" className="w-24 flex justify-center bg-secondary-200">
                          {user.tier.toUpperCase()}
                        </Badge>
                      )}
                      {user.tier === "enterprise" && (
                        <Badge variant="outline" className="w-24 flex justify-center bg-secondary-300">
                          {user.tier.toUpperCase()}
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>{format(new Date(user.created), "PP")}</TableCell>
                    <TableCell>
                      <div className="flex justify-end">
                        <AdminEditAccountUser user={user} />
                        <AdminEditAccountUserPassword user={user} />
                        <AdminDeleteAccountUser user={user} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};
