import { WebhookDetails } from "@/api/types/response";
import { cn } from "@/utils/ui.util";
import { X } from "lucide-react";

export interface DynamicWebhookItemDetailsProps {
  requestItem?: WebhookDetails;
  onClickFn: () => void;
  name: string;
  responseCode?: string;
  repeats?: number;
  delay?: string;
  contentType: string;
  contentSize: string;
  height?: string;
}

export const DynamicWebhookItemDetails: React.FC<DynamicWebhookItemDetailsProps> = ({
  onClickFn,
  responseCode,
  repeats,
  delay,
  contentType,
  contentSize,
  height,
}) => {
  return (
    <div className="flex justify-end w-full">
      <div className={cn(height && height, "rounded-lg p-3 text-sm w-full")} onClick={onClickFn}>
        <div className="flex flex-col w-full gap-1">
          <div className="flex items-center gap-4">
            <div className="flex flex-col sm:flex-row items-center justify-between w-full">
              <div className="flex items-center gap-2">
                <p className="text-sm font-mono">{repeats}</p>
                <X className="w-3" />
                {responseCode && <p className="text-sm font-bold">{responseCode}</p>}
              </div>
              <div className="flex gap-2">
                <p className="text-xs">{Number(delay)} Sec</p>
                <p className="text-xs font-mono">{contentType}</p>
                <p className="text-xs">({contentSize})</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
