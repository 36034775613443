export function keyValueTable(map: Record<string, string | number | undefined | null>) {
  return (
    <div className="grid gap-x-5 gap-y-1 grid-cols-[minmax(min-content,0.5fr)_minmax(min-content,2fr)] text-md font-mono">
      {Object.entries(map).map(([key, value], i) => (
        <>
          <div
            key={i}
            className="flex  border-2 border-solid  select-all rounded-md bg-white px-3 py-2  ring-offset-white  "
          >
            {key}
          </div>
          <div className="flex  border-2 border-solid  select-all rounded-md bg-white px-3 py-2  ring-offset-white  ">
            {value}
          </div>
        </>
      ))}
    </div>
  );
}
