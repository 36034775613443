import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import * as x509 from "@peculiar/x509";
import { CertificateState } from "../states/certificate.state";
import { CertificateChainState } from "../states/certificate-chain.state";
import { ToolsIcons } from "./common-options/options.transformations";
import * as jose from "jose";
import { CryptoKeyState } from "../states";

export const keyAlgorithms = [
  "PS256",
  "PS384",
  "PS512",
  "RS256",
  "RS384",
  "RS512",
  "RSA-OAEP",
  "RSA-OAEP-256",
  "RSA-OAEP-384",
  "RSA-OAEP-512",
  "ES256",
  "ES384",
  "ES512",
  "ECDH-ES",
  "ECDH-ES+A128KW",
  "ECDH-ES+A192KW",
  "ECDH-ES+A256KW",
  "EdDSA",
] as const;

export class ToCryptoKeys extends StateTransformation<State> {
  constructor() {
    super("to-crypto-keys", "Parse keys", ["string"]);
  }

  icon(): ToolsIcons {
    return "certificate";
  }

  async parse(state: State): Promise<State | null> {
    if (state.config().stringValue) {
      const input = state.stringValue();

      try {
        const pemPrivatePattern = /-----BEGIN PRIVATE KEY-----[\s\S]*?-----END PRIVATE KEY-----/g;
        const privateKeyMatch = input.match(pemPrivatePattern);
        if (privateKeyMatch) {
          const algMatches = [];
          for (const alg of keyAlgorithms) {
            try {
              const key = await jose.importPKCS8(input, alg, { extractable: true });
              if (key) algMatches.push(alg);
              // else algMatches.push("-"+alg);
            } catch (e) {
              // algMatches.push("-"+alg);
              console.error(alg, e);
            }
          }
          if (algMatches.length > 0) return new CryptoKeyState(input, algMatches, "private");
        }
        const pemPublicKey = /-----BEGIN PUBLIC KEY-----[\s\S]*?-----END PUBLIC KEY-----/g;
        const publicKeyMatch = input.match(pemPublicKey);
        if (publicKeyMatch) {
          const algMatches = [];
          for (const alg of keyAlgorithms) {
            try {
              const key = await jose.importSPKI(input, alg, { extractable: true });

              if (key) algMatches.push(alg);
            } catch (e) {
              console.error(alg, e);
            }
          }
          if (algMatches.length > 0) return new CryptoKeyState(input, algMatches, "public");
        }

        const pemCertificatePattern = /-----BEGIN CERTIFICATE-----[\s\S]*?-----END CERTIFICATE-----/g;
        const certificateInputs = input.match(pemCertificatePattern);
        if (certificateInputs !== null && certificateInputs.length > 1) {
          const chain = certificateInputs.map((certificateInput) => {
            return new x509.X509Certificate(certificateInput);
          });
          return new CertificateChainState(new x509.X509Certificates(chain));
        }
      } catch (_) {
        /* empty */
      }

      const x509PublicKey = /-----BEGIN CERTIFICATE-----[\s\S]*?-----END CERTIFICATE-----/g;
      const x509KeyMatch = input.match(x509PublicKey);
      if (x509KeyMatch) {
        try {
          return new CertificateState(new x509.X509Certificate(input));
        } catch {
          /* empty */
        }

        const algMatches = [];
        for (const alg of keyAlgorithms) {
          try {
            const key = await jose.importX509(input, alg, { extractable: true });

            if (key) algMatches.push(alg);
          } catch (e) {
            console.error(alg, e);
          }
        }
        if (algMatches.length > 0) return new CryptoKeyState(input, algMatches, "certificate");
      }
    }
    return null;
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    const s = await this.parse(state);
    console.log("parse", state, s);
    return s !== null ? CanTransformResult.Yes : CanTransformResult.No;
  }

  async transform(state: State): Promise<State> {
    const certificateState = await this.parse(state);
    if (!certificateState) throw new Error("Invalid certificate.");

    return certificateState;
  }
}
export default new ToCryptoKeys();
