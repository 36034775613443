import React from "react";

export interface FrameWrapperProps {
  src: string;
  className?: string;
}

export const FrameWrapper: React.FC<FrameWrapperProps> = ({ src }: FrameWrapperProps) => {
  const ref = React.useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState<string>("0px");
  const margin = 20;

  const onLoad = () => {
    if (ref.current) {
      const scrollHeight = ref.current.contentWindow!.document.body.scrollHeight + margin;
      setHeight(scrollHeight + "px");
    }
  };

  return (
    <iframe
      ref={ref}
      onLoad={onLoad}
      id="frameWrapper"
      srcDoc={src}
      height={height}
      className="w-full overflow-auto"
    ></iframe>
  );
};
