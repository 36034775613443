import { ApiMutation } from "@/api";
import { useRepositories } from "@/api/queries";
import { CreatePipelinePanelRequest } from "@/api/types/request";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { WorkflowSelectionAccordion } from "@/lib/pipelines/panel-builder/workflow-selection-accordion";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { Plus } from "lucide-react";
import React from "react";
import { Navigate } from "react-router-dom";

export const PipelinePanelBuilderDialog: React.FC = () => {
  const { isPending, isError, data: response } = useRepositories();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const pipelinePanelMutation = ApiMutation.useCreatePipelinePanel({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [["pipelines", "panel", "list"]] });
      toast({ title: "Success!", description: data.message });
      setOpen(false);
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const initialValues: CreatePipelinePanelRequest = {
    panelName: "",
    defaultView: "flow",
    selectedWorkflows: [],
    isPublic: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      pipelinePanelMutation.mutate(values);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="neu-flat" size="icon" className="bg-secondary-200 hover:bg-primary-200 w-8 h-8">
          <Plus />
        </Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Pipeline Panel Builder</DialogTitle>
        <DialogDescription>
          Select the workflows you want to display on your pipeline panel. You can choose multiple workflows from
          different repositories.
        </DialogDescription>
        <form className="flex flex-col gap-2" onSubmit={formik.handleSubmit}>
          <p className="text-sm font-semibold">Panel Name</p>
          <Input
            id="panelName"
            type="text"
            variant="neu-flat"
            placeholder="Input name..."
            required
            value={formik.values.panelName}
            onChange={formik.handleChange}
          />

          <div className="flex justify-between items-center mt-4">
            <span className="text-sm font-semibold">Select Workflows</span>
            {!!formik.values.selectedWorkflows.length && (
              <span className="text-xs">{formik.values.selectedWorkflows.length} selected</span>
            )}
          </div>
          <Accordion type="single" collapsible>
            {!isPending && !isError && (
              <>
                {response.status === "reject" && <Navigate to="/pipelines/auth" replace={true} />}
                {response.status === "success" && (
                  <>
                    {response.data.repositories.map((repository, index) => (
                      <AccordionItem key={index} value={`item-${index}`} className="last:border-b-0">
                        <AccordionTrigger>
                          <div className="flex gap-4 items-center">
                            <img src={repository.owner.avatarUrl} className="w-6 rounded-full" />
                            <p className="text-sm font-mono">
                              {repository.owner.login}/{repository.name}
                            </p>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <WorkflowSelectionAccordion
                            owner={repository.owner.login}
                            repo={repository.name}
                            selectedWorkflows={formik.values.selectedWorkflows}
                            setFieldValue={formik.setFieldValue}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </>
                )}
              </>
            )}
          </Accordion>

          <div className="flex justify-end mt-4">
            <Button
              type="submit"
              variant="neu-flat"
              size="sm"
              className="bg-secondary-200 gap-1 hover:bg-primary-200"
              disabled={pipelinePanelMutation.isPending}
            >
              {pipelinePanelMutation.isPending ? <LoadingSpinner message="Creating" /> : <>Create Panel</>}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
