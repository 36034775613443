import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { ColorState } from "../states";
import parse from "parse-color";

export class ToColorTransformation extends StateTransformation<ColorState> {
  constructor() {
    super("to-color", "To Color", ["string"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) {
      const res = parse(state.stringValue());
      return res.rgb ? CanTransformResult.Yes : CanTransformResult.No;
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<ColorState> {
    const res = parse(state.stringValue());
    return new ColorState(res);
  }
}

export default new ToColorTransformation();
