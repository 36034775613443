import { DateTime } from "luxon";
import type { State } from "../state";
import { StateValidator } from "../state-validator";
import { ExpiresInfo, StateExpires, StateExpiresMulti } from "../state.expires";

class ExpiryValidator extends StateValidator {
  validateSingle(expiresInfo: ExpiresInfo) {
    const { expiresRequired, resourceName, validFrom, validTo } = expiresInfo;
    if (!validTo && expiresRequired) {
      return [this.createFailureResult(`No ${resourceName} expiry`, `${resourceName} must have expiry`)];
    }
    if (!validFrom && !validTo) return [];

    if (validFrom && DateTime.fromJSDate(validFrom) > DateTime.now()) {
      return [this.createFailureResult(`${resourceName} not yet valid`, `${resourceName} not yet valid`)];
    }

    if (validTo && DateTime.now() > DateTime.fromJSDate(validTo)) {
      return [this.createFailureResult(`${resourceName} has expired`, `${resourceName} has expired`)];
    }

    return [this.createSuccessResult(`${resourceName} expiry valid`, `${resourceName} is valid`)];
  }

  public override async validate(state: State) {
    if (state.config().validationTags?.includes("expires")) {
      const expiresInterface = state as unknown as StateExpires;
      return this.validateSingle(expiresInterface.getExpiresInfo());
    }
    if (state.config().validationTags?.includes("expires-multi")) {
      const expiresMultiInterface = state as unknown as StateExpiresMulti;
      return expiresMultiInterface
        .getExpiresMultiInfo()
        .map((expiresInfo) => {
          return this.validateSingle(expiresInfo);
        })
        .flat();
    }
    return [];
  }
}

export default new ExpiryValidator();
