import { useUser } from "@/api/queries";
import { useAuth } from "@/auth/useAuth";
import { AuthAvatar } from "@/components/auth-avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { FileText, LogIn, LogOut, Settings } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";

export const ToggleMore: React.FC = () => {
  const { status } = useAuth();

  return <>{status === "authenticated" ? <AuthenticatedDropdown /> : <UnauthenticatedLinks />}</>;
};

const AuthenticatedDropdown: React.FC = () => {
  const { data: user } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    navigate("/auth");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="bg-transparent">
          <AuthAvatar className="w-8 h-8" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {user && (
          <>
            <DropdownMenuLabel className="flex flex-col gap-1">
              <div className="flex justify-between">
                {user.name}
                <Badge variant="secondary" className="ml-2 uppercase">
                  {user.tier}
                </Badge>
              </div>
              <div className="font-light text-secondary-950/90">{user.email}</div>
            </DropdownMenuLabel>

            <Separator className="h-[2px]" />
          </>
        )}

        <DropdownMenuItem asChild>
          <Link to="/settings" className="no-underline text-secondary-950">
            <Settings size={16} className="mr-2" />
            <span>Settings</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link to={docsURL} target="_blank" className="no-underline text-secondary-950">
            <FileText size={16} className="mr-2" />
            <span>Docs</span>
          </Link>
        </DropdownMenuItem>

        <Separator className="h-[2px]" />

        <DropdownMenuItem onClick={handleLogOut}>
          <LogOut size={16} className="mr-2" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const UnauthenticatedLinks: React.FC = () => {
  return (
    <div className="flex gap-5 p-3">
      <Button asChild variant="ghost" size="sm" className="bg-transparent hover:underline p-0">
        <Link to={docsURL} target="_blank" className="no-underline">
          Docs
        </Link>
      </Button>
      <Button asChild variant="ghost" size="sm" className="bg-transparent hover:underline p-0 gap-1">
        <Link to="/auth" className="no-underline">
          Login
          <LogIn size={16} />
        </Link>
      </Button>
    </div>
  );
};
