import React from "react";
import { EmailDetails } from "@/api/types/response";
import { EmailDisplay } from "@/lib/emails/page/elements/email-display";
import { ScrollArea } from "@/components/ui/scroll-area";
import { EmailSidebar } from "@/lib/emails/page/elements/email-sidebar";
import { WebhookStatusCard } from "@/lib/webhooks/page/elements/help/webhook-status-card";
import { Link } from "react-router-dom";
import { MailCheck } from "lucide-react";

interface EmailPageCoreProps {
  id: string;
  email: string;
  emailId?: string;
  items: EmailDetails[];
}

export const EmailPageCore: React.FC<EmailPageCoreProps> = ({ id, email, emailId, items }) => {
  const selected: EmailDetails | undefined = React.useMemo(
    () => items.find((item) => item.id === emailId),
    [items, emailId]
  );

  const sidebar = <EmailSidebar email={email} items={items} id={id} selected={selected} />;

  return (
    <div className="flex flex-col md:flex-row w-full">
      {/* Sidebar */}
      <div className="hidden sm:flex flex-col md:w-3/12 bg-secondary-200">{sidebar}</div>

      {/* Content */}
      <ScrollArea className="flex flex-col md:w-9/12 sm:border-l border-secondary-950">
        <div className="flex flex-col">
          {items.length === 0 && (
            <>
              <div className="hidden sm:flex p-10 justify-center">
                <div className="flex flex-col gap-8 w-full max-w-7xl">
                  <WebhookStatusCard
                    message="No emails received yet."
                    description={
                      <span>
                        Use your email client, web email client or email sending service to send emails to{" "}
                        <Link to={`mailto:${email}`} className="text-secondary-950">
                          {email}
                        </Link>
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="sm:hidden flex flex-col">{sidebar}</div>
            </>
          )}
          {items.length > 0 && !selected && (
            <>
              <div className="hidden sm:flex p-10 justify-center">
                <div className="flex flex-col gap-8 w-full max-w-7xl">
                  <WebhookStatusCard
                    message="Select an email to view details."
                    icon={<MailCheck size={20} />}
                    description={
                      <span>
                        Or use your email client, web email client or email sending service to send emails to{" "}
                        <Link to={`mailto:${email}`} className="text-secondary-950">
                          {email}
                        </Link>
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="sm:hidden flex flex-col">{sidebar}</div>
            </>
          )}
          {items.length > 0 && selected && <EmailDisplay id={id} mailDetails={selected} />}
        </div>
      </ScrollArea>
    </div>
  );
};
