export type LocalStorageKeyType = "webhooks" | "emails" | "secrets";

export interface LocalStorageDataBase {
  id: string;
  created: string;
}

export interface WebhooksLocalStorageData extends LocalStorageDataBase {
  endpoint: string;
  isDynamic: boolean;
}
export interface EmailsLocalStorageData extends LocalStorageDataBase {
  email: string;
}

interface VersionedData<T> {
  version: string;
  data: T[];
}

const VERSIONS: Record<LocalStorageKeyType, string> = {
  webhooks: "1.0",
  emails: "1.0",
  secrets: "1.0",
};

export class LocalStorageService {
  static store<T>(key: LocalStorageKeyType, data: T): void {
    const existingData: T[] = LocalStorageService.retrieve(key);
    existingData.push(data);
    LocalStorageService.storeAll(key, existingData);
  }

  static storeAll<T>(key: LocalStorageKeyType, data: T[]): void {
    const versionedData: VersionedData<T> = {
      version: VERSIONS[key],
      data: data,
    };
    try {
      localStorage.setItem(key, JSON.stringify(versionedData));
    } catch (error) {
      console.error("Error storing data:", error);
    }
  }

  static retrieve<T>(key: LocalStorageKeyType): T[] {
    const content = localStorage.getItem(key);

    if (!content) {
      return [];
    }

    try {
      const versionedData: VersionedData<T> = JSON.parse(content);

      if (versionedData.version !== VERSIONS[key]) {
        console.warn(
          `Version mismatch for ${key}: expected ${VERSIONS[key]} but found ${versionedData.version}. Clearing local storage for ${key}.`
        );
        localStorage.removeItem(key);
        return [];
      }

      return versionedData.data;
    } catch (error) {
      console.error("Error retrieving data:", error);
      return [];
    }
  }
}
