import {
  adminFetchAccountUsers,
  fetchAccount,
  fetchAccountUsers,
  fetchCustomerSubscription,
  fetchEmailById,
  fetchGithubAuth,
  fetchLatestRunJobs,
  fetchPipelineInfo,
  fetchPipelinePanel,
  fetchPipelinePanelList,
  fetchReceivedEmails,
  fetchReceivedRequests,
  fetchRecentEmails,
  fetchRecentWebhooks,
  fetchRepositories,
  fetchRequestById,
  fetchUser,
  fetchWebhookInfo,
  fetchWorkflows,
} from "./requests";
import { createQuery } from "./utils";

/* Webhooks */
export const useReceivedRequests = createQuery(["webhook", "received"], fetchReceivedRequests);
export const useRequest = createQuery(["webhook"], fetchRequestById);
export const useWebhookInfo = createQuery(["webhook", "info"], fetchWebhookInfo);
export const useRecentWebhooks = createQuery(["webhook", "recent"], fetchRecentWebhooks);

/* Emails */
export const useReceivedEmails = createQuery(["email", "received"], fetchReceivedEmails);
export const useEmail = createQuery(["email"], fetchEmailById);
export const useRecentEmails = createQuery(["email", "recent"], fetchRecentEmails);

/* Users */
export const useUser = createQuery(["user"], fetchUser);
export const useCustomerSubscription = createQuery(["user", "subscription"], fetchCustomerSubscription);

export const useAccount = createQuery(["account"], fetchAccount);
export const useAccountUsers = createQuery(["account", "users"], fetchAccountUsers);

/* Admin */
export const useAdminAccountUsers = createQuery(["admin", "account", "users"], adminFetchAccountUsers);

/* Pipelines */
export const useConnectGithub = createQuery(["github", "auth"], fetchGithubAuth);
export const useRepositories = createQuery(["repositories"], fetchRepositories);
export const useWorkflows = createQuery(["workflows"], fetchWorkflows);
export const useLatestRunJobs = createQuery(["workflows", "latest", "jobs"], fetchLatestRunJobs);
export const usePipelineInfo = createQuery(["pipelines", "info"], fetchPipelineInfo);
export const usePipelinePanelList = createQuery(["pipelines", "panel", "list"], fetchPipelinePanelList);
export const usePipelinePanel = createQuery(["pipelines", "panel"], fetchPipelinePanel);
