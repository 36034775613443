import { State, StateConfig } from "../state";
import { ReactNode } from "react";
import { keyValueTable } from "../utils/tools-display-utils";

type StringMap = {
  [key: string]: string | number;
};

export class KeyValueState extends State<StringMap> {
  constructor(
    value: StringMap,
    readonly type: string
  ) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: true,
      customDisplay: [this.type],
      defaultDisplayMode: this.type,
    };
  }

  jsonValue(): object {
    return this.value;
  }
  stringValue(): string {
    const lines = Object.entries(this.value).map(([key, value]) => {
      return `${key}: ${value}`;
    });
    return lines.join("\n");
  }

  toReactNode(_: string): ReactNode {
    return keyValueTable(this.value);
  }
}
