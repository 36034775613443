import { Button } from "@/components/ui/button";
import { cn } from "@/utils/ui.util";
import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

export interface ItemDetails {
  name: string | React.ReactNode;
  description: string | React.ReactNode;
  info: string | React.ReactNode;
}

interface RecentRequestItemProps {
  label?: string | React.ReactNode;
  item: ItemDetails;
  path: string;
  height?: string;
  isSelected?: boolean;
  disabled?: boolean;
}

export const RecentRequestItem: React.FC<RecentRequestItemProps> = ({
  label,
  item,
  path,
  height,
  isSelected,
  disabled,
}) => {
  return (
    <div className="flex w-full">
      {!disabled && (
        <Button
          asChild
          scheme={isSelected ? "black" : "white-black"}
          size="lg"
          className={cn(height && height, "rounded-lg p-3 text-sm w-full border")}
          disabled={disabled}
        >
          <Link to={path} className="no-underline">
            <RecentRequestItemContent label={label} item={item} isSelected={isSelected} />
          </Link>
        </Button>
      )}

      {disabled && (
        <Button
          scheme={isSelected ? "black" : "white-black"}
          size="lg"
          className={cn(height && height, "rounded-lg p-3 text-sm w-full border")}
          disabled={disabled}
        >
          <RecentRequestItemContent label={label} item={item} isSelected={isSelected} />
        </Button>
      )}
    </div>
  );
};

interface RecentRequestItemContentProps {
  label?: string | React.ReactNode;
  item: ItemDetails;
  isSelected?: boolean;
}

export const RecentRequestItemContent: React.FC<RecentRequestItemContentProps> = ({ label, item, isSelected }) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex">
        <div className="flex items-center w-full gap-2 justify-between">
          <div className="flex items-center gap-2">
            {label && <p className="font-mono text-sm">{label}</p>}
            <p className="font-semibold text-sm">{item.name}</p>
            <div className="break-all line-clamp-1 text-wrap">
              <p className="font-mono text-sm">{item.description}</p>
            </div>
          </div>
          <div className="hidden md:flex items-center gap-2">
            <p className="text-xs">{item.info}</p>
            <div className="flex justify-end items-center">{isSelected && <ChevronRight className="h-4" />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
