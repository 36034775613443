import { StateValidator } from "../state-validator";
import { State } from "../state";

class JsonValidator extends StateValidator {
  public override async validate(state: State) {
    if (!state.config().stringValue) return [];

    try {
      JSON.parse(state.stringValue());
      return [this.createSuccessResult("JSON", "This is valid JSON document")];
    } catch (e) {
      return [];
    }
  }
}

export default new JsonValidator();
