import React from "react";
import { SecretsContext } from "@/lib/tools/secrets-manager/secrets-provider";

export const useSecretsManager = () => {
  const context = React.useContext(SecretsContext);
  if (!context) {
    throw new Error("useSecretsManager must be used within a SecretsProvider");
  }
  return context;
};
