import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { useFormik } from "formik";
import { ApiMutation } from "@/api";
import { useToast } from "@/components/ui/use-toast";
import { VerificationType } from "@/api/types/request";
import { LoadingSpinner } from "@/components/loading-spinner";
import { defaultError } from "./utils/default-error-message";
import { Link } from "react-router-dom";

interface ForgotPasswordFormProps {
  title: string;
  description: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const triggerVerification = ApiMutation.useTriggerVerification({
    onSuccess: () => {
      navigate("/auth/reset-password", { state: { email: formik.values.email } });
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      triggerVerification.mutate({ email: values.email, type: VerificationType.ResetPassword });
    },
  });

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex items-center gap-3">
          <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
          <h3 className="font-bold font-mono">wt.dev</h3>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small className="text-center">{description}</small>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1.5">
            <Input
              id="email"
              placeholder="Email Address"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              required
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <Button
            type="submit"
            scheme="black"
            size="sm"
            disabled={triggerVerification.isPending}
            className="bg-secondary-950"
          >
            {triggerVerification.isPending ? <LoadingSpinner message={"Sending Code"} /> : "Send Code"}
          </Button>
        </div>
      </form>
      <div className="flex justify-center gap-2 text-sm">
        <small>Back to Sign In?</small>
        <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign In</small>
        </Link>
      </div>
    </Card>
  );
};
