import { State, StateConfig } from "../state";
import { ReactNode } from "react";
import { HttpHeaderState } from "./http-header.state";
import { getStandardHeaderInfo } from "../utils/http-header-utils";
import { HoverInfo } from "@/components/hover-info";

type HeadersMap = {
  [key: string]: string | undefined;
};

export class HttpHeadersState extends State<HeadersMap> {
  readonly headers: HttpHeaderState[];
  constructor(value: HeadersMap) {
    super(value);
    this.headers = Object.entries(this.value).map(([key, value]) => new HttpHeaderState(value, key));
  }

  config(): StateConfig {
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: true,
      customDisplay: ["Headers"],
      defaultDisplayMode: "Headers",
    };
  }

  jsonValue(): object {
    return this.value;
  }
  stringValue(): string {
    return JSON.stringify(this.value);
  }

  toReactNode(_: string): ReactNode {
    return (
      <div className="grid grid-cols-[min-content,min-content,1fr]  max-h-full gap-y-1">
        {this.headers.map((header) => {
          const stdHeader = getStandardHeaderInfo(header.headerName);
          return (
            <>
              <div className="font-bold text-nowrap pr-4 border-b-2">{header.headerName}</div>
              <div className="pr-4 border-b-2">{stdHeader && <HoverInfo message={stdHeader.Description} />}</div>

              <div className="select-all border-b-2">{header.value}</div>
            </>
          );
        })}
      </div>
    );
  }
}
