import { ScrollArea } from "@/components/ui/scroll-area";
import { Outlet } from "react-router-dom";

export const PipelinesPublicLayout: React.FC = () => {
  return (
    <div className="flex flex-col h-dvh">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex w-full bg-secondary-200">
          <ScrollArea className="w-full">
            <div className="p-4">
              <Outlet />
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};
