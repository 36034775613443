import { SnakeContext, State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { SuccessFailState } from "../states";
import { compareResultToString, compareStrings, smartCompare } from "../utils/compare-utils";
import { TransformationOptionsStringInput } from "./common-options/options.input.transformation";
import { ToolsIcons } from "./common-options/options.transformations";
import { TransformationOptionsCheckbox } from "./common-options/options.checkbox.transformation";
import { ReactNode } from "react";
import { Equal, MoveRight } from "lucide-react";
import { SmartCompareResultState } from "../states/smart-compare-result.state";

type CompareMode = {
  compareValue: string;
  extraString: string;
  smartMode: boolean;
};

export class CompareTransformation extends StateTransformation<State, CompareMode> {
  constructor() {
    super("smart-compare", "Compare", ["string"]);
  }

  icon(): ToolsIcons {
    return "compare";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().jsonValue || state.config().stringValue) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async getOptions(_: State, options?: CompareMode, _snakeContext?: SnakeContext): Promise<CompareMode> {
    return {
      compareValue: options?.compareValue || "",
      extraString: options?.extraString || "",
      smartMode: options?.smartMode || false,
    };
  }

  async transform(state: State, options: CompareMode): Promise<State> {
    const input = state.stringValue();
    const compareValue = options.compareValue ?? "";
    if (options.smartMode) {
      const results = smartCompare(input, compareValue, options.extraString);
      const description: ReactNode = (
        <div className="mt-2">
          {results.map((result) => (
            <div className="flex mb-2 items-center gap-1">
              <span className="bg-element-input px-2 py-0.5">input</span>
              {result.successPath.map((path, index) => (
                <>
                  <MoveRight />
                  <span className="bg-element-argument rounded-md px-2 py-0.5" key={index}>
                    {path}
                  </span>{" "}
                </>
              ))}
              <Equal />
              <span className="bg-element-argument rounded-md px-2 py-0.5">
                {result.s2transform !== "identity" ? <>{result.s2transform}(argument)</> : <>argument</>}
              </span>
              ({`${compareResultToString(result.successMessage).description}`})
              <span className="bg-status-success rounded-md px-2 py-0.5 ml-auto">Score: {result.score}</span>
            </div>
          ))}

          <div className="text-xs mt-4">
            * Smart compare finds a way to compare input and comparison value via common transformations such as
            case-changes, trimming, hash and HMAC. Used to find untrimmed strings, wrong encoding, hash values and more.
          </div>
        </div>
      );

      if (results.length > 0) {
        return new SmartCompareResultState(description);
      } else return new SuccessFailState(false, "Strings not equal");
    } else {
      const result = compareResultToString(compareStrings(input, compareValue));
      return new SuccessFailState(result.equals, result.description);
    }
  }

  getOptionsReactNode(options: CompareMode, onChange: (options: CompareMode) => void): React.ReactNode {
    return (
      <div className="flex gap-1">
        <TransformationOptionsStringInput
          options={options}
          defaultValue={options.compareValue}
          selectedKey="compareValue"
          onChange={onChange}
          placeholder="String to compare"
          label="Compare to:"
          highlight="argument"
        />
        <TransformationOptionsCheckbox
          options={options}
          defaultValue={options.smartMode}
          selectedKey="smartMode"
          onChange={onChange}
          label="Smart mode"
        />
        {options.smartMode && (
          <TransformationOptionsStringInput
            options={options}
            defaultValue={options.extraString}
            selectedKey={"extraString"}
            onChange={onChange}
            placeholder="optional HMAC key"
            label="key"
            highlight="secret"
          />
        )}
      </div>
    );
  }
}

export default new CompareTransformation();
