import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { StringState } from "../states";
import { hmac, hash as hashAlg } from "../utils/hash-utils";
import { TransformationOptionsCheckbox } from "./common-options/options.checkbox.transformation";
import { TransformationOptionsStringInput } from "./common-options/options.input.transformation";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

type HmacAlgorithms = "md5" | "sha1" | "sha256" | "sha224" | "sha512" | "sha384" | "sha3" | "ripemd160";
type HmacMode = {
  selected: HmacAlgorithms;
  values: Record<HmacAlgorithms, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
  secret: string;
  hmac: boolean;
};

export class HmacTransformation extends StateTransformation<StringState, HmacMode> {
  constructor() {
    super("string-hmac", "Hash & HMAC", ["string", "number"]);
  }

  icon(): ToolsIcons {
    return "calculator";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State, options: HmacMode): Promise<StringState> {
    const input = state.stringValue();
    let hash: string = "";
    const alg = options.selected;
    const secret = options.secret;
    if (options.hmac) {
      switch (alg) {
        case "sha1":
          hash = hmac.sha1(input, secret);
          break;
        case "md5":
          hash = hmac.md5(input, secret);
          break;
        case "sha256":
          hash = hmac.sha256(input, secret);
          break;
        case "sha224":
          hash = hmac.sha224(input, secret);
          break;
        case "sha512":
          hash = hmac.sha512(input, secret);
          break;
        case "sha384":
          hash = hmac.sha384(input, secret);
          break;
        case "sha3":
          hash = hmac.sha3(input, secret);
          break;
        case "ripemd160":
          hash = hmac.ripemd160(input, secret);
          break;
        default:
          throw new Error("Invalid algorithm.");
      }
    } else {
      switch (alg) {
        case "sha1":
          hash = hashAlg.sha1(input);
          break;
        case "md5":
          hash = hashAlg.md5(input);
          break;
        case "sha256":
          hash = hashAlg.sha256(input);
          break;
        case "sha224":
          hash = hashAlg.sha224(input);
          break;
        case "sha512":
          hash = hashAlg.sha512(input);
          break;
        case "sha384":
          hash = hashAlg.sha384(input);
          break;
        case "sha3":
          hash = hashAlg.sha3(input);
          break;
        case "ripemd160":
          hash = hashAlg.ripemd160(input);
          break;
        default:
          throw new Error(`Invalid algorithm ${alg}`);
      }
    }

    return new StringState(hash, options.hmac ? `${alg} HMAC` : `${alg} hash`);
  }

  async getOptions(_: State, options?: HmacMode): Promise<HmacMode | undefined> {
    const hmac = options?.hmac ?? false;
    return {
      selected: options?.selected || "sha256",
      values: {
        md5: "MD5",
        sha1: "SHA1",
        sha256: "SHA256",
        sha224: "SHA224",
        sha512: "SHA512",
        sha384: "SHA384",
        sha3: "SHA3",
        ripemd160: "RIPEMD160",
      },
      label: hmac ? "HMAC" : "Hash",
      secret: options?.secret ?? "",
      hmac: hmac,
    };
  }
  getOptionsReactNode(options: HmacMode, onChange: (options: HmacMode) => void): React.ReactNode {
    return (
      <>
        <div className="neu flex p-1 gap-3  bg-white items-center">
          <TransformationOptionsSelect onChange={onChange as any} options={options} />

          <TransformationOptionsCheckbox
            options={options}
            defaultValue={options.hmac}
            selectedKey="hmac"
            onChange={onChange}
            label="HMAC?"
          />

          <div>
            {options.hmac && (
              <TransformationOptionsStringInput
                options={options}
                defaultValue={options.secret}
                selectedKey={"secret"}
                placeholder="Hmac key"
                onChange={onChange}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default new HmacTransformation();
