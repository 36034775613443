import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAuth } from "@/auth/useAuth";
import { PrivateDashboard } from "@/lib/webhooks/dashboard/private/private-dashboard";
import { PublicDashboard } from "@/lib/webhooks/dashboard/public/public-dashboard";
import { LoadingSpinner } from "@/components/loading-spinner";

export const WebhooksDashboard: React.FC = () => {
  return (
    <div className="flex w-full bg-secondary-200">
      <ScrollArea className="w-full">
        <div className="flex justify-center py-4 2xl:py-10">
          <div className="w-full max-w-[1920px]">
            <div className="flex flex-col gap-6 2xl:gap-12 px-4">
              <DashboardContent />
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export const DashboardContent: React.FC = () => {
  const { status } = useAuth();

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full">
        <LoadingSpinner message={"Loading..."} />
      </div>
    );
  }

  if (status === "public") {
    return <PublicDashboard />;
  }

  return <PrivateDashboard />;
};
