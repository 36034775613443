import { Card } from "@/components/ui/card.tsx";
import Stripe from "stripe";

interface PaymentMethodItemProps {
  paymentMethod: Stripe.PaymentMethod;
  defaultPaymentMethodId: string | null;
}

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({ paymentMethod, defaultPaymentMethodId }) => {
  return (
    <Card className="flex justify-between items-center gap-2 p-5 rounded-xl min-h-24">
      <div className="text-3xl font-extrabold">{paymentMethod.card?.brand}</div>

      <div className="grid grid-cols-1 items-center">
        <div className="font-semibold text-sm">Ending in {paymentMethod.card?.last4}</div>
        <span className="text-gray-600 font-normal text-xs">
          Expiry {paymentMethod.card?.exp_month}/{paymentMethod.card?.exp_year}
        </span>
        {paymentMethod.id === defaultPaymentMethodId && (
          <span className="text-gray-600 font-semibold text-xs">Default</span>
        )}
      </div>
    </Card>
  );
};
