import { supportedContentTypes } from "@/enums/supported-content-types";
import prettyBytes from "pretty-bytes";

export const getResponseCodeColor = (code: number) => {
  if (code >= 200 && code < 300) return "text-lime-600";
  if (code >= 300 && code < 400) return "text-sky-600";
  if (code >= 400 && code < 500) return "text-red-600";
  if (code >= 500 && code < 600) return "text-orange-600";
  return "";
};

export const getContentSizeInKB = (content: string, contentType?: string) => {
  const options = {
    type: supportedContentTypes[contentType as keyof typeof supportedContentTypes] || "application/octet-stream",
  };

  const blob = new Blob([content], options);

  return prettyBytes(blob.size);
};
