import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { getIdFromEmail } from "@/lib/emails/dashboard/utils";
import { HoverInfo } from "@/components/hover-info";
import { useFilteredItems } from "@/hooks/useFilteredItems";
import { EmailsLocalStorageData } from "@/utils/storage/localstorage-service";
import { Link } from "react-router-dom";

export const PublicRecentEmailsList: React.FC = () => {
  const { filteredItems, ttl } = useFilteredItems<EmailsLocalStorageData>("emails");

  return (
    <div>
      <div className="flex-1 space-y-4 pt-6">
        <div className="flex-1 items-center space-y-2">
          <Card>
            <CardHeader>
              <CardTitle className="font-semibold text-base">
                <div className="flex items-center justify-between">
                  Latest Email Addresses
                  <HoverInfo message="Public users can only store up to 10 email addresses at a time. Sign up to increase the limit." />
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <>
                {filteredItems && filteredItems.length === 0 && (
                  <small>Generate a new email address to start capturing data.</small>
                )}
                {filteredItems && filteredItems.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <Table className="whitespace-nowrap">
                      <TableHeader>
                        <TableRow>
                          <TableHead></TableHead>
                          <TableHead>Receive address</TableHead>
                          <TableHead>Created</TableHead>
                          <TableHead className="text-right">Expires In</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {filteredItems.map((item, index) => {
                          if (!item.email) {
                            return null;
                          }

                          const createdTimestamp = new Date(item.created).getTime();
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell className="font-medium font-mono">
                                <Button asChild variant="ghost" size="sm" className="p-0 hover:underline">
                                  <Link to={"/emails/" + getIdFromEmail(item.email)} className="no-underline">
                                    {item.email}
                                  </Link>
                                </Button>
                              </TableCell>
                              <TableCell>{formatDistanceToNow(item.created)} ago</TableCell>
                              <TableCell className="text-right">
                                {formatDistanceToNow(new Date(createdTimestamp + ttl))}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
