import md5 from "crypto-js/md5";
import sha1 from "crypto-js/sha1";
import sha256 from "crypto-js/sha256";
import sha224 from "crypto-js/sha224";
import sha512 from "crypto-js/sha512";
import sha384 from "crypto-js/sha384";
import sha3 from "crypto-js/sha3";
import ripemd160 from "crypto-js/ripemd160";
import hmacMd5 from "crypto-js/hmac-md5";
import hmacSha1 from "crypto-js/hmac-sha1";
import hmacSha256 from "crypto-js/hmac-sha256";
import hmacSha224 from "crypto-js/hmac-sha224";
import hmacSha512 from "crypto-js/hmac-sha512";
import hmacSha384 from "crypto-js/hmac-sha384";
import hmacSha3 from "crypto-js/hmac-sha3";
import hmacRipemd160 from "crypto-js/hmac-ripemd160";

import base64enc from "crypto-js/enc-base64";
import base64urlenc from "crypto-js/enc-base64url";

export const hash = {
  md5: (s: string) => md5(s).toString(),
  sha1: (s: string) => sha1(s).toString(),
  sha256: (s: string) => sha256(s).toString(),
  sha224: (s: string) => sha224(s).toString(),
  sha512: (s: string) => sha512(s).toString(),
  sha384: (s: string) => sha384(s).toString(),
  sha3: (s: string) => sha3(s).toString(),
  ripemd160: (s: string) => ripemd160(s).toString(),
};

export const hmac = {
  md5: (s: string, key: string) => hmacMd5(s, key).toString(),
  sha1: (s: string, key: string) => hmacSha1(s, key).toString(),
  sha256: (s: string, key: string) => hmacSha256(s, key).toString(),
  sha224: (s: string, key: string) => hmacSha224(s, key).toString(),
  sha512: (s: string, key: string) => hmacSha512(s, key).toString(),
  sha384: (s: string, key: string) => hmacSha384(s, key).toString(),
  sha3: (s: string, key: string) => hmacSha3(s, key).toString(),
  ripemd160: (s: string, key: string) => hmacRipemd160(s, key).toString(),
};

export const base64 = {
  encode: {
    base64: base64enc,
    base64url: base64urlenc,
  },
};
