import { VerifyUserForm } from "@/lib/auth/verify-user-form";

export const VerifyUser: React.FC = () => {
  return (
    <VerifyUserForm
      title={"Almost finished"}
      description={"Confirmation code has been sent to your email address. It may take a few minutes to arrive."}
    />
  );
};
