import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { StringListState, StringState } from "../states";
import { TransformationOptionsCheckbox } from "./common-options/options.checkbox.transformation";
import { TransformationOptionsStringInput } from "./common-options/options.input.transformation";
import { TransformationOptionsLabel } from "./common-options/options.label.transformation";
import { TransformationOptionsSelectAsList } from "./common-options/options.select-as-list.transformation";
import { ToolsIcons } from "./common-options/options.transformations";

const optionNames = ["split", "join"] as const;
type StringUtilsAlgorithms = (typeof optionNames)[number];

type StringUtilsMode = {
  selected: StringUtilsAlgorithms;
  values: Record<string, StringUtilsAlgorithms>;
  splitSeparator: string;
  joinString: string;
  isSplitRegex: boolean;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class StringUtilsTransformation extends StateTransformation<State, StringUtilsMode> {
  constructor() {
    super("string-list-utils", "List utils", ["string"]);
  }

  icon(): ToolsIcons {
    return "list";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  split(input: string, separator: string | RegExp): string[] {
    return input.split(separator);
  }

  join(input: string, splitSeparator: string, joinString: string): string {
    return this.split(input, splitSeparator).join(joinString);
  }

  removeDoubleLines(input: string) {
    return input.replace(/\n\n+/g, "\n");
  }

  async transform(state: State, options?: StringUtilsMode): Promise<State> {
    const input = state.stringValue();

    const alg = options?.selected;
    let output = "";

    switch (alg) {
      case "split":
        {
          let split: string | RegExp = options?.splitSeparator || " ";
          if (options?.isSplitRegex) {
            split = new RegExp(options?.splitSeparator, "g");
          }
          const lines = this.split(input, split).filter((x) => x.length > 0);

          if (lines.length > 1) {
            return new StringListState(lines, "split");
          } else output = lines.join("\n");
        }
        break;
      case "join":
        output = this.join(input, "\n", options?.joinString || " ");
        break;
      default:
        throw new Error(`Invalid algorithm ${alg}`);
    }

    return new StringState(this.removeDoubleLines(output), null);
  }

  async getOptions(_: State, options?: StringUtilsMode): Promise<StringUtilsMode | undefined> {
    return {
      selected: options?.selected || "split",
      values: Object.fromEntries(optionNames.map((name) => [name, name])),
      label: "String utils",
      splitSeparator: options?.splitSeparator || ",",
      joinString: options?.joinString || ",",
      isSplitRegex: options?.isSplitRegex || false,
    };
  }

  getOptionsReactNode(options: StringUtilsMode, onChange: (options: StringUtilsMode) => void): React.ReactNode {
    return (
      <div className="flex  gap-2">
        <TransformationOptionsSelectAsList options={options} onChange={onChange as any} />

        <div className="flex gap-1">
          {options.selected === "split" && (
            <>
              <TransformationOptionsStringInput
                options={options}
                defaultValue={options.splitSeparator}
                selectedKey="splitSeparator"
                onChange={onChange}
                label="Split chars:"
                placeholder="Split chars/regex"
              />
              <TransformationOptionsLabel
                className="flex justify-center neu-flat rounded-md bg-neutral-200 w-10"
                label={options.splitSeparator.length.toString()}
              />
              <TransformationOptionsCheckbox
                options={options}
                defaultValue={options.isSplitRegex}
                selectedKey="isSplitRegex"
                onChange={onChange}
                label="Regex?"
              />
            </>
          )}
          {options.selected === "join" && (
            <>
              <TransformationOptionsStringInput
                options={options}
                defaultValue={options.joinString}
                selectedKey="joinString"
                onChange={onChange}
                label="Join string:"
                placeholder="Join string"
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default new StringUtilsTransformation();
