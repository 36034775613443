import { Card, CardTitle } from "@/components/ui/card.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { usePricingTiers } from "@/lib/landing-page/pricing-tiers";
import React from "react";

interface PaymentPlanComponentProps {
  currentPeriodEnd: number;
}

export const PaymentPlanComponent: React.FC<PaymentPlanComponentProps> = ({ currentPeriodEnd }) => {
  const pricingTiers = usePricingTiers();

  const paidPricingTier = React.useMemo(
    () => pricingTiers.find((pricingTier) => pricingTier.name === "Paid"),
    [pricingTiers]
  );

  const currentPeriodEndDate = new Date(currentPeriodEnd * 1000);
  const formattedDate = currentPeriodEndDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Card className="flex justify-between items-center gap-2 p-3 rounded-xl min-h-24">
      <div className="w-full">
        <CardTitle className="font-bold text-md mb-2">
          <div className="flex gap-2 items-center justify-between">
            <div>{paidPricingTier?.name}</div>
            <Badge className="text-sm font-semibold bg-secondary-950 hover:no-underline hover:bg-secondary-950 h-fit">
              Billed yearly
            </Badge>
          </div>
        </CardTitle>
        <div className="flex gap-4 items-center">
          <div className="font-semibold text-sm">
            {paidPricingTier?.currency}
            {paidPricingTier?.price} USD
          </div>
          <span className="text-gray-600 font-normal text-xs">(Next renew on {formattedDate})</span>
        </div>
      </div>
    </Card>
  );
};
