import React from "react";
import { Button } from "@/components/ui/button";
import { successResponse } from "@/lib/webhooks/builder/elements/success-response";
import { supportedContentTypes } from "@/enums/supported-content-types";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { MinusCircle, Plus, PlusCircle, Trash2 } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Card } from "@/components/ui/card";
import { DynamicWebhookItemDetails } from "@/lib/webhooks/builder/elements/dynamic-webhook-item-details";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/utils/ui.util";
import { getContentSizeInKB } from "@/lib/webhooks/utils/webhook-utils";
import * as Yup from "yup";
import { Request } from "@/api/types";
import { ApiMutation } from "@/api";
import { useNavigate } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { DynamicWebhookResponse } from "@/lib/webhooks/builder/dynamic-webhook-builder";
import { DynamicWebhookItemContent } from "@/lib/webhooks/builder/elements/dynamic-webhook-item-content";
import { LocalStorageService, WebhooksLocalStorageData } from "@/utils/storage/localstorage-service";

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      responseCode: Yup.number()
        .typeError("Response code must be a number")
        .required("Response code is required")
        .positive("Response code must be a positive number")
        .integer("Response code must be an integer")
        .min(100, "Response code must be greater than or equal to 100")
        .max(599, "Response code must be less than or equal to 599"),
      delay: Yup.number()
        .typeError("Response code must be a number")
        .required("Response code is required")
        .positive("Response code must be a positive number")
        .integer("Response code must be an integer")
        .min(0, "Response code must be greater than or equal to 0")
        .max(20, "Response code must be less than or equal to 20"),
      contentType: Yup.string()
        .typeError("Content type must be a string")
        .required("Content type is required")
        .oneOf(Object.keys(supportedContentTypes), "Content type must be one of the supported content types"),
      responseData: Yup.string().typeError("Response data must be a string").required("Response data is required"),
      repeat: Yup.number()
        .typeError("Repeat must be a number")
        .required("Repeat is required")
        .positive("Repeat must be a positive number")
        .integer("Repeat must be an integer")
        .min(1, "Repeat must be greater than or equal to 1")
        .max(10, "Repeat must be less than or equal to 10"),
    })
  ),
});

export interface DynamicWebhookPipeline {
  items: DynamicWebhookResponse[];
}

export const DynamicWebhookBuilderContent: React.FC = () => {
  const navigate = useNavigate();
  const [selectedAccordionItem, setSelectedAccordionItem] = React.useState<number | undefined>(undefined);
  const [totalRequests, setTotalRequests] = React.useState<number>(1);

  const initialValues: DynamicWebhookPipeline = {
    items: [successResponse],
  };

  const createDynamicWebhookMutation = ApiMutation.useCreateDynamicWebhook({
    onSuccess: (data) => {
      LocalStorageService.store<WebhooksLocalStorageData>("webhooks", {
        ...data,
        isDynamic: true,
        created: new Date().toISOString(),
      });
      navigate(`/webhooks/${data.id}`);
    },
    onError: () => {
      toast({
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request. Please try again.",
      });
    },
  });

  const formik = useFormik<DynamicWebhookPipeline>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: DynamicWebhookPipeline) => {
      const body: Request.DynamicWebhookRequest = {
        specifiedRetries: 0,
        responseCodes: [],
        delayBetweenRetries: [],
        contentTypes: [],
        responseData: [],
      };
      values.items.forEach((item) => {
        body.specifiedRetries += item.repeat;
        for (let i = 0; i < item.repeat; i++) {
          body.responseCodes.push(Number(item.responseCode));
          body.delayBetweenRetries.push(Number(item.delay));
          body.contentTypes.push(supportedContentTypes[item.contentType]);
          body.responseData.push(item.responseData);
        }
      });

      createDynamicWebhookMutation.mutate(body);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TooltipProvider delayDuration={200}>
          <Accordion type="single" value={`item-${selectedAccordionItem}`} collapsible>
            <div className="flex flex-col gap-2">
              <FieldArray name="items">
                {({ push, remove }) => (
                  <div className="flex flex-col gap-2">
                    {formik.values.items.map((item, index) => {
                      const itemInvalid =
                        formik.touched.items &&
                        formik.touched.items[index] &&
                        formik.errors.items &&
                        formik.errors.items[index];
                      return (
                        <div key={index}>
                          <AccordionItem
                            value={`item-${index}`}
                            key={index}
                            className="flex items-center gap-2 border-none"
                          >
                            <Card
                              className={cn("flex flex-col gap-1 w-full bg-secondary-50", itemInvalid && "bg-red-100")}
                            >
                              <div
                                className={cn(
                                  index === selectedAccordionItem
                                    ? "border-b-2 border-secondary-950 rounded-t-lg"
                                    : "rounded-lg",
                                  "flex gap-1 items-center px-2 h-12 hover:cursor-pointer bg-secondary-100",
                                  itemInvalid && "bg-red-200"
                                )}
                              >
                                <DynamicWebhookItemDetails
                                  onClickFn={() => {
                                    index === selectedAccordionItem
                                      ? setSelectedAccordionItem(undefined)
                                      : setSelectedAccordionItem(index);
                                  }}
                                  name={`${index + 1}.`}
                                  responseCode={item.responseCode}
                                  repeats={item.repeat}
                                  delay={item.delay}
                                  contentType={item.contentType}
                                  contentSize={getContentSizeInKB(item.responseData, item.contentType)}
                                />

                                <Separator className="w-[2px]" orientation="vertical" />

                                <div className="flex items-center gap-1">
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    scheme="link"
                                    className="hover:bg-secondary-50"
                                    size="icon"
                                    disabled={item.repeat === 1}
                                    onClick={() => {
                                      setTotalRequests(totalRequests - 1);
                                      formik.setFieldValue(`items[${index}].repeat`, item.repeat - 1);
                                    }}
                                  >
                                    <MinusCircle className="w-4" />
                                  </Button>
                                  <Tooltip>
                                    <TooltipTrigger asChild>
                                      <div className="flex items-center justify-center font-semibold text-xs w-4">
                                        {item.repeat}
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <Badge className="bg-secondary-950 hover:bg-secondary-950">
                                        <p className="text-xs font-semibold">Repeats</p>
                                      </Badge>
                                    </TooltipContent>
                                  </Tooltip>
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    scheme="link"
                                    className="hover:bg-secondary-50 focus:outline-none"
                                    size="icon"
                                    disabled={totalRequests >= 10}
                                    onClick={() => {
                                      setTotalRequests(totalRequests + 1);
                                      formik.setFieldValue(`items[${index}].repeat`, item.repeat + 1);
                                    }}
                                  >
                                    <PlusCircle className="w-4" />
                                  </Button>
                                </div>

                                <Separator className="w-[2px]" orientation="vertical" />

                                <div className="flex">
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    scheme="link"
                                    className="hover:bg-secondary-50"
                                    size="icon"
                                    disabled={formik.values.items.length === 1}
                                    onClick={() => {
                                      setTotalRequests(totalRequests - item.repeat);
                                      remove(index);
                                    }}
                                  >
                                    <Trash2 className="w-4 text-red-600" />
                                  </Button>
                                </div>
                              </div>
                              <AccordionContent>
                                <DynamicWebhookItemContent index={index} item={item} formik={formik} />
                              </AccordionContent>
                            </Card>
                          </AccordionItem>
                        </div>
                      );
                    })}
                    <Button
                      scheme="black"
                      size="sm"
                      disabled={totalRequests >= 10}
                      onClick={(e) => {
                        e.preventDefault();
                        setTotalRequests(totalRequests + 1);
                        push(successResponse);
                      }}
                      className="w-16"
                    >
                      <Plus className="w-5" />
                    </Button>
                  </div>
                )}
              </FieldArray>
            </div>
          </Accordion>
        </TooltipProvider>
        <div className="flex justify-end">
          <Button
            type="submit"
            size="sm"
            disabled={createDynamicWebhookMutation.isPending}
            className="bg-primary-300 min-w-32"
          >
            {createDynamicWebhookMutation.isPending ? "Creating Webhook..." : "Create"}
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
