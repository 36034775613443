import { ReactNode } from "react";
import { State, StateConfig } from "../state";
import { X509Certificate } from "@peculiar/x509";
import { ExpiresInfo, StateExpires } from "../state.expires";

export class CertificateState extends State<X509Certificate> implements StateExpires {
  constructor(value: X509Certificate) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      validations: "tags-only",
      validationTags: ["expires"],
      stringValue: false,
      jsonValue: false,
      customDisplay: ["PEM", "Text", "Base64", "Hex"],
      defaultDisplayMode: "Text",
    };
  }

  getExpiresInfo(): ExpiresInfo {
    return {
      validFrom: this.value.notBefore,
      validTo: this.value.notAfter,
      resourceName: "Certificate",
      expiresRequired: true,
    };
  }

  stringValue(): string {
    throw new Error("Method not implemented.");
  }

  toReactNode(displayMode: string): ReactNode {
    //"asn" | "text" | "hex" | "base64" | "base64url";
    if (displayMode === "PEM") {
      return <pre>{this.value.toString("pem")}</pre>;
    } else if (displayMode === "Text") {
      return <pre>{this.value.toString("text")}</pre>;
    } else if (displayMode === "Hex") {
      return <pre className="whitespace-pre-wrap w-full">{this.value.toString("hex")}</pre>;
    } else if (displayMode === "Base64") {
      return <pre className="whitespace-pre-wrap w-full">{this.value.toString("base64")}</pre>;
    }
  }
}
