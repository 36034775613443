import { Attachment } from "@protontech/jsmimeparser";

interface AttachmentsProps {
  attachments: { blob: Blob; data: Attachment }[];
}

export const Attachments: React.FC<AttachmentsProps> = ({ attachments }: AttachmentsProps) => {
  const downloadAttachment = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = filename || "attachment";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="h-full max-w-full">
      <div className="flex flex-col gap-4 p-4">
        <p className="text-sm font-semibold">Attachments</p>
        <div className="flex gap-0 flex-col">
          {attachments.map((attachment, index) => {
            return (
              <div className="flex gap-2 items-center">
                <p
                  key={index}
                  className="text-sm font-mono hover:underline hover:cursor-pointer"
                  onClick={() => downloadAttachment(attachment.blob, attachment.data.fileName || "attachment")}
                >
                  {attachment.data.fileName}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
