import React from "react";
import { Extension, lineNumbers, useCodeMirror } from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { markdown } from "@codemirror/lang-markdown";
import { xml } from "@codemirror/lang-xml";
import { yaml } from "@codemirror/lang-yaml";
import { githubDark } from "@uiw/codemirror-theme-github";
import { cn } from "@/utils/ui.util";

// Define language extensions
const languageMap: Record<string, Extension> = {
  json: json(),
  typescript: javascript({ jsx: true, typescript: true }),
  javascript: javascript({ jsx: true }),
  html: html(),
  css: css(),
  markdown: markdown(),
  // not supported, only in extensions?: shell: shell(),
  xml: xml(),
  yaml: yaml(),
};

// Define props for the CodeBlock component
interface CodeBlockProps {
  code: string;
  language?: keyof typeof languageMap;
  minHeight?: number;
  resizable?: boolean;
  readonly?: boolean;
  handleCodeChange?: (code: string | undefined) => void;
  className?: string;
  placeholder?: string;
}

// CodeBlock component definition
export const CodeBlock: React.FC<CodeBlockProps> = ({
  code,
  language,
  minHeight,
  readonly = true,
  resizable = true,
  handleCodeChange,
  className,
  placeholder,
}) => {
  const editor = React.useRef(null);

  // Define extensions for CodeMirror
  const extensions = [
    EditorView.lineWrapping,
    EditorView.contentAttributes.of({ style: "font-size: 11pt;" }),
    lineNumbers(),
  ];

  // Add language-specific extension if available
  if (language && languageMap[language]) {
    extensions.push(languageMap[language]);
  }

  const { setContainer } = useCodeMirror({
    container: editor.current,
    extensions,
    value: code,
    theme: githubDark,
    readOnly: readonly,
    indentWithTab: true,
    placeholder: placeholder,
    onChange: handleCodeChange,
    minHeight: minHeight ? `${minHeight}px` : "",
    maxHeight: !resizable ? `${minHeight}px` : "",
  });

  React.useEffect(() => {
    setContainer(editor.current);
  }, [setContainer]);

  return (
    <div className={cn("rounded-lg overflow-auto bg-secondary-950", className)}>
      <div ref={editor} />
    </div>
  );
};
