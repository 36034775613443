import { TransformationOptionsSelectBase, toToolIcon } from "./options.transformations";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import React from "react";

type TransformationOptionsSelectProps<T extends TransformationOptionsSelectBase> = {
  options: T;
  onChange: (options: T) => void;
};

// Define the component as a React.FC
export const TransformationOptionsSelect: React.FC<
  TransformationOptionsSelectProps<TransformationOptionsSelectBase>
> = ({ options, onChange }) => {
  const selectId = React.useId();
  if (!options.values) return null; // Handling the case where values might be undefined or empty

  return (
    <div className="flex min-h-11">
      <div className="flex neu-flat px-2 rounded-l-md bg-zinc-300 items-center text-nowrap" key={options.selected}>
        <Label htmlFor={selectId}>{options.label}</Label>
      </div>
      <Select onValueChange={(value) => onChange({ ...options, selected: value })} value={options.selected}>
        <SelectTrigger id={selectId} className="rounded-l-none border-l-0">
          <SelectValue placeholder={options.label} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.entries(options.values).map(([value, label]) => (
              <SelectItem key={value} value={value}>
                <div className="flex items-center">
                  {toToolIcon(options.icons?.[value] ?? null)}
                  {label || value}
                </div>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
