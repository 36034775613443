import { Button } from "@/components/ui/button";
import clsx from "clsx";
import { CheckCircle2 } from "lucide-react";
import React from "react";

interface KeyFeaturesProps {
  features?: React.ReactNode[];
  className?: string;
  onClick?: () => void;
}

export const KeyFeatures: React.FC<KeyFeaturesProps> = ({ features, className, onClick }) => {
  return (
    <div className={clsx("flex flex-col gap-3", className)}>
      {features?.map((feature, i) => (
        <Button
          key={`feature-${i}`}
          onClick={onClick}
          className="neu-button !px-4 !py-2 h-fit flex items-center !justify-start !text-left gap-4 rounded-md bg-tertiary-200 hover:bg-white active:bg-white active:border-tertiary-700 active:text-tertiary-700 select-none"
        >
          <CheckCircle2 className="h-8 w-8 current" />
          <p className="w-full">{feature}</p>
        </Button>
      ))}
    </div>
  );
};
