import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { DateState, InvalidState } from "../states";
import { TransformationOptionsSelect } from "./common-options/options.select.transformation";
import { ToolsIcons } from "./common-options/options.transformations";
import { IANAZone } from "luxon";

type TimezoneMode = {
  selected: string;
  values: Record<string, string>;
  label: string;
  icons?: Record<string, ToolsIcons>;
};

export class MainipulateDateTransformation extends StateTransformation<State, TimezoneMode> {
  constructor() {
    super("mainipulate-date", "Manipulate", ["date"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (DateState.is(state)) return CanTransformResult.Recommended;
    return CanTransformResult.No;
  }

  async transform(state: State, options: TimezoneMode): Promise<State> {
    if (DateState.is(state)) {
      const timezone = options?.selected;
      const date = state.value.setZone(timezone, { keepLocalTime: true });
      return new DateState(date, "set timezone to " + timezone);
    }

    return new InvalidState("Not a Date");
  }

  async getOptions(_: State, options?: TimezoneMode): Promise<TimezoneMode | undefined> {
    const allTimezones = Intl.supportedValuesOf("timeZone");
    const validTimezones = allTimezones.filter(IANAZone.isValidZone);
    // console.log(allTimezones, validTimezones  );
    const retVal = {
      selected: options?.selected || validTimezones[0],
      values: Object.fromEntries(validTimezones.map((timezone) => [timezone, timezone])),
      label: "Change Timezone",
    };
    return retVal;
  }

  getOptionsReactNode(options: TimezoneMode, onChange: (options: TimezoneMode) => void): React.ReactNode {
    return <TransformationOptionsSelect onChange={onChange as any} options={options} />;
  }
}

export default new MainipulateDateTransformation();
