import { State, StateConfig } from "../state";

export class XMLState extends State<string> {
  constructor(value: string) {
    super(value);
  }

  config(): StateConfig {
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: false,
      customDisplay: null,
      defaultDisplayMode: "string",
    };
  }

  stringValue(): string {
    return this.value;
  }
}
