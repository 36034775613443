import { ForgotPasswordForm } from "@/lib/auth/forgot-password-form";

export const ForgotPassword: React.FC = () => {
  return (
    <ForgotPasswordForm
      title={"Forgot Password"}
      description={"Enter your email address to receive a password reset code."}
    />
  );
};
