import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { StringState } from "../states";
import { BlobState } from "../states/blob.state";
import ExifReader from "exifreader";

export class ToExifTransformation extends StateTransformation<StringState> {
  constructor() {
    super("image-exif", "Exif", ["blob", "image"]);
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (BlobState.is(state)) return CanTransformResult.Yes;
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<StringState> {
    if (BlobState.is(state)) {
      if (state.value == null) throw new Error("Invalid state, no data.");
      const result = ExifReader.load(await state.value.arrayBuffer(), { expanded: true, includeUnknown: true });
      return new StringState(JSON.stringify(result));
    }
    throw new Error("Input must be image Blob");
  }
}
export default new ToExifTransformation();
