import { ReactNode } from "react";
import { State, StateConfig } from "../state";

type KeyType = "public" | "private" | "certificate";

export class CryptoKeyState extends State<string> {
  constructor(
    value: string,
    readonly algorithms: string[],
    readonly type: KeyType
  ) {
    super(value);
  }

  get typeName(): string {
    switch (this.type) {
      case "public":
        return "Public key";
      case "private":
        return "Private key";
      case "certificate":
        return "Certificate";
    }
  }

  config(): StateConfig {
    return {
      transformations: "tags-only",
      validations: "tags-only",
      stringValue: true,
      jsonValue: false,
      customDisplay: [this.typeName],
      defaultDisplayMode: this.typeName,
    };
  }

  stringValue(): string {
    return this.value.toString();
  }

  toReactNode(displayMode: string): ReactNode {
    if (displayMode === this.typeName) {
      return (
        <div>
          <div className="text-xs bg-secondary-200">Type</div>
          <div>{this.typeName}</div>
          <div className="text-xs bg-secondary-200">Supported algorithms</div>
          <ul>
            {this.algorithms.map((algo) => (
              <li>{algo}</li>
            ))}
          </ul>
        </div>
      );
    } else if (displayMode === "string") {
      return <pre>{this.value}</pre>;
    }
  }
}
