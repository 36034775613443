import React from "react";
import { ApiMutation } from "@/api";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const CustomerPortal: React.FC = () => {
  const { toast } = useToast();

  const customerPortalSession = ApiMutation.useCutomerPortalSession({
    onSuccess: (data) => {
      if (data.session.url) {
        window.open(data.session.url, "_blank");
      }
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast({
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request. Please try again or contact support.",
        });
      }
    },
  });

  return (
    <Button
      size="sm"
      className="bg-primary-300 gap-1"
      onClick={() => {
        customerPortalSession.mutate({
          returnUrl: window.location.href,
        });
      }}
      disabled={customerPortalSession.isPending}
    >
      {customerPortalSession.isPending ? (
        <LoadingSpinner message={"Redirecting..."} />
      ) : (
        <>
          Manage Subscription
          <ArrowRight size={16} />
        </>
      )}
    </Button>
  );
};
