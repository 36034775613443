import React from "react";
import { EmailDetails } from "@/api/types/response";
import { CopyableInput } from "@/components/copyable-input";
import { RecentEmails } from "@/lib/emails/page/elements/recent-emails";
import { Separator } from "@/components/ui/separator";
import { ScrollArea } from "@/components/ui/scroll-area";
import { HoverInfo } from "@/components/hover-info";

interface EmailSidebarProps {
  email: string;
  items: EmailDetails[];
  id: string;
  selected?: EmailDetails;
}

export const EmailSidebar: React.FC<EmailSidebarProps> = ({ email, items, id, selected }) => {
  return (
    <>
      <div className="space-y-4 pt-4">
        <div className="flex flex-col gap-2 py-2 px-4">
          <h5 className="font-semibold tracking-tight">Generated email address</h5>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <small className="text-sm">Send your test emails to this address</small>
              <CopyableInput value={email} />
            </div>
          </div>
        </div>
        <Separator />
      </div>
      <ScrollArea>
        <div className="py-4">
          <h5 className="font-semibold tracking-tight mb-2 px-4">Inbox</h5>
          <div className="flex flex-col gap-4 px-4">
            {items.length === 0 && (
              <div className="flex items-center gap-2 mt-4 m-auto">
                <small>Received emails will appear here.</small>
                <HoverInfo message="It may take a few seconds for emails to appear here after they are sent." />
              </div>
            )}
            {items.length > 0 && <RecentEmails id={id} items={items} selected={selected} />}
          </div>
        </div>
      </ScrollArea>
    </>
  );
};
