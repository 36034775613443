import { Navigate, Outlet, useParams } from "react-router-dom";
import { useWebhookInfo } from "@/api/queries";
import { LoadingSpinner } from "@/components/loading-spinner";

export interface DynamicResponsePipeline {
  specifiedRetries?: number;
  contentTypes?: string[];
  responseData?: string[];
  responseCodes?: number[];
  delayBetweenRetries?: number[];
}

export const Webhooks: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { isPending, isError, data } = useWebhookInfo({
    webhook_id: id ?? "",
  });

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <Outlet
          context={{
            id: id,
            data: data,
          }}
        />
      )}
    </>
  );
};
