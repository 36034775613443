import { Button } from "@/components/ui/button";

interface SelectOptionProps {
  value: string;
  selected?: boolean;
  handleOnClick?: () => void;
}

export const SelectOption: React.FC<SelectOptionProps> = ({ value, selected, handleOnClick }) => {
  return (
    <Button variant="neu" scheme={selected ? "black" : "white-black"} className="h-12 " onClick={handleOnClick}>
      <p className="font-mono text-sm font-semibold">{value}</p>
    </Button>
  );
};
