import React from "react";
import { EmailDetails, EmailResponseEnvelope } from "@/api/types/response";
import useWebSocket from "react-use-websocket";
import { EmailPageCore } from "@/lib/emails/page/email-page-core";
import { useOutletContext, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface EmailPageOutletContext {
  id: string;
  email: string;
  data: EmailResponseEnvelope;
}

const socketUrl = `${import.meta.env.VITE_WEBSOCKET_URL}`;

export const EmailPage: React.FC = () => {
  const { id, email, data } = useOutletContext<EmailPageOutletContext>();
  const { emailId } = useParams<{ emailId: string }>();
  const queryClient = useQueryClient();

  const { lastMessage } = useWebSocket(socketUrl, {
    queryParams: { id: id, token: data.resourceJwt },
  });

  const [received, setReceived] = React.useState<EmailDetails[]>(data.emailDetails);

  React.useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data) as EmailDetails;
      if (message.to === email) {
        setReceived((prev) => [...prev, message]);
        queryClient.invalidateQueries({ queryKey: [["email", "received"], { email_address: email }] });
      }
    }
  }, [lastMessage, email, queryClient]);

  return <EmailPageCore id={id} email={email} items={received} emailId={emailId} />;
};
