import { ListState, State, StateConfig } from "../state";
import { ReactNode } from "react";

export class StringListState extends State<string[]> {
  constructor(
    value: string[],
    readonly type: string
  ) {
    super(value);
  }

  asList(): ListState | undefined {
    return { type: "string[]", value: this.value };
  }

  config(): StateConfig {
    return {
      transformations: "all",
      validations: "all",
      stringValue: true,
      jsonValue: true,
      customDisplay: [this.type],
      defaultDisplayMode: this.type,
    };
  }

  jsonValue(): object {
    return this.value;
  }
  stringValue(): string {
    return this.value.join("\n");
  }

  toReactNode(_: string): ReactNode {
    return (
      <div className="grid gap-x-5 gap-y-1 grid-cols-1 text-md font-mono">
        {this.value.map((line) => (
          <div className="flex  border-2 border-solid  select-all rounded-md bg-white px-3 py-2  ring-offset-white  ">
            {line}
          </div>
        ))}
      </div>
    );
  }
}
