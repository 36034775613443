import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, JsonState, XMLState } from "../states";
import { calculateTransformResult, parseStringToJson } from "../utils/json-utils";
import { xml2json } from "../utils/xml2json";
import { ToolsIcons } from "./common-options/options.transformations";

export class ToJsonTransformation extends StateTransformation<State> {
  constructor() {
    super("to-json", "To JSON", ["string", "xml"]);
  }

  icon(): ToolsIcons {
    return "json";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().jsonValue) return calculateTransformResult(state.jsonValue());

    if (state.config().stringValue) {
      const input = state.stringValue();
      try {
        const res = parseStringToJson(atob(input));
        if (res !== null) {
          return calculateTransformResult(res);
        }
      } catch {
        /* empty */
      }

      return CanTransformResult.No;
    }

    return CanTransformResult.No;
  }

  async transform(state: State): Promise<State> {
    if (state.config().jsonValue) {
      return new JsonState(state.jsonValue(), "JSON");
    }
    if (state.config().stringValue) {
      const parsed = parseStringToJson(state.stringValue());
      if (parsed !== null) return new JsonState(parsed, "JSON");

      if (XMLState.is(state)) {
        const parsed = parseStringToJson(xml2json(state.value, ""));
        if (parsed !== null) return new JsonState(parsed, "JSON");
      }

      try {
        const res = parseStringToJson(atob(state.stringValue()));
        if (res !== null) return new JsonState(res, "JSON");
      } catch (_) {
        /* empty */
      }
    }

    return new InvalidState("Invalid JSON value");
  }
}

export default new ToJsonTransformation();
