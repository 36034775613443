import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { DateState, InvalidState, NumberState } from "../states";
import { DateTime } from "luxon";
import parser from "any-date-parser";
import { ToolsIcons } from "./common-options/options.transformations";

type AnyParserDate = {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  millisecond: number;
  offset: number;
  invalid: boolean;
};

function isUnixMilliseconds(timestamp: number): boolean {
  // Threshold for max Unix timestamp in seconds for 32-bit systems
  const maxUnixSeconds = 2147483647;

  // If the timestamp is greater than the max 32-bit Unix timestamp, it's likely in milliseconds
  if (timestamp > maxUnixSeconds) {
    return true;
  }

  // Convert the timestamp to a date assuming it's milliseconds
  const dateFromMilli = new Date(timestamp);

  // Check if the year is within a reasonable range to assume it's milliseconds
  const year = dateFromMilli.getUTCFullYear();
  if (year > 1970 && year < 2100) {
    return true;
  }

  // Otherwise, consider the possibility it could be seconds by checking the year range
  const dateFromSeconds = new Date(timestamp * 1000);
  const yearFromSeconds = dateFromSeconds.getUTCFullYear();
  if (yearFromSeconds > 1970 && yearFromSeconds < 2100) {
    return false;
  }

  // Default to false if none of the conditions indicate a millisecond timestamp
  return false;
}

export class ToDateTransformation extends StateTransformation<State> {
  constructor() {
    super("to-date", "To Date", ["string", "number"]);
  }

  icon(): ToolsIcons {
    return "date";
  }

  parse(input: string): [DateTime, string, boolean] {
    input = input.trim();
    if (input.length === 0) throw new InvalidState("Empty string", "", "Empty");
    let d = DateTime.fromISO(input, { setZone: true });
    if (d.isValid) return [d, "luxon fromISO", true];
    d = DateTime.fromRFC2822(input, { setZone: true });
    if (d.isValid) return [d, "luxon fromRFC2822", true];
    d = DateTime.fromSQL(input, { setZone: true });
    if (d.isValid) return [d, "luxon fromSQL", true];
    d = DateTime.fromHTTP(input, { setZone: true });
    if (d.isValid) return [d, "luxon fromHTTP", true];

    const num = Number(input);
    if (!isNaN(num) && num !== 0) {
      if (isUnixMilliseconds(num)) {
        d = DateTime.fromMillis(Number(input));
        if (d.isValid) return [d, "luxon UNIX millis", false];
      } else {
        d = DateTime.fromSeconds(Number(input));
        if (d.isValid) return [d, "luxon UNIX", false];
      }
    }
    const anyDate: AnyParserDate = parser.attempt(input);
    //console.log("anyDate", anyDate);
    if (anyDate.invalid) throw new Error("Invalid date");

    //if (anyDate.month) anyDate.month -= 1;

    d = DateTime.fromObject(anyDate);
    if (d.isValid) return [d, "any-date-parser", false];

    throw new Error("Invalid date");
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().stringValue || NumberState.is(state)) {
      const str = state.stringValue().trim();
      if (str.length === 0) return CanTransformResult.No;
      if (str.length > 100) return CanTransformResult.No; // dates longer than 100 characters almost 100% not dates
      try {
        const [date, _, sure] = this.parse(str);

        if (!sure) {
          if (Math.abs(date.diffNow("years").years) < 25) return CanTransformResult.Likely; // dates detected to be withing 25 of today are more likely to bo correct
          return CanTransformResult.Maybe;
        }
        if (str.length < 6) return CanTransformResult.Likely; // dates less than 6 characters are less likely to be dates
        return CanTransformResult.Yes;
      } catch {
        return CanTransformResult.No;
      }
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<State> {
    if (state.config().stringValue) {
      const [date, source] = this.parse(state.stringValue());
      return new DateState(date, source);
    }

    return new InvalidState("Invalid date");
  }
}

export default new ToDateTransformation();
