import { LocalStorageDataBase, LocalStorageKeyType, LocalStorageService } from "@/utils/storage/localstorage-service";
import React from "react";

const ttl = 3600000; // 1 hour

export function useFilteredItems<T extends LocalStorageDataBase>(localstorageKey: LocalStorageKeyType) {
  const items = LocalStorageService.retrieve<T>(localstorageKey);

  const filteredItems = React.useMemo(() => {
    const filteredItems = items.filter((item) => {
      const createdTimestamp = new Date(item.created).getTime();
      const ttlMinutesAgo = Date.now() - ttl;

      return createdTimestamp > ttlMinutesAgo;
    });

    if (filteredItems.length !== items.length) {
      LocalStorageService.storeAll<T>(localstorageKey, filteredItems);
    }

    return filteredItems;
  }, [items, localstorageKey]);

  return { filteredItems, ttl };
}
