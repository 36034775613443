import { Card } from "@/components/ui/card";
import { CircleOff } from "lucide-react";

interface WebhookStatusCardProps {
  message?: string | React.ReactNode;
  icon?: React.ReactNode;
  description?: string | React.ReactNode;
}

export const WebhookStatusCard: React.FC<WebhookStatusCardProps> = ({ message, icon, description }) => {
  return (
    <Card className="bg-primary-100 flex w-full px-2 py-2 gap-2">
      <div className="mt-1">{icon ?? <CircleOff size={20} />}</div>
      <div className="flex flex-col gap-2">
        <h5 className="tracking-tight">{message}</h5>
        {description && <span className="text-sm">{description}</span>}
      </div>
    </Card>
  );
};
