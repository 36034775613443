import { hash, hmac } from "./hash-utils";
import stringUtils from "./string-utils";

export type CompareStringResults =
  | "notEquals"
  | "equals"
  | "trimmedEquals"
  | "caseInsensitiveEquals"
  | "trimmedCaseInsensitiveEquals";

export function compareStrings(str1: string | null, str2: string | null): CompareStringResults {
  if (str1 === str2) return "equals";
  if (str1 === null || str2 == null) return "notEquals";

  const str1Trim = str1.trim();
  const str2Trim = str2.trim();

  const str1LC = str1.toLowerCase();
  const str2LC = str2.toLowerCase();

  if (str1Trim === str2Trim) return "trimmedEquals";
  if (str1LC === str2LC) return "caseInsensitiveEquals";
  if (str1Trim.toLowerCase() === str2Trim.toLowerCase()) return "trimmedCaseInsensitiveEquals";

  return "notEquals";
}

export function compareResultToString(result: CompareStringResults): { description: string; equals: boolean } {
  switch (result) {
    case "notEquals":
      return { description: "Strings are not equal", equals: false };
    case "equals":
      return { description: "Strings are equal", equals: true };
    case "trimmedEquals":
      return { description: "Strings are equal when trimmed", equals: true };
    case "caseInsensitiveEquals":
      return { description: "Strings are equal when case insensitive", equals: true };
    case "trimmedCaseInsensitiveEquals":
      return { description: "Strings are equal when trimmed and case insensitive", equals: true };
    default:
      return { description: "Strings are not equal", equals: false };
  }
}

type TransformString = (s: string) => string;

type CompareTransform = keyof typeof compareTransformsInternal;

const compareTransformsInternal: Record<string, TransformString> = {
  identity: stringUtils.identity,
  trim: stringUtils.trim,
  normalizeWhitespace: stringUtils.normalizeWhitespace,
  normalizeWhitespaceWithTrim: stringUtils.normalizeWhitespaceWithTrim,
  toLower: stringUtils.toLower,
  toUpper: stringUtils.toUpper,
  base64Encode: stringUtils.base64Encode,
  base64Decode: stringUtils.base64Decode,
  base64UrlEncode: stringUtils.base64UrlEncode,
  base64UrlDecode: stringUtils.base64UrlDecode,
  urlEncode: stringUtils.urlEncode,
  urlDecode: stringUtils.urlDecode,
  appendSpaceFront: stringUtils.appendSpaceFront,
  appendSpaceBack: stringUtils.appendSpaceBack,
  md5: hash.md5,
  sha1: hash.sha1,
  sha256: hash.sha256,
  sha224: hash.sha224,
  sha512: hash.sha512,
  sha384: hash.sha384,
  sha3: hash.sha3,
  ripemd160: hash.ripemd160,
};

export const compareTransformsScore: Record<CompareTransform, number> = {
  identity: 0,
  trim: 1,
  normalizeWhitespace: 1,
  normalizeWhitespaceWithTrim: 1.5,
  toLower: 1,
  toUpper: 1,
  base64Encode: 1,
  base64Decode: 1,
  base64UrlEncode: 1,
  base64UrlDecode: 1,
  urlEncode: 1,
  urlDecode: 1,
  md5: 1,
  sha1: 1,
  sha256: 1,
  sha224: 1,
  sha512: 1,
  sha384: 1,
  sha3: 1,
  ripemd160: 1,
  hmacMd5: 1,
  hmacSha1: 1,
  hmacSha256: 1,
  hmacSha224: 1,
  hmacSha512: 1,
  hmacSha384: 1,
  hmacSha3: 1,
  hmacRipemd160: 1,
  appendSpaceFront: 1,
  appendSpaceBack: 1,
};

type CompareResult = {
  bestScore: number;
  results: CompareResultItem[];
};

interface CompareResultItem {
  successPath: CompareTransform[];
  successMessage: CompareStringResults;
  s2transform: CompareTransform;
  score: number;
}

type CompareStepResult =
  | {
      continue: false;
    }
  | {
      continue: true;
      transformedS1: string;
    };

const compareStep = (
  s1: string,
  s2: string,
  step: CompareTransform,
  path: CompareTransform[],
  results: CompareResult,
  s2step: CompareTransform,
  compareTransforms: Record<string, TransformString>
): CompareStepResult => {
  try {
    const transform = compareTransforms[step];
    //console.log(`Comparing '${s1}' to '${s2}' with ${step}, ${path}, s2step: ${s2step}`);
    const transformedS1 = transform(s1);
    if (s1 === transformedS1 && step !== "identity") return { continue: false };
    // const result = compareStrings(trans1, s2);
    // if (result !== "notEquals") {
    //   results.push({ successPath: path, successMessage: result, s2transform: s2step, score: path.length + s2step === "identity" ? 0 : 1});
    //   return true;
    // }

    if (transformedS1 === s2) {
      const score = path.reduce((acc, step) => acc + compareTransformsScore[step], 0) + compareTransformsScore[s2step];
      if (score <= results.bestScore) {
        results.results.push({
          successPath: path.filter((x) => x !== "identity"),
          successMessage: "equals",
          s2transform: s2step,
          score: score,
        });
        results.bestScore = score;
      }

      return { continue: false };
    }
    return { continue: true, transformedS1: transformedS1 };
  } catch (_) {
    return { continue: false };
  }
};

export const smartCompare = (s1: string, s2: string, hmacKey?: string) => {
  function process(
    s1: string,
    s2: string,
    paths: CompareTransform[][],
    currentPath: CompareTransform[],
    currentS2Step: CompareTransform
  ) {
    const [processPath, ...pathLeft] = paths;

    for (let i = 0; i < processPath.length; i++) {
      const currentTransform = processPath[i];
      const newPath = currentPath.concat([currentTransform]);

      const score =
        newPath.reduce((acc, step) => acc + compareTransformsScore[step], 0) + compareTransformsScore[currentS2Step];
      if (score > compareResult.bestScore) continue;

      const result = compareStep(s1, s2, processPath[i], newPath, compareResult, currentS2Step, compareTransforms);
      if (!result.continue) continue;

      if (pathLeft.length > 0) process(result.transformedS1, s2, pathLeft, newPath, currentS2Step);
    }
  }

  const compareResult: CompareResult = { bestScore: 1000, results: [] };

  const steps: CompareTransform[] = [
    "identity",
    "trim",
    "normalizeWhitespace",
    "toLower",
    "toUpper",
    "normalizeWhitespaceWithTrim",
    "appendSpaceFront",
    "appendSpaceBack",
  ];
  // steps to perform on compare string
  const s2steps: CompareTransform[] = [
    "identity",
    "trim",
    "normalizeWhitespace",
    "toLower",
    "toUpper",
    "normalizeWhitespaceWithTrim",
  ];
  const decodes: CompareTransform[] = ["base64Decode", "base64UrlDecode", "urlDecode", "identity"];
  const encodes: CompareTransform[] = [
    "base64Encode",
    "base64UrlEncode",
    "urlEncode",
    "identity",
    "md5",
    "sha1",
    "sha256",
    "sha224",
    "sha512",
    "sha384",
    "sha3",
    "ripemd160",
  ];

  let compareTransforms: Record<string, TransformString> = compareTransformsInternal;
  if (hmacKey) {
    compareTransforms = {
      ...compareTransformsInternal,

      hmacMd5: (s: string) => hmac.md5(s, hmacKey),
      hmacSha1: (s: string) => hmac.sha1(s, hmacKey),
      hmacSha256: (s: string) => hmac.sha256(s, hmacKey),
      hmacSha224: (s: string) => hmac.sha224(s, hmacKey),
      hmacSha512: (s: string) => hmac.sha512(s, hmacKey),
      hmacSha384: (s: string) => hmac.sha384(s, hmacKey),
      hmacSha3: (s: string) => hmac.sha3(s, hmacKey),
      hmacRipemd160: (s: string) => hmac.ripemd160(s, hmacKey),
    };
    encodes.push(
      "hmacMd5",
      "hmacSha1",
      "hmacSha256",
      "hmacSha224",
      "hmacSha512",
      "hmacSha384",
      "hmacSha3",
      "hmacRipemd160"
    );
  }
  // const directCompare = compareStrings(s1, s2);
  // if (directCompare !== "notEquals") return [{ successPath: [], successMessage: directCompare }];

  for (let s2step = 0; s2step < s2steps.length; s2step++) {
    const currentS2Step: CompareTransform = s2steps[s2step];
    const processedS2 = compareTransforms[currentS2Step](s2);
    if (currentS2Step !== "identity" && processedS2 === s2) continue;

    process(s1, processedS2, [steps, steps, decodes, encodes, steps], [], currentS2Step);
  }

  return compareResult.results.sort((a, b) => a.score - b.score);
};
