import { State } from "../state";
import { CanTransformResult, StateTransformation } from "../state-transformation";
import { InvalidState, JsonState } from "../states";
import { parse as parseTOML } from "smol-toml";
import { ToolsIcons } from "./common-options/options.transformations";
import { calculateTransformResult, parseStringToJson } from "../utils/json-utils";

export class ToTomlTransformation extends StateTransformation<JsonState | InvalidState> {
  constructor() {
    super("to-toml", "To TOML", ["string"]);
  }

  icon(): ToolsIcons {
    return "json";
  }

  async canTransform(state: State): Promise<CanTransformResult> {
    if (state.config().jsonValue) return 0.5 * calculateTransformResult(state.jsonValue());

    if (state.config().stringValue) {
      try {
        const input = state.stringValue();
        if (parseStringToJson(input) !== null) return CanTransformResult.No;
        const res = parseTOML(input);
        return calculateTransformResult(res);
      } catch {
        /* empty */
      }
    }
    return CanTransformResult.No;
  }

  async transform(state: State): Promise<JsonState | InvalidState> {
    if (state.config().jsonValue) {
      return new JsonState(state.jsonValue(), "TOML");
    }
    if (state.config().stringValue) {
      try {
        const toml = parseTOML(state.stringValue());
        if (toml) return new JsonState(toml, "TOML");
      } catch {
        /* empty */
      }
    }

    return new InvalidState("Invalid JSON value");
  }
}

export default new ToTomlTransformation();
