import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import clsx from "clsx";
import React from "react";
import { StatePreview } from "./StatePreview";
import { useCurrentTransformation } from "./useCurrentTransformation";
import { ToolsSnakeNode, UpdateSnakeItemWithIndexFunction, getTransformationById } from "./state-utils";
import { toToolIcon } from "./transformations/common-options/options.transformations";
import { SnakeContext, ValidTransformation } from "./state";

interface StateDisplayProps {
  hideValue?: boolean;
  classNames?: Partial<Record<"tabs", string>>;
  node: ToolsSnakeNode;
  nodeIndex: number;
  updateItem: UpdateSnakeItemWithIndexFunction;
  snakeContext: SnakeContext;
}

export const StateDisplay: React.FC<StateDisplayProps> = ({
  hideValue,
  classNames,
  node,
  nodeIndex,
  updateItem,
  snakeContext,
}) => {
  const { allTransformations, validTransformations, optionsRender, state, transformationId } = useCurrentTransformation(
    {
      node,
      updateTransformation: (transformationId, transformationOptions) => {
        updateItem(nodeIndex, { transformationId, options: transformationOptions });
      },
    }
  );
  const [showAllTransformations, setShowAllTransformations] = React.useState(false);

  let transformations: ValidTransformation[] = [];

  if (transformationId) {
    const currentTransformation = getTransformationById(transformationId);
    if (currentTransformation) {
      transformations = [{ transformation: currentTransformation, valid: 1.5 }];
    }
  }
  if (transformations.length === 0) {
    if (showAllTransformations) {
      transformations = allTransformations.map((t) => ({ transformation: t, valid: 1 }));
    } else {
      transformations = validTransformations;
    }
  }

  return (
    <div className="flex flex-col gap-2 items-center w-full">
      {!hideValue && <StatePreview state={state} snakeContext={snakeContext} />}

      {!!transformations.length && (
        <>
          {/* <ArrowBigDownDash size={24} className="fill-black" /> */}

          <Tabs
            value={transformationId}
            onValueChange={(value) => {
              if (value === undefined) return;
              if (value === "more") {
                setShowAllTransformations(true);
                updateItem(nodeIndex, { transformationId: undefined });
              } else if (value === "less") {
                setShowAllTransformations(false);
                updateItem(nodeIndex, { transformationId: undefined });
              } else {
                updateItem(nodeIndex, { transformationId: value as any });
              }
            }}
            className="w-fit"
          >
            <TabsList className={clsx("flex flex-wrap h-fit gap-1.5 w-fit", classNames?.tabs)}>
              {transformations.map(({ valid, transformation }, i) => {
                return (
                  <TabsTrigger
                    key={`tabs-trigger-${i}`}
                    value={transformation.id}
                    disabled={!valid}
                    className="data-[state='active']:!bg-element-transformation"
                  >
                    {toToolIcon(transformation.icon())} {transformation.id}
                    {valid < 1 && ` (${valid * 100}%)`}
                  </TabsTrigger>
                );
              })}

              {optionsRender && <div className="bg-white px-4 py-2 rounded-lg">{optionsRender}</div>}

              <TabsTrigger key={`tabs-trigger-more`} value={showAllTransformations ? "less" : "more"} className="">
                {toToolIcon("circleEllipsis")} {showAllTransformations ? "Less" : "More"}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </>
      )}

      {/* {JSON.stringify(selectedTransformationOptions)} */}
    </div>
  );
};
