import { cn } from "@/utils/ui.util";
import { useEffect, useState } from "react";

export interface BlobImageProps {
  blob: Blob;
  className?: string;
}

export const BlobImage: React.FC<BlobImageProps> = ({ blob, className }: BlobImageProps) => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    let url: string | null = null;
    url = URL.createObjectURL(blob);
    setSrc(url);

    return () => {
      if (url !== null) {
        URL.revokeObjectURL(url);
      }
    };
  }, [blob]);

  return <>{src && <img src={src} alt="blob" className={cn("", className)} />}</>;
};
