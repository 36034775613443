import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TransformationOptionsSelectBase } from "./options.transformations";
import { cn } from "@/utils/ui.util";

type TabsComponentProps<T extends TransformationOptionsSelectBase> = {
  options: T;
  onChange: (options: T) => void;
};

export const TransformationOptionsSelectAsList: React.FC<TabsComponentProps<TransformationOptionsSelectBase>> = ({
  options,
  onChange,
}) => {
  // If the values are undefined or empty, render nothing.
  if (!options.values) return null;

  // CSS classes for styling the tabs.
  const classNameTabs = '[&>*]:bg-white [&>[data-state="active"]]:bg-primary-300 h-12';

  return (
    <Tabs
      value={options.selected}
      onValueChange={(value) => onChange({ ...options, selected: value })}
      className="w-fit"
    >
      <TabsList className={cn("flex flex-nowrap h-fit gap-1.5", classNameTabs)}>
        {Object.entries(options.values).map(([value, label], index) => (
          <TabsTrigger
            key={`tab-trigger-${index}`}
            value={value}
            className="neu flex-1 w-24 h-12 px-2 py-2 whitespace-nowrap"
          >
            {label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
