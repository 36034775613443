import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export const usePricingTiers = () => {
  const awsMarketplaceURL =
    "https://aws.amazon.com/marketplace/pp/prodview-iexicxypemf54?sr=0-1&ref_=beagle&applicationId=AWSMPContessa";

  const pricingTiers = [
    {
      name: "Free",
      description: "Jumpstart your projects with basic webhook testing tools.",
      price: 0,
      currency: "EUR",
      features: ["Low rate limit", "1-day data persistence"],
      actionButton: (
        <Button asChild className="bg-secondary-700 text-white gap-2">
          <Link to="/dashboard" className="no-underline">
            Try Now
          </Link>
        </Button>
      ),
    },
    {
      name: "Signed Up",
      description: "Keep the momentum with extended data retention.",
      price: 0,
      currency: "EUR",
      features: ["Medium rate limit", "7-day data persistence"],
      actionButton: (
        <Button asChild className="bg-secondary-700 text-white gap-2">
          <Link to="/auth" className="no-underline">
            Sign In
          </Link>
        </Button>
      ),
    },
    {
      name: "Paid",
      description: "For professionals needing advanced testing features.",
      price: 299,
      currency: "$",
      options: {
        popular: true,
      },
      features: ["High rate limit", "Permanent data persistence", "Webhook aliasing", "S3 bucket data retention"],
      actionButton: (
        <Button asChild className="bg-secondary-700 text-white gap-2">
          <Link to="/auth/register" className="no-underline">
            Get Started
          </Link>
        </Button>
      ),
    },
    {
      name: "Enterprise",
      description: "Comprehensive solutions for large-scale operations.",
      price: "AWS Marketplace",
      features: [
        "AWS Marketplace billing",
        "High rate limit",
        "Permanent data persistence",
        "Webhook aliasing",
        "S3 bucket data retention",
        "Custom domain",
      ],
      actionButton: (
        <Button asChild className="bg-secondary-700 text-white gap-2">
          <Link to={awsMarketplaceURL} target="_blank" className="no-underline">
            Get Started
          </Link>
        </Button>
      ),
    },
  ];

  return pricingTiers;
};
