import { Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import React from "react";
import { User } from "@/api/types/response";

interface AdminDeleteAccountUserProps {
  user: User;
}

export const AdminDeleteAccountUser: React.FC<AdminDeleteAccountUserProps> = () => {
  return (
    <div>
      <Button size="icon" variant="ghost" className="hover:hover:bg-red-300" disabled>
        <Trash2 size={16} />
      </Button>
    </div>
  );
};
