import React from "react";
import { Button } from "@/components/ui/button";
import classes from "@/lib/webhooks/page/elements/webhooks.module.scss";
import { WebhookResponse } from "@/api/types/response";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ApiMutation } from "@/api";
import { toast } from "@/components/ui/use-toast";
import { useFormik, FieldArray, FormikProvider } from "formik";
import { Input } from "@/components/ui/input";
import * as Yup from "yup";
import { cn } from "@/utils/ui.util";
import { Cog, Plus, Trash2 } from "lucide-react";
import { CodeBlock } from "@/components/code-block";
import { useQueryClient } from "@tanstack/react-query";
import mime from "mime";

const validationSchema = Yup.object().shape({
  statusCode: Yup.number()
    .typeError("Status code must be a number")
    .required("Status code is required")
    .positive("Status code must be a positive number")
    .integer("Status code must be an integer")
    .min(200, "Status code must be greater than or equal to 200")
    .max(599, "Status code must be less than or equal to 599"),
  body: Yup.string().required("Response body is required"),
  headers: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required("Header key is required"),
      value: Yup.string().required("Header value is required"),
    })
  ),
});

interface EditResponsePopoverProps {
  id: string;
  response: WebhookResponse;
}

export const EditResponsePopover: React.FC<EditResponsePopoverProps> = ({ id, response }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <div>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setDialogOpen(!dialogOpen)}
        className={`pt-2 bg-transparent h-fit text-md ${classes["icon-button"]}`}
      >
        <span>
          <Cog size={15} />
        </span>
        <span>
          <Cog size={15} className={"text-secondary-400"} />
        </span>
      </Button>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent className="max-w-[1200px]">
          <DialogHeader>
            <DialogTitle>Edit Webhook Response</DialogTitle>
            <small>Change your webhook response here.</small>
          </DialogHeader>
          <EditResponsePopoverContent id={id} response={response} setDialogOpen={setDialogOpen} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

interface EditResponseValues {
  statusCode: number;
  body: string;
  headers: { key: string; value: string }[];
}

interface EditResponsePopoverContentProps {
  id: string;
  response: WebhookResponse;
  setDialogOpen: (open: boolean) => void;
}

const EditResponsePopoverContent: React.FC<EditResponsePopoverContentProps> = ({ id, response, setDialogOpen }) => {
  const queryClient = useQueryClient();

  const updateWebhookMutation = ApiMutation.useUpdateWebhook({
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Webhook response updated.",
      });
      queryClient.invalidateQueries({ queryKey: [["webhook", "info"], { webhook_id: id }] });
      setDialogOpen(false);
    },
    onError: () => {
      toast({
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request. Please try again.",
      });
    },
  });

  const initialValues: EditResponseValues = {
    statusCode: response.statusCode,
    body: response.body,
    headers: Object.keys(response.headers).map((key) => ({ key, value: response.headers[key] })),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: EditResponseValues) => {
      updateWebhookMutation.mutate({
        webhook_id: id,
        response: {
          statusCode: parseInt(values.statusCode.toString()), // For some reason, Yup number is not number type
          headers: values.headers.reduce((acc, header) => ({ ...acc, [header.key]: header.value }), {}),
          body: values.body,
        },
      });
    },
  });

  const extension = mime.getExtension(
    formik.values.headers.find((header) => header.key === "Content-Type" || header.key === "content-type")?.value ??
      "text/plain"
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold">Response Code</p>
                <Input
                  variant={"neu-flat"}
                  id="statusCode"
                  name="statusCode"
                  type="number"
                  maxLength={3}
                  value={formik.values.statusCode}
                  onChange={formik.handleChange}
                  className={cn(formik.touched.statusCode && formik.errors.statusCode && "bg-red-200")}
                />
              </div>

              {/* headers array */}
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-semibold">Response Headers</p>
                  <FieldArray name="headers">
                    {({ push, remove }) => (
                      <>
                        {formik.values.headers.map((header, index) => (
                          <div key={index} className="flex flex-row gap-2">
                            <Input
                              variant={"neu-flat"}
                              id={`headers[${index}].key`}
                              name={`headers[${index}].key`}
                              value={header.key}
                              onChange={formik.handleChange}
                              className={cn(
                                formik.touched.headers &&
                                  formik.touched.headers[index] &&
                                  formik.errors.headers &&
                                  formik.errors.headers[index] &&
                                  "bg-red-200"
                              )}
                            />
                            <Input
                              variant={"neu-flat"}
                              id={`headers[${index}].value`}
                              name={`headers[${index}].value`}
                              value={header.value}
                              onChange={formik.handleChange}
                              className={cn(
                                formik.touched.headers &&
                                  formik.touched.headers[index] &&
                                  formik.errors.headers &&
                                  formik.errors.headers[index] &&
                                  "bg-red-200"
                              )}
                            />
                            <Button
                              type="button"
                              variant={"ghost"}
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                            >
                              <Trash2 className="w-4 h-4 text-red-600" />
                            </Button>
                          </div>
                        ))}
                        <Button
                          scheme="black"
                          size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ key: "", value: "" });
                          }}
                          className="w-16"
                        >
                          <Plus className="w-5" />
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full w-full">
              <p className="text-sm font-semibold">Response Body</p>
              <CodeBlock
                code={formik.values.body}
                language={extension ?? "text"}
                minHeight={300}
                className="min-h-[300px]"
                resizable={false}
                readonly={false}
                handleCodeChange={(code: string | undefined) => {
                  formik.setFieldValue("body", code);
                }}
              />
              {formik.touched.body && formik.errors.body && (
                <p className="text-xs text-red-600">Response body is required.</p>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              type="submit"
              size="sm"
              disabled={updateWebhookMutation.isPending}
              className="bg-primary-300 min-w-32"
            >
              {updateWebhookMutation.isPending ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};
