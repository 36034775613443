import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ApiMutation } from "@/api";
import { useToast } from "@/components/ui/use-toast";
import { Card } from "@/components/ui/card";
import { LoadingSpinner } from "@/components/loading-spinner";
import { defaultError } from "../utils/default-error-message";
import { ArrowRight } from "lucide-react";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { cn } from "@/utils/ui.util";
import { Link } from "react-router-dom";

const validationSchema = Yup.object().shape({
  companyName: Yup.string().min(2, "Must have at least 2 characters").max(50, "Too long").required("Required"),
  contactPerson: Yup.string().min(2, "Must have at least 2 characters").max(50, "Too long").required("Required"),
  contactEmail: Yup.string().email("Invalid email").required("Required"),
  contactPhone: Yup.string().min(6, "Invalid phone number").max(15, "Invalid phone number").required("Required"),
});

interface AccountRegistrationDetails {
  companyName: string;
  contactPerson: string;
  contactEmail: string;
  contactPhone: string;
}

interface AWSMarketplaceFormProps {
  title: string;
  description: string;
  regToken: string | undefined;
  setStep: (step: number) => void;
  setName: (name: string) => void;
  setEmail: (email: string) => void;
}

export const AWSMarketplaceAccountForm: React.FC<AWSMarketplaceFormProps> = ({
  title,
  description,
  regToken,
  setStep,
  setName,
  setEmail,
}) => {
  const { toast } = useToast();

  const registerMarketplaceSubscriberMutation = ApiMutation.useRegisterMarketplaceSubscriber({
    onSuccess: () => {
      setStep(1);
    },
    onError: (error) => {
      const message = error.response?.data as string;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const initialValues: AccountRegistrationDetails = {
    companyName: "",
    contactPerson: "",
    contactEmail: "",
    contactPhone: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: AccountRegistrationDetails) => {
      if (!regToken) {
        toast({
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request. Please try again.",
        });
        return;
      }

      setName(values.contactPerson);
      setEmail(values.contactEmail);

      registerMarketplaceSubscriberMutation.mutate({
        companyName: values.companyName,
        contactPerson: values.contactPerson,
        contactEmail: values.contactEmail,
        contactPhone: values.contactPhone,
        regToken: regToken,
      });
    },
  });

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex flex-col sm:flex-row justify-center items-center sm:gap-4">
        <div className="flex items-center gap-3">
          <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
          <h3 className="font-bold font-mono">wt.dev</h3>
        </div>
        <p className="text-sm mt-1">&#x2715;</p>
        <div className="flex items-center gap-2">
          <h4 className="mt-1">AWS Marketplace</h4>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small>{description}</small>
      </div>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1.5">
              <Input
                id="companyName"
                placeholder="Company"
                type="text"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                required
                value={formik.values.companyName}
                onChange={formik.handleChange}
                className={cn(formik.touched.companyName && formik.errors.companyName && "bg-red-200")}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <p className="text-xs">{formik.errors.companyName}</p>
              )}
              <Input
                id="contactPerson"
                placeholder="Name"
                type="text"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                required
                value={formik.values.contactPerson}
                onChange={formik.handleChange}
                className={cn(formik.touched.contactPerson && formik.errors.contactPerson && "bg-red-200")}
              />
              {formik.touched.contactPerson && formik.errors.contactPerson && (
                <p className="text-xs">{formik.errors.contactPerson}</p>
              )}
              <Input
                id="contactEmail"
                placeholder="Email Address"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                required
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                className={cn(formik.touched.contactEmail && formik.errors.contactEmail && "bg-red-200")}
              />
              {formik.touched.contactEmail && formik.errors.contactEmail && (
                <p className="text-xs">{formik.errors.contactEmail}</p>
              )}
              <Input
                id="contactPhone"
                placeholder="Phone"
                type="tel"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                value={formik.values.contactPhone}
                onChange={formik.handleChange}
                className={cn(formik.touched.contactPhone && formik.errors.contactPhone && "bg-red-200")}
              />
              {formik.touched.contactPhone && formik.errors.contactPhone && (
                <p className="text-xs">{formik.errors.contactPhone}</p>
              )}
            </div>
            <Button
              type="submit"
              scheme="black"
              size="sm"
              disabled={registerMarketplaceSubscriberMutation.isPending}
              className="bg-secondary-950"
            >
              {registerMarketplaceSubscriberMutation.isPending ? (
                <LoadingSpinner message={"Creating..."} />
              ) : (
                <div className="flex items-center gap-1">
                  Continue
                  <ArrowRight size={14} />
                </div>
              )}
            </Button>
          </div>
        </form>
      </FormikProvider>
      <div className="flex justify-center gap-2 text-sm">
        <small>Already have an account?</small>
        <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign In</small>
        </Link>
      </div>
    </Card>
  );
};
