import React from "react";
import { ApiMutation } from "@/api";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@/auth/useAuth";
import { LoadingSpinner } from "@/components/loading-spinner";

export const GithubVerify: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [triggered, setTriggered] = React.useState(false);

  const verificationToken = searchParams.get("token");

  const verifyGithubUserMutation = ApiMutation.userVerifyGithubUser({
    onSuccess: (data) => {
      if (data.token) {
        login(data.token);
        navigate("/dashboard", { replace: true });
      }
    },
    onError: (error) => {
      console.log("Error verifying Github user");
      console.error(error);
      navigate("/auth", { replace: true });
    },
  });

  React.useEffect(() => {
    if (verificationToken && !triggered && !verifyGithubUserMutation.isPending) {
      setTriggered(true);
      verifyGithubUserMutation.mutate({ token: verificationToken });
    }
  }, [verificationToken, verifyGithubUserMutation, triggered, setTriggered]);

  if (!verificationToken) {
    return <Navigate to="/auth" replace={true} />;
  }

  return <LoadingSpinner message={"Verifying..."} />;
};
