import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ApiMutation } from "@/api";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/auth/useAuth";
import { Card } from "@/components/ui/card";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useFormik } from "formik";
import { defaultError } from "./utils/default-error-message";
import { Link } from "react-router-dom";
import { SignInWithGithub } from "@/lib/auth/github/sign-in-with-github";

interface LoginFormProps {
  title: string;
  description: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { login } = useAuth();

  const loginMutation = ApiMutation.useLoginUser({
    onSuccess: (data) => {
      if (data.token) {
        // Login if token is present
        login(data.token);
        navigate("/dashboard");
      } else {
        // Navigate to verify page if token is not present
        navigate("/auth/verify", { state: { email: formik.values.email } });
      }
    },
    onError: (error) => {
      const message = (error.response?.data as { message?: string })?.message;

      if (message) {
        toast({ title: "Oops!", description: message });
      } else {
        toast(defaultError);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      loginMutation.mutate({
        email: values.email,
        password: values.password,
      });
    },
  });

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex items-center gap-3">
          <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
          <h3 className="font-bold font-mono">wt.dev</h3>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small>{description}</small>
      </div>

      <div className="flex flex-col gap-4">
        <SignInWithGithub />

        <div className="flex justify-center">
          <div className="flex items-center w-11/12 gap-2">
            <hr className="w-full border-t border-gray-500" />
            <span className="text-muted-foreground text-gray-800">or</span>
            <hr className="w-full border-t border-gray-500" />
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1.5">
              <Input
                id="email"
                placeholder="Email Address"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <Input
                id="password"
                placeholder="Password"
                type="password"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                required
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </div>
            <Button
              type="submit"
              scheme="black"
              size="sm"
              disabled={loginMutation.isPending}
              className="bg-secondary-950"
            >
              {loginMutation.isPending ? <LoadingSpinner message={"Signing In"} /> : "Sign In"}
            </Button>
          </div>
        </form>
      </div>
      <div className="flex justify-center gap-2 text-sm">
        <small>Don't have an account?</small>

        <Link to="/auth/register" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign Up</small>
        </Link>
      </div>
      <div className="flex justify-center gap-2 text-sm">
        <Link to="/auth/forgot-password" className="underline hover:cursor-pointer text-secondary-950">
          <small>Forgot Password?</small>
        </Link>
      </div>
    </Card>
  );
};
