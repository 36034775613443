import React from "react";
import { ApiMutation } from "@/api";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { DynamicWebhookBuilder } from "@/lib/webhooks/builder/dynamic-webhook-builder";
import { RecentWebhooksList } from "@/lib/webhooks/dashboard/private/elements/recent-webhooks-list";

export const PrivateDashboard: React.FC = () => {
  const navigate = useNavigate();

  const webhookMutation = ApiMutation.useCreateWebhook({
    onSuccess: (data) => {
      navigate(`/webhooks/${data.id}`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <div className="flex flex-col space-y-2">
        <h4 className="font-bold tracking-tight">Webhooks</h4>
        <small>
          Monitor and test your system's webhook responses in real time. Create temporary webhook endpoints to capture
          and inspect the data your systems sends.
        </small>
      </div>
      <div className="flex flex-col gap-2 2xl:gap-6">
        <div className="flex gap-2">
          <Button
            className="bg-primary-300"
            size="sm"
            onClick={webhookMutation.mutate}
            disabled={webhookMutation.isPending}
          >
            {webhookMutation.isPending ? "Creating Webhook..." : "Create Webhook"}
          </Button>

          <Button size="sm" onClick={() => setDialogOpen(true)}>
            Create Sequential Webhook
          </Button>

          <DynamicWebhookBuilder dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
        </div>
        <div>
          <RecentWebhooksList />
        </div>
      </div>
    </>
  );
};
