import { parseISO, intervalToDuration } from "date-fns";
import React from "react";

interface JobTimerProps {
  startTime: string;
  endTime: string | null;
}

export const JobTimer: React.FC<JobTimerProps> = ({ startTime, endTime }) => {
  const [currentTime, setCurrentTime] = React.useState(new Date());

  React.useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (!endTime) {
      timerId = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
    } else {
      setCurrentTime(parseISO(endTime));
    }

    return () => clearInterval(timerId);
  }, [endTime]);

  const duration = intervalToDuration({
    start: parseISO(startTime),
    end: currentTime,
  });

  const formattedTime = `${duration.hours ? `${duration.hours}h ` : ""}${duration.minutes ? `${duration.minutes}m ` : ""}${duration.seconds ? `${duration.seconds}s` : ""}`;

  return <div>{formattedTime}</div>;
};
