import React from "react";
import { supportedContentTypes } from "@/enums/supported-content-types";
import { FormikContextType } from "formik";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CodeBlock } from "@/components/code-block";
import { DynamicWebhookResponse } from "@/lib/webhooks/builder/dynamic-webhook-builder";
import { DynamicWebhookPipeline } from "@/lib/webhooks/builder/dynamic-webhook-builder-content";

export interface DynamicWebhookItemContentProps {
  index: number;
  item: DynamicWebhookResponse;
  formik: FormikContextType<DynamicWebhookPipeline>;
}

export const DynamicWebhookItemContent: React.FC<DynamicWebhookItemContentProps> = ({ index, item, formik }) => {
  return (
    <div className="w-full">
      <div className="px-4 pt-2">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-full">
              <Label htmlFor="responseCode" className="text-xs">
                Response Code
              </Label>
              <Input
                name={`items[${index}].responseCode`}
                id={`items[${index}].responseCode`}
                type="number"
                variant="neu-flat"
                value={item.responseCode}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-full">
              <Label htmlFor="delay" className="text-xs">
                Response Delay
              </Label>
              <Input
                name={`items[${index}].delay`}
                id={`items[${index}].delay`}
                type="number"
                variant="neu-flat"
                value={item.delay}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <Label htmlFor="contentType" className="text-xs">
                Content-Type
              </Label>
              <Select
                name={`items[${index}].contentType`}
                value={item.contentType}
                onValueChange={(value) => {
                  formik.setFieldValue(`items[${index}].contentType`, value);
                }}
              >
                <SelectTrigger>
                  <SelectValue id={`items[${index}].contentType`} placeholder="Content-Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Content Type</SelectLabel>
                    {Object.keys(supportedContentTypes).map((type) => {
                      return (
                        <SelectItem key={type} value={type}>
                          {type.toUpperCase()}
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <CodeBlock
              code={item.responseData}
              language={item.contentType ?? "text"}
              minHeight={200}
              resizable={false}
              readonly={false}
              handleCodeChange={(code: string | undefined) => {
                formik.setFieldValue(`items[${index}].responseData`, code);
              }}
              className="min-h-[200px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
