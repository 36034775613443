import React from "react";
import { useRequest } from "@/api/queries";
import { WebhookResponse } from "@/api/types/response";
import { CodeBlock } from "@/components/code-block";
import { LoadingSpinner } from "@/components/loading-spinner";
import { getContentSizeInKB } from "@/lib/webhooks/utils/webhook-utils.ts";
import { HeadersTable } from "@/components/headers-table";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { Separator } from "@/components/ui/separator";
import mime from "mime";

interface ResponseDisplayProps {
  id: string;
  requestId: string;
}

export const ResponseDisplay: React.FC<ResponseDisplayProps> = ({ id, requestId }) => {
  const { isPending, isError, data } = useRequest({
    webhook_id: id,
    request_id: requestId,
  });

  return (
    <>
      {isPending && (
        <div className="p-8 text-center text-sm font-medium">
          <LoadingSpinner message="Loading..." />
        </div>
      )}
      {isError && <p className="p-8 text-center text-sm font-medium">Error loading message...</p>}
      {!isPending && !isError && data && <ResponseDisplayContent response={data.response} />}
    </>
  );
};

interface ResponseDisplayContentProps {
  response: WebhookResponse;
}

export const ResponseDisplayContent: React.FC<ResponseDisplayContentProps> = ({ response }) => {
  const contentType = React.useMemo(() => {
    return response.headers["content-type"] || response.headers["Content-Type"] || "text/paint";
  }, [response.headers]);

  const contentSize = React.useMemo(() => {
    return getContentSizeInKB(response.body, contentType);
  }, [response.body, contentType]);

  const responseDetails = React.useMemo(() => {
    return {
      "Response Code": response.statusCode,
      Size: contentSize,
      "Content-Type": contentType,
    };
  }, [response.statusCode, contentSize, contentType]);

  const extension = mime.getExtension(contentType);

  return (
    <div className="flex flex-col">
      <ContentPanel details={responseDetails} response={response} />
      <Separator />
      <div className="flex flex-col gap-4 p-4">
        <p className="text-sm font-semibold">Response Body</p>
        <CodeBlock code={response.body} language={extension ?? "text"} minHeight={300} />
      </div>
    </div>
  );
};

interface ContentPanelProps {
  details: { [key: string]: string | string[] | React.ReactNode | undefined };
  response: WebhookResponse;
}

const ContentPanel: React.FC<ContentPanelProps> = ({ details, response }) => {
  return (
    <>
      <div className="sm:hidden">
        <div className="flex flex-col gap-2">
          <HeadersTable title={"Response Details"} headers={details} type="custom" />
          <Separator />
          <HeadersTable title={"Response Headers"} headers={response.headers} type="http-headers" />
        </div>
      </div>
      <div className="hidden sm:block">
        <ResizablePanelGroup direction="horizontal" className="max-h-[500px]">
          <ResizablePanel defaultSize={50} minSize={25}>
            <HeadersTable title={"Response Details"} headers={details} type="custom" />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={50} minSize={25}>
            <HeadersTable title={"Response Headers"} headers={response.headers} type="http-headers" />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </>
  );
};
