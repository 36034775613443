import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { AWSMarketplaceAccountForm } from "@/lib/auth/marketplace/aws-marketplace-account-form";
import { AWSMarketplaceUserForm } from "@/lib/auth/marketplace/aws-marketplace-user-form";
import StepperIndicator from "@/lib/auth/elements/stepper-indicator";

export const AWS: React.FC = () => {
  const [searchParams] = useSearchParams();
  const regToken = searchParams.get("x-amzn-marketplace-token");

  const [step, setStep] = React.useState<number>(0);

  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");

  if (!regToken) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="flex flex-col items-center gap-6 w-full">
      <StepperIndicator activeStep={step} />
      {step === 0 && (
        <AWSMarketplaceAccountForm
          title={"Account Details"}
          description={"Please enter your account details."}
          regToken={regToken ? regToken : undefined}
          setStep={setStep}
          setName={setName}
          setEmail={setEmail}
        />
      )}
      {step === 1 && <AWSMarketplaceUserForm emailPrefill={email} namePrefill={name} regToken={regToken} />}
    </div>
  );
};
